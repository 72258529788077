import * as React from "react";
import { LoginPage } from "../../components/pages/login";
import { post } from "../../lib/request";
import { useNavigate } from "react-router-dom";
import { LoginRequest, LoginResponse } from "../../../../server/types/request/login";
import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError } from "axios";
import { endpoint } from "../../../../server/router/endpoint";
import { NoMenuNavigationBar } from "../../components/parts/nomenu-navigation-bar";
import { clientEndpoint } from "../../routes/endpoint";
import { ErrorObject } from "../../../../server/types/error";

export const LoginContainter = () => {
	const navigate = useNavigate();
	const [error, setError] = React.useState<string>();
	const [errors, setErrors] = React.useState<ErrorObject>();
	const onLogin = React.useCallback(
		(email: string, password: string) => {
			const request: LoginRequest = { email, password };
			post<LoginResponse>(endpoint.login, request)
				.then((response) => {
					if (response.data.account) {
						navigate(response.data.redirectUrl || "/");
						setError(undefined);
						setErrors(undefined);
					}
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					setError(error.response.data.message);
					setErrors(error.response.data.errors);
				});
		},
		[navigate],
	);
	const onPasswordChangePage = React.useCallback(() => {
		navigate(clientEndpoint.passwordResetMail);
	}, [navigate]);
	return (
		<>
			<NoMenuNavigationBar />
			<LoginPage onLogin={onLogin} error={error} errors={errors} onPasswordChangePage={onPasswordChangePage} />
		</>
	);
};
