import * as React from "react";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { strategyGroupCategoryLabels, strategyTargetGroupLabels } from "../../../../../../../label/activity";
import { makeAgeLabel, makePriceLabel } from "../../../../../../../server/lib/quota/concept-quota";
import {
	BeautyType,
	StrategyGroup,
	Married,
	Children,
	Occupation,
	SkinAttribute,
	Fragrance,
	Channel,
	SensitiveSkin,
	FoundationType,
	FacewashFunction,
} from "../../../../../../../server/models/activity";
import { SurveyChoice } from "../../../../../../../server/models/shared";
import { Checkbox } from "../../../input";
import { ConditionsTable } from "../.";

const ConditionsWrapper = styled.div`
	table {
		margin-bottom: 16px;
	}

	.form-check {
		margin-bottom: 16px;
	}

}
`;

const CategoryCondition = styled.div`
	margin-top: calc(16px + 0.75em);
	margin-bottom: 16px;

	.inner {
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		padding-bottom: 8px;
		padding-left: 16px;
		padding-right: 8px;
		padding-top: calc(8px + 0.75em);
		position: relative;

		td:nth-child(2) {
			cursor: defauly;
			padding: 0 8px;
			user-select: none;
		}

		.table-wrapper {
			margin-left: 32px;
		}

		.title {
			background-color: white;
			left: 8px;
			position: absolute;
			padding: 0 8px;
			top: -0.75em;
		}

		.text {
			margin-top: 8px;
			margin-bottom: 16px;
		}
	}
`;

const strategyConditionProps: (target: StrategyGroup) => { label: string; value: string }[] = (target) => {
	const categories = target.category
		? target.category.map((c, i) => {
				const surveyChoie = c.category.surveyChoice.find((sc) => sc.value === c.surveyChoice);
				return { label: i == 0 ? strategyTargetGroupLabels.category : "", value: surveyChoie.label };
		  })
		: [];

	return [{ label: strategyTargetGroupLabels.age, value: makeAgeLabel(target.age) }].concat(categories).filter(Boolean);
};

const strategyConditionsDetailsProps = (strategyTargetGroup: StrategyGroup): { label: string; value: string }[] => {
	const list = [{ label: strategyTargetGroupLabels.age, value: makeAgeLabel(strategyTargetGroup.age) }];
	list.push(
		...(strategyTargetGroup.married && !!strategyTargetGroup.married.length
			? strategyTargetGroup.married.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.married : "",
						value: Married[target],
					};
			  })
			: []),
		...(strategyTargetGroup.children && !!strategyTargetGroup.children.length
			? strategyTargetGroup.children.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.children : "",
						value: Children[target],
					};
			  })
			: []),
		...(strategyTargetGroup.occupation && !!strategyTargetGroup.occupation.length
			? strategyTargetGroup.occupation.map((target, i): { label: string; value: Occupation } => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.occupation : "",
						value: Occupation[target],
					};
			  })
			: []),
		...(strategyTargetGroup.skinAttribute && !!strategyTargetGroup.skinAttribute.length
			? strategyTargetGroup.skinAttribute.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.skinAttribute : "",
						value: SkinAttribute[target].toString(),
					};
			  })
			: []),
		...(strategyTargetGroup.sensitiveSkin && !!strategyTargetGroup.sensitiveSkin.length
			? strategyTargetGroup.sensitiveSkin.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.sensitiveSkin : "",
						value: SensitiveSkin[target].toString(),
					};
			  })
			: []),
		...(strategyTargetGroup.fragrance && !!strategyTargetGroup.fragrance.length
			? strategyTargetGroup.fragrance.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.fragrance : "",
						value: Fragrance[target].toString(),
					};
			  })
			: []),
		...(strategyTargetGroup.channel && !!strategyTargetGroup.channel.length
			? strategyTargetGroup.channel.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.channel : "",
						value: Channel[target].toString(),
					};
			  })
			: []),
		...(strategyTargetGroup.foundationType
			? strategyTargetGroup.foundationType.map((target, i) => {
					return {
						label: i == 0 ? strategyTargetGroupLabels.foundationType : "",
						value: FoundationType[target].toString(),
					};
			  })
			: []),
		...(strategyTargetGroup.facewashFunction
			? [
					{
						label: strategyTargetGroupLabels.facewashFunction,
						value: FacewashFunction[strategyTargetGroup.facewashFunction].toString(),
					},
			  ]
			: []),
	);
	return list.filter(Boolean);
};

export const surveyChoiceProps = (
	strategyTargetGroup: StrategyGroup,
): { surveyChoice: SurveyChoice; tableProps: { label: string; value: string }[] }[] => {
	return strategyTargetGroup.category.map((cate) => {
		const surveyChoice = cate.category.surveyChoice.find((sc) => sc.value === cate.surveyChoice);
		return {
			surveyChoice: surveyChoice,
			tableProps: [
				cate.price
					? {
							label: strategyGroupCategoryLabels.price,
							value: makePriceLabel(cate.price),
					  }
					: undefined,
				...(cate.beautyType && !!cate.beautyType.length
					? cate.beautyType.map((target, i) => {
							return {
								label: i == 0 ? strategyGroupCategoryLabels.beautyType : "",
								value: BeautyType[target].toString(),
							};
					  })
					: []),
				...(cate.usedBrand && !!cate.usedBrand.length
					? cate.usedBrand.map((brand, i) => {
							return {
								label: i == 0 ? strategyGroupCategoryLabels.usedBrand : "",
								value: brand,
							};
					  })
					: []),
				...(cate.unusedBrand && !!cate.unusedBrand.length
					? cate.unusedBrand.map((brand, i) => {
							return {
								label: i == 0 ? strategyGroupCategoryLabels.unusedBrand : "",
								value: brand,
							};
					  })
					: []),
			].filter(Boolean),
		};
	});
};

export type StrategyConditionsProps = {
	strategyTargetGroup: StrategyGroup;
};

export const StrategyConditions: FunctionComponent<StrategyConditionsProps> = ({ strategyTargetGroup }) => {
	const [showDetails, setShowDetails] = useState(false);
	const props = strategyConditionProps(strategyTargetGroup);
	const detailsProps = strategyConditionsDetailsProps(strategyTargetGroup);
	const scProps = surveyChoiceProps(strategyTargetGroup);

	return (
		<ConditionsWrapper>
			<Checkbox
				onChange={(checked) => setShowDetails(checked)}
				checked={showDetails}
				label="詳細を表示"
				id="show-conditions-detail"
			></Checkbox>
			<ConditionsTable tableProps={props} style={{ display: showDetails ? "none" : "block" }} />
			<ConditionsTable tableProps={detailsProps} style={{ display: showDetails ? "block" : "none" }} />

			<CategoryCondition style={{ display: showDetails ? "block" : "none" }}>
				<div className="inner">
					<div className="title">カテゴリ条件</div>
					<div>
						<div className="text">以下カテゴリ間の条件を{strategyTargetGroup.categoryOrAnd}条件で設定します。</div>
						<ul>
							{scProps.map(({ surveyChoice, tableProps }) => (
								<li key={surveyChoice.value}>
									<div className="label-area">{surveyChoice.label}</div>
									<div className="table-wrapper">
										<ConditionsTable key={surveyChoice.value} tableProps={tableProps} />
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</CategoryCondition>
		</ConditionsWrapper>
	);
};
