import * as React from "react";
import { reducer, initState } from "../../../reducers/activity/list";
import { ActivityPage } from "../../../components/pages/activity";
import { useNavigate } from "react-router-dom";
import { ObjectId } from "mongodb";
import { get, makeError, remove } from "../../../lib/request";
import { GetActivitiesResponse, LoadActivityResponse } from "../../../../../server/types/request/activity";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { clientEndpoint } from "../../../routes/endpoint";

export const ActivityListContainer = (loginAccount: AccountWithoutPassword) => {
	const navigate = useNavigate();

	const [state, dispatch] = React.useReducer(reducer, { ...initState });
	const { activities, brandOptions, categoryOptions, searches, accounts, info, loading, yearOptions } = state;

	React.useEffect(() => {
		get<LoadActivityResponse>(endpoint.laodActivity, state.searches)
			.then((response) => {
				dispatch({ type: "loadActivity", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
		// WARNING: KEEP THE DEPENDENCY ARRAY EMPTY - adding state.searches will break it.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeSearchValue = React.useCallback((name: string, value: string | number) => {
		dispatch({ type: "changeSearch", payload: { name, value } });
	}, []);
	const onEditPage = React.useCallback(
		(id?: string | ObjectId) =>
			navigate(replaceEndpointUrl(clientEndpoint.activityCreate, { activityId: id ? id.toString() : "new" })),
		[navigate],
	);
	const onStatusPage = React.useCallback(
		(id?: string | ObjectId) =>
			navigate(replaceEndpointUrl(clientEndpoint.activityStatus, { activityId: id ? id.toString() : "new" })),
		[navigate],
	);
	const onJobListPage = React.useCallback(
		(id: string | ObjectId) => navigate(replaceEndpointUrl(clientEndpoint.job, { activityId: id.toString() })),
		[navigate],
	);
	const onSearch = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		get<GetActivitiesResponse>(endpoint.activity, searches)
			.then((response) => {
				dispatch({ type: "setActivity", payload: response.data.activities });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [searches]);
	const onDeleteActivity = React.useCallback((id: string | ObjectId) => {
		remove(`${endpoint.activity}/${id}`)
			.then(() => {
				dispatch({ type: "deleteActivity", payload: id });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"処理"} />
			<ActivityPage
				activities={activities}
				accounts={accounts}
				loginAccount={loginAccount}
				brandOptions={brandOptions}
				yearOptions={yearOptions}
				categoryOptions={categoryOptions}
				searchValues={searches}
				onChangeSearchValue={onChangeSearchValue}
				onSearch={onSearch}
				onStatusPage={onStatusPage}
				onEditPage={onEditPage}
				onJobListPage={onJobListPage}
				onDeleteActivity={onDeleteActivity}
			/>
		</>
	);
};
