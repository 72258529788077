import React, { memo, useCallback, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";

import { Info } from "../../../../../server/models/info";

import { ConfirmButton } from "../confirm-dropdown-button";
import { TextRed } from "../font";

import { ReadOnlyInfoContent } from "./read-only-info-content";
import { AccordionIcon } from "./accordion-icon";
import { PastInfoAccordionToggle } from "./past-info-accordion-toggle";

type Props = {
	className?: string;
	onCopy: (content: string) => void;
	onDelete: (id: string) => void;
	info: Info;
};

const _PastInfo = (props: Props) => {
	const { className, onCopy, onDelete, info } = props;

	// states

	const [isShow, setIsShow] = useState(false);

	// handlers

	const handleDelete = useCallback(() => {
		onDelete(String(info._id));
	}, [info._id, onDelete]);

	const handleCopy = useCallback(() => {
		onCopy(info.content);
	}, [info.content, onCopy]);

	const handleAccordionToggle = useCallback(() => {
		setIsShow((prev) => !prev);
	}, []);

	return (
		<Card className={className}>
			<Accordion>
				<PastInfoAccordionToggle eventKey={String(info._id)} onClick={handleAccordionToggle}>
					<div className="d-flex justify-content-between">
						<div>
							<AccordionIcon isShow={isShow} className="me-3" />
							<span className="h4" style={{ borderBottom: "1px solid #ddd" }}>
								{info.title}
							</span>
						</div>
						<div>
							{new Date(info.timestamp).toLocaleString("ja-JP", {
								timeZone: "Asia/Tokyo",
								day: "numeric",
								month: "short",
								year: "numeric",
								hour: "numeric",
								minute: "2-digit",
							})}{" "}
							更新
						</div>
					</div>
				</PastInfoAccordionToggle>
				<Accordion.Collapse eventKey={String(info._id)}>
					<>
						<Card.Body>
							<ReadOnlyInfoContent content={info.content} />
						</Card.Body>
						<Card.Footer className="d-flex justify-content-between">
							<Button onClick={handleCopy} variant="outline-secondary">
								本文をエディタにコピー
							</Button>
							<ConfirmButton
								body={
									<>
										<p>「{info.title}」を削除してよろしいでしょうか。</p>
										<p>
											<TextRed>※一度削除すると、データ復旧できませんので再度ご確認ください。</TextRed>
										</p>
									</>
								}
								buttonText="削除"
								onOk={handleDelete}
								title="お知らせ削除"
								variant="secondary"
							/>
						</Card.Footer>
					</>
				</Accordion.Collapse>
			</Accordion>
		</Card>
	);
};

export const PastInfo = memo(_PastInfo);
