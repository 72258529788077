import * as React from "react";
import { ReportTitle } from "../../parts/report-title";
import { ActionStandardTable, SummaryProps } from "./action-standard-table";
import { ConceptType, ConceptKey } from "../../../../../../../server/models/concept";
import {
	DemandForecast,
	OneNumberScoreNorm,
	OneNumberScoreData,
	OneNumberTarget,
} from "../../../../../../../server/models/graph/demand-forecast";
import {
	getAnalyticsVersion,
	overTagetVersion,
	judgeNorm,
	judgeOneNumberScore,
} from "../../../../../lib/action-standard";
import { Job } from "../../../../../../../server/models/job";
import { ElementEvaluation } from "../../../../../../../server/models/graph/element-evaluation";
import { ActionStandardTableVersion5 } from "./action-standard-table/v5";

import { isDefined } from "class-validator";
import { TargetGroup } from "../../../../../../../server/models/graph/target-group";

const findEvaluationScore = (
	evaluations: ElementEvaluation[],
	targetGroup: TargetGroup,
	concept: ConceptKey | "norm",
	evaluationItemLabel: string,
): number | undefined => {
	const target = evaluations.find((ons) => ons.target === targetGroup && ons.conceptType === concept);
	if (!target) return;
	const targetItem = target.evaluvationItem.find((v) => v.label === evaluationItemLabel);
	if (!targetItem) return;
	if (targetItem.val == null) return;
	return targetItem.val;
};

export type ActionStandardData = SummaryProps & {
	conceptType: keyof typeof ConceptType;
};
export type ActionStandardProps = {
	demandForecast: DemandForecast[];
	oneNumberScoreNorm?: OneNumberScoreNorm;
	goalSalesVolume: number;
	oneNumberScores: OneNumberScoreData[];
	job: Job;
	evaluations: ElementEvaluation[];
};

export const ActionStandard = React.memo((props: ActionStandardProps) => {
	const { demandForecast, oneNumberScoreNorm, oneNumberScores, goalSalesVolume, job, evaluations } = props;
	const overVersion5 = React.useMemo(() => overTagetVersion(getAnalyticsVersion(job.endDatetime), "v5"), [job]);
	const data = React.useMemo<ActionStandardData[]>(() => {
		const baseLine = (goalSalesVolume * 50) / 100;
		const map = oneNumberScores.reduce(
			(a, b) => {
				if (!(b.conceptType in a)) a[b.conceptType] = {};
				a[b.conceptType][b.target] = b;
				return a;
			},
			{} as {
				[concept in ConceptKey]?: { [target in OneNumberTarget]?: OneNumberScoreData };
			},
		);
		return demandForecast.map((df) => {
			const tmp: ActionStandardData = {
				label: ConceptType[df.conceptType],
				conceptType: df.conceptType,
				potential: df.sellPotential.total >= baseLine ? "〇" : "×",
				job,
			};
			const categoryConceptScore =
				map[df.conceptType] && map[df.conceptType].category ? map[df.conceptType].category : undefined;
			const strategyConceptScore =
				map[df.conceptType] && map[df.conceptType].strategy ? map[df.conceptType].strategy : undefined;
			if (!categoryConceptScore && !strategyConceptScore) return tmp;
			if (categoryConceptScore && oneNumberScoreNorm) {
				tmp.norm = judgeNorm(categoryConceptScore.score, oneNumberScoreNorm);
			}
			const targetScore = findEvaluationScore(evaluations, "strategy", df.conceptType, "他の商品とは違っている");

			if (map.benchmark) {
				const { category, strategy } = map.benchmark;
				tmp.vsBenchmark = {};
				if (category) {
					tmp.vsBenchmark.category = judgeOneNumberScore(
						categoryConceptScore.score,
						category.score,
						category.sampleSize,
					);
				}
				if (strategy) {
					tmp.vsBenchmark.strategy = judgeOneNumberScore(
						strategyConceptScore.score,
						strategy.score,
						strategy.sampleSize,
					);
					const benchmarkScore = findEvaluationScore(evaluations, "strategy", "benchmark", "他の商品とは違っている");
					if (isDefined(targetScore) && isDefined(benchmarkScore)) {
						console.log({ targetScore, benchmarkScore });
						tmp.vsBenchmarkByOriginality = {};
						tmp.vsBenchmarkByOriginality.strategy = judgeOneNumberScore(
							targetScore,
							benchmarkScore,
							strategy.sampleSize,
						);
					}
				}
			}
			if (map.currentProduct) {
				const { category, strategy } = map.currentProduct;
				tmp.vsCurrent = {};
				if (category) {
					tmp.vsCurrent.category = judgeOneNumberScore(categoryConceptScore.score, category.score, category.sampleSize);
				}
				if (strategy) {
					tmp.vsCurrent.strategy = judgeOneNumberScore(strategyConceptScore.score, strategy.score, strategy.sampleSize);
					const currentProductScore = findEvaluationScore(
						evaluations,
						"strategy",
						"currentProduct",
						"他の商品とは違っている",
					);
					if (isDefined(targetScore) && isDefined(currentProductScore)) {
						tmp.vsCurrentByOriginality = {};
						console.log({ targetScore, currentProductScore });
						tmp.vsCurrentByOriginality.strategy = judgeOneNumberScore(
							targetScore,
							currentProductScore,
							strategy.sampleSize,
						);
					}
				}
			}
			return tmp;
		});
	}, [demandForecast, oneNumberScoreNorm, oneNumberScores, goalSalesVolume, job, evaluations]);
	return (
		<>
			<ReportTitle>{overVersion5 ? "主要指標の結果" : "アクションスタンダード判定結果"}</ReportTitle>
			{data.map((d) =>
				overVersion5 ? (
					<ActionStandardTableVersion5 key={d.conceptType} evaluations={evaluations} {...d} />
				) : (
					<ActionStandardTable key={d.conceptType} {...d} />
				),
			)}
		</>
	);
});
