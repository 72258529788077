import * as React from "react";
import { ResizableOverlayProps, ResizableOverlay } from "../resizable-overlay";
import { Coordinate } from "../../../../models/coordinate";
import { Rect } from "../../../../models/rect";
import { Area } from "../../../../models/area";

export type ResizableImageProps = Omit<ResizableOverlayProps, "children" | "rect" | "onResized"> & {
	coordinate: Coordinate;
	src: string;
	width: number;
	onResized: (width: number) => void;
};
export const ResizableImage = React.memo((props: ResizableImageProps) => {
	const { src, width, coordinate, onResized, ...resizableOverlayProps } = props;
	const [loaded, setLoaded] = React.useState<boolean>();
	const [rect, setRect] = React.useState<Rect>({ width: 100, height: 100, ...coordinate });
	const [imageArea, setImageArea] = React.useState<Area>();

	// preload
	React.useEffect(() => {
		setLoaded(false);
		const image = new Image();
		image.src = src;
		image.onload = () => {
			setImageArea({ width: image.width, height: image.height });
			setLoaded(true);
		};
	}, [src]);
	React.useEffect(() => {
		if (!imageArea) return;
		setRect({
			...coordinate,
			width,
			height: (width / imageArea.width) * imageArea.height,
		});
	}, [imageArea, width, coordinate]);
	const imageRef = React.createRef<HTMLImageElement>();
	const onResizedOverlay = React.useCallback(() => {
		if (imageRef.current) {
			onResized(imageRef.current.width);
		}
	}, [onResized, imageRef]);
	if (loaded) {
		return (
			<ResizableOverlay fixed rect={rect} onResized={onResizedOverlay} {...resizableOverlayProps}>
				<img
					ref={imageRef}
					src={src}
					style={{ maxWidth: "100%", maxHeight: "100%", border: "1px solid #ddd", boxSizing: "unset" }}
				/>
			</ResizableOverlay>
		);
	}
	return <></>;
});
