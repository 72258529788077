import * as React from "react";
import { Col, Row } from "react-bootstrap";

import { Title } from "../../../../../../../react-components/lu-component/src/index";
import { type AdCopyConcept, AdCopyConceptTypeMap } from "../../../../../../../server/models/ad-copy/concept";

type Props = {
	concepts: AdCopyConcept[];
};

export const AdCopyConceptPreview = React.memo((props: Props) => {
	return (
		<>
			{props.concepts.map((concept, index) => (
				<Row key={`concept-${index}`}>
					<Col md={12}>
						<Title>{AdCopyConceptTypeMap[concept.type]}</Title>
					</Col>

					<Col md={{ offset: 1, span: 8 }}>
						<img src={concept.imageUrl} style={{ maxWidth: "1000px" }} />
					</Col>
				</Row>
			))}
		</>
	);
});
