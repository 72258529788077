import * as React from "react";

import { evaluateUseAdCopyBrandsFilter } from "../../../../../../server/lib/ad-copy/brand";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
// import { isNotEmptyArray } from "../../../../lib/ad-copy/common";
import { InTrialMessageWrap } from "./styled";

const classBase = `ad-copy-in-trial-message`;

type Props = {
	account: AccountWithoutPassword;
};

export const InTrialMessage = React.memo(({ account: _account }: Props) => {
	const [useAdCopyBrandsFilter, _setUseAdCopyBrandsFilter] = React.useState(evaluateUseAdCopyBrandsFilter());

	// const { availableAdCopyBrands } = account;

	return (
		useAdCopyBrandsFilter && (
			<InTrialMessageWrap>
				<div className={`${classBase}__container`}>
					<div className={`${classBase}__message`}>コピーテストは、特定のブランドに絞ってトライアル運用中です。</div>

					{/* NOTE: 2024-07-18 メールにてトライアル運用ブランドは非表示になった */}
					{/* {isNotEmptyArray(availableAdCopyBrands) && (
						<ul className={`${classBase}__brands`}>
							{availableAdCopyBrands.map(({ name }, i) => (
								<li key={i}>{name}</li>
							))}
						</ul>
					)} */}
				</div>
			</InTrialMessageWrap>
		)
	);
});
