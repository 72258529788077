import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { ObjectId } from "mongodb";

export type SettingMenuItem = {
	label: string;
	event: (id?: SettingIconMenuProps["id"]) => void;
};

export type SettingIconMenuProps = {
	id?: string | ObjectId;
	items: SettingMenuItem[];
};

export const SettingIconMenu = React.memo((props: SettingIconMenuProps) => {
	const { items, id } = props;
	return (
		<Dropdown>
			<Dropdown.Toggle as={"span"}>
				<FontAwesomeIcon icon={faCog} fixedWidth size={"lg"} />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{items.map(({ label, event }, index) => (
					<Dropdown.Item key={`item-${index}`} onClick={() => event(id)}>
						{label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
});
