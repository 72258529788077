export const targetGroup = {
	category: "カテゴリターゲットグループ",
	strategy: "戦略ターゲットグループ",
	general: "REP",
} as const;

export type TargetGroup = keyof typeof targetGroup;

export const targetKeys = Object.keys(targetGroup) as TargetGroup[];
export const targetGroupOptions = Object.entries(targetGroup).map(([value, label]) => {
	return { label, value } as { label: string; value: TargetGroup };
});
