import * as React from "react";
import { Option } from "../../../../../../../../react-components/lu-component/src/index";
import { MultipleSelect } from "../../../../../parts/select";
import { ObjectId } from "mongodb";
import { Category } from "../../../../../../../../server/models/category";

export type BrandSelectProps = {
	values?: (ObjectId | string)[];
	filteredValue?: (ObjectId | string)[];
	category: Category;
	onChange: (values: string[]) => void;
};
export const BrandSelect = React.memo((props: BrandSelectProps) => {
	const { values, category, onChange, filteredValue } = props;
	const brandOptions = React.useMemo<Option[]>(() => {
		const { brandlist } = category;
		if (!brandlist) return []; // 設定できない。
		return brandlist
			.filter((brand) => {
				return !filteredValue || !filteredValue.includes(brand.brandName);
			})
			.map((brand) => {
				return {
					value: brand.brandName,
					label: brand.brandName,
				};
			});
	}, [category, filteredValue]);
	return (
		<>
			<MultipleSelect
				options={brandOptions}
				value={(values as string[]) || []}
				onChange={(v) => onChange(v as string[])}
			/>
		</>
	);
});
