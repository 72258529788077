import * as React from "react";
import { Modal, Button, ButtonProps } from "react-bootstrap";

export type ConfirmInfoProps = {
	show: boolean;
	title: React.ReactNode;
	message: React.ReactNode;
	onClose: () => void;
	buttonText?: string;
	buttonVariant?: ButtonProps["variant"];
};
export const ConfirmInfo = React.memo((props: ConfirmInfoProps) => {
	const { show, title, message, onClose, buttonText = "閉じる", buttonVariant = "secondary" } = props;
	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header>
				<div style={{ textAlign: "left" }}>{title}</div>
			</Modal.Header>
			<Modal.Body>
				<div style={{ whiteSpace: "pre-wrap" }}>{message}</div>
			</Modal.Body>
			<Modal.Footer style={{ textAlign: "right" }}>
				<Button variant={buttonVariant} onClick={onClose}>
					{buttonText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
});
