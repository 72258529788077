/* eslint-disable react/jsx-newline */

import * as React from "react";
import { styled } from "styled-components";

import type { AdCopyResultDetailsResBody } from "../../../../../../../server/types/request/ad-copy/report/resultDetails";
import type { AdCopyResultByTargetAction } from "../../../../../types/actions";
import { AdCopyReportTitle } from "../parts/report-title";
import { AdCopyResultDetail } from "./detail";
import { AdCopyResultSummary } from "./summary";

const StyledDiv = styled.div`
	margin: 0px auto;
	padding: 0px 5px;
	max-width: 1200px;
`;

type Props = AdCopyResultDetailsResBody & {
	dispatch: React.Dispatch<AdCopyResultByTargetAction>;
};

export const AdCopyResultByTargeReportPage = React.memo((props: Props) => {
	const { activity, data, dispatch } = props;

	return (
		<StyledDiv>
			<AdCopyReportTitle>アクションスタンダード</AdCopyReportTitle>

			{activity.actionStandards?.map((x, i) => (
				<div key={i}>
					アクションスタンダード{i + 1}. {x}
				</div>
			))}

			{/* 結果サマリー */}
			<AdCopyResultSummary tableData={data.differentiationAndONS} />

			{/* 結果詳細 */}
			<AdCopyResultDetail data={data} dispatch={dispatch} />
		</StyledDiv>
	);
});
