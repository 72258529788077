import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import type { AdCopyActivityWithBrand } from "../../../../../../../server/models/ad-copy/activity";
import type { AdCopyConcept } from "../../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import { AdCopyJobDetail } from "../../../../parts/ad-copy/job-detail";

type Props = {
	activity: AdCopyActivityWithBrand;
	concepts: AdCopyConcept[];
	estimate: string | undefined;
	evaluationItem: string[] | undefined;
	job: AdCopyJob | undefined;
	onJobList: () => void;
};

export const AdCopyJobDetailPage = React.memo((props: Props) => {
	const { activity, concepts, estimate, evaluationItem, job, onJobList } = props;

	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■ジョブ詳細</div>
				</Col>
			</Row>

			<Row>
				<Col className="mb-3" md={12}>
					{job && (
						<AdCopyJobDetail
							activity={activity}
							concepts={concepts}
							estimate={estimate}
							evaluationItem={evaluationItem}
							job={job}
						/>
					)}
				</Col>
			</Row>

			<Row className="mt-5">
				<Col className="text-center">
					<Button onClick={onJobList} variant="outline-secondary">
						ジョブ一覧に戻る
					</Button>
				</Col>
			</Row>
		</div>
	);
});
