import * as React from "react";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../label/ad-copy/activity";
import { composeGypsySubLabel } from "../../../../../../../server/lib/ad-copy/enquete/quota/helpers";
import type { AdCopyGypsy } from "../../../../../../../server/models/ad-copy/activity";
import { AdCopyGroupPanel } from "./parts";

type Props = {
	gypsy: AdCopyGypsy;
};

export const GypsyGroupPanel = React.memo((props: Props) => {
	const { gypsy } = props;

	const subLabelString = React.useMemo(() => composeGypsySubLabel(gypsy), [gypsy]);

	return (
		<AdCopyGroupPanel
			label={adCopyStrategyTargetGroupLabels.gypsy ?? ""}
			value={`${gypsy.surveyChoice.label}${subLabelString}`}
		/>
	);
});
