import * as React from "react";
import { JobPage, JobPageType } from "../../../components/pages/job";
import { initState, reducer } from "../../../reducers/job/list";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { get, makeError, put } from "../../../lib/request";
import { GetJobsResponse, PutJobStatusResponse } from "../../../../../server/types/request/job";
import { GetEnqueteTestResponse } from "../../../../../server/types/request/preview";
import { replaceEndpointUrl, endpoint } from "../../../../../server/router/endpoint";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { JobStatus } from "../../../../../server/models/status";
import { clientEndpoint } from "../../../routes/endpoint";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export const JobListContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, ...jobPageProps } = state;
	const navigate = useNavigate();
	const { activityId } = useParams<{ activityId: string }>();
	React.useEffect(() => {
		get<GetJobsResponse>(replaceEndpointUrl(endpoint.loadJob, { activityId }))
			.then((response) => {
				dispatch({ type: "loadJobs", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [activityId]);
	const onEditPage = React.useCallback(
		(id: string) => navigate(replaceEndpointUrl(clientEndpoint.jobCreate, { activityId, jobId: id })),
		[activityId, navigate],
	);
	const onRetest = React.useCallback(
		(id: string) => navigate(replaceEndpointUrl(clientEndpoint.jobCreateRetest, { activityId, retestJobId: id })),
		[activityId, navigate],
	);
	const onChangePage = React.useCallback(
		(id: string, page: JobPageType) => {
			if (page === "enquete-test") {
				get<GetEnqueteTestResponse>(`${endpoint.enqueteTest}/${id}`)
					.then((response) => {
						window.open(response.data.url);
					})
					.catch((error) => {
						dispatch({ type: "changeMessageInfo", payload: makeError(error) });
					});
			} else {
				navigate(`/${page}/${activityId}/${id}`);
			}
		},
		[activityId, navigate],
	);
	const onOutlinePage = React.useCallback(
		(id: string) => {
			navigate(replaceEndpointUrl(clientEndpoint.outline, { activityId, jobId: id }));
		},
		[activityId, navigate],
	);
	const onJobStatusChange = React.useCallback((jobId: string, status: keyof typeof JobStatus) => {
		put<PutJobStatusResponse>(`${endpoint.jobStatus}/${jobId}`, { status })
			.then((response) => {
				dispatch({ type: "changeJobStatus", payload: { ...response.data, jobId } });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"処理"} />
			<JobPage
				{...jobPageProps}
				loginAccount={loginAccount}
				onEditPage={onEditPage}
				onChangePage={onChangePage}
				onOutlinePage={onOutlinePage}
				onJobStatusChange={onJobStatusChange}
				onRetest={onRetest}
			/>
		</>
	);
};
