import * as React from "react";

import { JobWithSurveyStatus } from "../../../../../../server/models/job";
import { JobStatus, surveyStatusOptions } from "../../../../../../server/models/status";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { GetJobSearchResponse } from "../../../../../../server/types/request/job";

import { SubTitle } from "../../../../../../react-components/lu-component/src";
import { Search, SearchColumn } from "../../../parts/search";
import { JobPageType } from "..";
import { JobSearchResult } from "./job-search-result";

const searchColumns: SearchColumn[] = [
	{
		name: "jobNum",
		label: "ジョブNo.",
		type: "string",
	},
	{
		name: "surveyStatus",
		type: "select",
		label: "調査ステータス",
		options: surveyStatusOptions,
	},
];

export type JobSearchPageProps = GetJobSearchResponse & {
	loginAccount: AccountWithoutPassword;
	onAggregatePage: (id: string) => void;
	onChangePage: (id: string, page: JobPageType) => void;
	onChangeSearches: <K extends keyof JobWithSurveyStatus>(name: K, value: JobWithSurveyStatus[K]) => void;
	onEditPage: (id: string) => void;
	onJobStatusChange: (id: string, status: keyof typeof JobStatus) => void;
	onOutlinePage: (id: string) => void;
	onRetest: (id: string) => void;
	onSubmit: () => void;
	searches: { [key in keyof JobWithSurveyStatus]?: JobWithSurveyStatus[key] };
	result?: "empty" | "non-empty" | "initial";
};

export const JobSearchPage = React.memo((props: JobSearchPageProps) => {
	const {
		accountOptions,
		data,
		loginAccount,
		onAggregatePage,
		onChangePage,
		onChangeSearches,
		onEditPage,
		onJobStatusChange,
		onOutlinePage,
		onRetest,
		onSubmit,
		searches,
		result,
	} = props;

	return (
		<>
			<div className="m-3 clearfix">
				<div className="h3 float-start">■ジョブ検索</div>
			</div>

			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>検索</SubTitle>
				<Search
					columns={searchColumns}
					searchValues={searches as any}
					onChangeSearchValue={onChangeSearches as any}
					onSearch={onSubmit}
				/>
			</div>

			{data.length === 0 && result === "empty" && (
				<div className="m-3">
					<SubTitle style={{ margin: "20px 0px" }}>一致するものが見つかりませんでした</SubTitle>
				</div>
			)}

			{data.map((d) => (
				<JobSearchResult
					key={`job-page-${d.activity._id.toString()}`}
					accountOptions={accountOptions}
					activity={d.activity}
					hideTitle
					jobs={d.jobs}
					loginAccount={loginAccount}
					onAggregatePage={onAggregatePage}
					onChangePage={onChangePage}
					onEditPage={onEditPage}
					onJobStatusChange={onJobStatusChange}
					onOutlinePage={onOutlinePage}
					onRetest={onRetest}
					searches={searches}
				/>
			))}
		</>
	);
});
