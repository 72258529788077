import { Concept } from "../../models/concept";
import { replaceBreakAndSpace } from "../common";

export const imageWidth = 500;

export const concept2html = (concept: Concept): string => {
	return `
		<!DOCTYPE html>
			<meta charset="UTF-8">
			<style type="text/css">
				body{
					font-family: "IPAPゴシック","IPAPGothic";
				}
				div.key-sentence,div.brand-sentence {
					font-weight:bold;
				}
				div.sku-item,div.sentence{
					font-size:120%;
					text-align:center;
					padding: 0 0.5em;
				}
				div.key-sentence {
					font-size:150%;
				}
				div.brand-sentence {
					font-size:125%;
					margin-top: 0.5em;
					margin-bottom: 1em;
				}
				div.all-wrapper{
					width:100%;
					text-align:center;
				}
				div.sentence-area{
					margin-bottom:1em;
				}
				img.logo{
					width: ${concept.logo ? concept.logo.width : 100}px;
				}
				img.package{
					width: ${concept.package ? concept.package.width : 100}px;
				}
				div.sentence{
					word-break: break-all;
					white-space: pre-wrap;
				}
				div.image-wrap{
					text-align: center;
					margin-bottom:1em;
				}
			</style>
			<body style="width:${imageWidth}px;">
				<div id="all-wrapper">
					${concept.logo && concept.logo.url ? `<div class="image-wrap"><img class="logo" src="${concept.logo.url}"></div>` : ""}
					<div class="sentence-area">
						${concept.sentences
							.map((sentence, index) => {
								if (!sentence.sentence || replaceBreakAndSpace(sentence.sentence).length === 0) {
									return "";
								}
								return `<div class="sentence ${
									sentence.bold ? (index <= 1 ? "key-sentence" : "brand-sentence") : "sub-sentence"
								}">${sentence.sentence}</div>`;
							})
							.join("")}
					</div>
					${
						concept.package && concept.package.url
							? `<div class="image-wrap"><img class="package" src="${concept.package.url}"></div>`
							: "" /* packageなしのパターンがある */
					}
					<div class="sku-area">
						${concept.sku
							.map((s, index) => {
								return `
								<div class="sku-item" ${index === concept.sku.length - 1 ? "" : `style="margin-bottom:1em;"`}>
									${
										s.name && s.price && s.includeTaxPrice
											? `<div>${
													s.name
											  }</div><div>${s.price.toLocaleString()}円（税抜）／${s.includeTaxPrice.toLocaleString()}円（税込）</div>`
											: ""
									}
								</div>
							`;
							})
							.join("")}
					</div>
				</div>
			</Body>
		</html>
	`;
};
