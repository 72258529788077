import { AccountAction } from "../../types/actions";
import { AccountWithoutPassword } from "../../../../server/models/account";
import { MessageInfo } from "../../types/info";
import { Option } from "../../../../react-components/lu-component/src/index";

export type State = {
	brandList: Option[];
	accounts: AccountWithoutPassword[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	brandList: [],
	accounts: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: AccountAction): State => {
	switch (action.type) {
		case "initAccounts":
			return { ...state, ...action.payload, loading: false };
		case "setAccounts":
			return { ...state, accounts: action.payload, loading: false };
		case "uploadAccounts":
			return {
				...state,
				accounts: action.payload,
				loading: false,
				info: { message: "アカウント情報の更新が完了しました。", isSuccess: true },
			};
		case "addAccount":
			return {
				...state,
				accounts: state.accounts.concat(action.payload),
				loading: false,
				info: { message: "アカウントの作成が完了しました。", isSuccess: true },
			};
		case "deleteAccount":
			return {
				...state,
				accounts: state.accounts.filter((account) => account._id !== action.payload),
				loading: false,
				info: { message: "アカウントの削除が完了しました。", isSuccess: true },
			};
		case "changeAccount":
			return {
				...state,
				accounts: state.accounts.map((account) => (account._id === action.payload._id ? action.payload : account)),
				loading: false,
				info: { message: "アカウントの更新が完了しました。", isSuccess: true },
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
