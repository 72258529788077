import * as React from "react";
import {
	CP,
	SimilarProductInformation,
	isProductType,
	productTypeOptions,
} from "../../../../../../../../server/models/cp";
import { Category } from "../../../../../../../../server/models/category";
import { Brand } from "../../../../../../../../server/models/brand";
import { Col, Row, Button } from "react-bootstrap";
import { InputRow, TextRow } from "../../../../../parts/input-row";

import { Radios } from "../../../../../../../../react-components/lu-component/src/index";
import { cpLabels } from "../../../../../../../../label/cp";
import { SimilarProductInfoSetting } from "./similar-product-info";
import { ReportTitle } from "../../../parts/report-title";
import { ActivityWithBrand } from "../../../../../../../../server/models/activity";
import { getErrorObject } from "../../../../../../lib/error";
import { TextRed } from "../../../../../parts/font";
import { ConfirmButton } from "../../../../../parts/confirm-dropdown-button";
import { ErrorObject } from "../../../../../../../../server/types/error";

export type CPSettingProps = {
	cp: Partial<CP>;
	errors?: ErrorObject;
	categories: Category[];
	brands: Brand[];
	onChange: <K extends keyof CP>(key: K, value: CP[K]) => void;
	onSubmit: () => void;
	activity: ActivityWithBrand;
	hasEditRole: boolean;
	onClear: () => void;
};

export const CPSetting = React.memo<CPSettingProps>(
	({ cp, categories, brands, onChange, errors, onSubmit, hasEditRole, onClear }) => {
		const { actualValue, type, similarProductInfo, reason } = cp;
		const handleChangeSimilarProductInfo = React.useCallback(
			(similarProductInfo: SimilarProductInformation) => onChange("similarProductInfo", similarProductInfo),
			[onChange],
		);
		const canSubmit = React.useMemo(() => {
			if (cp.type === "currentProduct") return !!cp.actualValue && !!cp.reason;
			return (
				!!cp.actualValue &&
				!!cp.similarProductInfo &&
				!!cp.similarProductInfo.category &&
				!!cp.similarProductInfo.brandId &&
				!!cp.reason
			);
		}, [cp]);
		const disabled = React.useMemo(() => !!cp?._id && !!cp.correctionValues && !!cp.correctionValues.result, [cp]);
		return (
			<>
				<Row>
					<Col md={10}>
						<ReportTitle>現行品/類似品のCP実績設定</ReportTitle>
					</Col>
				</Row>
				{!!cp._id && (
					<Row style={{ marginBottom: "0.8rem" }}>
						<Col md={{ span: 2 }}>
							{/* block */}
							<ConfirmButton
								variant="outline-secondary"
								onOk={onClear}
								title="設定値のクリア"
								body={"CP実績設定のクリアをします。よろしいでしょうか。"}
								buttonText="クリア"
							>
								CP実績設定のクリア
							</ConfirmButton>
						</Col>
					</Row>
				)}
				<Row>
					<Col md={7}>
						<TextRow
							id="actualValue"
							name={"actualValue"}
							type="decimal"
							rowName={cpLabels.actualValue}
							value={actualValue ? actualValue.toString() : ""}
							onChange={(value) => onChange("actualValue", value)}
							errors={errors}
							disabled={disabled}
						/>
						<TextRow
							as="textarea"
							id="reason"
							name={"reason"}
							rowName={
								<>
									<div>{cpLabels.reason}</div>
									<div>
										<TextRed>※CP実績を入力した場合必須</TextRed>
									</div>
								</>
							}
							value={reason ? reason.toString() : ""}
							onChange={(value) => onChange("reason", value)}
							errors={errors}
							disabled={disabled}
						/>
						<InputRow name={"salesScale"} rowName={cpLabels.type} errors={errors}>
							<Radios
								name={"salesScale"}
								choices={productTypeOptions}
								checked={type}
								onChange={(value) => isProductType(value) && onChange("type", value)}
								disabled={disabled}
							/>
						</InputRow>
						{type === "similarProduct" && (
							<SimilarProductInfoSetting
								similarProductInfo={similarProductInfo ?? {}}
								onChange={handleChangeSimilarProductInfo}
								errors={getErrorObject("similarProductInfo", errors)}
								categories={categories}
								brands={brands}
								disabled={disabled}
							/>
						)}
					</Col>
				</Row>
				{hasEditRole && (
					<Row>
						{disabled ? (
							<Col md={{ span: 8, offset: 2 }}>
								<TextRed>値を変更する場合はクリアボタンを押して下さい。</TextRed>
							</Col>
						) : (
							<Col md={{ span: 2, offset: 3 }}>
								{/* block */}
								<Button variant="secondary" onClick={onSubmit} disabled={!canSubmit}>
									CP値 更新
								</Button>
							</Col>
						)}
					</Row>
				)}
			</>
		);
	},
);
