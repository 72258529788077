import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyGroupCategoryLabels } from "../../../../../../../../label/ad-copy/activity";
import type { ErrorObject } from "../../../../../../../../react-components/lu-component/src/index";
import type { AdCopyStrategyGroupCategory } from "../../../../../../../../server/models/ad-copy/activity";
import { fromAdCopyPriceOptions, toAdCopyPriceOptions } from "../../../../../../../../server/models/category";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";
import type { RangeValue } from "../../../../../parts/range";
import { RangeSelect } from "../../../../../parts/range";

type Props = {
	errors: ErrorObject | undefined;
	onChangeStrategyGroupCategory: (surveyChoice: number, name: keyof AdCopyStrategyGroupCategory, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
	surveyChoiceMap: Map<number, string>;
};

export const AdCopyPriceForms = React.memo((props: Props) => {
	const { errors, onChangeStrategyGroupCategory, strategyGroup, surveyChoiceMap } = props;

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm
					defaultShow={strategyGroup.category?.some((cate) => !!cate.price && (cate.price.from || cate.price.to))}
					eventKey="price"
					title={adCopyStrategyGroupCategoryLabels.price ?? ""}
				>
					<Row style={{ paddingLeft: "15px" }}>
						<Col md={12}>上限を設定しない場合は、上限の価格帯を選択しないでください。</Col>

						{strategyGroup.category?.map((category, index) => (
							<Col key={`price-category-${category.surveyChoice}`} md={4} style={{ paddingBottom: "1em" }}>
								{surveyChoiceMap.get(category.surveyChoice)}

								<RangeSelect
									errors={
										errors &&
										"category" in errors &&
										typeof errors["category"] === "object" &&
										index in errors["category"] &&
										typeof errors["category"][index] === "object" &&
										"price" in (errors["category"][index] as ErrorObject) &&
										typeof (errors["category"][index] as ErrorObject)["price"] === "object"
											? ((errors["category"][index] as ErrorObject)["price"] as ErrorObject)
											: undefined
									}
									fromOptions={fromAdCopyPriceOptions}
									onChange={(v) => {
										onChangeStrategyGroupCategory(category.surveyChoice, "price", !v.from && !v.to ? undefined : v);
									}}
									toOptions={toAdCopyPriceOptions}
									value={(category.price as RangeValue) || { from: undefined, to: undefined }}
								/>
							</Col>
						))}
					</Row>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
