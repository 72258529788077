import { useState, useEffect } from "react";
import { GetInfoResponse } from "../../../server/types/request/info";
import { endpoint } from "../../../server/router/endpoint";
import { get } from "../lib/request";
import { Info } from "../../../server/models/info";

const useInfo = (): GetInfoResponse => {
	const [data, setData] = useState(null as GetInfoResponse);

	useEffect(() => {
		get<GetInfoResponse>(`${endpoint.info}`).then((response) => {
			if (response.data != null) {
				//dispatch({ type: "loadInfo", payload: response.data });
				setData(response.data);
			}

			if (response.data.message == null) {
				setData({
					message: {
						title: "",
						content: "",
						timestamp: new Date(2023, 7, 7),
					} as Info,
				} as GetInfoResponse);
			}
		});
	}, []);

	return data;
};

export default useInfo;
