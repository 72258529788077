import * as React from "react";
import styled from "styled-components";

import type { AdCopyConcept } from "../../../../../../server/models/ad-copy/concept";
import { FloatingMenu } from "../../../parts/floating-menu";
import { AdCopyConceptPreview } from "../concept/preview/concept-preview";

const Iframe = styled.iframe`
	width: 100%;
	border: 0px;
	height: 600px;
`;

type Props = {
	concepts: AdCopyConcept[];
	url: string;
};

export const AdCopyPreviewPage = React.memo((props: Props) => {
	const { concepts, url } = props;

	const surveyPreviewRef = React.useRef<HTMLDivElement>();

	const conceptPreviewRef = React.useRef<HTMLDivElement>();

	const onSurveyPreview = React.useCallback(() => {
		if (!conceptPreviewRef.current) {
			return;
		}

		window.scroll(0, surveyPreviewRef.current.offsetTop);
	}, [surveyPreviewRef]);

	const onConceptPreview = React.useCallback(() => {
		if (!conceptPreviewRef.current) {
			return;
		}

		window.scroll(0, conceptPreviewRef.current.offsetTop);
	}, [conceptPreviewRef]);

	const menus = React.useMemo(
		() => [
			{ event: onSurveyPreview, label: "調査画面プレビュー" },
			{ event: onConceptPreview, label: "呈示素材プレビュー" },
		],
		[onSurveyPreview, onConceptPreview],
	);

	return (
		<>
			<FloatingMenu menus={menus} />

			<div ref={surveyPreviewRef} className="m-3" id="survey-preview">
				<div className="h3">■調査画面プレビュー</div>
			</div>

			<Iframe src={url} />

			<div ref={conceptPreviewRef} className="m-3" id="concept-preview">
				<div className="h3">■呈示素材プレビュー</div>
			</div>

			<AdCopyConceptPreview concepts={concepts} />
		</>
	);
});
