import * as React from "react";
import { PasswordResetPage } from "../../components/pages/password-reset-form";
import { useParams, useNavigate } from "react-router-dom";
import { reducer, initState } from "../../reducers/password-reset";
import { Loading } from "../../../../react-components/lu-component/src/index";
import { post, makeError } from "../../lib/request";
import { endpoint } from "../../../../server/router/endpoint";
import { ConfirmBox } from "../../components/parts/confirm-box";
import { clientEndpoint } from "../../routes/endpoint";

export const PasswordResetContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info } = state;
	const navigate = useNavigate();
	const { hash } = useParams<{ hash: string }>();
	const onSubmit = React.useCallback(
		(password: string) => {
			post<{}>(`${endpoint.passwordReset}/${hash}`, { password })
				.then(() => {
					navigate(clientEndpoint.successPasswordReset);
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		},
		[hash, navigate],
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<PasswordResetPage onSubmit={onSubmit} errors={info.errors} />
		</>
	);
};
