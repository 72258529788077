import * as React from "react";

import type { AdCopyOAColumn, AdCopyOAData, AdCopyOADataName } from "../../../../../../../server/models/ad-copy/output";

type Props = {
	choiceMap: Partial<Record<AdCopyOADataName, Map<number | string | undefined, string>>>;
	filteredData: AdCopyOAData[];
	oaColumns: AdCopyOAColumn[];
};

export const AdCopyOASearchTableRows = React.memo((props: Props) => {
	const { choiceMap, filteredData, oaColumns } = props;

	return filteredData.map((data) => (
		<tr key={`data-${data.num}`} className="data-section">
			{oaColumns.map((column) => {
				const value = data[column.name];

				const choice = choiceMap[column.name];

				return (
					<td key={`data-${data.num}-${column.name}`} className={`data-${column.name}`}>
						{column.type === "text" && value}

						{column.type === "select" &&
							(Array.isArray(value) ? value.map((v) => choice?.get(v)).join(",") : choice?.get(value))}
					</td>
				);
			})}
		</tr>
	));
});
