import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { AdCopyResultDetailsResBody } from "../../../../../../server/types/request/ad-copy/report/resultDetails";
import { AdCopyRetestMenu } from "../../../../components/pages/ad-copy/report/common";
import { AdCopyReportBigTitle } from "../../../../components/pages/ad-copy/report/parts/report-title";
import { AdCopyResultByTargeReportPage } from "../../../../components/pages/ad-copy/report/result-by-target";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/report/result-by-target";

export const AdCopyResultByTargetReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { activity, data, info, job, loading } = state;

	const { activityId, jobId } = useParams<{
		activityId: string;
		jobId: string;
	}>();

	React.useEffect(() => {
		get<AdCopyResultDetailsResBody>(`${adCopyEndpoint.resultDetails}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadData" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	return (
		<>
			<Loading loading={loading} />

			{job && job.retestJobId && (
				<AdCopyRetestMenu activityId={activityId ?? ""} jobId={job.retestJobId.toString()} page="result-by-target" />
			)}

			<ConfirmBox info={info} titleLabel="コピー" />

			<AdCopyReportBigTitle>結果の詳細{job ? `（${job.jobNum}）` : ""}</AdCopyReportBigTitle>

			{activity && data && job && (
				<AdCopyResultByTargeReportPage activity={activity} data={data} dispatch={dispatch} job={job} />
			)}
		</>
	);
};
