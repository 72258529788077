import * as React from "react";
import { StandardLine, StockedBarGraph, StokedBarGroup } from "../../../../parts/graph/stocked-bar-graph";

import { ResultTable, SellPotentialValue } from "./result-table";
import { GoalTable } from "./goal-table";
import { UserTable } from "./user-table";
import {
	SalesVolumeConstValue,
	SalesVolumePredictionData,
} from "../../../../../../../server/types/request/report/simulation";
import { CPCorrectionValueTable } from "./cp-correction-value";
import { hasCorrectionValues, makeCorrectedValues } from "../../../../../../../server/lib/common";

export type SimulationResultProps = {
	data: SalesVolumePredictionData;
	constValue: SalesVolumeConstValue;
	goalSalesVolume: number;
	initLocalRatio: number;
	initInboundRatio: number;
	hasCP: boolean;
};

const noCorrectionValues = {
	total: undefined,
	trial: undefined,
	repeat: undefined,
	localOther: undefined,
	inbound: undefined,
} as any;

export const SimulationResult = React.memo((props: SimulationResultProps) => {
	const { data, goalSalesVolume, constValue, hasCP } = props;
	const sellPotentials = React.useMemo<SellPotentialValue[]>(() => {
		const { value, correctionValue, cpCorrectionValue } = data;
		const { total, trial, repeat, localOther, inbound, sumTrialRepeat } = makeCorrectedValues(
			value,
			correctionValue,
			constValue,
		);
		const noChange = !hasCorrectionValues(correctionValue);

		const ret = [
			{
				label: "初期値（万個）",
				sellPotential: {
					total: value.u,
					trial: value.o,
					repeat: value.p,
					localOther: value.q + value.r + value.s,
					inbound: value.t,
				},
			},
			{
				label: "補整値（万個）",
				sellPotential: noChange ? noCorrectionValues : { total, trial, repeat, localOther, inbound },
			},
		];
		if (hasCP) {
			ret.push({
				label: "実績補正",
				sellPotential: {
					total: (sumTrialRepeat + localOther + inbound) * cpCorrectionValue,
					trial: trial * cpCorrectionValue,
					repeat: repeat * cpCorrectionValue,
					localOther: localOther * cpCorrectionValue,
					inbound: inbound * cpCorrectionValue,
				},
			});
		}
		return ret;
	}, [data, constValue, hasCP]);
	const salesData = React.useMemo<StokedBarGroup[]>(() => {
		return sellPotentials.map(({ label, sellPotential }) => {
			return {
				label,
				total: sellPotential.total,
				bars: [
					{ label: "トライアル", value: sellPotential.trial, color: "#f56302" },
					{ label: "リピート", value: sellPotential.repeat, color: "#ffc000" },
					{ label: "ローカルその他", value: sellPotential.localOther, color: "#83db4d" },
					{ label: "インバウンド", value: sellPotential.inbound, color: "#6492e3" },
				],
			};
		});
	}, [sellPotentials]);
	const lines = React.useMemo<StandardLine[]>(() => {
		return [
			{
				value: goalSalesVolume,
				color: "red",
				label: "目標値（万個）",
				width: 2,
			},
			/*/{
				value: (goalSalesVolume * 10 * 5) / 100,
				color: "red",
				label: "下限値（万個）",
				dashed: true,
				width: 2,
			},
			/*/
		];
	}, [goalSalesVolume]);
	const graphBase = React.useMemo(() => {
		const tmp = Math.max(...salesData.map((d) => d.bars.reduce((a, b) => a + (b.value || 0), 0)), goalSalesVolume);
		if (tmp < 10) {
			const max = Math.floor(tmp + 1);
			const step = Math.floor((max / 10) * 100) / 100;
			return { max, step };
		}
		const max = Math.ceil(Math.round(tmp / 10) + 1) * 10;
		let step = Math.ceil(max / 10);
		if (step > 5 && step < 10) {
			step = 10;
		} else if (step % 10 !== 0) {
			step = Math.ceil(step / 10) * 10;
		}
		return { max, step };
	}, [goalSalesVolume, salesData]);
	return (
		<table className={"wrap-table"}>
			<tbody>
				<tr>
					<td className={"table-area"}>
						<UserTable salesVolumePrediction={data.value} constValue={constValue} />
						<GoalTable goalSalesVolume={goalSalesVolume} />
						{hasCP && <CPCorrectionValueTable cpCorrectionValue={data.cpCorrectionValue} />}
						<ResultTable sellPotentials={sellPotentials} goalSalesVolume={goalSalesVolume} />
					</td>
					<td>
						<StockedBarGraph
							hidePrecedent
							height={410}
							width={500}
							lines={lines}
							barGroups={salesData}
							max={graphBase.max}
							step={graphBase.step}
							unit={"（年間数量：単位　万個）"}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
});
