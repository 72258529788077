import { Loading } from "../../../../../react-components/lu-component/src/index";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { GetAggregatesResponse } from "../../../../../server/types/request/aggregate";
import { AggregatePage, AggregatePageProps } from "../../../components/pages/aggregate/list";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { fileDownloadStream, get, makeError, remove } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/aggregate/list";
import { clientEndpoint } from "../../../routes/endpoint";

export const AggreagteContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, accountOptions, crossAggregates, searches } = state;
	const navigate = useNavigate();
	React.useEffect(() => {
		get<GetAggregatesResponse>(endpoint.aggregate)
			.then((response) => {
				dispatch({ type: "loadAggreagteJobs", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	const onChangeSearches = React.useCallback<AggregatePageProps["onChangeSearches"]>(
		(name, value) => dispatch({ type: "changeSearches", payload: { name, value } }),
		[],
	);
	const onEdit = React.useCallback<AggregatePageProps["onEdit"]>(
		(jobId, aggregateId) =>
			navigate(
				replaceEndpointUrl(clientEndpoint.aggtegateCreate, {
					jobId: jobId.toString(),
					aggregateId: aggregateId.toString(),
				}),
			),
		[navigate],
	);
	const onView = React.useCallback<AggregatePageProps["onView"]>(
		(jobId, aggregateId) =>
			navigate(
				replaceEndpointUrl(clientEndpoint.aggtegateView, {
					jobId: jobId.toString(),
					aggregateId: aggregateId.toString(),
				}),
			),
		[navigate],
	);
	const onDownload = React.useCallback<AggregatePageProps["onDownload"]>(
		(id) => {
			dispatch({ type: "changeLoading", payload: true });
			const crossAggregate = crossAggregates.find(({ _id }) => _id.toString() === id.toString());
			fileDownloadStream(`${endpoint.aggregateDownload}/${id}`, `クロス集計-${crossAggregate.name}.csv`)
				.then(() => {
					dispatch({ type: "changeLoading", payload: false });
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		},
		[crossAggregates],
	);
	const onDelete = React.useCallback<AggregatePageProps["onDelete"]>((id) => {
		remove(`${endpoint.aggregate}/${id}`)
			.then(() => {
				dispatch({ type: "deleteAggregate", payload: id });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, []);
	return (
		<>
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} />
			<AggregatePage
				searches={searches}
				crossAggregates={crossAggregates}
				accountOptions={accountOptions}
				onChangeSearches={onChangeSearches}
				onEdit={onEdit}
				onDownload={onDownload}
				onView={onView}
				onDelete={onDelete}
			/>
		</>
	);
};
