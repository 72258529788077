import * as React from "react";

export type TextProps = {
	x: number;
	y: number;
	value: string | number;
	textAnchor?: string;
	fontSize?: string;
	fontWeight?: string | number;
	textDecoration?: string;
	dominantBaseline?: string;
	writingMode?: string;
	fill?: string;
};
export const Text = React.memo((props: TextProps) => {
	const { value, textAnchor = "middle", dominantBaseline = "central", ...textProps } = props;
	return (
		<text {...textProps} textAnchor={textAnchor} dominantBaseline={dominantBaseline}>
			{typeof value === "number" ? value.toFixed(2) : value}
		</text>
	);
});

export type BarProps = {
	x: number;
	y: number;
	width: number;
	height: number;
	color: string;
	value?: string | number;
	fontSize?: string;
};
export const Bar = React.memo((props: BarProps) => {
	const { x, y, width, height, value, color, ...textProps } = props;
	return (
		<g>
			<rect x={x} y={y} width={width} height={height} style={{ fill: color, opacity: 0.4 }} />
			{value && (
				<Text
					x={x + width / 2}
					y={y + height / 2}
					value={typeof value === "number" ? value.toFixed(2) : value}
					{...textProps}
				/>
			)}
		</g>
	);
});
