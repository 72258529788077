import * as React from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

export type DatetimePicakerProps = {
	name: string;
	placeholder?: string;
	onChange: (value: string) => void;
	value: string;
};
export const Datepicker = React.memo((props: DatetimePicakerProps) => {
	const { name, onChange, placeholder } = props;
	const [date, setDate] = React.useState<Date>(props.value ? new Date(props.value) : undefined);
	React.useEffect(() => {
		if (date || !props.value) return;
		setDate(new Date(props.value));
	}, [date, props.value]);
	const onChangeInput = React.useCallback(
		(date: Date) => {
			setDate(date);
			onChange(dayjs(date).format("YYYY-MM-DD"));
		},
		[onChange],
	);
	return (
		<div>
			<DatePicker
				placeholderText={placeholder}
				className={"form-control"}
				name={name}
				dateFormatCalendar={"yyyy-MM"}
				selected={date}
				onChange={onChangeInput}
				dateFormat="yyyy-MM-dd"
			/>
		</div>
	);
});
