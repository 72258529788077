import * as React from "react";
import { Button, Card, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import type { BetweenDateValues } from "../../../../../../../react-components/lu-component/src/index";
import { BetweenDate } from "../../../../../../../react-components/lu-component/src/index";
import type { ErrorObject } from "../../../../../../../server/types/error";

type Props = {
	errors?: ErrorObject;
	filtered: BetweenDateValues;
	onDownload: () => void;
	onFilteredChange: (values: BetweenDateValues) => void;
};

export const AdCopyJobDownloadPage = React.memo((props: Props) => {
	const { errors, filtered, onDownload, onFilteredChange } = props;

	return (
		<div className="m-3">
			<Card>
				<Card.Body>
					<Row>
						<FormGroup as={Col}>
							<FormLabel>案件終了日</FormLabel>

							<BetweenDate name="endDatetime" onChange={onFilteredChange} values={filtered} />

							{errors && <Feedback type="invalid">開始日、または終了日を入力してください。</Feedback>}
						</FormGroup>
					</Row>

					<Row>
						<Col md={{ offset: 1, span: 2 }}>
							<Button onClick={onDownload} variant="secondary">
								ダウンロード
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	);
});
