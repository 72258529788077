import * as React from "react";
import { Row, Col, FormGroup, FormLabel, FormControl, FormCheck, Form } from "react-bootstrap";
import { Column } from "../../types/column";
import { GroupCheckbox } from "../group-checkbox";
import Feedback from "react-bootstrap/Feedback";
import { Radios } from "../radios";
import { Checkboxes } from "../checkboxes";
import { ButtonSwitch } from "../button-switch";
import { MultipleSelect } from "../multiple-select";
import { BetweenDate } from "../between-date";
import { BetweenInput } from "../between-input";
import { Datetimepicker } from "../datetime-picker";
import { getErrorMessage } from "../../../../../client/scripts/lib/error";

export type ErrorObject = { [key: string]: string | ErrorObject };

export type Data = { [key: string]: any };
export type FormInputGroupsProps = {
	columns: Column[][];
	data: Data;
	errors?: ErrorObject;
	onChange: (name: string, value: any) => void;
};
export const FormInputGroups = React.memo((props: FormInputGroupsProps) => {
	const { columns, data, onChange, errors } = props;
	return (
		<>
			{columns.map((cols, index) => (
				<Row key={`formrow-${index}`}>
					{cols.map((column) => {
						// isShowが定義されているときは表示条件を検索する。検索に渡すデータは全データとする。
						if (column.isShow && !column.isShow(data)) {
							return <React.Fragment key={`field-${column.name}`} />;
						}
						if (column.type === "readonly") {
							return <React.Fragment key={`field-${column.name}`} />;
						}
						if (column.type === "checkbox") {
							return (
								<Col
									className={`col-${column.name}`}
									key={`field-${column.name}`}
									{...(column.col ? { md: column.col } : {})}
									style={{ margin: "5px 0px" }}
								>
									<FormCheck
										name={column.name}
										label={column.label}
										required
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(column.name, e.target.checked)}
										checked={data[column.name] || false}
									/>
								</Col>
							);
						}
						return (
							<FormGroup
								className={`col-${column.name}`}
								as={Col}
								key={`field-${column.name}`}
								{...(column.col ? { md: column.col } : {})}
							>
								<FormLabel>{column.label}</FormLabel>
								{(column.type === "text" || column.type === "textarea" || column.type === "datetime") && (
									<FormControl
										name={column.name}
										onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
											onChange(column.name, e.target.value)
										}
										value={data[column.name] || ""}
										placeholder={column.placeholder}
										required
										isInvalid={errors && column.name in errors}
										{...(column.type === "datetime" && {
											type: "datetime-local",
										})}
										{...(column.type === "textarea" && { as: "textarea" })}
										disabled={column.disabled}
										style={column.disabled ? { opacity: 0.5 } : undefined}
									/>
								)}

								{column.type === "datetimepicker" && (
									<Datetimepicker
										name={column.name}
										value={data[column.name] || ""}
										placeholder={column.placeholder}
										onChange={(value: string) => onChange(column.name, value)}
									/>
								)}
								{column.type === "switch" && (
									<ButtonSwitch
										name={column.name}
										checked={data[column.name] || false}
										onChange={(v: boolean) => onChange(column.name, v)}
										enableText={column.enableText}
										disableText={column.disableText}
									/>
								)}
								{column.type === "select" && (
									<Form.Select
										name={column.name}
										isInvalid={errors && column.name in errors}
										value={data[column.name] || ""}
										onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(column.name, e.target.value)}
									>
										<option value={""}>選択してください</option>
										{column.options.map((option) => (
											<option key={`field-${column.name}-${option.value}`} value={option.value}>
												{option.label}
											</option>
										))}
									</Form.Select>
								)}
								{column.type === "groupCheckbox" && (
									<GroupCheckbox
										groups={column.groups}
										checkeds={data[column.name] || []}
										onChange={(values) => onChange(column.name, values)}
										name={column.name}
									/>
								)}
								{column.type === "custom" &&
									(typeof column.children === "function" ? column.children(data) : column.children)}
								{column.type === "checkboxes" && (
									<Checkboxes
										checkeds={data[column.name] || []}
										onChange={(values) => onChange(column.name, values)}
										choices={column.choices}
										name={column.name}
										inline={column.inline}
									/>
								)}
								{column.type === "radios" && (
									<Radios
										checked={data[column.name]}
										onChange={(values) => onChange(column.name, values)}
										choices={column.choices}
										name={column.name}
										inline={column.inline}
										disabled={column.disabled}
									/>
								)}
								{column.type === "multiple-select" && (
									<MultipleSelect
										name={column.name}
										onChange={(values) => onChange(column.name, values)}
										values={data[column.name] || []}
										options={column.options}
										isInvalid={errors && column.name in errors}
									/>
								)}
								{column.type === "between-date" && (
									<BetweenDate
										name={column.name}
										onChange={(values) => onChange(column.name, values)}
										values={data[column.name] || { from: undefined, to: undefined }}
									/>
								)}
								{column.type === "between-number" && (
									<BetweenInput
										name={column.name}
										onChange={(values) => onChange(column.name, values)}
										values={data[column.name] || { from: undefined, to: undefined }}
									/>
								)}
								{errors && column.name in errors && (
									<Feedback style={{ display: "block" }} type={"invalid"}>
										{getErrorMessage(column.name, errors)}
									</Feedback>
								)}
							</FormGroup>
						);
					})}
				</Row>
			))}
		</>
	);
});
