import { CrossAggregateJob } from "../../../../../server/models/cross-aggregate";
import { GetAggregatesResponse } from "../../../../../server/types/request/aggregate";
import { AggregateListAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";

export type State = GetAggregatesResponse & {
	searches: { [key in keyof CrossAggregateJob]?: CrossAggregateJob[key] };
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	crossAggregates: [],
	accountOptions: [],
	loading: true,
	info: { isSuccess: true, message: "" },
	searches: {},
};

export const reducer = (state: State, action: AggregateListAction): State => {
	switch (action.type) {
		case "loadAggreagteJobs":
			return {
				...state,
				...action.payload,
				loading: false,
			};
		case "changeSearches":
			return {
				...state,
				searches: { ...state.searches, [action.payload.name]: action.payload.value },
			};
		case "deleteAggregate":
			return {
				...state,
				crossAggregates: state.crossAggregates.filter(({ _id }) => _id.toString() !== action.payload.toString()),
				loading: false,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
