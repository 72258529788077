import { Labels } from ".";
import { SimulationValue } from "../server/models/simulation-value";

export const simulationValueLabels: Labels<SimulationValue> = {
	_id: "補整＆提案フォーマット",
	jobId: "ジョブ",
	conceptType: "コンセプト",
	recognitionRate: "商品認知率(%)",
	purchaseQuantity: "購入者あたり年間購入個数",
	localRatio: "ローカルその他*比率(％)",
	inboundRatio: "インバウンド比率(%)",

	// 変更理由。
	localRatioReason: "商品認知率(%)：補整根拠",
	inboundRatioReason: "購入者あたり年間購入個数：補整根拠",
	recognitionRateReason: "ローカルその他*比率(％)：補整根拠",
	purchaseQuantityReason: "インバウンド比率(%)：補整根拠",
};
