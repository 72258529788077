import * as React from "react";
import { FormCheck } from "react-bootstrap";

import type { ErrorObject } from "../../../../../../../server/types/error";
import { InputRow } from "../../../../parts/input-row";

const key = "targetCategoryBrandValues";

type Props = {
	checked: boolean;
	errors: ErrorObject | undefined;
	onChangeActivity: (name: typeof key, value: number[]) => void;
	targetCategoryBrandValues: number[];
};

export const IsDemandForecastEnabledSwitch = React.memo((props: Props) => {
	const { checked, errors, onChangeActivity, targetCategoryBrandValues } = props;

	return (
		<InputRow errors={errors} name={key} rowName="需要予測の有無">
			<FormCheck
				checked={checked}
				onChange={(e) => {
					onChangeActivity(key, e.target.checked ? targetCategoryBrandValues : []);
				}}
				type="switch"
			/>
		</InputRow>
	);
});
