import * as React from "react";
import { Row, Col, FormGroup, FormLabel, Button, Alert } from "react-bootstrap";
import { Column, FormInputGroups, MultipleText } from "../../../../../../react-components/lu-component/src/index";
import { Job } from "../../../../../../server/models/job";
import { List } from "../../../parts/list";
import styled from "styled-components";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { Title } from "../../../parts/title";
import Feedback from "react-bootstrap/esm/Feedback";
import { getErrorMessage } from "../../../../lib/error";
import { ErrorObject } from "../../../../../../server/types/error";

const AlertMessage = styled.div`
	color: red;
`;
const IndentArea = styled.div`
	margin-left: 25px;
`;
const WrapArea = styled.div`
	.col-hasBenchmark,
	.col-hasCurrentProduct {
		margin-left: 10px;
	}
`;

export type JobCreatePageProps = {
	errors?: ErrorObject;
	job: Partial<Job>;
	loginAccount: AccountWithoutPassword;
	evaluationItem: string[];
	cantEditMessage?: string;
	hasStrategyGroup: boolean;
	hasCategoryGroup: boolean;
	onChangeJob: (name: keyof Job, value: Job[keyof Job]) => void;
	onChangeEvaluationItem: (items: string[]) => void;
	onSubmit: () => void;
	// question?: SimpleQuestion;
	// onChangeQuestion: (question?: SimpleQuestion) => void;
};
export const JobCreatePage = React.memo((props: JobCreatePageProps) => {
	const {
		job,
		evaluationItem,
		cantEditMessage,
		onChangeJob,
		onChangeEvaluationItem,
		onSubmit,
		errors,
		hasStrategyGroup,
		hasCategoryGroup,
		// question,
		// onChangeQuestion,
		// loginAccount,
	} = props;
	const jobColumns = React.useMemo<Column[][]>(() => {
		const defaultcolumns: Column[][] = [
			[
				{
					type: "radios",
					name: "hasTwoTest",
					label: "自社テスト品",
					choices: [
						{ value: false, label: "1案のみ" },
						{ value: true, label: "2案" },
					],
					col: 5,
				},
			],
			[
				{
					type: "datetimepicker",
					name: "startDatetime",
					label: (
						<div>
							調査開始日時 <AlertMessage>※ 調査開始から4日後が最長の終了予定日となります。</AlertMessage>
						</div>
					),
					col: 6,
				},
			],
		];
		const strategySettings: Column[][] = [
			[
				{
					type: "radios",
					name: "hasStrategicTarget",
					choices: [
						{ value: true, label: "回収する" },
						{ value: false, label: "回収しない" },
					],
					label: "戦略ターゲットグループ",
				},
			],
		];
		const otherConceptSettings: Column[][] = [
			[
				{
					type: "label",
					label: "ベンチマーク",
					name: "head",
					col: 6,
				},
			],
			[
				{
					type: "radios",
					name: "hasCurrentProduct",
					label: "現行品", // TODO:nameの変更
					choices: [
						{ value: true, label: "あり" },
						{ value: false, label: "なし" },
					],
					col: 5,
				},
			],
			[
				{
					type: "radios",
					name: "hasBenchmark",
					label: "競合品",
					choices: [
						{ value: true, label: "あり" },
						{ value: false, label: "なし" },
					],
					col: 5,
				},
				{
					type: "text",
					name: "benchmarkName",
					label: (
						<>
							競合品ありの場合はブランド名と商品名を入力してください
							<br />
							（レポートのみに反映されます）
						</>
					),
					isShow: (data: Job) => {
						return data.hasBenchmark;
					},
				},
			],
		];
		const tmp = [];
		if (hasStrategyGroup && job.retestJobId) tmp.push(...strategySettings);
		if ((hasCategoryGroup || hasStrategyGroup) && !job.retestJobId) tmp.push(...otherConceptSettings);
		tmp.push(...defaultcolumns);
		return tmp;
	}, [hasStrategyGroup, hasCategoryGroup, job]);
	return (
		<>
			{!!cantEditMessage && (
				<div className="m-3">
					<Alert variant={"danger"}>{cantEditMessage}</Alert>
				</div>
			)}
			<div className="m-3">
				<div className="h3">■{job._id ? "ジョブ更新" : job.retestJobId ? "再調査登録" : "ジョブ新規登録"}</div>
			</div>
			<div className={"m-3"}>
				<Row>
					<Col md={12}>
						<Title>基本設定</Title>
					</Col>
				</Row>
				<IndentArea>
					<Row>
						<Col md={8}>
							<Row>
								<Col md={12}>
									<WrapArea>
										<FormInputGroups
											columns={jobColumns}
											onChange={onChangeJob}
											data={job}
											errors={errors && errors["job"] && typeof errors["job"] === "object" ? errors["job"] : undefined}
										/>
									</WrapArea>
								</Col>
							</Row>
						</Col>
					</Row>
				</IndentArea>
				<Row>
					<Col md={12}>
						<Title>カスタム設定</Title>
					</Col>
				</Row>
				<IndentArea>
					<Row>
						<Col md={12}>
							<FormGroup style={{ paddingBottom: "10px", borderBottom: "1px solid #ddd" }}>
								<FormLabel>印象項目設定</FormLabel>
								<Row>
									<Col md={4}>
										<div>
											標準項目 <AlertMessage>※変更や削除はできません</AlertMessage>
										</div>
										<List
											list={[
												"新しさを感じる",
												"他の商品とは違っている",
												"自分向けの商品だと思う",
												"効果が期待できそう",
												"自分の魅力を高めてくれそう",
												"自分の悩みや不安を解消してくれそう",
												"使うと気分が上がりそう",
												"商品の特徴がわかりやすい",
												"このブランドの他の商品も使ってみたくなる",
												"信じられる",
												"価格が高すぎる",
												"買い求めやすい",
											]}
										/>
									</Col>
									<Col md={8}>
										<div>
											追加項目（任意/5つまで）<AlertMessage>文字数を30文字以内としてください</AlertMessage>
										</div>
										<MultipleText
											max={5}
											hasAddEvent
											values={evaluationItem}
											onChange={onChangeEvaluationItem}
											sentenceOptions={{ max: 30 }}
										/>
										{errors && "evaluationItem" in errors && (
											<Feedback type={"invalid"} style={{ display: "block" }}>
												{getErrorMessage("evaluationItem", errors)}
											</Feedback>
										)}
									</Col>
								</Row>
							</FormGroup>
						</Col>
					</Row>
				</IndentArea>
				{/* 追加設問は別ページにて
				Role.hasManageSystemRole(loginAccount) && (
					<IndentArea>
						<Row>
							<Col md={12}>
								<FormGroup style={{ paddingBottom: "10px", borderBottom: "1px solid #ddd" }}>
									<FormLabel>追加設問</FormLabel>
									<Col md={12}>
										<EditQuestion question={question} mapQuestions={{}} onSave={onChangeQuestion} />
									</Col>
								</FormGroup>
							</Col>
						</Row>
					</IndentArea>
				)*/}
				<Row>
					<Col md={{ span: 2, offset: 4 }}>
						{!cantEditMessage ? (
							<Button className="w-100" variant="secondary" onClick={onSubmit}>
								{job._id ? "更新" : "登録"}
							</Button>
						) : (
							<Button className="w-100" variant="secondary" disabled>
								{job._id ? "更新" : "登録"}できません
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
});
