import * as React from "react";
import { NormType } from "../../../../../../../../server/models/graph/norm";
import styled from "styled-components";
import { TextRed } from "../../../../../parts/font";
import { Job } from "../../../../../../../../server/models/job";
import { isLack, doJudge } from "../../../../../../lib/action-standard";
import { ElementEvaluation } from "../../../../../../../../server/models/graph/element-evaluation";

export const Norm = {
	A: "Aランク",
	B: "Bランク",
	C: "Cランク",
	D: "Dランク",
} as const;

const ReportTable = styled.table`
	td,
	th {
		border: 1px solid #888;
		padding: 5px;
		text-align: center;
	}
	th {
		background-color: #ddd;
	}
`;
const Title = styled.div`
	margin-top: 10px;
	padding-left: 10px;
`;

// △判定が入ってきたらいやなので、booleanにはしない
export type Judge = "〇" | "×";
export type SummaryResult = {
	sampleSize: number;
	judge: Judge;
};
export type Versus = {
	category?: SummaryResult;
	strategy?: SummaryResult;
};
export type SummaryProps = {
	label: string;
	potential: Judge;
	norm?: NormType;
	vsBenchmark?: Versus;
	vsCurrent?: Versus;
	job: Job;
	evaluations: ElementEvaluation[];
	// 以下を追加
	vsBenchmarkByOriginality?: Versus;
	vsCurrentByOriginality?: Versus;
};

export const VersusRow = React.memo(
	(props: {
		target: "ワンナンバースコア" | "差別性（平均値）";
		bigLabel?: {
			rowSpan: number;
			title: "購入意向" | "差別性";
		};
		versus: Versus;
		label: string;
		job: Job;
	}) => {
		const { versus, bigLabel, label, job, target } = props;
		const { rowSpan, title } = bigLabel ?? {};
		const data = React.useMemo(() => {
			const d: { key: "category" | "strategy"; label: string; value: string; lack: boolean; doJudge?: boolean }[] = [];

			if (versus.strategy)
				d.push({
					key: "strategy",
					label: "戦略ターゲット",
					value: versus.strategy.judge,
					lack: isLack(versus.strategy.sampleSize),
					/**
					 * No.8 エグゼクティブサマリーで戦略ターゲットGがn30未満であればＡＳ判定しない
					 * 戦略ターゲットのみ判定するかのチェックを行う。
					 */
					doJudge: doJudge(versus.strategy.sampleSize, job.endDatetime),
				});
			return d;
		}, [versus.strategy, job.endDatetime]);
		return (
			<>
				{data.map((d, index) => (
					<tr key={`judge-${d.key}`}>
						{index === 0 && (
							<>
								{rowSpan && <td rowSpan={rowSpan}>{title}</td>}
								<td rowSpan={data.length}>{`対 ${label}比較`}</td>
							</>
						)}
						{d.lack ? (
							<>
								<td>
									{/* No.8 エグゼクティブサマリーで戦略ターゲットGがn30未満であればＡＳ判定しない */}
									<TextRed>{d.doJudge ? d.value : "-"}</TextRed>
								</td>
								<td>
									<TextRed>{d.label}</TextRed>
								</td>
							</>
						) : (
							<>
								<td>{d.value}</td>
								<td>{d.label}</td>
							</>
						)}
						{index === 0 && (
							<td rowSpan={data.length}>
								{target}が
								<br />
								{label}を上回る
							</td>
						)}
					</tr>
				))}
			</>
		);
	},
);

export const ActionStandardTableVersion5 = React.memo((props: SummaryProps) => {
	const { label, norm, vsBenchmark, vsCurrent, job, vsBenchmarkByOriginality, vsCurrentByOriginality } = props;
	const rowSpan = React.useMemo(() => {
		let cnt = 0;
		if (norm) cnt++;
		// if (vsBenchmark && vsBenchmark.category) cnt++;
		if (vsBenchmark && vsBenchmark.strategy) cnt++;
		// if (vsCurrent && vsCurrent.category) cnt++;
		if (vsCurrent && vsCurrent.strategy) cnt++;
		console.log({ norm, vsBenchmark, vsCurrent });
		return cnt;
	}, [vsBenchmark, vsCurrent, norm]);
	const originalityRowSpan = React.useMemo(() => {
		let cnt = 0;
		// if (vsBenchmarkByOriginality && vsBenchmarkByOriginality.category) cnt++;
		if (vsBenchmarkByOriginality && vsBenchmarkByOriginality.strategy) cnt++;
		// if (vsCurrentByOriginality && vsCurrentByOriginality.category) cnt++;
		if (vsCurrentByOriginality && vsCurrentByOriginality.strategy) cnt++;
		return cnt;
	}, [vsBenchmarkByOriginality, vsCurrentByOriginality]);

	return (
		<>
			<Title>{label}</Title>
			<ReportTable>
				<thead>
					<tr>
						<th colSpan={2}>主要指標</th>
						<th>結果</th>
						<th>結果算出のベース</th>
						<th>基準</th>
					</tr>
				</thead>
				<tbody>
					{norm && (
						<tr>
							<td rowSpan={rowSpan}>購入意向</td>
							<td>ノーム比較</td>
							<td>{Norm[norm]}</td>
							<td>カテゴリターゲット</td>
							<td>{/*normJudgeStandard(job.endDatetime)}ランク以上*/}-</td>
						</tr>
					)}
					{!!vsBenchmark && (
						<VersusRow
							target={"ワンナンバースコア"}
							bigLabel={!norm ? { title: "購入意向", rowSpan } : undefined}
							versus={vsBenchmark}
							label={"競合"}
							job={job}
						/>
					)}
					{!!vsCurrent && (
						<VersusRow
							target={"ワンナンバースコア"}
							bigLabel={!norm && !vsBenchmark ? { title: "購入意向", rowSpan } : undefined}
							versus={vsCurrent}
							label={"現行"}
							job={job}
						/>
					)}
					{!!vsBenchmarkByOriginality && (
						<VersusRow
							target={"差別性（平均値）"}
							bigLabel={{ rowSpan: originalityRowSpan, title: "差別性" }}
							versus={vsBenchmarkByOriginality}
							label={"競合"}
							job={job}
						/>
					)}
					{!!vsCurrentByOriginality && (
						<VersusRow
							target={"差別性（平均値）"}
							bigLabel={!vsBenchmarkByOriginality ? { rowSpan: originalityRowSpan, title: "差別性" } : undefined}
							versus={vsCurrentByOriginality}
							label={"現行"}
							job={job}
						/>
					)}
				</tbody>
			</ReportTable>
		</>
	);
});
