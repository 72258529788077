import { MessageInfo } from "../../../types/info";
import { ReportDownloadAction } from "../../../types/actions";
import { Job } from "../../../../../server/models/job";

export type State = {
	loading: boolean;
	info: MessageInfo;
	job: Job;
};

export const initState: State = {
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
	job: undefined,
};

export const reducer = (state: State, action: ReportDownloadAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "loadData":
			return {
				...state,
				loading: false,
				job: action.payload.job,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
