import * as React from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import styled from "styled-components";

type Props = {
	max: number;
	min: number;
	onChange: (values: string[]) => void;
	values: string[];
};

const NumberArea = styled.div`
	padding: 3px;
	text-align: right;
`;

export const AdCopyMultipleText = React.memo((props: Props) => {
	const { max, min, onChange, values } = props;

	return values.map((value, index) => (
		<Row key={index} style={{ margin: "10px" }}>
			<Col md="auto">
				<NumberArea>{index + 1}.</NumberArea>
			</Col>

			<Col md={9}>
				<FormControl
					onChange={(e) => {
						onChange(values.map((v, i) => (i === index ? e.target.value : v)));
					}}
					value={value}
				/>
			</Col>

			<Col md={2}>
				<Button
					disabled={values.length <= min}
					onClick={() => {
						onChange(values.filter((_, i) => i !== index));
					}}
					style={{ display: "inline", margin: "0px 5px" }}
					tabIndex={-1}
					variant="danger"
				>
					×
				</Button>

				<Button
					disabled={max <= values.length}
					onClick={() => {
						onChange([...values.slice(0, index), "", ...values.slice(index)]);
					}}
					style={{ display: "inline", margin: "0px 5px" }}
					tabIndex={-1}
					variant="outline-secondary"
				>
					+
				</Button>
			</Col>
		</Row>
	));
});
