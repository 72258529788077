import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyJobResponse } from "../../../../../../server/types/request/ad-copy/job";
import type { AdCopyDownloadType } from "../../../../components/pages/ad-copy/report/download";
import { AdCopyReportDownloadPage } from "../../../../components/pages/ad-copy/report/download";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { fileDownloadStream, get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/report/download";

export const AdCopyDownloadReportContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading } = state;

	const { jobId } = useParams<{
		jobId: string;
	}>();

	const onDownload = React.useCallback(
		(type: AdCopyDownloadType) => {
			dispatch({ payload: true, type: "changeLoading" });

			fileDownloadStream(`/api/ad-copy/download/${type}/${jobId}`, `${type}.csv`)
				.then(() => {
					dispatch({
						payload: false,
						type: "changeLoading",
					});
				})
				.catch((error) => {
					dispatch({
						payload: makeError(error),
						type: "changeMessageInfo",
					});
				});
		},
		[jobId],
	);

	React.useEffect(() => {
		get<GetAdCopyJobResponse>(`${adCopyEndpoint.jobDetail}/${jobId}`)
			.then((response) => {
				dispatch({
					payload: response.data,
					type: "loadData",
				});
			})
			.catch((error) => {
				dispatch({
					payload: makeError(error),
					type: "changeMessageInfo",
				});
			});
	}, [jobId]);

	const { job } = state;

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} />

			<AdCopyReportDownloadPage job={job} loginAccount={loginAccount} onDownload={onDownload} />
		</>
	);
};
