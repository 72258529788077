import * as React from "react";
import { Form } from "react-bootstrap";
import {
	NoSettingStrategyGroup,
	noSettingStrategyGroupReason,
	NoSettingStrategyGroupReason,
	noSettingStrategyGroupReasonKeys,
} from "../../../../../../../server/models/activity";
import styled from "styled-components";
import Feedback from "react-bootstrap/esm/Feedback";
import { getErrorMessage } from "../../../../../lib/error";
import { ErrorObject } from "../../../../../../../server/types/error";

const CheckWrap = styled.div`
	margin-bottom: 0.5rem;
`;

export type NoSettingStrategyTargetFormProps = {
	noSettingStrategyGroup?: NoSettingStrategyGroup;
	errors?: ErrorObject;
	onChange: (key: keyof NoSettingStrategyGroup, value: NoSettingStrategyGroup[keyof NoSettingStrategyGroup]) => void;
};

export const NoSettingStrategyTargetForm = React.memo((props: NoSettingStrategyTargetFormProps) => {
	const { noSettingStrategyGroup, onChange, errors } = props;
	const onChangeCheckbox = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const checkeds: NoSettingStrategyGroupReason[] =
				noSettingStrategyGroup && noSettingStrategyGroup.reasons ? noSettingStrategyGroup.reasons : [];
			const checked = e.target.checked;
			const value = e.target.value as NoSettingStrategyGroupReason;
			onChange("reasons", checked ? [...checkeds, value] : checkeds.filter((ch) => ch !== value));
		},
		[noSettingStrategyGroup, onChange],
	);
	const onChangeSpecifically = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange("specifically", e.target.value.toString());
		},
		[onChange],
	);
	return (
		<div style={{ marginBottom: "1.5rem" }}>
			{errors && (
				<Feedback type={"invalid"} style={{ display: "block" }}>
					{getErrorMessage("reasons", errors) ?? getErrorMessage("specifically", errors)}
				</Feedback>
			)}
			{noSettingStrategyGroupReasonKeys.map((noSettingStrategyGroupReasonKey) => {
				const checked = noSettingStrategyGroup?.reasons.includes(noSettingStrategyGroupReasonKey);
				return (
					<CheckWrap key={`reason-${noSettingStrategyGroupReasonKey}`}>
						<Form.Check
							checked={checked}
							onChange={onChangeCheckbox}
							value={noSettingStrategyGroupReasonKey}
							name={"reasons"}
							label={
								<>
									<>{noSettingStrategyGroupReason[noSettingStrategyGroupReasonKey]}</>
									{noSettingStrategyGroupReasonKey === "other" && (
										<Form.Control
											size="sm"
											disabled={!checked}
											value={noSettingStrategyGroup?.specifically ?? ""}
											onChange={onChangeSpecifically}
											style={{ display: "inline-block", width: "400px", marginLeft: "1rem" }}
										/>
									)}
								</>
							}
						/>
					</CheckWrap>
				);
			})}
		</div>
	);
});
