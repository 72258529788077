import type { AdCopyActivity } from "../../../../../server/models/ad-copy/activity";

export function composeTargetCategoryBrandsString(activity: AdCopyActivity) {
	const targetCategoryBrandValues = activity.targetCategoryBrandValues;

	if (targetCategoryBrandValues == null) {
		return;
	}

	if (targetCategoryBrandValues.length <= 0) {
		return;
	}

	return activity.category.adCopyBrandlist
		.flatMap((x) => x.surveyChoice)
		.filter((x) => targetCategoryBrandValues.includes(x.value))
		.map((x) => x.label)
		.join("、");
}
