import { ObjectId } from "mongodb";
import { Brand } from "./brand";

export enum AccountRole {
	"admin" = "システム管理者",
	"operation-manager" = "運用管理者",
	"viewer1" = "特権閲覧者",
	"approver" = "承認者",
	"applicant" = "申請者",
	"viewer2" = "閲覧者",
}
export const AccountOptions = Object.entries(AccountRole)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type Account = {
	_id?: string | ObjectId;
	code: string;
	name: string;
	email: string;
	isActive: boolean;
	password: string;
	role: keyof typeof AccountRole;
	brand: (ObjectId | string)[];
};

/**
 * @property {Brand[]} brands 認証で利用するアカウント情報だけはbrandsを持つ
 * @property {Brand[]} viewAdCopyBrands frontでセットされる。型定義の参照のため
 */
export type AccountWithoutPassword = Omit<Account, "password"> & {
	/**
	 * -- warning --
	 * 後日削除予定
	 * brands: 認証で利用するアカウント情報だけはbrandsを持つ
	 * viewAdCopyBrands: frontでセットするが、型定義の参照の問題があるため、ここに置いておく
	 * availableAdCopyBrands: トライアル期間中にコピーテストで利用可能なブランド
	 */
	brands?: Brand[];
	viewAdCopyBrands?: Brand[];
	availableAdCopyBrands?: Omit<Brand, "brandRecognition">[];
};
