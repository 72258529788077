import * as React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { Confirm } from "../confirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export type ConfirmButtonProps = {
	type?: "button" | "times-circle";
	variant?: ButtonProps["variant"];
	buttonText: string;
	title: string;
	body: string | JSX.Element | JSX.Element[];
	onCancel?: () => void;
	onOk: () => void;
};

export const ConfirmButton = React.memo((props: ConfirmButtonProps) => {
	const { type, variant, buttonText, title, body, onCancel, onOk } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const onClose = React.useCallback(() => {
		setShow(false);
		if (onCancel) onCancel();
	}, [onCancel]);
	const onOkClick = React.useCallback(() => {
		setShow(false);
		onOk();
	}, [onOk]);
	const onOpen = React.useCallback(() => setShow(true), []);
	return (
		<>
			{type === "times-circle" ? (
				<FontAwesomeIcon icon={faTimesCircle} onClick={onOpen} />
			) : (
				<Button variant={variant || "outline-danger"} onClick={onOpen}>
					{buttonText}
				</Button>
			)}

			<Confirm
				show={show}
				onCancel={onClose}
				onOk={onOkClick}
				title={title}
				body={body}
				buttonText={buttonText}
				variant={variant}
			/>
		</>
	);
});
