import * as React from "react";
import styled from "styled-components";

import type { OutputTableData } from "../../../../../../../server/models/ad-copy/output";
import { AdCopyReportTitle } from "../parts/report-title";
import { OutputTbody } from "./output-tbody";
import { OutputThead } from "./output-thead";

const StyledTable = styled.table`
	td,
	th {
		border: 1px dotted #888;
		padding: 3px;
	}

	td {
		min-width: 6rem;
	}
`;

type Props = {
	tableData: OutputTableData;
};

export const AdCopyResultSummary = React.memo((props: Props) => {
	const { tableData } = props;

	return (
		<>
			<AdCopyReportTitle>結果サマリー</AdCopyReportTitle>

			{/* TODO: 需要予測が開始されたら、ここにポテンシャル需要のテーブルが設置される。下のテーブルと一緒かも？ */}

			{/* 差別性、情報検索意向、購入意向 */}
			<StyledTable className={tableData.className} style={tableData.style}>
				<OutputThead data={tableData.tHead} />

				{tableData.tBodies.map((tBody, i) => (
					<OutputTbody key={i} data={tBody} />
				))}
			</StyledTable>
		</>
	);
});
