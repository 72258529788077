import * as React from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

const LoadWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: RGB(0, 0, 0, 0.4);
	color: white;
	z-index: 2000;
	top: 0px;
	left: 0px;
`;

export const Loading = React.memo((props: { loading: boolean; text?: string }) => (
	<LoadWrapper style={{ display: props.loading ? "" : "none" }}>
		<Spinner animation="border" variant="light" />
		{props.text || "loading..."}
	</LoadWrapper>
));
