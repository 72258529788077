import React, { memo } from "react";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";

const modules = {
	toolbar: [
		[{ font: [] as string[] }],
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		["bold", "italic", "underline", "strike"],
		[{ color: [] as string[] }, { background: [] as string[] }],
		[{ script: "sub" }, { script: "super" }],
		["blockquote", "code-block"],
		[{ list: "ordered" }, { list: "bullet" }],
		[{ indent: "-1" }, { indent: "+1" }, { align: [] as string[] }],
		["link", "image", "video"],
		["clean"],
	],
};

type Props = {
	content: string;
	onContentChange: (content: string) => void;
	onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	title: string;
};

const _InfoEditor = (props: Props) => {
	const { content, onContentChange, onTitleChange, title } = props;

	return (
		<Form>
			<Form.Group controlId="infoTitleForm">
				<Form.Label>タイトル</Form.Label>
				<Form.Control type="text" placeholder="お知らせタイトル" value={title} onChange={onTitleChange} />
			</Form.Group>
			<Form.Group controlId="infoContentForm">
				<Form.Label>本文</Form.Label>
				<ReactQuill
					value={content}
					onChange={onContentChange}
					theme="snow"
					modules={modules}
					placeholder="お知らせ本文"
				/>
			</Form.Group>
		</Form>
	);
};

export const InfoEditor = memo(_InfoEditor);
