import { SubTitle } from "../../../../../../react-components/lu-component/src/index";
import * as React from "react";
import { JobPage, JobPageType } from "..";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { JobWithSurveyStatus } from "../../../../../../server/models/job";
import { JobStatus, surveyStatusOptions } from "../../../../../../server/models/status";
import { GetJobAllResponse } from "../../../../../../server/types/request/job";
import { Search, SearchColumn } from "../../../parts/search";
import { TextSearch } from "../../../parts/text-search";

export type JobAllPageProps = GetJobAllResponse & {
	searches: { [key in keyof JobWithSurveyStatus]?: JobWithSurveyStatus[key] };
	onChangeSearches: <K extends keyof JobWithSurveyStatus>(name: K, value: JobWithSurveyStatus[K]) => void;
	loginAccount: AccountWithoutPassword;
	onEditPage: (id: string) => void;
	onOutlinePage: (id: string) => void;
	onChangePage: (id: string, page: JobPageType) => void;
	onJobStatusChange: (id: string, status: keyof typeof JobStatus) => void;
	onRetest: (id: string) => void;
	onAggregatePage: (id: string) => void;
};

export const JobAllPage = React.memo((props: JobAllPageProps) => {
	const { data, accountOptions, searches, onChangeSearches, ...jobPageProps } = props;
	const searchColums: SearchColumn[] = React.useMemo(
		() => [
			{
				name: "jobNum",
				label: "ジョブNo.",
				type: "string",
			},
			{
				name: "surveyStatus",
				type: "select",
				label: "調査ステータス",
				options: surveyStatusOptions,
			},
		],
		[],
	);
	const filteredData = React.useMemo(() => {
		const { jobNum, surveyStatus } = searches;
		if (!jobNum && !surveyStatus) return data;
		return data
			.map((d) => {
				const jobs = d.jobs.filter((job) => {
					if (jobNum && jobNum !== job.jobNum) return false;
					if (surveyStatus && surveyStatus !== job.surveyStatus) return false;
					return true;
				});
				if (jobs.length === 0) return undefined;
				return { ...d, jobs };
			})
			.filter((d) => !!d);
	}, [data, searches]);

	return (
		<TextSearch>
			<div className="m-3 clearfix">
				<div className="h3 float-start">■ジョブ検索</div>
			</div>
			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>検索</SubTitle>
				<Search columns={searchColums} searchValues={searches as any} onChangeSearchValue={onChangeSearches as any} />
			</div>
			{filteredData.map((d) => (
				<JobPage
					key={`job-page-${d.activity._id.toString()}`}
					activity={d.activity}
					jobs={d.jobs}
					hideTitle
					accountOptions={accountOptions}
					{...jobPageProps}
				/>
			))}
		</TextSearch>
	);
});
