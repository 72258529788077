import * as React from "react";
import { Navigate, useParams } from "react-router-dom";

import { adCopyEndpoint } from "../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyProgressStatusResponse } from "../../../../../server/types/request/ad-copy/activity";
import { get } from "../../../lib/request";
import { clientEndpoint } from "../../../routes/endpoint";

type Props = {
	children: (isEditalbe: boolean) => React.ReactNode;
};

export const AdCopyActivityProgress = React.memo((props: Props) => {
	const { children } = props;

	const [requested, setRequested] = React.useState(false);

	const [isEditalbe, setIsEditalbe] = React.useState<boolean>();

	const { activityId } = useParams<{ activityId: string }>();

	React.useEffect(() => {
		if (activityId === "new") {
			setRequested(true);

			setIsEditalbe(true);

			return;
		}

		get<GetAdCopyProgressStatusResponse>(`${adCopyEndpoint.activityProgressStatus}/${activityId}`)
			.then((response) => {
				setIsEditalbe(response.data.status === "preparation");

				setRequested(true);
			})
			.catch(() => {
				setRequested(true);
			});
	}, [activityId]);

	return (
		requested &&
		(isEditalbe === undefined ? (
			// 認証がない場合はloginへ遷移
			<Navigate to={clientEndpoint.permissionDenied} />
		) : (
			children(isEditalbe)
		))
	);
});
