import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { AdCopyRetestMenu } from "../../../../components/pages/ad-copy/report/common";
import { AdCopyKeyMeasurementsReportPage } from "../../../../components/pages/ad-copy/report/key-measurements";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/report/key-mearurements";
import type { GetAdCopyKeyMeasurementsResponse } from "../../../../types/ad-copy/report";

export const AdCopyKeyMeasurementsReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { data, info, job, loading } = state;

	const { activityId, jobId } = useParams<{
		activityId: string;
		jobId: string;
	}>();

	React.useEffect(() => {
		get<GetAdCopyKeyMeasurementsResponse>(`${adCopyEndpoint.keyMeasurementsReport}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadData" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	return (
		<>
			<Loading loading={loading} />

			{job && job.retestJobId && (
				<AdCopyRetestMenu activityId={activityId ?? ""} jobId={job.retestJobId.toString()} page="key-mesurements" />
			)}

			<ConfirmBox info={info} />

			<AdCopyKeyMeasurementsReportPage data={data} job={job} />
		</>
	);
};
