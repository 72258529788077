import { InfoAction } from "../../types/actions";
import { MessageInfo } from "../../types/info";
import { Info } from "../../../../server/models/info";

type State = {
	confirmType?: "delete" | "create";
	loading: boolean;
	info: MessageInfo;
	message?: Info;
	messages: Info[];
};

export const initState: State = {
	messages: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: InfoAction): State => {
	switch (action.type) {
		case "loadInfoAll":
			return {
				...state,
				loading: false,
				message: action.payload.messages[0],
				messages: action.payload.messages,
			};

		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};

		case "updateMessage": {
			const message = action.payload;
			const messages = [message, ...state.messages];

			return {
				...state,
				confirmType: "create",
				loading: false,
				message,
				messages,
				info: { message: "お知らせの更新が完了しました。", isSuccess: true },
			};
		}

		case "updateMessageContent":
			return {
				...state,
				message: {
					...state.message,
					content: action.payload,
				},
			};

		case "updateMessageTitle":
			return {
				...state,
				message: { ...state.message, title: action.payload },
			};

		case "clearMessageTitleAndContent":
			return {
				...state,
				message: { ...state.message, content: "", title: "" },
			};

		case "resetMessageTitleAndContent":
			return {
				...state,
				message: { ...state.message, content: state.messages[0].content, title: state.messages[0].title },
			};

		case "deleteSinglePastInfo": {
			return {
				...state,
				confirmType: "delete",
				info: { message: "お知らせの削除が完了しました。", isSuccess: true },
				loading: false,
				messages: state.messages.filter((message) => String(message._id) !== action.payload),
			};
		}

		case "changeConfirmType": {
			return {
				...state,
				confirmType: action.payload,
			};
		}

		default:
			return state;
	}
};
