import * as React from "react";
import { NormType, Norm } from "../../../../../../../../server/models/graph/norm";
import styled from "styled-components";
import { TextRed } from "../../../../../parts/font";
import { Job } from "../../../../../../../../server/models/job";
import { isLack, doJudge, normJudgeStandard, getAnalyticsVersion } from "../../../../../../lib/action-standard";

const ReportTable = styled.table`
	td,
	th {
		border: 1px solid #888;
		padding: 5px;
		text-align: center;
	}
	th {
		background-color: #ddd;
	}
`;
const Title = styled.div`
	margin-top: 10px;
	padding-left: 10px;
`;

// △判定が入ってきたらいやなので、booleanにはしない
export type Judge = "〇" | "×";
export type SummaryResult = {
	sampleSize: number;
	judge: Judge;
};
export type Versus = {
	category?: SummaryResult;
	strategy?: SummaryResult;
};
export type SummaryProps = {
	label: string;
	potential: Judge;
	norm?: NormType;
	vsBenchmark?: Versus;
	vsCurrent?: Versus;
	job: Job;
	// 以下を追加
	vsBenchmarkByOriginality?: Versus;
	vsCurrentByOriginality?: Versus;
};

export const VersusRow = React.memo((props: { versus: Versus; label: string; rowSpan?: number; job: Job }) => {
	const { versus, rowSpan, label, job } = props;
	const version = React.useMemo(() => getAnalyticsVersion(job.endDatetime), [job.endDatetime]);
	const data = React.useMemo(() => {
		const d: { key: "category" | "strategy"; label: string; value: string; lack: boolean; doJudge?: boolean }[] = [];
		if (versus.category && (version === "v1" || version === "v2"))
			d.push({
				key: "category",
				label: "カテゴリターゲット",
				value: versus.category.judge,
				lack: isLack(versus.category.sampleSize),
				doJudge: true,
			});
		if (versus.strategy)
			d.push({
				key: "strategy",
				label: "戦略ターゲット",
				value: versus.strategy.judge,
				lack: isLack(versus.strategy.sampleSize),
				/**
				 * No.8 エグゼクティブサマリーで戦略ターゲットGがn30未満であればＡＳ判定しない
				 * 戦略ターゲットのみ判定するかのチェックを行う。
				 */
				doJudge: doJudge(versus.strategy.sampleSize, job.endDatetime),
			});
		return d;
	}, [versus.category, versus.strategy, version, job.endDatetime]);
	return (
		<>
			{data.map((d, index) => (
				<tr key={`judge-${d.key}`}>
					{index === 0 && (
						<>
							{rowSpan && <td rowSpan={rowSpan}>購入意向</td>}
							<td rowSpan={data.length}>{`対 ${label}比較`}</td>
						</>
					)}
					{d.lack ? (
						<>
							<td>
								{/* No.8 エグゼクティブサマリーで戦略ターゲットGがn30未満であればＡＳ判定しない */}
								<TextRed>{d.doJudge ? d.value : "-"}</TextRed>
							</td>
							<td>
								<TextRed>{d.label}</TextRed>
							</td>
						</>
					) : (
						<>
							<td>{d.value}</td>
							<td>{d.label}</td>
						</>
					)}
					{index === 0 && (
						<td rowSpan={data.length}>
							ワンナンバースコアが
							<br />
							{label}を上回る
						</td>
					)}
				</tr>
			))}
		</>
	);
});

export const ActionStandardTable = React.memo((props: SummaryProps) => {
	const { label, potential, norm, vsBenchmark, vsCurrent, job } = props;
	const rowSpan = React.useMemo(() => {
		let cnt = 0;
		if (norm) cnt++;
		if (vsBenchmark && vsBenchmark.category) cnt++;
		if (vsBenchmark && vsBenchmark.strategy) cnt++;
		if (vsCurrent && vsCurrent.category) cnt++;
		if (vsCurrent && vsCurrent.strategy) cnt++;
		return cnt;
	}, [vsBenchmark, vsCurrent, norm]);
	const viewPotential = React.useMemo(() => {
		const version = getAnalyticsVersion(job.endDatetime);
		return version === "v1" || version === "v2";
	}, [job]);
	return (
		<>
			<Title>{label}</Title>
			<ReportTable>
				<thead>
					<tr>
						<th colSpan={2}>AS指標</th>
						<th>判定結果</th>
						<th>判定のベース</th>
						<th>判定基準</th>
					</tr>
				</thead>
				<tbody>
					{viewPotential && (
						<tr>
							<td colSpan={2}>ポテンシャル需要</td>
							<td>{potential}</td>
							<td>REP</td>
							<td>目標値の△50%以内</td>
						</tr>
					)}
					{norm && (
						<tr>
							<td rowSpan={rowSpan}>購入意向</td>
							<td>ノーム判定</td>
							<td>{Norm[norm]}</td>
							<td>カテゴリターゲット</td>
							<td>{normJudgeStandard(job.endDatetime)}ランク以上</td>
						</tr>
					)}
					{!!vsBenchmark && (
						<VersusRow rowSpan={!norm ? rowSpan : undefined} versus={vsBenchmark} label={"競合"} job={job} />
					)}
					{!!vsCurrent && (
						<VersusRow
							rowSpan={!norm && !vsBenchmark ? rowSpan : undefined}
							versus={vsCurrent}
							label={"現行"}
							job={job}
						/>
					)}
				</tbody>
			</ReportTable>
		</>
	);
});
