import { JobStatusAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";
import { JobStatus } from "../../../../../server/models/status";

export type State = {
	status: keyof typeof JobStatus;
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	status: undefined,
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: JobStatusAction): State => {
	switch (action.type) {
		case "loadStatus":
			return {
				...state,
				loading: false,
				status: action.payload,
			};
		case "changeStatus":
			return {
				...state,
				status: action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
