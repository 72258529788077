import type { AdCopyKeyMeasurementsAction } from "../../../../types/actions";
import type { GetAdCopyKeyMeasurementsResponse } from "../../../../types/ad-copy/report";
import type { MessageInfo } from "../../../../types/info";

type State = {
	data: GetAdCopyKeyMeasurementsResponse["data"];
	info: MessageInfo;
	job?: GetAdCopyKeyMeasurementsResponse["job"];
	loading: boolean;
};

export const initState: State = {
	data: {
		tBodies: [],
		tHead: {
			trs: [],
		},
	},
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
};

export const reducer = (state: State, action: AdCopyKeyMeasurementsAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
