import * as React from "react";
import styled from "styled-components";
import { PurchaseIntentSKU } from "../../../../../../../server/models/graph/purchase-intent-sku";
import { ReportTitle } from "../../parts/report-title";
import { BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";
import { ConceptType } from "../../../../../../../server/models/concept";

const SubTitle = styled.div`
	padding: 10px 5px;
`;
const GraphWrap = styled.div`
	max-width: 1000px;
	margin: 0px auto;
`;
type SKUGraphProps = {
	data: PurchaseIntentSKU;
};
const barSize = 30;
const SKUGraph = React.memo((props: SKUGraphProps) => {
	const { data } = props;
	const left = React.useMemo(() => {
		return data.skuChart.reduce((a, { label }) => {
			const w = label.length * 10;
			if (w > a) return Math.min(w, 500);
			return a;
		}, 0);
	}, [data]);
	return (
		<GraphWrap>
			<SubTitle>{ConceptType[data.conceptType]}</SubTitle>
			<BarChart
				data={data.skuChart}
				layout={"vertical"}
				height={barSize * 2 * data.skuChart.length}
				width={1000}
				margin={{ top: 5, right: 30, left, bottom: 5 }}
				barSize={barSize}
			>
				<XAxis type={"number"} domain={[0, 100]} orientation={"top"} />
				<YAxis type={"category"} dataKey={"label"} />
				<Bar dataKey={"val"} fill={"#aaa"}>
					<LabelList dataKey={"val"} position={"right"} formatter={(v: number | string) => `${v}%`} />
				</Bar>
			</BarChart>
		</GraphWrap>
	);
});

export type SKUGraphsProps = {
	data: PurchaseIntentSKU[];
};

export const SKUGraphs = React.memo((props: SKUGraphsProps) => {
	const { data } = props;
	return (
		<>
			<ReportTitle>参考）ポテンシャル需要（発売後12か月お客さま購買個数）SKU別構成比</ReportTitle>
			{data.map((d, index) => (
				<SKUGraph key={`skugraph-${d.conceptType}-${index}`} data={d} />
			))}
		</>
	);
});
