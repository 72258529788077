import * as React from "react";

import type { AdCopyConceptKey } from "../../../../../../../../../server/models/ad-copy/concept";
import type { AdCopyTargetGroup } from "../../../../../../../../../server/models/ad-copy/enquete";
import { adCopyTargetGroupMap } from "../../../../../../../../../server/models/ad-copy/enquete";
import type { AdCopySampleSizes } from "../../../../../../../../../server/types/request/ad-copy/report/outline";

type Props = {
	filteredOrderedConceptTypes: AdCopyConceptKey[];
	sampleSizes: AdCopySampleSizes;
	targetGroupType: AdCopyTargetGroup;
};

export const AdCopySampleSizeRow = React.memo((props: Props) => {
	const { filteredOrderedConceptTypes, sampleSizes, targetGroupType } = props;

	return (
		<tr className="bordered">
			<td className="names-area">{adCopyTargetGroupMap[targetGroupType]}</td>

			{filteredOrderedConceptTypes.map((conceptType) => {
				const sampleSize = sampleSizes[conceptType];

				return (
					<td key={conceptType} className={sampleSize != null && sampleSize < 30 ? "missing-sample" : ""}>
						{sampleSize ? `${sampleSize}s` : "-"}
					</td>
				);
			})}
		</tr>
	);
});
