import * as React from "react";
import { GroupChoice, Choice } from "../../types/column";
import { FormGroup, FormCheck } from "react-bootstrap";

export type GroupCheckboxProps = {
	groups: GroupChoice[];
	checkeds: Choice["value"][];
	name: string;
	onChange: (checkedValues: Choice["value"][]) => void;
};

export const GroupCheckbox = React.memo((props: GroupCheckboxProps) => {
	const { groups: checkboxGroups, checkeds, onChange, name } = props;
	const onChangeCatergory = React.useCallback(
		(id: string, checked: boolean) => {
			const target = checkboxGroups.find((checkboxGroup) => checkboxGroup.id === id);
			const tmp = new Set(checkeds);
			target.choices.forEach((choice) => {
				if (checked) {
					tmp.add(choice.value);
				} else {
					tmp.delete(choice.value);
				}
			});
			onChange(Array.from(tmp));
		},
		[checkboxGroups, onChange, checkeds],
	);
	const onChangeItem = React.useCallback(
		(value: Choice["value"], checked: boolean) => {
			onChange(checked ? checkeds.concat(value) : checkeds.filter((c) => c !== value));
		},
		[onChange, checkeds],
	);
	return (
		<>
			<FormGroup>
				{checkboxGroups.map((checkboxGroup, index) => {
					return (
						<React.Fragment key={`checkbox-group-${index}`}>
							<FormCheck
								label={checkboxGroup.label}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									onChangeCatergory(checkboxGroup.id, e.target.checked);
								}}
							/>
							<div style={{ marginLeft: "15px", marginBottom: "5px" }}>
								{checkboxGroup.choices.map((choice) => (
									<FormCheck
										key={`checkbox-group-${index}-${choice.value}`}
										label={choice.label}
										checked={checkeds.includes(choice.value)}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											onChangeItem(choice.value, e.target.checked);
										}}
										name={name}
									/>
								))}
							</div>
						</React.Fragment>
					);
				})}
			</FormGroup>
		</>
	);
});
