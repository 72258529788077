import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyJobStatus } from "../../../../../../server/models/ad-copy/status";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type {
	GetAdCopyJobSearchResponse,
	PutAdCopyJobStatusResponse,
} from "../../../../../../server/types/request/ad-copy/job";
import type { GetAdCopyEnqueteTestResponse } from "../../../../../../server/types/request/ad-copy/preview";
import type { AdCopyJobPageType } from "../../../../components/pages/ad-copy/job";
import type { AdCopyJobSearchPageProps } from "../../../../components/pages/ad-copy/job/search";
import { AdCopyJobSearchPage } from "../../../../components/pages/ad-copy/job/search";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/search";
import { adCopyClientEndpoint, adCopyRoot } from "../../../../routes/adCopyEndpoint";

const getActivityId = (data: GetAdCopyJobSearchResponse["data"], jobId: string) => {
	for (const d of data) {
		if (d.jobs.some((job) => job._id?.toString() === jobId)) {
			return d.activity._id?.toString() ?? "";
		}
	}

	return "";
};

export const AdCopyJobSearchContainer = (loginAccount: AccountWithoutPassword): JSX.Element => {
	// states

	const [state, dispatch] = React.useReducer(reducer, initState);
	const { accountOptions, data, info, loading, result, searches } = state;

	// hooks

	const navigate = useNavigate();

	// handlers

	const onEditPage = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(adCopyClientEndpoint.jobCreate, {
					activityId: getActivityId(data, id),
					jobId: id,
				}),
			);
		},
		[data, navigate],
	);

	const onRetest = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(adCopyClientEndpoint.jobCreateRetest, {
					activityId: getActivityId(data, id),
					retestJobId: id,
				}),
			);
		},
		[data, navigate],
	);

	// edit a particular job that we have fetched ig
	const onChangePage = React.useCallback(
		(id: string, page: AdCopyJobPageType) => {
			if (page === "enquete-test") {
				get<GetAdCopyEnqueteTestResponse>(`${adCopyEndpoint.enqueteTest}/${id}`)
					.then((response) => {
						window.open(response.data.url);
					})
					.catch((error) => {
						dispatch({ payload: makeError(error), type: "changeMessageInfo" });
					});
			} else {
				navigate(`${adCopyRoot}/${page}/${getActivityId(data, id)}/${id}`);
			}
		},
		[data, navigate],
	);

	// go to another page
	const onOutlinePage = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(adCopyClientEndpoint.outline, {
					activityId: getActivityId(data, id),
					jobId: id,
				}),
			);
		},
		[data, navigate],
	);

	// change job
	const onJobStatusChange = React.useCallback((jobId: string, status: keyof typeof AdCopyJobStatus) => {
		put<PutAdCopyJobStatusResponse>(`${adCopyEndpoint.jobStatus}/${jobId}`, { status })
			.then((response) => {
				dispatch({
					payload: { ...response.data, jobId },
					type: "changeJobStatus",
				});
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, []);

	// change the search tags/filters/data
	const handleChangeSearches = React.useCallback<AdCopyJobSearchPageProps["onChangeSearches"]>((name, value) => {
		dispatch({ payload: { name, value }, type: "changeSearches" });
	}, []);

	// make this work
	const handleSubmit = React.useCallback(() => {
		dispatch({ payload: true, type: "changeLoading" });

		get<GetAdCopyJobSearchResponse>(adCopyEndpoint.jobSearch, searches)
			.then((response) => {
				dispatch({ payload: response.data, type: "setJob" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [searches]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="処理" />

			<AdCopyJobSearchPage
				accountOptions={accountOptions}
				data={data}
				loginAccount={loginAccount}
				onChangePage={onChangePage}
				onChangeSearches={handleChangeSearches}
				onEditPage={onEditPage}
				onJobStatusChange={onJobStatusChange}
				onOutlinePage={onOutlinePage}
				onRetest={onRetest}
				onSubmit={handleSubmit}
				result={result}
				searches={searches}
			/>
		</>
	);
};
