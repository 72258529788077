import * as React from "react";
import { Col, Row, FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import styled from "styled-components";
import { Sku } from "../../../../../server/models/concept";
import Feedback from "react-bootstrap/Feedback";
import { getErrorMessage } from "../../../lib/error";
import { ErrorObject } from "../../../../../server/types/error";

export type SkuFormProps = {
	sku: Sku;
	errors?: ErrorObject;
	onChange: (name: keyof Sku, value: Sku[keyof Sku]) => void;
	setTaxIncluded: () => void;
};

const TaxIncludedArea = styled.div`
	display: inline-block;
	width: 85%;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ddd;
	background-color: #eee;
	border-radius: 0.25rem;
`;
const SubTitle = styled.div`
	padding: 0.4rem;
	margin-bottom: 0.4rem;
	border-bottom: 1px solid #ddd;
`;
export const SkuForm = React.memo((props: SkuFormProps) => {
	const { setTaxIncluded, sku, onChange, errors } = props;
	const onBlurPrice = React.useCallback(() => {
		if (sku.price) {
			setTaxIncluded();
		}
	}, [setTaxIncluded, sku]);
	const onChangePrice = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const tmp = Number(e.target.value);
			if (isNaN(tmp)) {
				return alert("半角数値で入力してください。");
			}
			onChange("price", tmp);
		},
		[onChange],
	);
	return (
		<>
			<Row>
				<Col md={4}>
					<FormGroup>
						<FormLabel>SKU説明（容量必須）</FormLabel>
						<FormControl
							value={sku.name || ""}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("name", e.target.value)}
							isInvalid={!!errors && !!errors["name"]}
						/>
						{!!errors && !!errors["name"] && <Feedback type={"invalid"}>{getErrorMessage("name", errors)}</Feedback>}
					</FormGroup>
				</Col>
				<Col md={4}>
					<FormGroup>
						<FormLabel>価格（税抜金額）</FormLabel>
						<div>
							<FormControl
								onBlur={onBlurPrice}
								value={sku.price === undefined ? "" : sku.price.toString()}
								onChange={onChangePrice}
								isInvalid={!!errors && !!errors["price"]}
								style={{ width: "85%", display: "inline" }}
							/>
							円
						</div>
						{!!errors && !!errors["price"] && (
							<Feedback type={"invalid"} style={{ display: "block" }}>
								{getErrorMessage("price", errors)}
							</Feedback>
						)}
					</FormGroup>
				</Col>
				<Col md={4}>
					<FormGroup>
						<FormLabel>価格（税込金額:消費税10%）</FormLabel>
						<TaxIncludedArea>{sku.includeTaxPrice || "　"}</TaxIncludedArea>円
						{!!errors && !!errors["includeTaxPrice"] && (
							<Feedback type={"invalid"} style={{ display: "block" }}>
								{getErrorMessage("includeTaxPrice", errors)}
							</Feedback>
						)}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
});

export type MultipleSkuFormProps = {
	skuItems: Sku[];
	errors?: ErrorObject;
	onAddSku: (index: number) => void;
	onDeleteSku: (index: number) => void;
	onChangeSku: (index: number, name: keyof Sku, value: Sku[keyof Sku]) => void;
	setTaxIncluded: (index: number) => void;
};

export const MultipleSkuForm = React.memo((props: MultipleSkuFormProps) => {
	const { skuItems, onChangeSku, setTaxIncluded, onAddSku, onDeleteSku, errors } = props;
	return (
		<>
			{skuItems.length === 0 && (
				<div style={{ padding: "10px" }}>
					<Button variant={"outline-secondary"} onClick={() => onAddSku(0)}>
						価格・容量設定
					</Button>
				</div>
			)}
			{skuItems.map((skuItem, index) => (
				<Row key={`sku-item-${index}`}>
					<Col md={12}>
						<SubTitle>{index + 1}SKU目</SubTitle>
					</Col>
					<Col md={8}>
						<SkuForm
							errors={
								errors && errors[index] && typeof errors[index] !== "string"
									? (errors[index] as ErrorObject)
									: undefined
							}
							sku={skuItem}
							setTaxIncluded={() => setTaxIncluded(index)}
							onChange={(name, value) => onChangeSku(index, name, value)}
						/>
					</Col>
					<Col>
						<Button variant={"outline-danger"} style={{ display: "inline" }} onClick={() => onDeleteSku(index)}>
							×
						</Button>
						<Button variant={"outline-secondary"} style={{ display: "inline" }} onClick={() => onAddSku(index + 1)}>
							+
						</Button>
					</Col>
				</Row>
			))}
		</>
	);
});
