import type { Option } from "../../../../../../react-components/lu-component/src/index";
import { isOverCost } from "../../../../../../server/lib/ad-copy/aggregate/estimate";
import type { AdCopyApplication } from "../../../../../../server/models/ad-copy/application";
import type { AdCopyApplicationAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	accountOptions: Option[];
	application: AdCopyApplication;
	info: MessageInfo;
	loading: boolean;
	// 100万超える場合に出すアラート。問題なければtrueになる。
	overEstimateAgreement: boolean;
};

export const initState: State = {
	accountOptions: [],
	application: {
		accountId: "",
		applicationDate: "",
		applicationMessage: "",
		approve: [{ approver: "" }],
		jobId: "",
	},
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	overEstimateAgreement: true,
};

export const reducer = (state: State, action: AdCopyApplicationAction): State => {
	switch (action.type) {
		case "loadApplication":
			return {
				...state,
				accountOptions: action.payload.accountOptions,
				application: action.payload.application || state.application,
				loading: false,
				overEstimateAgreement: !(
					(!action.payload.application || action.payload.application.cancel) &&
					isOverCost(action.payload.estimate)
				),
			};

		case "changeAgreement":
			return {
				...state,
				overEstimateAgreement: action.payload,
			};

		case "updateApplication":
			return {
				...state,
				application: action.payload,
				info: {
					isSuccess: true,
					message: "申請が完了しました。",
				},
				loading: false,
			};

		case "cancelApplication":
			return {
				...state,
				application: action.payload,
				info: {
					isSuccess: true,
					message: "申請を取り消しました。",
				},
				loading: false,
			};

		case "changeApplication":
			return {
				...state,
				application: { ...state.application, [action.payload.name]: action.payload.value },
			};

		case "changeApprover":
			return {
				...state,
				application: {
					...state.application,
					approve:
						!action.payload.value && action.payload.index === 1
							? [state.application.approve[0]]
							: state.application.approve.length <= action.payload.index
							? state.application.approve.concat({ approver: action.payload.value })
							: state.application.approve.map((ap, index) => {
									if (index === action.payload.index) {
										return {
											...ap,
											approver: action.payload.value,
										};
									}

									return ap;
							  }),
				},
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return { ...state, loading: action.payload };
	}
};
