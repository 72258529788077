import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyConcept } from "../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { AdCopyJobDetailAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	activity?: AdCopyActivityWithBrand;
	concepts: AdCopyConcept[];
	estimate?: string;
	evaluationItem?: string[];
	info: MessageInfo;
	job?: AdCopyJob;
	loading: boolean;
};

export const initState: State = {
	concepts: [],
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
};

export const reducer = (state: State, action: AdCopyJobDetailAction): State => {
	switch (action.type) {
		case "loadJob":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return { ...state, loading: action.payload };
	}
};
