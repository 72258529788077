import styled from "styled-components";

export const TextRed = styled.span`
	color: red;
	font-weight: bold;
`;

export const TextRedAndUnderline = styled.span`
	color: red;
	font-weight: bold;
	text-decoration: underline;
`;

export const TextBlue = styled.span`
	color: blue;
	font-weight: bold;
`;

export const TextBlueAndUnderline = styled.span`
	color: blue;
	font-weight: bold;
	text-decoration: underline;
`;

export const TextUnderDecorate = styled.span`
	text-decoration: underline;
`;

export const StrongAndUnderDecorate = styled.span`
	text-decoration: underline;
	font-weight: bold;
`;

export const StrongDecorate = styled.span`
	font-weight: bold;
`;

export const AlertText = styled.span`
	color: red;
`;
