/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Option } from "../../../../../../../../react-components/lu-component/src/index";
import { Radios } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopyFacewashFunctionOptions } from "../../../../../../../../server/models/ad-copy/activity";
import {
	canSetFacewachFunction,
	isCleansing,
	isFaceWash,
	Category,
} from "../../../../../../../../server/models/category";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	categoryMap: Record<string, Category>;
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyFacewashFunctionRadios = React.memo((props: Props) => {
	const { categoryMap, onChange, strategyGroup } = props;

	const filterFacewashFunctionOptions = React.useMemo(() => {
		if (!strategyGroup.category || strategyGroup.category.length === 0) {
			return;
		}

		const categories = strategyGroup.category.filter((category) => {
			const id = category.category._id?.toString() ?? "";

			if (!(id in categoryMap)) {
				return false;
			}

			return canSetFacewachFunction(categoryMap[id]);
		});

		if (!categories.length) {
			return;
		}

		return adCopyFacewashFunctionOptions.filter(({ value }) => {
			if (value.toString() === "3") {
				return false;
			}

			return categories.some((cate) => {
				if (value === "0") {
					return isFaceWash(cate.category);
				}

				if (value === "1") {
					return isCleansing(cate.category);
				}

				return true;
			});
		});
	}, [categoryMap, strategyGroup.category]);

	const facewashFunctionChecked = React.useMemo(() => {
		if (!filterFacewashFunctionOptions) {
			return;
		}

		if (!strategyGroup.facewashFunction) {
			return;
		}

		return strategyGroup.facewashFunction;
	}, [strategyGroup, filterFacewashFunctionOptions]);

	return (
		<Row>
			<FormGroup as={Col} md={5}>
				<AccordionForm
					defaultShow={!!strategyGroup.facewashFunction}
					eventKey="facewashFunction"
					title={adCopyStrategyTargetGroupLabels.facewashFunction ?? ""}
				>
					{filterFacewashFunctionOptions ? (
						<Radios
							checked={facewashFunctionChecked ?? ""}
							// その他は除外
							choices={filterFacewashFunctionOptions as Option[]}
							name="facewashFunction"
							onChange={(value) => {
								onChange("facewashFunction", value);
							}}
						/>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
