import { Labels } from "../";
import { AdCopyJob } from "../../server/models/ad-copy/job";

export const adCopyJobLabels: Labels<AdCopyJob> = {
	_id: "ジョブ",
	activityId: "アクティビティ",
	jobNum: "ジョブNo",
	accountId: "担当者",
	startDatetime: "開始日時",
	endDatetime: "終了日時",
	numOfTests: "自社テスト品の数",
	hasCurrentProduct: "現行品の有無",
	hasBenchmark: "競合品の有無",
	benchmarkName: "競合品製品名",
	hasKeyVisual: "キービジュアルの有無",
	retestJobId: "再調査",
	status: "ステータス",
	method: "調査手法",
	enqueteId: "アンケートID",
};
