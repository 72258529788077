import * as React from "react";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { reducer, initState } from "../../../reducers/job/quota";
import { QuotaPage } from "../../../components/pages/job/quota";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { useParams } from "react-router-dom";
import { get, makeError } from "../../../lib/request";
import { GetQuotasResponse } from "../../../../../server/types/request/quota";
import { endpoint } from "../../../../../server/router/endpoint";
import { ConfirmBox } from "../../../components/parts/confirm-box";

export const QuotaContainer = (_account: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { quotas, info, loading } = state;
	const { jobId } = useParams<{ jobId: string }>();
	const [isCMI, setIsCMI] = React.useState<boolean>(false);
	React.useEffect(() => {
		get<GetQuotasResponse>(`${endpoint.quota}/${jobId}`)
			.then((response) => {
				dispatch({ type: "setQuotas", payload: response.data.quotas });
				setIsCMI(response.data.mode === "admin");
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"取得処理"} />
			<QuotaPage isCMI={isCMI} quotas={quotas} />
		</>
	);
};
