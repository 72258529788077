import * as React from "react";
import { Activity } from "../../../../../../../server/models/activity";
import { Job } from "../../../../../../../server/models/job";
import { Sample } from "../../../../../../../server/models/graph/outline";
import { SamplePanel } from "./sample-panel";
import { TargetPanel } from "./target-panel";
import { OutlinePanel, OutlinePanelTitle } from "..";

export type TestPanelProps = {
	activity: Activity;
	job: Job;
	samples: Sample[];
	benchmarkName?: string;
	onViewQuotaPage: () => void;
};
export const TestPanel = React.memo((props: TestPanelProps) => {
	const { activity, job, samples, onViewQuotaPage, benchmarkName } = props;
	return (
		<OutlinePanel className={"panel"}>
			<OutlinePanelTitle>テスト概要</OutlinePanelTitle>
			<TargetPanel activity={activity} hasStrategyGroup={job.hasStrategicTarget} />
			<SamplePanel samples={samples} onViewQuotaPage={onViewQuotaPage} job={job} benchmarkName={benchmarkName} />
		</OutlinePanel>
	);
});
