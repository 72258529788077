import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type { GetAdCopyJobDetailResponse } from "../../../../../../server/types/request/ad-copy/job-detail";
import { AdCopyJobDetailPage } from "../../../../components/pages/ad-copy/job/detail";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/detail";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";

export const AdCopyJobDetailContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { activity, concepts, estimate, evaluationItem, info, job, loading } = state;

	const navigate = useNavigate();

	const { activityId, jobId } = useParams<{ activityId: string; jobId: string }>();

	React.useEffect(() => {
		get<GetAdCopyJobDetailResponse>(`${adCopyEndpoint.jobDetail}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadJob" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onJobList = React.useCallback(() => {
		navigate(replaceEndpointUrl(adCopyClientEndpoint.job, { activityId: activityId ?? "" }));
	}, [activityId, navigate]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} onClose={onJobList} />

			{!!activity && (
				<AdCopyJobDetailPage
					activity={activity}
					concepts={concepts}
					estimate={estimate}
					evaluationItem={evaluationItem}
					job={job}
					onJobList={onJobList}
				/>
			)}
		</>
	);
};
