import classNames from "classnames";
import * as React from "react";
import { FormControl } from "react-bootstrap";
import Select from "react-select";

import type { AdCopySelectOption } from ".";
import { AdCopySelectWrap } from ".";

type Props = {
	error?: string;
	onChange: (value: any) => void;
	options: AdCopySelectOption[];
	value: any;
};

export const AdCopySingleSelect = React.memo((props: Props) => {
	const { error, onChange, options, value } = props;

	const selected = options.find((opt) => opt.value == value);

	const onChangeSelect = React.useCallback(
		(selected: AdCopySelectOption) => {
			onChange(selected.value);
		},
		[onChange],
	);

	return (
		<AdCopySelectWrap>
			<Select
				className={classNames("react-select-container", { "react-select-container--invalid": error })}
				classNamePrefix="react-select"
				isSearchable={false}
				onChange={onChangeSelect as React.ComponentProps<Select>["onChange"]}
				options={options}
				placeholder="選択してください"
				value={value && selected ? { label: selected.label, value } : undefined}
			/>

			{!!error && (
				<FormControl.Feedback style={{ display: "block" }} type="invalid">
					{error}
				</FormControl.Feedback>
			)}
		</AdCopySelectWrap>
	);
});
