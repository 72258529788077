import * as React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";

import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";

type Props = {
	title: "Test it コピーテスト" | "Test it コンセプトテスト" | "Test it";
};

export const LogoWithDropdown = React.memo((props: Props) => {
	const { title } = props;

	return (
		<Navbar.Brand className="fw-bold">
			<NavDropdown title={title}>
				<NavDropdown.Item href={clientEndpoint.activity}>1.コンセプトテスト</NavDropdown.Item>

				<NavDropdown.Item href={adCopyClientEndpoint.activity}>2.コピーテスト</NavDropdown.Item>
			</NavDropdown>
		</Navbar.Brand>
	);
});
