import styled from "styled-components";

export const InTrialMessageWrap = styled.div`
	.ad-copy-in-trial-message {
		&__container {
			padding: 1rem;
		}
		&__message {
			padding-bottom: 0.5rem;
		}
	}
`;
