import { deepCopy } from "../../../../../server/lib/common";
import type { AdCopyConceptKey } from "../../../../../server/models/ad-copy/concept";
import type { RetestAdCopyConcepts } from "../../../../../server/types/request/ad-copy/concept";
import type { AdCopyConceptAction } from "../../../types/actions";
import type { TrueAdCopyConcept, TrueAdCopySku } from "../../../types/ad-copy/concept";
import type { MessageInfo } from "../../../types/info";

type State = {
	cantEditMessage?: string;
	concepts: TrueAdCopyConcept[];
	info: MessageInfo;
	loading: boolean;
	prompt: boolean;
	retestConcepts?: RetestAdCopyConcepts;
};

export const initState: State = {
	concepts: [],
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	prompt: false,
};

const addSku = (sku: TrueAdCopySku[], index: number) => {
	const tmp = sku.concat();

	tmp.splice(index, 0, {
		includeTaxPrice: undefined,
		name: "",
		price: undefined,
	});

	return tmp;
};

const deleteSku = (sku: TrueAdCopySku[], index: number) => {
	const tmp = sku.concat();

	tmp.splice(index, 1);

	return tmp;
};

const copyConcept = (
	concepts: TrueAdCopyConcept[],
	original: AdCopyConceptKey,
	copyTo: AdCopyConceptKey,
): TrueAdCopyConcept[] => {
	const originalConcept = deepCopy(concepts.find((concept) => concept.type === original));

	if (originalConcept == null) {
		return concepts;
	}

	return concepts.map((concept) => {
		if (concept.type === copyTo) {
			if (concept._id) {
				originalConcept._id = concept._id;
			} else {
				delete originalConcept._id;
			}

			return { ...originalConcept, type: copyTo };
		}

		return concept;
	});
};

const copyRetest = ({
	concepts,
	copyTo,
	original,
	retestConcepts,
}: {
	concepts: TrueAdCopyConcept[];
	copyTo: AdCopyConceptKey;
	original: AdCopyConceptKey;
	retestConcepts: RetestAdCopyConcepts | undefined;
}): TrueAdCopyConcept[] => {
	const originalRetestConcept = retestConcepts?.[original];

	if (!originalRetestConcept) {
		return concepts;
	}

	return concepts.map((concept) =>
		concept.type === copyTo ? { ...deepCopy(originalRetestConcept), type: copyTo } : concept,
	);
};

export const reducer = (state: State, action: AdCopyConceptAction): State => {
	switch (action.type) {
		case "initConcepts":
			return {
				...state,
				...action.payload,
				concepts:
					action.payload.retestConcepts?.benchmark == null
						? action.payload.concepts
						: copyRetest({
								concepts: action.payload.concepts,
								copyTo: "benchmark",
								original: "benchmark",
								retestConcepts: action.payload.retestConcepts,
						  }),
				loading: false,
			};

		case "updateConcepts":
			return {
				...state,
				...action.payload,
				info: {
					isSuccess: true,
					message: `${state.concepts.some((c) => !!c._id) ? "更新" : "作成"}が完了しました。`,
				},
				loading: false,
				prompt: false,
			};

		case "temporarySaveConcepts":
			return {
				...state,
				...action.payload,
				info: {
					isSuccess: true,
					message: "一時保存が完了しました。",
				},
				loading: false,
				prompt: false,
			};

		case "changeConcept":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							[action.payload.name]: action.payload.value,
						};
					}

					return concept;
				}),
				prompt: true,
			};

		case "changeSku":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							sku: concept.sku.map((s, index) => {
								if (action.payload.index === index) {
									return {
										...s,
										[action.payload.name]: action.payload.value,
									};
								}

								return s;
							}),
						};
					}

					return concept;
				}),
				prompt: true,
			};

		case "addSku":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							sku: addSku(concept.sku, action.payload.index),
						};
					}

					return concept;
				}),
				prompt: true,
			};

		case "deleteSku":
			return {
				...state,
				concepts: state.concepts.map((concept) => {
					if (concept.type === action.payload.type) {
						return {
							...concept,
							sku: deleteSku(concept.sku, action.payload.index),
						};
					}

					return concept;
				}),
				prompt: true,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};

		case "onCopy":
			return {
				...state,
				concepts: copyConcept(state.concepts, action.payload.original, action.payload.copyTo),
			};

		case "onRetestCopy":
			return {
				...state,
				concepts: copyRetest({
					concepts: state.concepts,
					copyTo: action.payload.copyTo,
					original: action.payload.original,
					retestConcepts: state.retestConcepts,
				}),
			};
	}
};
