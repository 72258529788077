import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import { Checkboxes, Radios } from "../../../../../../../../react-components/lu-component/src/index";
import {
	childrenAdCopyOptions,
	marriedAdCopyOptions,
	occupationAdCopyOptions,
} from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyMarriedChildrenOccupationForm = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	return (
		<Row>
			<FormGroup as={Col} md={3}>
				{/* 今後離婚死別が別れたらMAになるので、配列のまま置いておく。 */}
				<AccordionForm
					defaultShow={!!strategyGroup.married && strategyGroup.married.length !== 0}
					eventKey="married"
					title={adCopyStrategyTargetGroupLabels.married ?? ""}
				>
					<Radios
						checkOff
						checked={strategyGroup.married && strategyGroup.married.length ? strategyGroup.married[0] : ""}
						choices={marriedAdCopyOptions}
						name="married"
						onChange={(value) => {
							onChange("married", value === undefined ? [] : [value]);
						}}
					/>
				</AccordionForm>
			</FormGroup>

			<FormGroup as={Col} md={4}>
				<AccordionForm
					defaultShow={!!strategyGroup.children && strategyGroup.children.length !== 0}
					eventKey="children"
					title={adCopyStrategyTargetGroupLabels.children ?? ""}
				>
					<Checkboxes
						checkeds={strategyGroup.children ?? []}
						choices={childrenAdCopyOptions}
						name="children"
						onChange={(values) => {
							onChange("children", values);
						}}
					/>
				</AccordionForm>
			</FormGroup>

			<FormGroup as={Col} md={3}>
				<AccordionForm
					defaultShow={!!strategyGroup.occupation && strategyGroup.occupation.length !== 0}
					eventKey="occupation"
					title={adCopyStrategyTargetGroupLabels.occupation ?? ""}
				>
					<Checkboxes
						checkeds={strategyGroup.occupation ?? []}
						choices={occupationAdCopyOptions}
						name="occupation"
						onChange={(value) => {
							onChange("occupation", value);
						}}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
