import * as React from "react";
import styled from "styled-components";

import type { ErrorObject } from "../../../../../../../../react-components/lu-component/src/index";
import type { AdCopyConceptAction } from "../../../../../../types/actions";
import { ImageResizer } from "../../../../../parts/ad-copy/image-resizer";

// アップロード画像の最大サイズ（mb）
export const conceptImageMaxMb = 10;

type Props = {
	disabled: boolean;
	dispatch: React.Dispatch<AdCopyConceptAction>;
	errors: ErrorObject | undefined;
	imageUrl: string;
	onChangeImageUrl: (value: string) => void;
};

const AlertMessage = styled.span`
	color: red;
`;

export const AdCopyConceptImageInput = React.memo((props: Props) => {
	const { disabled, dispatch, errors, imageUrl, onChangeImageUrl } = props;

	const handleMbLimitError = React.useCallback(() => {
		dispatch({
			payload: {
				isSuccess: false,
				message: `アップロードするファイルのサイズは${conceptImageMaxMb}MBまでです。`,
			},
			type: "changeMessageInfo",
		});
	}, [dispatch]);

	return (
		<ImageResizer
			disabled={disabled}
			error={
				errors?.["imageUrl"]
					? typeof errors["imageUrl"] === "object"
						? errors["imageUrl"].toString()
						: "画像をアップロードしてください"
					: undefined
			}
			imageUrl={imageUrl}
			label={
				<>
					呈示素材登録
					<AlertMessage> ※必須　（{conceptImageMaxMb}MB以下の画像ファイルを登録してください）</AlertMessage>
				</>
			}
			maxMb={conceptImageMaxMb}
			onChangeImageUrl={onChangeImageUrl}
			onMbLimitError={handleMbLimitError}
		/>
	);
});
