import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Datepicker } from "../date-picker";

export type BetweenDateValues = {
	from: string;
	to: string;
};

export type BetweenDateProps = {
	name: string;
	values: BetweenDateValues;
	onChange: (values: BetweenDateValues) => void;
};

export const BetweenDate = React.memo((props: BetweenDateProps) => {
	const { name, values, onChange } = props;
	const onChangeInput = React.useCallback(
		(target: keyof BetweenDateValues, value: string) => {
			onChange({ ...values, [target]: value });
		},
		[values, onChange],
	);
	return (
		<Row>
			<Col md={"auto"}>
				<Datepicker
					name={`${name}-from`}
					value={values.from || ""}
					onChange={(value: string) => onChangeInput("from", value)}
				/>
			</Col>
			<Col md={"auto"} style={{ textAlign: "center" }}>
				<span style={{ verticalAlign: "text-top" }}>〜</span>
			</Col>
			<Col md={"auto"}>
				<Datepicker
					name={`${name}-to`}
					value={values.to || ""}
					onChange={(value: string) => onChangeInput("to", value)}
				/>
			</Col>
		</Row>
	);
});
