import * as React from "react";
import { Col, Row, Form, Badge, Alert } from "react-bootstrap";
import { Application } from "../../../../../server/models/application";
import { SingleSelect } from "../../parts/select";
import { Confirm, ErrorObject, Option } from "../../../../../react-components/lu-component/src/index";
import Feedback from "react-bootstrap/Feedback";
import { ConfirmButton } from "../../parts/confirm-dropdown-button";
import { TextRed } from "../../parts/font";
import { getErrorMessage } from "../../../lib/error";

export type ApplicationSetting = {
	overEstimateAgreement: boolean;
	brandholders: Option[];
	application: Application;
	errors?: ErrorObject;
	onChange: (name: keyof Application, value: Application[keyof Application]) => void;
	onChangeApprover: (index: number, value: string) => void;
	onAgreeOverEstimate: () => void;
	onCancelOverEstimate: () => void;
	onSubmit: () => void;
	onCancel?: () => void;
};

export const ApplicationPage = React.memo((props: ApplicationSetting) => {
	const {
		brandholders,
		onChange,
		application,
		onChangeApprover,
		onCancelOverEstimate,
		onSubmit,
		onCancel,
		errors,
		overEstimateAgreement,
		onAgreeOverEstimate,
	} = props;
	const brands1 = React.useMemo(() => {
		const approver =
			application.approve[1] && application.approve[1].approver ? application.approve[1].approver : undefined;
		return brandholders.filter((bh) => {
			return bh.value !== approver;
		});
	}, [brandholders, application]);
	const brands2 = React.useMemo(() => {
		const approver =
			application.approve[0] && application.approve[0].approver ? application.approve[0].approver : undefined;
		return brandholders.filter((bh) => {
			return bh.value !== approver;
		});
	}, [brandholders, application]);
	return (
		<div className="m-3 clearfix">
			<Confirm
				show={!overEstimateAgreement}
				onCancel={onCancelOverEstimate}
				onOk={onAgreeOverEstimate}
				title={"見積もり金額"}
				body={
					<TextRed>
						調査実施費用が100万円を超える可能性があります。
						<br />
						稟議書が承認されていることをご確認ください。
					</TextRed>
				}
				buttonText={"はい"}
				variant={"secondary"}
			/>
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■配信申請</div>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Row className="mt-2">
						<Col md={2}>
							<div className="text-end">
								Approver 1（1次承認者）
								<Badge bg={"danger"}>必須</Badge>
							</div>
						</Col>
						<Col md={3}>
							<SingleSelect
								options={brands1}
								value={
									application.approve[0] && application.approve[0].approver
										? application.approve[0].approver.toString()
										: ""
								}
								onChange={(selected) => {
									onChangeApprover(0, selected ? selected.toString() : "");
								}}
								error={
									!!errors && !!errors["approve"]
										? typeof errors["approve"] === "string"
											? errors["approve"]
											: typeof errors["approve"][0] === "string"
											? errors["approve"][0]
											: errors["approve"][0]["approver"].toString()
										: undefined
								}
							/>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col md={2}>
							<div className="text-end">Approver 2（2次承認者）</div>
						</Col>
						<Col md={3}>
							<SingleSelect
								options={brands2}
								value={
									application.approve[1] && application.approve[1].approver
										? application.approve[1].approver.toString()
										: ""
								}
								onChange={(selected) => {
									onChangeApprover(1, selected ? selected.toString() : undefined);
								}}
								error={
									!!errors && !!errors["approve"] && typeof errors["approve"] === "object" && !!errors["approve"][1]
										? typeof errors["approve"][1] === "string"
											? errors["approve"][1]
											: errors["approve"][1]["approver"].toString()
										: undefined
								}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<Row className="mt-3">
						<Col md={2}>
							<div className="text-end">申請メッセージ</div>
						</Col>
						<Col md={5}>
							<Form.Control
								as="textarea"
								rows={5}
								value={application.applicationMessage}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange("applicationMessage", e.target.value)}
								isInvalid={!!errors && !!errors["applicationMessage"]}
							/>
							{!!errors && !!errors["applicationMessage"] && (
								<Feedback type={"invalid"}>{getErrorMessage("applicationMessage", errors)}</Feedback>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="mt-5">
				<Col className="text-center">
					{application.applicationDate && !application.cancel ? (
						<>
							<Alert variant="secondary">
								{application.applicationDate}にすでに申請済みです。
								{
									// No.11 申請したジョブ
									!!onCancel && (
										<ConfirmButton
											buttonText={"申請を取り消す"}
											onOk={onCancel}
											title={"申請取消"}
											body={"申請を取り消します。よろしいですか。"}
											variant={"secondary"}
										/>
									)
								}
							</Alert>
						</>
					) : (
						<ConfirmButton
							buttonText={"申請する"}
							onOk={onSubmit}
							title={"配信申請"}
							body={"申請します。よろしいですか。"}
							variant={"secondary"}
						/>
					)}
				</Col>
			</Row>
		</div>
	);
});
