import type { Brand } from "../../../../../server/models/brand";
import type { Category } from "../../../../../server/models/category";

type Options = {
	brand: Brand | undefined;
	category: Category | undefined;
};

export function findTargetCategoryBrandValues({ brand, category }: Options) {
	if (brand == null || category == null) {
		return;
	}

	return category.adCopyBrandUserDefinitions.find((x) => x.nBrand === brand.nBrand)?.targetSurveyChoiceValues;
}
