import * as React from "react";
import { Button } from "react-bootstrap";
import { ActivityWithBrand } from "../../../../../../../server/models/activity";
import { ConceptType } from "../../../../../../../server/models/concept";
import { Job } from "../../../../../../../server/models/job";
import {
	SalesVolumePredictionData,
	GetSimulationResponse,
} from "../../../../../../../server/types/request/report/simulation";
import { makeInboundRate, makeLocalOtherRate } from "../../../../../../../server/lib/common";
import { CorrectionValueSetting, CorrectionValueSettingProps } from "../correction-value";
import { NormTable } from "../norm-table";
import { SimulationResult } from "../result";
import { ElementEvaluation } from "../../../../../../../server/models/graph/element-evaluation";
import { getAnalyticsVersion, overTagetVersion } from "../../../../../lib/action-standard";
import { normRankExplain } from "../../../../parts/graph/norm";
import { CP } from "../../../../../../../server/models/cp";
import { NormTableVersion5 } from "../norm-table/v5";

export type SimulationOneProps = {
	evaluations: ElementEvaluation[];
	job: Job;
	activity: ActivityWithBrand;
	target: "test1" | "test2";
	onSimulation: () => void;
	onSimulationSave: () => void;
	onChangeRatio: CorrectionValueSettingProps["onChangeRatio"];
	data: SalesVolumePredictionData;
	oneNumberScoreNorm: GetSimulationResponse["oneNumberScoreNorm"];
	oneNumberScoreData: GetSimulationResponse["oneNumberScoreData"];
	constValue: GetSimulationResponse["constValue"];
	goalSalesVolume: number;
	maxRecognition: number;
	cp?: CP;
	hasEditRole: boolean;
};

export const SimulationOne = React.memo((props: SimulationOneProps) => {
	const {
		evaluations,
		hasEditRole,
		data,
		job,
		activity,
		target,
		oneNumberScoreNorm,
		oneNumberScoreData,
		goalSalesVolume,
		constValue,
		onSimulation,
		onSimulationSave,
		onChangeRatio,
		maxRecognition,
		cp,
	} = props;
	const { value } = data;
	const initLocalRatio = React.useMemo(() => makeLocalOtherRate(value), [value]);
	const initInboundRatio = React.useMemo(() => makeInboundRate(value), [value]);
	// 相対パスだとサーバ側でよむことができないので、絶対パスで画像を読み込む必要がある。
	const baseUrl = React.useMemo(() => location.origin, []);
	const hasCP = React.useMemo(() => !!cp?._id && !!cp.correctionValues && !!cp.correctionValues.result, [cp]);
	const version = React.useMemo(() => getAnalyticsVersion(job.endDatetime), [job]);
	const overVersion5 = React.useMemo(() => overTagetVersion(version, "v5"), [version]);
	const normExlpain = React.useMemo(() => {
		const v = getAnalyticsVersion(job.endDatetime);
		return normRankExplain[v];
	}, [job]);
	return (
		<>
			{hasEditRole && (
				<>
					<Button onClick={onSimulationSave} variant={"outline-secondary"} style={{ margin: "10px 0px" }}>
						保存
					</Button>
					<Button onClick={onSimulation} variant={"outline-secondary"} style={{ margin: "10px" }}>
						画像Download
					</Button>
				</>
			)}
			<table className={"simulation-table"}>
				<tr>
					<td className={"concept-title"}>提示コンセプト：{ConceptType[target]}</td>
					<td className={"jobno"}>
						JOB#: {job.jobNum}（{activity.category.name}、{activity.brand.name}）
					</td>
				</tr>
				<tr>
					<td className={"main"}>
						<div className={"image-area"}>
							<img src={data.conceptUrl} />
						</div>
					</td>
					<td className={"main all-result"}>
						<table className={"sub-table"}>
							<tbody>
								<tr>
									<td className={"title-area"}>
										<img src={`${baseUrl}/images/title1-2.jpg`} />
									</td>
									<td>
										<div className={"graph-area"}>
											{overVersion5 ? (
												<NormTableVersion5
													target={target}
													evaluations={evaluations}
													oneNumberScoreNorm={oneNumberScoreNorm}
													oneNumberScoreData={oneNumberScoreData}
													activity={activity}
													job={job}
													cp={cp}
												/>
											) : (
												<NormTable
													target={target}
													evaluations={evaluations}
													oneNumberScoreNorm={oneNumberScoreNorm}
													oneNumberScoreData={oneNumberScoreData}
													activity={activity}
													job={job}
													cp={cp}
												/>
											)}
											<div className={"explain"}>
												{`＊購入意向はTB,T2Bをウエイト処理したワンナンバースコアで表示/計算　＊ノームランクは、${normExlpain}${
													overVersion5 ? "" : "＝再テスト基準"
												}`}
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<hr className={"dashed"} />
						<table className={"sub-table"}>
							<tbody>
								<tr>
									<td className={"title-area"}>
										<img src={`${baseUrl}/images/title2.png`} />
									</td>
									<td>
										<div className={"graph-area"}>
											<CorrectionValueSetting
												hasEditRole={hasEditRole}
												maxRecognition={maxRecognition}
												value={data.value}
												correctionValue={data.correctionValue ?? {}}
												initLocalRatio={initLocalRatio}
												initInboundRatio={initInboundRatio}
												onChangeRatio={onChangeRatio}
											/>
											<SimulationResult
												hasCP={hasCP}
												data={data}
												goalSalesVolume={goalSalesVolume}
												constValue={constValue}
												initLocalRatio={initLocalRatio}
												initInboundRatio={initInboundRatio}
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</table>
		</>
	);
});
