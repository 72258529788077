import { MessageInfo } from "../../../types/info";
import { SimulationAction } from "../../../types/actions";
import { GetSimulationResponse } from "../../../../../server/types/request/report/simulation";
import { SalesVolumePrediction } from "../../../../../server/analytics/sales-volume";
import { SalesVolumeCorrectionValue } from "../../../../../server/models/simulation-value";

export type SalesVolumePredictionData = {
	value: SalesVolumePrediction;
	correctionValue: SalesVolumeCorrectionValue;
};

export type State = GetSimulationResponse & {
	loading: boolean;
	info: MessageInfo;
	confirmMessage: string | undefined;
};

export const initState: State = {
	oneNumberScoreNorm: undefined,
	oneNumberScoreData: [],
	job: undefined,
	activity: undefined,
	data: {},
	goalSalesVolume: 0,
	constValue: undefined,
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
	evaluations: [],
	maxRecognition: undefined,
	categories: [],
	brands: [],
	hasCurrentProduct: false,
	confirmMessage: undefined,
};

export const reducer = (state: State, action: SimulationAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				loading: false,
				confirmMessage: undefined,
				...action.payload,
			};
		case "updateData":
			return {
				...state,
				loading: false,
				confirmMessage: undefined,
				...action.payload,
				info: { message: "保存が完了しました。", isSuccess: true },
			};
		case "clearCPData":
			return {
				...state,
				loading: false,
				confirmMessage: undefined,
				...action.payload,
				info: { message: "CP実績補正のクリアが完了しました。", isSuccess: true },
			};
		case "changeRatio":
			return {
				...state,
				confirmMessage: "保存せずにページを移動すると入力内容がクリアされます。移動してよろしいでしょうか。",
				data: {
					...state.data,
					[action.payload.target]: {
						...state.data[action.payload.target],
						correctionValue: {
							...state.data[action.payload.target].correctionValue,
							[action.payload.name]: action.payload.value,
						},
					},
				},
			};
		case "changeCP": {
			const ret: State["cp"] = { ...(state.cp ?? {}), [action.payload.name]: action.payload.value };
			if (action.payload.name === "type" && action.payload.value === "currentProduct") {
				delete ret.similarProductInfo;
			}
			if (action.payload.name !== "correctionValues") {
				delete ret._id;
				delete ret.salesVolumes;
				delete ret.correctionValues;
			}
			return {
				...state,
				confirmMessage:
					"CP値を入力し、推計値算出まで実施しないとCP実績補整は提案フォーマットに反映されません。\n移動してよろしいでしょうか。",
				cp: ret,
			};
		}
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "downloadSimulation":
			return {
				...state,
				loading: false,
				confirmMessage: undefined,
			};
		case "saveSimulation":
			return {
				...state,
				info: action.payload,
				loading: false,
				confirmMessage: action.payload.isSuccess ? undefined : state.confirmMessage,
			};
		case "setChanged":
			return { ...state, confirmMessage: action.payload ? state.confirmMessage : undefined };
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
