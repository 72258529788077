import * as React from "react";

import { SubTitle } from "../../../../../../../react-components/lu-component/src";
import type { AccountWithoutPassword } from "../../../../../../../server/models/account";
import type { AdCopyJobWithSurveyStatus } from "../../../../../../../server/models/ad-copy/job";
import { type AdCopyJobStatus, adCopysurveyStatusOptions } from "../../../../../../../server/models/ad-copy/status";
import type { GetAdCopyJobSearchResponse } from "../../../../../../../server/types/request/ad-copy/job";
import type { SearchColumn } from "../../../../parts/search";
import { Search } from "../../../../parts/search";
import type { AdCopyJobPageType } from "..";
import { AdCopyJobSearchResult } from "./job-search-result";

const searchColumns: SearchColumn[] = [
	{
		label: "ジョブNo.",
		name: "jobNum",
		type: "string",
	},
	{
		label: "調査ステータス",
		name: "surveyStatus",
		options: adCopysurveyStatusOptions,
		type: "select",
	},
];

export type AdCopyJobSearchPageProps = GetAdCopyJobSearchResponse & {
	loginAccount: AccountWithoutPassword;
	onChangePage: (id: string, page: AdCopyJobPageType) => void;
	onChangeSearches: <K extends keyof AdCopyJobWithSurveyStatus>(name: K, value: AdCopyJobWithSurveyStatus[K]) => void;
	onEditPage: (id: string) => void;
	onJobStatusChange: (id: string, status: keyof typeof AdCopyJobStatus) => void;
	onOutlinePage: (id: string) => void;
	onRetest: (id: string) => void;
	onSubmit: () => void;
	result?: "empty" | "initial" | "non-empty";
	searches: { [key in keyof AdCopyJobWithSurveyStatus]?: AdCopyJobWithSurveyStatus[key] };
};

export const AdCopyJobSearchPage = React.memo((props: AdCopyJobSearchPageProps) => {
	const {
		accountOptions,
		data,
		loginAccount,
		onChangePage,
		onChangeSearches,
		onEditPage,
		onJobStatusChange,
		onOutlinePage,
		onRetest,
		onSubmit,
		result,
		searches,
	} = props;

	return (
		<>
			<div className="m-3 clearfix">
				<div className="h3 float-start">■ジョブ検索</div>
			</div>

			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>検索</SubTitle>

				<Search
					columns={searchColumns}
					onChangeSearchValue={onChangeSearches as any}
					onSearch={onSubmit}
					searchValues={searches as any}
				/>
			</div>

			{data.length === 0 && result === "empty" && (
				<div className="m-3">
					<SubTitle style={{ margin: "20px 0px" }}>一致するものが見つかりませんでした</SubTitle>
				</div>
			)}

			{data.map((d) => (
				<AdCopyJobSearchResult
					key={`job-page-${d.activity._id?.toString()}`}
					accountOptions={accountOptions}
					activity={d.activity}
					hideTitle
					jobs={d.jobs}
					loginAccount={loginAccount}
					onChangePage={onChangePage}
					onEditPage={onEditPage}
					onJobStatusChange={onJobStatusChange}
					onOutlinePage={onOutlinePage}
					onRetest={onRetest}
					searches={searches}
				/>
			))}
		</>
	);
});
