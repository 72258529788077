import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ActivityWithBrand, noSettingStrategyGroupReason } from "../../../../../server/models/activity";
import { Job, SurveyMethod } from "../../../../../server/models/job";
import { type SimpleQuestion } from "@muscat/types";
import { Concept } from "../../../../../server/models/concept";
import { makeCategoryGroupText, makeStrategyGroupText } from "../../../../../server/lib/quota/concept-quota";
import styled from "styled-components";
import { hasNorm, canSettingCategoryTarget } from "../../../../../server/lib/norm";
import { TextRed } from "../../parts/font";
import { questionLabel } from "@muscat/types/dist/label/question";

const TextWrap = styled.div`
	white-space: pre-wrap;
`;

export const Contains = React.memo((props: { field: React.ReactNode; children: React.ReactNode }) => {
	return (
		<Row className="mt-3">
			<Col md={3} className="text-end">
				{props.field}
			</Col>
			<Col>{props.children}</Col>
		</Row>
	);
});

export type JobDetailProps = {
	activity: ActivityWithBrand;
	job: Job;
	evaluationItem?: string[];
	question?: SimpleQuestion;
	concepts: Concept[];
	estimate: string;
};

export const JobDetail = React.memo((props: JobDetailProps) => {
	const { activity, job, evaluationItem, question, concepts, estimate } = props;
	const { categoryTargetGroup, strategyTargetGroup, noSettingStrategyGroup, category } = activity;
	const message = React.useMemo(() => {
		if (!hasNorm(category, categoryTargetGroup)) {
			return "ノームがないため、購入意向のノーム判定はできません。";
		}
	}, [category, categoryTargetGroup]);
	const categoryGroupText = React.useMemo(() => {
		if (!categoryTargetGroup) return "ノームがないため、カテゴリターゲットＧを設定できません。";
		const text = makeCategoryGroupText(categoryTargetGroup, category.gender);
		if (!canSettingCategoryTarget(category, categoryTargetGroup)) {
			return (
				<>
					<div>{text}</div>
					<TextRed>（この価格帯は出現率が低いため、カテゴリターゲットグループを設定できません。）</TextRed>
				</>
			);
		}
		return text;
	}, [category, categoryTargetGroup]);
	return (
		<>
			<Row className="m-3">
				<Col md={12}>
					<div className="h4">■アクティビティ情報</div>
					<Contains field="ブランド：">{activity.brand.name}</Contains>
					<Contains field="カテゴリ：">{category.name}</Contains>
					<Contains field="カテゴリターゲットグループ：">
						<div>{categoryGroupText}</div>
						{message && <TextRed>{message}</TextRed>}
					</Contains>
					<Contains field="戦略ターゲットグループ：">
						<TextWrap>
							{strategyTargetGroup ? (
								makeStrategyGroupText(strategyTargetGroup, category.gender)
							) : noSettingStrategyGroup ? (
								<>
									<TextRed>戦略ターゲットグループは以下の理由で設定されていません。</TextRed>
									{noSettingStrategyGroup.reasons.map((reason) => (
										<li key={`reason-${reason}`}>
											{noSettingStrategyGroupReason[reason]}
											{reason === "other" ? `（${noSettingStrategyGroup.specifically ?? ""}）` : ""}
										</li>
									))}
								</>
							) : (
								"定義なし"
							)}
						</TextWrap>
					</Contains>
					<Contains field="発売年月：">
						{activity.releaseYear}年 {activity.releaseMonth}月
					</Contains>
					<Contains
						field={
							<>
								日本人（当該性の）15～69歳における
								<br />
								想定商品認知率(%)：
							</>
						}
					>
						{activity.goalRecognitionRate}%
					</Contains>
					<Contains
						field={
							<>
								発売後12か月の目標お客さま購買
								<br />
								（万個）：
							</>
						}
					>
						{activity.goalSalesVolume}
					</Contains>
				</Col>
			</Row>
			<Row className="m-3">
				<Col md={12}>
					<div className="h4">■job情報</div>
					<Contains field="調査開始日時：">{job.startDatetime}</Contains>
					<Contains field="調査終了日時：">{job.endDatetime}</Contains>
					<Contains field="概算見積もり金額：">
						<div>
							{estimate.split("\n").map((text, index) => {
								return (
									<div key={`estimate-${index}`} style={index >= 2 ? { color: "red", fontWeight: "bold" } : {}}>
										{text}
									</div>
								);
							})}
						</div>
						{/* TODO: 金額計算ロジック 3,200,000円、最大5,000,000円まで*/}
					</Contains>
					<Contains field="調査手法：">{SurveyMethod[job.method]}</Contains>
					<Contains field="競合品の有無：">{job.hasBenchmark ? "あり" : "なし"}</Contains>
					<Contains field="現行品の有無：">{job.hasCurrentProduct ? "あり" : "なし"}</Contains>
					<Contains field="戦略ターゲットグループの有無：">{job.hasStrategicTarget ? "あり" : "なし"}</Contains>
					<Contains field="要素評価設問項目：">
						<>
							{evaluationItem && evaluationItem.length
								? evaluationItem.map((item, index) => <div key={`evaluationItem-${index}`}>{item}</div>)
								: "設定なし"}
						</>
					</Contains>
					<Contains field="追加設問：">
						{question ? (
							<>
								<Contains field="設問文：">{question.quetitle}</Contains>
								<Contains field="設問形式：">{questionLabel[question.type]}</Contains>
								{(question.type === "M" || question.type === "S") && (
									<Contains field="選択肢：">
										{question.choiceGroups.map((cg) => (
											<Contains
												key={`choice-group-${cg.id}`}
												field={`グループ${cg.id}${cg.header ? "(" + cg.header + ")" : ""}`}
											>
												{cg.choices.map((choice) => (
													<div key={`choice-${choice.value}`}>
														{choice.value}. {choice.text}
													</div>
												))}
											</Contains>
										))}
									</Contains>
								)}
							</>
						) : (
							"設定なし"
						)}
					</Contains>
					{concepts.map((concept) => (
						<React.Fragment key={`concept-${concept.type}`}>
							<Contains field="コンセプト画像：">
								<img src={concept.conseptUrl} style={{ maxWidth: "500px" }} className="border" />
							</Contains>
							<Contains field="コンセプト文：">
								{concept.sentences
									.filter((s) => !!s.sentence)
									.map((s, index) => (
										<Contains key={`sentence-${index}`} field={`${index + 1}：`}>
											<div style={{ whiteSpace: "pre" }}>{s.sentence}</div>
										</Contains>
									))}
							</Contains>
						</React.Fragment>
					))}
				</Col>
			</Row>
		</>
	);
});
