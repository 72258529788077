import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

import type { OutputTableData } from "../../../../../../server/models/ad-copy/output/outputTableData";
import { array2CsvBlob, downloadData } from "../../../../lib/data";
import { SideMenuWidthContext } from "../../sidemenu";
import { AdCopyCrossTableElement } from "./cross-table-element";

const ButtonAreaCaption = styled.caption`
	caption-side: top;
	text-align: left;
	margin-left: 10px;
`;

type CrossCellData = { className?: string; colSpan?: number; rowSpan?: number; value: number | string } | undefined;

type Props = {
	data: OutputTableData;
	filename: string | undefined;
	headers: CrossCellData[][];
};

export const AdCopyCrossTable = React.memo((props: Props) => {
	const { data, filename, headers } = props;

	const secondRowRef = useRef<HTMLTableRowElement>(null);

	const [secondRowHeight, setSecondRowHeight] = useState(0);

	const sideMenuWidth = React.useContext(SideMenuWidthContext);

	useEffect(() => {
		if (secondRowRef.current) {
			const height = secondRowRef.current.getBoundingClientRect().height;

			setSecondRowHeight(height);
		}
	}, [headers]);

	const thirdRowTopValue = 74 + secondRowHeight;

	const onDownload = React.useCallback(() => {
		const tmpHeaders = headers.map((ds) =>
			ds.map((d) => (d && d.value !== null && d.value !== undefined ? d.value.toString() : "")),
		);

		if (data.tBodies.length <= 0) {
			return;
		}

		const tmpData: string[][] = data.tBodies[0].trs.map((ds) =>
			ds.cells.map((d) => {
				if (d && d.value !== null && d.value !== undefined) {
					return d.value.toString().replace(/%$/, "");
				}

				return "";
			}),
		);

		downloadData(array2CsvBlob([...tmpHeaders, ...tmpData]), `${filename || "cross-data"}.csv`);
	}, [data, headers, filename]);

	return (
		<AdCopyCrossTableElement sideMenuWidth={sideMenuWidth}>
			<ButtonAreaCaption>
				<Button onClick={onDownload} size="sm" variant="outline-secondary">
					Download
				</Button>
			</ButtonAreaCaption>

			<tbody>
				{data.tBodies.at(0)?.trs.map((tr, rowIndex) => (
					<tr
						key={`data-${rowIndex}`}
						ref={rowIndex === 1 ? secondRowRef : null}
						className={tr.cells.some((cell) => cell && cell.value === "(n)") ? "total-row" : "data-row"}
					>
						{tr.cells.map((cell, colIndex) => {
							if (!cell) {
								return <React.Fragment key={`header-${rowIndex}-${colIndex}`} />;
							}

							const { className, colSpan, rowSpan, style, value } = cell;

							const classNames = ["value"];

							if (className) {
								classNames.push(className);
							}

							if (value) {
								if (/^\+/.test(value.toString())) {
									classNames.push("plus");
								}

								if (/^-/.test(value.toString())) {
									classNames.push("minus");
								}

								if (/^[-+]?(\d|\.)+[%|％]?$/.test(value.toString())) {
									classNames.push("number");
								}
							}

							const additionalClassRow = rowIndex < 3 ? `sticky-row-${rowIndex + 1}` : "";
							const additionalClassCol = colIndex < 4 ? `sticky-col-${colIndex + 1}` : "";

							return (
								<td
									key={`data-${rowIndex}-${colIndex}`}
									className={`${additionalClassCol} ${additionalClassRow} ${classNames.join(" ")}`}
									colSpan={colSpan}
									rowSpan={rowSpan}
									style={rowIndex === 2 ? { top: `${thirdRowTopValue}px`, ...style } : style}
								>
									{value ?? ""}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</AdCopyCrossTableElement>
	);
});
