import { ObjectId } from "mongodb";
import { Category } from "./category";
import { SalesVolumePredictionWithCorrectionValue } from "../analytics/sales-volume2/aggregate";
import { CorrectionValue } from "./simulation-value";

export const productType = {
	currentProduct: "現行品",
	similarProduct: "類似品",
} as const;

export type ProductType = keyof typeof productType;
export const productTypes = Object.keys(productType) as ProductType[];

export const productTypeOptions = (Object.entries(productType) as [ProductType, string][]).map(([value, label]) => ({
	value,
	label,
}));

export const isProductType = (value: unknown): value is ProductType => {
	if (typeof value !== "string") return false;
	return productTypes.includes(value as any);
};

export type SimilarProductInformation = {
	// カテゴリとブランドの情報から算出される。
	brandId: ObjectId | string;
	category: Category;
	recognition: number;
};
export type CPSalesVolumes = {
	test1: SalesVolumePredictionWithCorrectionValue;
	test2?: SalesVolumePredictionWithCorrectionValue;
};

export type CorrectedValue = {
	total: number;
	sumTrialRepeat: number;
	trial: number;
	repeat: number;
	localOther: number;
	inbound: number;
};
export type CpCorrectedResult = CorrectedValue & {
	correctionValue: number;
};

export type CPCurrectValues = CorrectionValue & {
	result: {
		test1: CpCorrectedResult;
		test2?: CpCorrectedResult;
	};
};

export type CP = {
	_id?: ObjectId | string;
	jobId: ObjectId | string;
	actualValue: number; // CP実績
	type: ProductType; // タイプ
	reason: string; //

	// 以下パラメータ情報
	/**
	 * 現行品=テスト品と同じ
	 * 類似品=入力される
	 */
	similarProductInfo?: SimilarProductInformation;
	salesVolumes: CPSalesVolumes;
	correctionValues?: CPCurrectValues;
};
