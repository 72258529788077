import * as React from "react";
import { Row, Col, Button, Card, FormGroup, FormLabel } from "react-bootstrap";
import { BetweenDateValues, BetweenDate } from "../../../../../../react-components/lu-component/src/index";
import Feedback from "react-bootstrap/Feedback";
import { ErrorObject } from "../../../../../../server/types/error";

export type JobDownloadPage = {
	filtered: BetweenDateValues;
	onFilteredChange: (values: BetweenDateValues) => void;
	errors?: ErrorObject;
	onDownload: () => void;
};

export const JobDownloadPage = React.memo((props: JobDownloadPage) => {
	const { filtered, onFilteredChange, onDownload, errors /*, categoryOptions, brandOptions */ } = props;
	return (
		<div className={"m-3"}>
			<Card>
				<Card.Body>
					<Row>
						<FormGroup as={Col}>
							<FormLabel>案件終了日</FormLabel>
							<BetweenDate name={"endDatetime"} values={filtered} onChange={onFilteredChange} />
							{errors && <Feedback type={"invalid"}>開始日、または終了日を入力してください。</Feedback>}
						</FormGroup>
					</Row>
					<Row>
						<Col md={{ offset: 1, span: 2 }}>
							<Button variant={"secondary"} onClick={onDownload}>
								ダウンロード
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	);
});
