import * as React from "react";
import styled from "styled-components";

const FloationgArea = styled.div`
	z-index: 100;
	position: fixed;
	top: 50px;
	right: 20px;
`;
const ItemWrap = styled.div`
	margin: 5px;
	border-radius: 10px;
	text-align: center;
	display: inline-block;
	background-color: #dedbd7;
	box-shadow:
		0 2px 1px -1px rgba(0, 0, 0, 0.2),
		0 1px 1px 0 rgba(0, 0, 0, 0.141),
		0 1px 3px 0 rgba(0, 0, 0, 0.122);
`;
const FloatingButton = styled.div`
	color: #555;
	cursor: pointer;
	display: inline-block;
	border-radius: 10px;
	text-align: center;
	padding: 5px;
	margin: 8px;
	background-color: #f3f0e9;
	:hover {
		background-color: white;
	}
`;

export type Menu = {
	label: React.ReactText | React.ReactNode;
	event: () => void;
};
export type FloatingButtonProps = {
	menus: Menu[];
};
export const FloatingMenu = React.memo((props: FloatingButtonProps) => {
	const { menus } = props;
	return (
		<FloationgArea>
			<ItemWrap>
				{menus.map((menu, index) => (
					<FloatingButton key={`menu-${index}`} onClick={menu.event}>
						{menu.label}
					</FloatingButton>
				))}
			</ItemWrap>
		</FloationgArea>
	);
});
