import dayjs from "dayjs";

import { Activity } from "../models/activity";
import { SelectionGroup } from "@analytics/types";

import { Brand } from "../models/brand";
import { Category, CategoryRecognition } from "../models/category";
import { ConceptKey, ConceptType } from "../models/concept";
import { Job } from "../models/job";
import { Option } from "../types/request";
import { targetGroup, TargetGroup } from "../models/graph/target-group";
import { categoryQuename, conceptQuename } from "./enquete/config";
import { conceptNumber } from "./enquete/static";
import { ObjectId } from "mongodb";
import { CorrectionValue } from "../models/simulation-value";
import { SalesVolumePrediction } from "../analytics/sales-volume";
import { SalesVolumeConstValue } from "../types/request/report/simulation";
import { CorrectedValue } from "../models/cp";

export const deepCopy = <T>(data: T): T => {
	return JSON.parse(JSON.stringify(data));
};

/**
 * 2値の比較
 * fromとtoがないケースがあるので、ない場合は最小値を0最大値を1000000000と仮置きしてチェックするようにする。
 * @param { {from?: number , to?:number} } a 比較1
 * @param { {from?: number , to?:number} } b 比較2
 * @returns { boolean }
 */
export const isCover = (a: { from?: number; to?: number }, b: { from?: number; to?: number }): boolean => {
	const { from: a1 = 0, to: a2 = 1000000000 } = a;
	const { from: b1 = 0, to: b2 = 1000000000 } = b;
	return b1 <= a2 && a1 <= b2;
};

export const endDay = 4;

export const makeEndDate = (startDate: string): string => {
	const tmp = dayjs(startDate).add(endDay, "days");
	const endDate = tmp.minute() < 29 ? tmp.add(-1, "hours") : tmp;
	return endDate.format("YYYY-MM-DDTHH:") + "29";
};

export const replaceBreakAndSpace = (str: string): string => {
	return str.replace(/[\n\s]/g, "");
};

export const hasCategroyTargetGroup = (activity: Activity): boolean => {
	return activity.categoryTargetGroup && activity.categoryTargetGroup.appearance > 0;
};

const maxRecognition = 100;

const makeMaxCategoryRecognition = (categoryRecognition: CategoryRecognition[]): number => {
	return Math.min(maxRecognition, Math.round(Math.max(...categoryRecognition.map((t) => t.gAwareness))) + 10);
};
/**
 * カテゴリ×ブランドから最大設定可能認知率を取得する。
 * 上から優先して取得を行う。
 * 　0. まだアクティビティに紐づくブランドがない　　　　　　 : 100％
 * 　1. カテゴリ×ブランドで認知率がある　　　　　　　　　　　: ヒットした認知率を返却
 * 　2. 他カテゴリに対象ブランドが存在する　　　 　　　　　　: 他ブランドの最大の認知率 + 10
 * 　3. 他カテゴリに対象ブランドが存在しない（新規ブランド） : カテゴリ内のブランドの認知率 + 10
 * TODO: 3についての確認
 * @param {{ brandId?: string; category?: Category }} parameter 対象のアクティビティ
 * @param {Category[]} categories 全カテゴリのリスト
 * @param {Brand[]} brands 全ブランドのリスト
 * @returns {number}
 */
export const getMaxRecognition = (
	parameter: Partial<{ brandId: string | ObjectId; category: Category }>,
	categories: Category[],
	brands: Brand[],
): number => {
	// 100%が最大
	if (!parameter.brandId || !parameter.category) return maxRecognition;
	const brandId = parameter.brandId.toString();
	const { category } = parameter;
	const brand = brands.find((b) => b._id.toString() === brandId);
	if (!brand) return maxRecognition;
	// nbrand=0は他ブランド
	// 1. カテゴリ×ブランドで認知率がある
	const filteredCategoryRecognition = category.categoryRecognition.filter(
		(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand,
	);
	if (filteredCategoryRecognition.length) {
		return makeMaxCategoryRecognition(filteredCategoryRecognition);
	}
	// 2. 他カテゴリに対象ブランドが存在する
	/* No.9　商品認知率の初期値を当該ブランド×当該カテゴリがない場合、当該カテゴリの競合トップブランドから＋10pt以上の入力禁止となっているが、当該ブランド他カテゴリのトップから＋10pt以上の入力禁止とする。*/
	const filteredBrandRecognition: CategoryRecognition[] = categories.reduce((a, { categoryRecognition }) => {
		// nbrand=0は他ブランド
		const tmp = categoryRecognition.filter(
			(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand,
		);
		if (tmp.length) {
			return [...a, ...tmp];
		}
		return a;
	}, []);
	if (filteredBrandRecognition.length) {
		return makeMaxCategoryRecognition(filteredBrandRecognition);
	}
	// 3. 他カテゴリに対象ブランドが存在しない（新規ブランド）
	return makeMaxCategoryRecognition(category.categoryRecognition);
};

export const hasValue = (v: any): boolean => {
	if (v === undefined || v === null) return false;
	return true;
};
export const systemName = {
	main: "Test It System",
	cron: "Test It Cron",
} as const;

export const waitFor = async (millseconds = 500): Promise<void> => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, millseconds);
	});
};

export const makeRate = (trial: number, repeat: number, targetVolume: number): number => {
	return targetVolume / (trial + repeat);
};

export const makeLocalOtherRate = (salesVolume: SalesVolumePrediction): number => {
	return makeRate(salesVolume.o, salesVolume.p, salesVolume.q + salesVolume.r + salesVolume.s);
};

export const makeInboundRate = (salesVolume: SalesVolumePrediction): number => {
	return makeRate(salesVolume.o, salesVolume.p, salesVolume.t);
};

const imageReg = /<img src=\s?"(?:[^"]*)(?:\/(.+))\/concept"/i;
/**
 * タグの削除
 * @param text
 * @returns {boolean}
 */
export const replaceTag = (text: string): string => {
	const imageMatch = text.match(imageReg);
	if (imageMatch) {
		if (imageMatch[1] in ConceptType) {
			return ConceptType[imageMatch[1] as ConceptKey];
		}
	}
	return text.replace(/<[^>]+\/?>/g, "");
};

/**
 * Rep,カテゴリ,戦略ターゲットのoptionの取得
 * @param {Activity} activity
 * @param {Job} job
 * @returns {Option[]}
 */
export const makeTargetGroupOptions = (activity: Activity, job: Job): Option[] => {
	const options: Option<TargetGroup>[] = [{ value: "general", label: targetGroup.general }];
	if (activity.category) options.push({ value: "category", label: targetGroup.category });
	if (job.hasStrategicTarget) options.push({ value: "strategy", label: targetGroup.strategy });
	return options;
};

/**
 * コンセプトのoptionの取得
 * @param {Job} job
 * @returns {Option[]}
 */
export const makeConceptOptions = (job: Job): Option[] => {
	const options: Option<ConceptKey>[] = [{ value: "test1", label: ConceptType.test1 }];
	if (job.hasTwoTest) options.push({ value: "test2", label: ConceptType.test2 });
	if (job.hasBenchmark) options.push({ value: "benchmark", label: ConceptType.benchmark });
	if (job.hasCurrentProduct) options.push({ value: "currentProduct", label: ConceptType.currentProduct });
	return options;
};

export const categorySelectionGroupKey = "Category";

/**
 * 選択肢グループ
 * @param {ConceptKey[]} concepts
 * @returns {SelectionGroup}
 */
export const makeConceptSelectionGroup = (concepts: ConceptKey[]): SelectionGroup => {
	return {
		name: categorySelectionGroupKey,
		label: "カテゴリ",
		quename: conceptQuename,
		grouping: concepts.map((conceptKey) => ({
			value: conceptNumber[conceptKey],
			label: ConceptType[conceptKey],
			condition: `${conceptQuename}=${conceptNumber[conceptKey]}`,
		})),
	};
};

export const categoryUserSelectionGroupKey = "CategoryUser";

export const makeCategoryUserSelectionGroup = (activity: Activity): SelectionGroup => {
	const categoryUsersValues = activity.category.surveyChoice.map((surveyChoice) => surveyChoice.value);
	return {
		name: categoryUserSelectionGroupKey,
		label: "カテゴリユーザ",
		quename: categoryQuename,
		grouping: [
			{ label: "ユーザ", value: 1, condition: `${categoryQuename}=${categoryUsersValues.join(",")}` },
			{ label: "ノンユーザ", value: 2, condition: `${categoryQuename}!=${categoryUsersValues.join(",")}` },
		],
	};
};

export const getRecognitionByCategoryAndBrand = (category: Category, brand: Brand): number | undefined => {
	const filteredCategoryRecognition = category.categoryRecognition.filter(
		(cRecognition) => cRecognition.nBrand !== 0 && cRecognition.nBrand === brand.nBrand,
	);
	if (filteredCategoryRecognition.length) {
		return Math.round(Math.max(...filteredCategoryRecognition.map((cRecognition) => cRecognition.gAwareness)));
	}
	return undefined;
};

export const isDefined = <T>(v: T | undefined | null): v is T => {
	if (v === undefined) return false;
	if (v === null) return false;
	return true;
};

export const hasCorrectionValues = (data: CorrectionValue): boolean => {
	return (
		hasValue(data.recognitionRate) ||
		hasValue(data.purchaseQuantity) ||
		hasValue(data.localRatio) ||
		hasValue(data.inboundRatio)
	);
};

export const makeCorrectedValues = (
	value: SalesVolumePrediction,
	correctionValue: CorrectionValue,
	constValue: SalesVolumeConstValue,
): CorrectedValue => {
	const initLocalRatio = makeLocalOtherRate(value);
	const initInboundRatio = makeInboundRate(value);
	const recognitionRate = isDefined(correctionValue.recognitionRate) ? correctionValue.recognitionRate : value.g;
	const purchaseQuantity = isDefined(correctionValue.purchaseQuantity) ? correctionValue.purchaseQuantity : value.h;
	const localRatio = isDefined(correctionValue.localRatio) ? correctionValue.localRatio : initLocalRatio;
	const inboundRatio = isDefined(correctionValue.inboundRatio) ? correctionValue.inboundRatio : initInboundRatio;
	const baseTrial =
		(value.m * value.e * constValue.nonUser + value.n * value.f * constValue.user) * recognitionRate * 100;
	const missingDeclaration = value.v || 0;
	const missingDeclarationTrial = baseTrial * missingDeclaration;
	const trial = baseTrial + missingDeclarationTrial;
	const baseRepeat = baseTrial * (purchaseQuantity - 1);
	const missingDeclarationRepeat = baseRepeat * missingDeclaration;
	const repeat = baseRepeat + missingDeclarationRepeat;
	const sumTrialRepeat = trial + repeat;
	const localOther = sumTrialRepeat * localRatio;
	const inbound = sumTrialRepeat * inboundRatio;
	const total = sumTrialRepeat + localOther + inbound;
	return { total, trial, repeat, localOther, inbound, sumTrialRepeat };
};

export const isNotEmptyArray = (value: any): boolean => {
	return Array.isArray(value) && value.length > 0;
};

/**
 *  objectからvalueを指定してkeyを取得する
 */
export const getObjKeyByValue = <T extends Record<string, unknown>>(obj: T, value: unknown): keyof T | undefined => {
	return Object.keys(obj).find((key) => obj[key] === value) as keyof T;
};
