const overSampleRate = 0.05;

/**
 * オーバーサンプルを含んだ
 * @param {number} sampleSize 回収予定数
 * @returns {number}
 */
export const makeOverSample = (sampleSize: number): number => {
	return Math.ceil(sampleSize * (1 + overSampleRate));
};
