import { PageConfig } from "@muscat/types";
import { makeEnquetePoint } from "../enquete/point";
import { Activity, CategoryGroup } from "../../models/activity";
import { Age, Gender, Price } from "../../models/shared";
import { Job } from "../../models/job";
import { GeneralGroupQuota } from "../../models/category";
import { maxAnsweredLimit } from "../enquete/config";
import { isCover } from "../common";
import { makeCost, aggregationCost } from "./common";
import { canSettingCategoryTarget } from "../norm";

const categorySampleSize = 200;
const strategySampleSize = 200;

// warning: 人口構成をハードコーディング
// 数表更新時に更新する必要あり。
export type PopulationAge = {
	age: Age;
	n: number;
};

export const population: { [key in keyof typeof Gender]: PopulationAge[] } = {
	female: [
		{ age: { from: 15, to: 19 }, n: 2.9 },
		{ age: { from: 20, to: 24 }, n: 2.8 },
		{ age: { from: 25, to: 29 }, n: 3.0 },
		{ age: { from: 30, to: 34 }, n: 3.5 },
		{ age: { from: 35, to: 39 }, n: 4.0 },
		{ age: { from: 40, to: 44 }, n: 4.7 },
		{ age: { from: 45, to: 49 }, n: 4.2 },
		{ age: { from: 50, to: 54 }, n: 3.9 },
		{ age: { from: 55, to: 59 }, n: 3.7 },
		{ age: { from: 60, to: 64 }, n: 4.3 },
		{ age: { from: 65, to: 69 }, n: 4.9 },
	],
	male: [
		{ age: { from: 15, to: 19 }, n: 3.0 },
		{ age: { from: 20, to: 24 }, n: 2.9 },
		{ age: { from: 25, to: 29 }, n: 3.1 },
		{ age: { from: 30, to: 34 }, n: 3.6 },
		{ age: { from: 35, to: 39 }, n: 4.1 },
		{ age: { from: 40, to: 44 }, n: 4.8 },
		{ age: { from: 45, to: 49 }, n: 4.3 },
		{ age: { from: 50, to: 54 }, n: 3.9 },
		{ age: { from: 55, to: 59 }, n: 3.7 },
		{ age: { from: 60, to: 64 }, n: 4.1 },
		{ age: { from: 65, to: 69 }, n: 4.6 },
	],
};

/**
 * ユーザー、カテゴリターゲットユーザ、ノンユーザでSC回収をカウントする
 */
const makeScreeningCount = (
	generalGroupQuota: GeneralGroupQuota[],
	priceAppearance: CategoryGroup[],
	count: number,
	gender: keyof typeof Gender,
) => {
	const targetPopulation = population[gender];
	const total = targetPopulation.reduce((a, b) => a + b.n, 0);
	return generalGroupQuota.reduce(
		(a, b) => {
			const pop = targetPopulation.find((tp) => isCover(b.age, tp.age));
			const tmp = priceAppearance.filter((ap) => isCover(b.age, ap.age));
			if (tmp.length === 0) return a;
			const userAppearance = (total * (tmp.reduce((a, b) => a + b.appearance, 0) / 100)) / pop.n;
			if (b.isUser) {
				a.user += Math.round((b.sampleSize / userAppearance) * count);
			} else {
				const nonUserAppearance = 1 - userAppearance;
				a.nonUser += Math.round((b.sampleSize / nonUserAppearance) * count);
			}
			return a;
		},
		{ user: 0, nonUser: 0 },
	);
};

const makePriceAppearance = (priceAppearance: CategoryGroup[], price: Price) => {
	return price ? priceAppearance.filter((ap) => isCover(ap.price, price)) : priceAppearance;
};

const includeCategorySample = (
	generalGroupQuota: GeneralGroupQuota[],
	priceAppearance: CategoryGroup[],
	count: number,
	gender: keyof typeof Gender,
	categoryGroup?: CategoryGroup,
) => {
	if (!categoryGroup) return 0;
	const targetPopulation = population[gender];
	const { price, age } = categoryGroup;
	// 価格帯がないケースも存在する。
	const total = targetPopulation.reduce((a, b) => a + b.n, 0);
	const targetPriceTargetAppearance = makePriceAppearance(priceAppearance, price);
	return generalGroupQuota.reduce((a, b) => {
		if (!b.isUser) return a;
		if (!isCover(b.age, age)) return a;
		const tmp = targetPriceTargetAppearance.filter((ap) => isCover(b.age, ap.age));
		const pop = targetPopulation.find((tp) => isCover(b.age, tp.age));
		const appearance = (total * (tmp.reduce((a, b) => a + b.appearance, 0) / 100)) / pop.n;
		return a + Math.round(b.sampleSize * count * appearance);
	}, 0);
};

const boostSampleSize = (activity: Activity, job: Job) => {
	let sampleSize = 0;
	if (activity.categoryTargetGroup) {
		sampleSize += categorySampleSize;
	}
	if (!!activity.strategyTargetGroup && job.hasStrategicTarget) {
		sampleSize += strategySampleSize;
	}
	return sampleSize;
};

const makeCategoryAppearance = (
	priceAppearance: CategoryGroup[],
	gender: keyof typeof Gender,
	categoryGroup?: CategoryGroup,
) => {
	if (!categoryGroup) return 0;
	const { age, price } = categoryGroup;
	const targetPopulation = population[gender];
	const total = targetPopulation.reduce((a, b) => a + b.n, 0);
	const targetPriceTargetAppearance = makePriceAppearance(priceAppearance, price);
	const tmp = targetPriceTargetAppearance
		.filter((ap) => isCover(age, ap.age))
		.map((ap) => {
			const pop = targetPopulation.find((tp) => isCover(ap.age, tp.age));
			return (total * ap.appearance) / pop.n;
		});
	return tmp.reduce((a, b) => a + b, 0) / tmp.length;
};

/**
 * 対象の金額をoverするか。
 * @param total 合計金額
 * @return {boolean}
 */
export const isOverCost = (total: number, target = 1000000): boolean => {
	return total > target;
};

/**
 * 金額を計算する
 * システム利用日を含んだ合計金額。
 * @param {number} pointMax
 * @param {number} [pointMin = undefined]
 * @returns {string}
 */
export const makeEstimateCost = (pointMax: number, pointMin?: number): string => {
	const total = pointMax + aggregationCost;
	const alert = isOverCost(total) ? "\n最大お見積金額が100万円を超えているため稟議が必要になります。" : "";
	const maxPointText = `最大金額：${total.toLocaleString()}円`;
	if (pointMin === undefined) return maxPointText + alert;
	return `${(pointMin + aggregationCost).toLocaleString()}円\n（${maxPointText}）${alert}`;
};

export const makeMaxCost = (activity: Activity, job: Job, questionnaire: PageConfig[]): number => {
	const boostTestCount = (job.hasBenchmark ? 1 : 0) + (job.hasCurrentProduct ? 1 : 0);
	const generalTotal = job.hasTwoTest ? 2000 : 1000;
	const boostTotal = boostSampleSize(activity, job) * boostTestCount;

	const { max, sc_max: min } = makeEnquetePoint(questionnaire);
	const honCount = generalTotal + boostTotal;
	const honPoint = (max - min) * honCount;
	const maxScreeningPoint = min * maxAnsweredLimit;
	return makeCost(honPoint + maxScreeningPoint, job.jobNum);
};

export const makeEstimate = (activity: Activity, job: Job, questionnaire: PageConfig[]): string => {
	const repTestCount = job.hasTwoTest ? 2 : 1;
	const boostTestCount = (job.hasBenchmark ? 1 : 0) + (job.hasCurrentProduct ? 1 : 0);
	// 各回収数
	const generalTotal = job.hasTwoTest ? 2000 : 1000;
	const boostTotal = boostSampleSize(activity, job) * boostTestCount;

	const { max, sc_max: min } = makeEnquetePoint(questionnaire);
	const honCount = generalTotal + boostTotal;
	const honPoint = (max - min) * honCount;
	const maxScreeningPoint = min * maxAnsweredLimit;

	const maxTotal = makeMaxCost(activity, job, questionnaire);
	// const maxToalText = `最大金額：${maxTotal.toLocaleString()}円`;

	const { category, categoryTargetGroup } = activity;
	const { priceAppearance, generalGroupQuota, gender } = category;
	if (
		!priceAppearance || // 出現設定がなされていない
		priceAppearance.length === 0 || // 出現率がない
		priceAppearance.every((pa) => pa.appearance === 0) || // すべて0%の出現である
		!canSettingCategoryTarget(category, categoryTargetGroup) // 設定不可能な対象である。
	) {
		/*
		出現がないものは6500スクリーニグ回収で良いものとする。
		以下は個別対応
		・男性　ファンデーション（BBクリーム・コンシーラー含む）：最大
		・男性　日焼け止め：12000
		・香水・フレグランス：13000
		・育毛剤･養毛剤：19000

		カテゴリターゲットのテスチ品のカウントに応じてその分をかけるようにする。
		*/
		if (category.name === "男性　ファンデーション（BBクリーム・コンシーラー含む）") return makeEstimateCost(maxTotal);
		const map: { [key: string]: number } = {
			"男性　日焼け止め": 12000,
			"香水・フレグランス": 13000,
			"育毛剤･養毛剤": 19000,
		};
		const scCount = (map[category.name] || 6500) * (repTestCount + boostTestCount);
		const total = makeCost(honPoint + min * scCount, job.jobNum);
		return makeEstimateCost(maxTotal, total); // `${total.toLocaleString()}円\n（${maxToalText}）`;
		// return maxToalText;
	}
	// ユーザ、ノンユーザの出現率を計算
	// 一般サンプルのSC回収予測。
	const { user, nonUser } = makeScreeningCount(generalGroupQuota, priceAppearance, repTestCount, gender);
	const repScreeningCount = user + nonUser;

	// カテゴリターゲットの出現率、スクリーニング算出
	const categoryAppearance = makeCategoryAppearance(priceAppearance, gender, categoryTargetGroup);

	// repの回収で含まれている本調査カウントを取得
	const categoryCount = includeCategorySample(
		generalGroupQuota,
		priceAppearance,
		repTestCount,
		gender,
		categoryTargetGroup,
	);
	const categoryScreeningCount =
		// 残りブーストで回収しなければならないカウントを計算。
		// ・テスト品1,2：回収予定数:(200ss * テスト品数) - repで回収できた本調査サンプル
		// ・競合品、現行品：回収予定数:(200ss * 競合品、現行品の個数)
		(Math.max(categorySampleSize * repTestCount - categoryCount, 0) + categorySampleSize * boostTestCount) /
		categoryAppearance;

	// ユーザ + ノンユーザー + repに含まれるカウントを除いたブーストカウント + テスト品以外でのブーストカウント
	const screeningCount = repScreeningCount + categoryScreeningCount;
	const scPoint = min * screeningCount;
	const total = makeCost(honPoint + scPoint, job.jobNum);
	console.log(`
		----------------
		jobNum: ${job.jobNum}
		----------------
		point
		min: ${min}
		max: ${max}
		----------------
		answered
		screeningCount:${screeningCount}
		honCount:${honCount}
		----------------
		answered
		screeningCount:${screeningCount}
		honCount:${honCount}
		----------------
		givies point
		screening: ${scPoint}
		maxScreening : ${maxScreeningPoint}
		hon: ${honPoint}
		----------------
		invoice
		total: ${total}
		maxTotal: ${maxTotal}
		----------------
	`);
	return makeEstimateCost(maxTotal, total);
};
