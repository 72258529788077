import { Labels } from ".";
import { CP, SimilarProductInformation } from "../server/models/cp";

export const similarProductParameterLabels: Labels<SimilarProductInformation> = {
	category: "カテゴリ",
	brandId: "ブランド",
	recognition: "認知率",
};
export const cpLabels: Labels<CP> = {
	_id: "コンセプト",
	jobId: "ジョブID",
	actualValue: "CP実績（万個）",
	type: "タイプ",
	similarProductInfo: "パラメータ情報",
	reason: "CP実績の定義（対象期間や抽出元など）を記載",
	salesVolumes: "現行・類似品需要予測",

	correctionValues: "補正値",
};
