import * as React from "react";
import { type Quota } from "@muscat/types";
import { Table } from "react-bootstrap";
import { quotaCode } from "../../../../../server/lib/enquete/static";

const QuotaCodeReg = {
	general: new RegExp(`^[${quotaCode.test1}${quotaCode.test2}]\\d+$`),
	category: new RegExp(`^${quotaCode.category}\\d+$`),
	strategy: new RegExp(`^${quotaCode.strategy}\\d+$`),
} as const;
type QuotaCategoryProps = {
	title: string;
	isCMI?: boolean;
	quotas: Quota[];
};
const QuotaCategory = React.memo((props: QuotaCategoryProps) => {
	const { title, quotas, isCMI } = props;
	const planTotal = React.useMemo(() => {
		return quotas.reduce((a, b) => a + b.plan, 0);
	}, [quotas]);
	const countTotal = React.useMemo(() => {
		return quotas.reduce((a, b) => a + b.count, 0);
	}, [quotas]);
	if (quotas.length === 0) return <></>;
	return (
		<>
			<tr>
				<th style={{ backgroundColor: "#ddd" }} colSpan={5}>
					{title}
				</th>
			</tr>
			{isCMI &&
				quotas.map((quota) => {
					const rate = Math.floor((quota.count / quota.plan) * 1000) / 10;
					return (
						<tr key={`quota-${quota.code}`}>
							<td>{quota.code}</td>
							<td>{quota.name}</td>
							<td>{quota.plan}</td>
							<td>{quota.count}</td>
							<td style={{ color: rate >= 100 ? "blue" : "red" }}>{rate}</td>
						</tr>
					);
				})}
			<tr>
				<td colSpan={2} style={{ textAlign: "center" }}>
					合計
				</td>
				<td>{planTotal}</td>
				<td>{countTotal}</td>
				<td>{Math.floor((countTotal / planTotal) * 1000) / 10}</td>
			</tr>
		</>
	);
});

export type QuotaStatusProps = {
	isCMI?: boolean;
	quotas: Quota[];
};

export const QuotaStatus = React.memo((props: QuotaStatusProps) => {
	const { quotas, isCMI } = props;
	const categoeizedQuotas = React.useMemo(() => {
		const tmp: { [group in "generalTargets" | "categoryTargets" | "strangicTargets"]: Quota[] } = {
			generalTargets: [],
			categoryTargets: [],
			strangicTargets: [],
		};
		quotas.forEach((q) => {
			if (QuotaCodeReg.general.test(q.code)) {
				tmp.generalTargets.push(q);
			}
			if (QuotaCodeReg.category.test(q.code)) {
				tmp.categoryTargets.push(q);
			}
			if (QuotaCodeReg.strategy.test(q.code)) {
				tmp.strangicTargets.push(q);
			}
		});
		return tmp;
	}, [quotas]);
	return (
		<Table>
			<thead>
				<tr>
					<th>No</th>
					<th>名前</th>
					<th>回収予定数</th>
					<th>回収数</th>
					<th>回収率</th>
				</tr>
			</thead>
			<tbody>
				{Object.entries(categoeizedQuotas).map(([group, quotas]) => {
					return (
						<QuotaCategory
							key={`quota-group-${group}`}
							isCMI={isCMI}
							title={
								group === "generalTargets"
									? "一般サンプル"
									: group === "categoryTargets"
									? "カテゴリターゲットグループ"
									: "戦略ターゲットグループ"
							}
							quotas={quotas}
						/>
					);
				})}
			</tbody>
		</Table>
	);
});
