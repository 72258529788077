import * as json2csv from "json2csv";
import { Question } from "@bleu/core";
import { AggregateTargetQuestionType, createAggregateJob as createAggregateJobBase } from "@brix/core";
import { Key } from "@lu/repository";
import { EditableAggregateJob } from "../types/aggregate";
import { CrossAggregate } from "../../../server/models/cross-aggregate";

export const array2CsvBlob = (input: (string | number | boolean)[][]): Blob => {
	const json2csvParser = new json2csv.Parser({ header: false });
	const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
	const blob = new Blob([bom, json2csvParser.parse(input)], { type: "text/csv" });
	return blob;
};

export const downloadData = (blob: Blob, filename: string): void => {
	const link = document.createElement("a");
	link.href = window.URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export const isDefined = (v: any) => {
	return v !== undefined;
};

/**
 * 対象の値がundefinedやnullでないかのチェック
 * @template {T}
 * @param {T | undefined | null} value
 * @returns {value is T}
 */
export const isDefinedUpdatedVersion = <T>(value: T | undefined | null): value is T => {
	if (value === undefined || value === null) return false;
	if (typeof value === "string" && value === "") return false;
	return true;
};

export const createAggregateJob = (
	{
		_id: jobId,
		enqueteId,
	}: {
		_id?: Key | undefined;
		enqueteId?: string | undefined;
	},
	questions: Array<Question<AggregateTargetQuestionType>>,
): EditableAggregateJob => {
	return createAggregateJobBase({ _id: jobId, enqueteId }, questions, {});
};

/**
 * 対象の値がundefinedやnullでないかのチェック
 * - empty array -> false
 * @param {T | undefined | null} value
 * @returns {value is T}
 */
export const isDefinedArray = <T extends Array<Partial<CrossAggregate>>>(value: T | undefined | null): value is T => {
	if (!isDefined(value)) return false;
	return value.length > 0;
};
