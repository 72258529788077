/* eslint-disable react/destructuring-assignment */

import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyApplication } from "../../../../../../server/models/ad-copy/application";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type {
	CancelAdCopyApplicationResponse,
	CreateAdCopyApplicationResponse,
	GetAdCopyApplicationResponse,
} from "../../../../../../server/types/request/ad-copy/application";
import { AdCopyApplicationPage } from "../../../../components/pages/ad-copy/approval";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError, post, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/application";
import { adCopyRoot } from "../../../../routes/adCopyEndpoint";

export const AdCopyApplicationContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { accountOptions, application, info, loading, overEstimateAgreement } = state;

	const { activityId, jobId } = useParams<{ activityId: string; jobId: string }>();

	const navigate = useNavigate();

	React.useEffect(() => {
		get<GetAdCopyApplicationResponse>(`${adCopyEndpoint.application}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadApplication" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onChange = React.useCallback(
		(name: keyof AdCopyApplication, value: AdCopyApplication[keyof AdCopyApplication]) =>
			dispatch({ payload: { name, value }, type: "changeApplication" }),
		[],
	);

	const onChangeApprover = React.useCallback(
		(index: number, value: string) => dispatch({ payload: { index, value }, type: "changeApprover" }),
		[],
	);

	const onSubmit = React.useCallback(() => {
		dispatch({ payload: true, type: "changeLoading" });

		post<CreateAdCopyApplicationResponse>(`${adCopyEndpoint.application}/${jobId}`, application)
			.then((response) => {
				dispatch({ payload: response.data.application, type: "updateApplication" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [application, jobId]);

	const onCancel = React.useCallback(() => {
		put<CancelAdCopyApplicationResponse>(`${adCopyEndpoint.application}/${jobId}`, {})
			.then((response) => {
				dispatch({ payload: response.data.application, type: "cancelApplication" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onClose = React.useCallback(() => {
		navigate(`${adCopyRoot}/activity/${activityId}/job`);
	}, [activityId, navigate]);

	const canCancel = React.useMemo(() => {
		if (!application || application.cancel) {
			return false;
		}

		return loginAccount._id?.toString() === application.accountId.toString();
	}, [loginAccount, application]);

	const onAgreeOverEstimate = React.useCallback(() => dispatch({ payload: true, type: "changeAgreement" }), []);

	const onCancelOverEstimate = React.useCallback(() => navigate(-1), [navigate]); // キャンセルの場合はページを戻る。

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} onClose={onClose} titleLabel="処理" />

			<AdCopyApplicationPage
				application={application}
				brandholders={accountOptions}
				errors={info.errors}
				onAgreeOverEstimate={onAgreeOverEstimate}
				onCancel={canCancel ? onCancel : undefined}
				onCancelOverEstimate={onCancelOverEstimate}
				onChange={onChange}
				onChangeApprover={onChangeApprover}
				onSubmit={onSubmit}
				overEstimateAgreement={overEstimateAgreement}
			/>
		</>
	);
};
