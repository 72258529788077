import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";

import type { ErrorObject } from "../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../lib/error";
import type { TrueAdCopySimpleActivity } from "../../../../../types/ad-copy/activity";
import { AdCopyMultipleText } from "../../../../parts/ad-copy/multiple-text";
import { Help } from "../../../../parts/help";
import { Title } from "../../../../parts/title";

const key = "actionStandards";

type Key = typeof key;

type Value = TrueAdCopySimpleActivity[Key];

type Props = {
	errors: ErrorObject | undefined;
	onChangeActivity: (name: Key, value: Value) => void;
	value: Value;
};

export const AdCopyActionStandardsInputs = React.memo((props: Props) => {
	const { errors, onChangeActivity, value } = props;

	return (
		<Container fluid>
			<Row>
				<Col md={12}>
					<Title>
						アクションスタンダード
						<Help
							explain="広告/コピーの目的に合わせて設定ください。ゲートマネジメントの対象調査ではありませんが、どのような結果が確認できるとネクストアクションに反映できるかの視点で設定ください。"
							id={key}
						/>
					</Title>
				</Col>
			</Row>

			<Row className="ms-3">
				<Col md={12}>
					<p>
						<span>記載例）購入意向（ワンナンバースコア）が競合・現行を上回る</span>

						<br />

						<span>　　　　情報検索意向「この商品についてもっと知りたい」（平均値）が競合・現行を上回る</span>
					</p>
				</Col>
			</Row>

			<Row className="ms-3">
				<Col md={12}>
					{errors?.[key] != null && (
						<Feedback style={{ display: "block" }} type="invalid">
							{getErrorMessage(key, errors)}
						</Feedback>
					)}
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<AdCopyMultipleText
						max={5}
						min={1}
						onChange={(v) => {
							onChangeActivity(key, v);
						}}
						values={value ?? []}
					/>
				</Col>
			</Row>
		</Container>
	);
});
