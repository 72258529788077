import * as React from "react";
import styled from "styled-components";

import type { AdCopyActivityWithBrand } from "../../../../../../../server/models/ad-copy/activity";
import type { AdCopyConcept } from "../../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import type { AdCopySampleSizesByType } from "../../../../../../../server/types/request/ad-copy/report/outline";
import { AdCopyGraphWrapArea } from "../common";
import { AdCopyActivityPanel } from "./activity-panel";
import { AdCopyConceptPanel } from "./concept-panel";
import { AdCopyTestPanel } from "./test-panel";

export const AdCopyOutlinePanelBody = styled.div`
	padding: 0 1em;
`;

export const AdCopyIndentArea = styled.div<{ indent?: number }>`
	margin-left: ${({ indent }) => indent || 2}em;
`;

export const AdCopyOutlinePanelTitle = styled.div`
	font-weight: bold;
	text-decoration: underline;
	margin-bottom: 10px;
`;

export const AdCopyOutlinePanel = styled.div`
	padding: 10px;
`;

export const AdCopyOutlineSubTitle = styled.div`
	margin-bottom: 0.3em;
`;

export const AdCopyChildPanel = styled.div`
	margin-bottom: 0.8em;
`;

export const AdCopyOutlineCard = styled.div<{ width: number }>`
	display: inline-block;
	vertical-align: top;
	width: ${({ width }) => width}px;
`;

export const AdCopyOutlineTable = styled.table`
	margin: 0px auto;
	margin-left: 0px;
	& td.colon {
		text-align: right;
	}
	& td {
		vertical-align: top;
	}
	& tr.sub-title td {
		padding-top: 5px;
		border-bottom: 1px solid #ddd;
	}
	& tr.strategy-category-head td,
	tr.bordered td,
	tr.bordered th {
		border-bottom: 1px solid #ddd;
	}
`;

type Props = {
	activity: AdCopyActivityWithBrand;
	benchmarkName?: string;
	concepts: AdCopyConcept[];
	job: AdCopyJob;
	onViewQuotaPage: (jobId: string) => void;
	sampleSizes: AdCopySampleSizesByType;
};

export const AdCopyOutline = React.memo((props: Props) => {
	const { activity, benchmarkName, concepts, job, onViewQuotaPage, sampleSizes } = props;

	const onViewQuota = React.useCallback(() => {
		onViewQuotaPage(job._id?.toString() ?? "");
	}, [onViewQuotaPage, job]);

	return (
		<AdCopyGraphWrapArea style={{ minWidth: "1180px" }}>
			<AdCopyActivityPanel activity={activity} />

			<AdCopyTestPanel
				activity={activity}
				benchmarkName={benchmarkName}
				job={job}
				onViewQuotaPage={onViewQuota}
				sampleSizesByType={sampleSizes}
			/>

			<AdCopyConceptPanel concepts={concepts} />
		</AdCopyGraphWrapArea>
	);
});
