import { Labels } from ".";
import { Account } from "../server/models/account";

export const accountLabels: Labels<Account> = {
	_id: "アカウント",
	code: "ID",
	name: "氏名",
	email: "メールアドレス",
	brand: "担当ブランド",
	role: "権限設定",
	password: "パスワード",
	isActive: "アクティブ",
};
