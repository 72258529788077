import * as React from "react";
import { Brand } from "../../../../../../server/models/brand";
import { Table } from "react-bootstrap";
import { ModalButton } from "../modal-button";

export type BrandRecognitionTableProps = {
	brand: Brand;
};
export const BrandRecognitionTable = React.memo((props: BrandRecognitionTableProps) => {
	const { brand } = props;
	return (
		<ModalButton buttonText={"当該ブランド認知率表"} title={`「${brand.name}」当該ブランド認知率表（15-69歳）`}>
			<>
				<Table>
					<thead>
						<tr>
							<th>UPIカテゴリ</th>
							<th>UPIブランド</th>
							<th>認知率</th>
						</tr>
					</thead>
					<tbody>
						{brand.brandRecognition
							.sort((a, b) => b.gAwareness - a.gAwareness)
							.map((bRecognition, index) => {
								return (
									<tr key={`brand-recognition-${index}`}>
										<td>{bRecognition.sCategoryUPI}</td>
										<td>{bRecognition.sBrandUPI2}</td>
										<td>{Math.round(bRecognition.gAwareness)}%</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</>
		</ModalButton>
	);
});
