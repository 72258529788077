import * as React from "react";
import { reducer, initState } from "../../../reducers/job/create";
import { JobCreatePage } from "../../../components/pages/job/create";
import { Job } from "../../../../../server/models/job";
import { useParams, useNavigate } from "react-router-dom";
import { makeError, post, put, get } from "../../../lib/request";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { AxiosResponse } from "axios";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { SwitchConfirm, SwitchConfirmEvent } from "../../../components/parts/switch-confirm";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { replaceBreakAndSpace } from "../../../../../server/lib/common";
import { Prompt } from "../../../components/parts/prompt";
import { GetJobResponse, UpsertJobResponse } from "../../../../../server/types/request/job";

export const JobCreateContariner = (loginAccount: AccountWithoutPassword) => {
	const { activityId, jobId, retestJobId } = useParams<{ activityId: string; jobId: string; retestJobId: string }>();
	const [state, dispatch] = React.useReducer(reducer, {
		...initState,
		job: { ...initState.job, ...(retestJobId ? { retestJobId, hasBenchmark: false, hasCurrentProduct: false } : {}) },
	});
	const { info, loading, ...jobCreatePageProps } = state;
	const navigate = useNavigate();
	const onChangeJob = React.useCallback((name: keyof Job, value: Job[keyof Job]) => {
		dispatch({ type: "changeJob", payload: { name, value } });
	}, []);
	const onChangeEvaluationItem = React.useCallback((items: string[]) => {
		dispatch({ type: "changeEvaluationItem", payload: items });
	}, []);
	/*/
	const onChangeQuestion = React.useCallback((question?: SimpleQuestion) => {
		dispatch({ type: "changeQuestion", payload: question });
	}, []);
	/*/
	React.useEffect(() => {
		get<GetJobResponse>(
			`${replaceEndpointUrl(endpoint.job, { activityId })}/${jobId || "new"}`,
			retestJobId ? { retestJobId } : undefined,
		)
			.then((response) => {
				dispatch({ type: "loadJob", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [activityId, jobId, retestJobId]);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		let request: Promise<AxiosResponse<UpsertJobResponse>>;
		const data = {
			...jobCreatePageProps,
			evaluationItem: jobCreatePageProps.evaluationItem.filter(
				(item) => !!item && replaceBreakAndSpace(item).length > 0,
			),
		};
		// 作成後のURLもnewのままだが、activityがsetされると_idがつくので、参照するようにする。
		const id = state.job._id ? state.job._id.toString() : jobId;
		if (id === "new" || !!retestJobId) {
			request = post(`${replaceEndpointUrl(endpoint.job, { activityId })}`, data);
		} else {
			request = put(`${replaceEndpointUrl(endpoint.job, { activityId })}/${id}`, data);
		}
		request
			.then((response) => {
				dispatch({ type: "updateJob", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobCreatePageProps, state.job._id, jobId, retestJobId, activityId]);
	//const onClose = React.useCallback(() => navigate(`/activity/${activityId}/job`), []);
	const onJobPage = React.useCallback(() => navigate(`/activity/${activityId}/job`), [activityId, navigate]);
	const onConceptPage = React.useCallback(
		() => navigate(`/concept/${activityId}/${jobCreatePageProps.job._id.toString()}`),
		[activityId, jobCreatePageProps.job._id, navigate],
	);
	const events = React.useMemo<SwitchConfirmEvent[]>(() => {
		return [
			{
				label: "ジョブ一覧に戻る",
				variant: "outline-secondary",
				func: onJobPage,
			},
			{
				label: "コンセプト設定へ",
				variant: "secondary",
				func: onConceptPage,
			},
		];
	}, [onJobPage, onConceptPage]);
	return (
		<>
			<Prompt message={"変更が保存されていません。ページを移動してよろしいですか？"} when={state.prompt && !loading} />
			<Loading loading={loading} text={"processing..."} />
			{!info.isSuccess && <ConfirmBox info={info} />}
			<SwitchConfirm
				show={info.isSuccess && !!info.message}
				title={`ジョブの${!jobId || jobId === "new" ? "新規作成" : "更新"}`}
				body={info.message}
				events={events}
			/>
			{/*<InfoMessage info={info} />*/}
			<JobCreatePage
				{...jobCreatePageProps}
				loginAccount={loginAccount}
				errors={info.errors}
				onChangeJob={onChangeJob}
				onChangeEvaluationItem={onChangeEvaluationItem}
				// onChangeQuestion={onChangeQuestion}
				onSubmit={onSubmit}
			/>
		</>
	);
};
