import dayjs from "dayjs";
import * as React from "react";
import { Alert, Button, Dropdown } from "react-bootstrap";
import styled from "styled-components";

import type { Column, Option } from "../../../../../../../../react-components/lu-component/src";
import { AdCopyRole } from "../../../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../../../server/models/ad-copy/activity";
import {
	type AdCopyJob,
	type AdCopyJobWithSurveyStatus,
	adCopySurveyMethodOptions,
} from "../../../../../../../../server/models/ad-copy/job";
import { type AdCopyJobStatus, AdCopySurveyStatus } from "../../../../../../../../server/models/ad-copy/status";
import { stopSurvey } from "../../../../../../lib/build-options";
import { ConfirmDropdownItem } from "../../../../../parts/confirm-dropdown-item";
import { TextRed } from "../../../../../parts/font";
import type { AdCopyJobPageType } from "../..";
import { AdCopyJobSearchListTable } from "../list-table";

const ButtonArea = styled.div`
	max-width: 120px;
`;

type Props = {
	accountOptions: Option[];
	activity: AdCopyActivityWithBrand;
	hideTitle?: boolean;
	jobs: AdCopyJobWithSurveyStatus[];
	loginAccount: AccountWithoutPassword;
	onChangePage: (id: string, page: AdCopyJobPageType) => void;
	onEditPage: (id: string) => void;
	onJobStatusChange: (id: string, status: keyof typeof AdCopyJobStatus) => void;
	onOutlinePage: (id: string) => void;
	onRetest: (id: string) => void;
	searches: { [key in keyof AdCopyJobWithSurveyStatus]?: AdCopyJobWithSurveyStatus[key] };
};

export const AdCopyJobSearchResult = React.memo((props: Props) => {
	const {
		accountOptions,
		activity,
		hideTitle,
		jobs,
		loginAccount,
		onChangePage,
		onEditPage,
		onJobStatusChange,
		onOutlinePage,
		onRetest,
		searches,
	} = props;

	const hasEditRole = React.useMemo(
		() => AdCopyRole.hasAcitivityEditRole(loginAccount, activity),
		[loginAccount, activity],
	);

	const hasQuotaViewRole = React.useMemo(
		() => AdCopyRole.hasViewQuotaRole(loginAccount, activity),
		[loginAccount, activity],
	);

	const hasViewInvoiceRole = React.useMemo(
		() => AdCopyRole.hasViewInvoiceRole(loginAccount, activity),
		[loginAccount, activity],
	);

	const hasCustomRole = React.useMemo(() => AdCopyRole.hasCustomRole(loginAccount, activity), [loginAccount, activity]);

	/**
	 * 削除可能
	 * - システム
	 * - 申請者
	 * - アクティビティメンバ
	 */
	const hasDeleteActivityRole = React.useMemo(() => {
		if (AdCopyRole.hasOperarorManageRole(loginAccount)) {
			return true;
		}

		if (AdCopyRole.isActivityMember(loginAccount, activity)) {
			return true;
		}

		return false;
	}, [loginAccount, activity]);

	const hasApplicationRole = React.useMemo(
		() => AdCopyRole.hasApplicationRole(loginAccount, activity),
		[loginAccount, activity],
	);

	const canSurvey = React.useMemo(() => !stopSurvey(), []);

	const retestJobIds = React.useMemo(() => {
		const ids = new Set<string>();

		for (const job of jobs) {
			if (job.retestJobId) {
				ids.add(job.retestJobId.toString());
			}
		}

		return ids;
	}, [jobs]);

	const columns = React.useMemo<Column[][]>(() => {
		const tmp: Column[][] = [
			[
				{
					label: "ジョブNo.",
					name: "jobNum",
					type: "text",
				},
			],
			[
				{
					label: "作成者",
					name: "accountId",
					options: accountOptions,
					type: "select",
				},
			],
			[
				{
					label: "調査日時",
					name: "surveyDatetime",
					type: "custom",
					view: (job: AdCopyJob) => {
						return (
							<>
								<div>{job.startDatetime}</div>

								<div>{dayjs(job.endDatetime).add(5, "minute").format("YYYY-MM-DDTHH:mm")}</div>
							</>
						);
					},
				},
			],
			[
				{
					label: "調査ステータス",
					name: "surveyStatus",
					type: "custom",
					view: (job: AdCopyJobWithSurveyStatus) => {
						const { inspection, surveyStatus } = job;

						return (
							<>
								{surveyStatus === "complete" ? (
									<Button
										className="w-100"
										onClick={() => onOutlinePage(job._id?.toString() ?? "")}
										size="sm"
										variant="outline-secondary"
									>
										結果閲覧({inspection ? "検収済" : "未検収"})
									</Button>
								) : (
									AdCopySurveyStatus[surveyStatus]
								)}
							</>
						);
					},
				},
			],
			[
				{
					label: "調査手法",
					name: "method",
					options: adCopySurveyMethodOptions,
					type: "select",
				},
			],
			[
				{
					label: "基本設定",
					name: "setting",
					type: "custom",
					view: (job: AdCopyJobWithSurveyStatus) => {
						return (
							<ButtonArea>
								<Button
									className="w-100"
									onClick={() => onEditPage(job._id?.toString() ?? "")}
									size="sm"
									variant="outline-secondary"
								>
									基本設定
								</Button>

								{job.method === "copy-appeal" && (
									<Button
										className="w-100 mt-2"
										onClick={() => onChangePage(job._id?.toString() ?? "", "concept")}
										size="sm"
										variant="outline-secondary"
									>
										コピー設定
									</Button>
								)}
							</ButtonArea>
						);
					},
				},
			],
			[
				{
					label: "その他設定",
					name: "otherSetting",
					type: "custom",
					view: (job: AdCopyJobWithSurveyStatus) => {
						const id = job._id?.toString() ?? "";

						if (job.surveyStatus === "conceptSetting" && !hasDeleteActivityRole) {
							return <></>;
						}

						return (
							<Dropdown>
								<Dropdown.Toggle id={`dropdown-${id}`} variant="secondary">
									設定
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{job.surveyStatus !== "conceptSetting" && (
										<>
											<Dropdown.Item onClick={() => onChangePage(id, "preview")} style={{ fontSize: "90%" }}>
												プレビュー
											</Dropdown.Item>

											{(job.surveyStatus === "beforeApplication" ||
												job.surveyStatus === "applicationReject" ||
												job.surveyStatus === "applying") && (
												<Dropdown.Item onClick={() => onChangePage(id, "enquete-test")} style={{ fontSize: "90%" }}>
													動作画面
												</Dropdown.Item>
											)}

											<Dropdown.Item onClick={() => onChangePage(id, "job-detail")} style={{ fontSize: "90%" }}>
												ジョブ詳細
											</Dropdown.Item>
										</>
									)}

									{canSurvey &&
										hasApplicationRole &&
										(job.surveyStatus === "beforeApplication" ||
											job.surveyStatus === "applying" ||
											job.surveyStatus === "applicationReject") && (
											<Dropdown.Item onClick={() => onChangePage(id, "application")} style={{ fontSize: "90%" }}>
												配信申請
											</Dropdown.Item>
										)}

									{canSurvey && (job.surveyStatus === "applying" || job.surveyStatus === "applicationReject") && (
										<Dropdown.Item onClick={() => onChangePage(id, "approval")}>承認状況</Dropdown.Item>
									)}

									{(job.surveyStatus === "progress" || job.surveyStatus === "complete") && hasQuotaViewRole && (
										<Dropdown.Item onClick={() => onChangePage(id, "quota")}>回収状況</Dropdown.Item>
									)}

									{job.surveyStatus === "complete" && hasViewInvoiceRole && (
										<Dropdown.Item onClick={() => onChangePage(id, "invoice")}>請求情報</Dropdown.Item>
									)}

									{hasCustomRole && job.surveyStatus === "stopping" && (
										<ConfirmDropdownItem
											body="調査を再開しようとしています。よろしいですか？"
											onOk={() => onJobStatusChange(id, "progress")}
											title="調査再開"
										/>
									)}

									{hasCustomRole && job.surveyStatus === "progress" && (
										<ConfirmDropdownItem
											body="調査を完了しようとしています。よろしいですか？"
											onOk={() => onJobStatusChange(id, "complete")}
											title="調査完了"
										/>
									)}

									{hasCustomRole &&
										job.surveyStatus !== "progress" &&
										job.surveyStatus !== "complete" &&
										job.surveyStatus !== "stopping" && (
											<ConfirmDropdownItem
												body="調査を中止しようとしています。よろしいですか？"
												onOk={() => onJobStatusChange(id, "stopping")}
												title="調査中止"
											/>
										)}

									{hasDeleteActivityRole &&
										job.surveyStatus !== "progress" &&
										job.surveyStatus !== "complete" &&
										job.surveyStatus !== "applying" &&
										job.surveyStatus !== "waitingToStart" && (
											<ConfirmDropdownItem
												body={
													<>
														<div>ジョブNo. {job.jobNum} を削除してよろしいでしょうか。</div>

														<div>
															<TextRed>※一度削除すると、データ復旧できませんので再度ご確認ください。</TextRed>
														</div>
													</>
												}
												onOk={() => onJobStatusChange(id, "deleted")}
												title="ジョブ削除"
											/>
										)}
								</Dropdown.Menu>
							</Dropdown>
						);
					},
				},
			],
			[
				{
					label: "再調査",
					name: "resurvey",
					type: "custom",
					view: (job: AdCopyJobWithSurveyStatus) => {
						const { retestJobId } = job;
						const targetJob = retestJobId ? jobs.find((job) => job._id === retestJobId) : undefined;

						return (
							<ButtonArea>
								{targetJob && `${targetJob.jobNum}の再調査`}

								{hasEditRole &&
									!retestJobIds.has(job._id?.toString() ?? "") &&
									(job.surveyStatus === "complete" || job.surveyStatus === "completeWithZeroSamples") && (
										<Button
											className="w-100"
											onClick={() => onRetest(job._id?.toString() ?? "")}
											size="sm"
											variant="outline-secondary"
										>
											再調査登録
										</Button>
									)}
							</ButtonArea>
						);
					},
				},
			],
		];

		return tmp;
	}, [
		accountOptions,
		canSurvey,
		hasApplicationRole,
		hasCustomRole,
		hasDeleteActivityRole,
		hasEditRole,
		hasQuotaViewRole,
		hasViewInvoiceRole,
		jobs,
		onChangePage,
		onEditPage,
		onJobStatusChange,
		onOutlinePage,
		onRetest,
		retestJobIds,
	]);

	return (
		<>
			{!hideTitle && (
				<div className="m-3 clearfix">
					<div className="h3 float-start">■ジョブ一覧</div>

					{jobs.length === 0 && (
						<div className="col-md-auto float-end">
							{hasEditRole && (
								<Button
									onClick={() => onEditPage("new")}
									size="lg"
									style={{ borderWidth: "1.6px" }}
									variant="secondary"
								>
									ジョブ新規作成
								</Button>
							)}
						</div>
					)}
				</div>
			)}

			<div className="m-3 ">
				<Alert variant="secondary">
					{activity &&
						`${hideTitle ? `No.${activity.no}　` : ""}${activity.releaseYear}年${activity.releaseMonth}月 ${
							activity.brand.name
						} ${activity.category.name} ${activity.memo || ""}`}
				</Alert>

				<AdCopyJobSearchListTable columns={columns} data={jobs} searches={searches} />
			</div>
		</>
	);
});
