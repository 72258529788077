import { MessageInfo } from "../../../types/info";
import { type SimpleQuestion, type CheckQuestion, type OpenQuestion } from "@muscat/types";
import { QuestionAction } from "../../../types/actions";

export type State = {
	question: Partial<SimpleQuestion>;
	loading: boolean;
	info: MessageInfo;
	prompt: boolean;
};

export const initState: State = {
	question: { quename: "A1", quelabel: "追加設問" },
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
	prompt: false,
};

const changeQuestion = (
	question: Partial<SimpleQuestion>,
	name: keyof SimpleQuestion,
	value: SimpleQuestion[keyof SimpleQuestion],
) => {
	const tmp = { ...question, [name]: value };
	if (name === "type") {
		if (value === "O") {
			(tmp as OpenQuestion).rows = 3;
			(tmp as OpenQuestion).cols = 30;
			delete (tmp as any).choiceGroups;
		} else if (value === "N") {
			delete (tmp as any).choiceGroups;
		} else {
			delete (tmp as any).rows;
			delete (tmp as any).cols;
			(tmp as CheckQuestion).choiceGroups =
				question.type === "M" || question.type === "S"
					? (tmp as CheckQuestion).choiceGroups
					: [{ id: 1, choices: [{ value: 1, text: "" }] }];
		}
	}
	return tmp;
};

const addChoice = (question: Partial<SimpleQuestion>, index: number) => {
	if (question.type !== "S" && question.type !== "M") return { ...question };
	const choices = question.choiceGroups[0].choices.concat();
	choices.splice(index, 0, { value: 0, text: "" });
	return {
		...question,
		choiceGroups: [
			{
				id: 1,
				choices: choices.map((choice, index) => {
					return { value: index + 1, text: choice.text };
				}),
			},
		],
	};
};
const deleteChoice = (question: Partial<SimpleQuestion>, index: number) => {
	if (question.type !== "S" && question.type !== "M") return { ...question };
	const choices = question.choiceGroups[0].choices.concat();
	choices.splice(index, 1);
	return {
		...question,
		choiceGroups: [
			{
				id: 1,
				choices: choices.map((choice, index) => {
					return { value: index + 1, text: choice.text };
				}),
			},
		],
	};
};

export const reducer = (state: State, action: QuestionAction): State => {
	switch (action.type) {
		case "loadQuestion":
			return {
				...state,
				loading: false,
				question: action.payload,
			};
		case "deleteQuestion":
			return {
				...state,
				loading: false,
				question: action.payload,
				info: {
					isSuccess: true,
					message: "追加設問の削除が完了しました。",
				},
				prompt: false,
			};
		case "updateQuestion":
			return {
				...state,
				loading: false,
				question: action.payload,
				info: {
					isSuccess: true,
					message: "追加設問の登録が完了しました。",
				},
				prompt: false,
			};
		case "changeQuestion":
			return {
				...state,
				question: changeQuestion(state.question, action.payload.name, action.payload.value),
				prompt: true,
			};
		case "addChoice":
			return {
				...state,
				question: addChoice(state.question, action.payload),
				prompt: true,
			};
		case "deleteChoice":
			return {
				...state,
				question: deleteChoice(state.question, action.payload),
				prompt: true,
			};
		case "changeChoice":
			if (state.question.type !== "M" && state.question.type !== "S") return state;
			return {
				...state,
				question: {
					...state.question,
					choiceGroups: [
						{
							...state.question.choiceGroups[0],
							choices: state.question.choiceGroups[0].choices.map((choice, index) => {
								if (index === action.payload.index) {
									return {
										...choice,
										[action.payload.name]: action.payload.value,
									};
								}
								return choice;
							}),
						},
					],
				},
				prompt: true,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
