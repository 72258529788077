import * as React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { Confirm } from "../../../../../react-components/lu-component/src/index";

export type ConfirmButtonProps = {
	buttonText: string;
	title: string;
	body: string | JSX.Element | JSX.Element[];
	onCancel?: () => void;
	onOk: () => void;
} & ButtonProps;

export const ConfirmButton = React.memo((props: ConfirmButtonProps) => {
	const { buttonText, title, body, onCancel, onOk, ...buttonProps } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const onClose = React.useCallback(() => {
		setShow(false);
		if (onCancel) onCancel();
	}, [onCancel]);
	const onOkClick = React.useCallback(() => {
		setShow(false);
		onOk();
	}, [onOk]);
	const onOpen = React.useCallback(() => setShow(true), []);
	return (
		<>
			<Button onClick={onOpen} {...buttonProps}>
				{buttonText}
			</Button>
			<Confirm
				show={show}
				onCancel={onClose}
				onOk={onOkClick}
				title={title}
				body={body}
				buttonText={"はい"}
				variant={"secondary"}
			/>
		</>
	);
});
