import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopySkinProblemFrequencyOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopySkinProblemFrequencyCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	return (
		<Row>
			<FormGroup as={Col} md={6}>
				<AccordionForm
					defaultShow={!!strategyGroup.skinProblemFrequency && strategyGroup.skinProblemFrequency.length !== 0}
					eventKey="skinProblemFrequency"
					title={adCopyStrategyTargetGroupLabels.skinProblemFrequency ?? ""}
				>
					<Checkboxes
						checkeds={strategyGroup.skinProblemFrequency ?? []}
						choices={adCopySkinProblemFrequencyOptions as Choice[]}
						name="skinProblemFrequency"
						onChange={(value) => onChange("skinProblemFrequency", value)}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
