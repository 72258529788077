/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopyLipstickTypeOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyLipstickTypeCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	const shouldShow = React.useMemo(
		() => strategyGroup.category?.some((c) => c.category.type === "lipstick"),
		[strategyGroup.category],
	);

	return (
		<Row>
			<FormGroup as={Col} md={5}>
				<AccordionForm
					defaultShow={strategyGroup.lipstickType != null && 0 < strategyGroup.lipstickType.length}
					eventKey="lipstickType"
					title={adCopyStrategyTargetGroupLabels.lipstickType ?? ""}
				>
					{shouldShow ? (
						<Checkboxes
							checkeds={strategyGroup.lipstickType ?? []}
							choices={adCopyLipstickTypeOptions.filter((x) => x.label !== "その他") as Choice[]}
							name="lipstickType"
							onChange={(value) => {
								onChange("lipstickType", value);
							}}
						/>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
