import * as React from "react";
import { Button, Modal } from "react-bootstrap";

import {
	adCopyStrategyGroupCategoryLabels,
	adCopyStrategyTargetGroupLabels,
} from "../../../../../../../label/ad-copy/activity";
import {
	makeAdCopyAgeLabel,
	makeAdCopyPriceLabel,
} from "../../../../../../../server/lib/ad-copy/enquete/quota/helpers";
import type { AdCopyStrategyGroup } from "../../../../../../../server/models/ad-copy/activity";
import {
	AdCopyAestheticMedicineExperience,
	AdCopyBeautyType,
	AdCopyChannel,
	AdCopyChildren,
	AdCopyEyeshaowType,
	AdCopyFacewashFunction,
	AdCopyFoundationType,
	AdCopyLipstickType,
	AdCopyMarried,
	AdCopyOccupation,
	AdCopySensitiveSkin,
	AdCopySensitiveSkinFrequencyRank,
	AdCopySkinAttribute,
	AdCopySkinProblemFrequency,
} from "../../../../../../../server/models/ad-copy/activity";
import { AdCopyIndentArea } from "../../../../pages/ad-copy/report/outline";
import { GypsyGroupPanel } from "./gypsy-group-panel";
import { AdCopyGroupPanel, AdCopySCategoryRow } from "./parts";

type Props = {
	strategyTargetGroup: AdCopyStrategyGroup;
};

export const AdCopyStrategyDetailsModalButton = React.memo((props: Props) => {
	const { strategyTargetGroup } = props;

	const [show, setShow] = React.useState(false);

	const onClose = React.useCallback(() => {
		setShow(false);
	}, []);

	const onOpen = React.useCallback(() => {
		setShow(true);
	}, []);

	return (
		<>
			<Button onClick={onOpen} size="sm" variant="dark">
				戦略ターゲット詳細
			</Button>

			<Modal onHide={onClose} show={show}>
				<Modal.Header>
					<Modal.Title>戦略ターゲット詳細</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{/* 年齢 */}
					<AdCopyGroupPanel
						label={adCopyStrategyTargetGroupLabels.age}
						value={makeAdCopyAgeLabel(strategyTargetGroup.age)}
					/>

					{/* 未既婚 */}
					{strategyTargetGroup.married && !!strategyTargetGroup.married.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.married ?? ""}
							value={strategyTargetGroup.married.map((target) => AdCopyMarried[target]).join("、")}
						/>
					)}

					{/* 子ども年齢（同居） */}
					{strategyTargetGroup.children && !!strategyTargetGroup.children.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.children ?? ""}
							value={strategyTargetGroup.children.map((target) => AdCopyChildren[target]).join("、")}
						/>
					)}

					{/* 職業 */}
					{strategyTargetGroup.occupation && !!strategyTargetGroup.occupation.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.occupation ?? ""}
							value={strategyTargetGroup.occupation.map((target) => AdCopyOccupation[target]).join("、")}
						/>
					)}

					{/* 肌悩み */}
					{strategyTargetGroup.skinAttribute && !!strategyTargetGroup.skinAttribute.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.skinAttribute ?? ""}
							value={strategyTargetGroup.skinAttribute.map((target) => AdCopySkinAttribute[target]).join("、")}
						/>
					)}

					{/* 敏感肌① */}
					{strategyTargetGroup.sensitiveSkin && !!strategyTargetGroup.sensitiveSkin.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.sensitiveSkin ?? ""}
							value={strategyTargetGroup.sensitiveSkin.map((target) => AdCopySensitiveSkin[target]).join("、")}
						/>
					)}

					{/* 敏感肌② */}
					{/* 敏感肌頻度 */}
					{strategyTargetGroup.sensitiveSkinFrequency && !!strategyTargetGroup.sensitiveSkinFrequency.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.sensitiveSkinFrequency ?? ""}
							value={strategyTargetGroup.sensitiveSkinFrequency
								.map((target) => AdCopySensitiveSkinFrequencyRank[target])
								.join("、")}
						/>
					)}

					{/* 肌トラブル頻度 */}
					{strategyTargetGroup.skinProblemFrequency && !!strategyTargetGroup.skinProblemFrequency.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.skinProblemFrequency ?? ""}
							value={strategyTargetGroup.skinProblemFrequency
								.map((target) => AdCopySkinProblemFrequency[target])
								.join("、")}
						/>
					)}

					{/* ファンデーションタイプ */}
					{strategyTargetGroup.foundationType && !!strategyTargetGroup.foundationType.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.foundationType ?? ""}
							value={strategyTargetGroup.foundationType.map((target) => AdCopyFoundationType[target]).join("、")}
						/>
					)}

					{/* 洗顔機能 */}
					{!!strategyTargetGroup.facewashFunction && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.facewashFunction ?? ""}
							value={AdCopyFacewashFunction[strategyTargetGroup.facewashFunction].toString()}
						/>
					)}

					{/* 口紅・リップグロス使用カテゴリ */}
					{strategyTargetGroup.lipstickType && !!strategyTargetGroup.lipstickType.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.lipstickType ?? ""}
							value={strategyTargetGroup.lipstickType.map((target) => AdCopyLipstickType[target]).join("、")}
						/>
					)}

					{/* アイシャドー使用カテゴリ */}
					{strategyTargetGroup.eyeshadowType && !!strategyTargetGroup.eyeshadowType.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.eyeshadowType ?? ""}
							value={strategyTargetGroup.eyeshadowType.map((target) => AdCopyEyeshaowType[target]).join("、")}
						/>
					)}

					{/* 購入チャネル */}
					{strategyTargetGroup.channel && !!strategyTargetGroup.channel.length && (
						<AdCopyGroupPanel
							label={adCopyStrategyTargetGroupLabels.channel ?? ""}
							value={strategyTargetGroup.channel.map((target) => AdCopyChannel[target]).join("、")}
						/>
					)}

					{/* ジプシー */}
					{strategyTargetGroup.gypsy && <GypsyGroupPanel gypsy={strategyTargetGroup.gypsy} />}

					{/* 美容医療利用経験 */}
					{strategyTargetGroup.aestheticMedicineExperience &&
						!!strategyTargetGroup.aestheticMedicineExperience.length && (
							<AdCopyGroupPanel
								label={adCopyStrategyTargetGroupLabels.aestheticMedicineExperience ?? ""}
								value={strategyTargetGroup.aestheticMedicineExperience
									.map((target) => AdCopyAestheticMedicineExperience[target])
									.join("、")}
							/>
						)}

					<hr />

					<div>カテゴリ条件</div>

					<div>以下カテゴリ間の条件を{strategyTargetGroup.categoryOrAnd}条件で設定します。</div>

					<hr />

					{strategyTargetGroup.category?.map((cate) => {
						const surveyChoice = cate.category.adCopySurveyChoice.find((sc) => sc.value === cate.surveyChoice);

						return (
							<React.Fragment key={`category-${surveyChoice?.value}`}>
								<div>{surveyChoice?.label}</div>

								<AdCopyIndentArea indent={1}>
									{/* 価格帯（税抜き） */}
									{cate.price && (
										<AdCopySCategoryRow
											label={adCopyStrategyGroupCategoryLabels.price ?? ""}
											value={makeAdCopyPriceLabel(cate.price)}
										/>
									)}

									{/* カテゴリタイプ（スキンケアのみ） */}
									{/* 美類 */}
									{cate.beautyType && !!cate.beautyType.length && (
										<AdCopySCategoryRow
											label={adCopyStrategyGroupCategoryLabels.beautyType ?? ""}
											value={cate.beautyType.map((target) => AdCopyBeautyType[target]).join("、")}
										/>
									)}

									{/* 過去1年購入＆使用ブランド */}
									{cate.usedBrand && !!cate.usedBrand.length && (
										<AdCopySCategoryRow
											label={adCopyStrategyGroupCategoryLabels.usedBrand ?? ""}
											value={cate.usedBrand.join("、")}
										/>
									)}

									{/* 過去1年非購入ブランド */}
									{cate.unusedBrand && !!cate.unusedBrand.length && (
										<AdCopySCategoryRow
											label={adCopyStrategyGroupCategoryLabels.unusedBrand ?? ""}
											value={cate.unusedBrand.join("、")}
										/>
									)}

									{/* NOTE: 本当になくなったら消す */}
									{/* 現在使用ブランド */}
									{/* {cate.currentlyUsedBrand && !!cate.currentlyUsedBrand.length && (
										<SCategoryRow
											label={adCopyStrategyGroupCategoryLabels.currentlyUsedBrand ?? ""}
											value={cate.currentlyUsedBrand.join("、")}
										/>
									)} */}

									{/* 現在使用していないブランド */}
									{/* {cate.currentlyUnusedBrand && !!cate.currentlyUnusedBrand.length && (
										<SCategoryRow
											label={adCopyStrategyGroupCategoryLabels.currentlyUnusedBrand ?? ""}
											value={cate.currentlyUnusedBrand.join("、")}
										/>
									)} */}
								</AdCopyIndentArea>
							</React.Fragment>
						);
					})}

					{strategyTargetGroup.nonPurchaseCategoryConfig && (
						<>
							<hr />

							<div>{adCopyStrategyTargetGroupLabels.nonPurchaseCategoryConfig}</div>

							<div>
								以下カテゴリ間の条件を{strategyTargetGroup.nonPurchaseCategoryConfig.categoryOrAnd}条件で設定します。
							</div>

							<hr />

							{strategyTargetGroup.nonPurchaseCategoryConfig.surveyChoices.map((x) => (
								<div key={x.value}>{x.label}</div>
							))}
						</>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={onClose} variant="outline-secondary">
						close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
