import { ErrorObject } from "../../../server/types/error";

export const getErrorMessage = (name: string | number, errors?: ErrorObject, index?: number): string => {
	if (!errors) return undefined;

	if (index !== undefined && errors[index]) {
		const error = errors[index];
		if (typeof error === "object" && error[name]) {
			return error[name].toString();
		}
	} else if (name in errors) {
		const error = errors[name];
		if (typeof error === "string") {
			return error;
		}
	}
	return undefined;
};

export const getErrorObject = (name: string, errors?: ErrorObject): ErrorObject => {
	if (!errors) return undefined;
	if (name in errors) {
		const error = errors[name];
		if (typeof error === "object") {
			return error;
		}
	}
	return undefined;
};

export const getErrors = (name: string, errors?: ErrorObject): string | ErrorObject => {
	if (!errors) return undefined;
	if (name in errors) {
		return errors[name];
	}
	return undefined;
};
