type PointRate = {
	main: number;
	sc: number;
};
type PointTarget = keyof PointRate;

export const adCopyRate: PointRate = {
	sc: 4,
	main: 8,
};

export const aggregationCost = 10000;

export const calCost = (point: number, target: PointTarget): number => {
	return Math.floor(point * adCopyRate[target]);
};

export const decimalCalc = (input: number, decimalPoint = 10): number => {
	return Math.round(input * decimalPoint) / decimalPoint;
};
export const percentDecimalCalc = (input: number, decimalPoint = 10): number => {
	return Math.round(input * decimalPoint * 100) / decimalPoint;
};

export const decimalCalcJson = <T extends Object>(values: T, decimalPoint = 100): T => {
	return Object.entries(values).reduce((a, [key, value]) => {
		return {
			...a,
			[key]: decimalCalc(value, decimalPoint),
		};
	}, {}) as T;
};
