import * as React from "react";
import styled from "styled-components";
import { GetKeyMeasurementsResponse } from "../../../../../../server/types/request/report/key-mesurements";
import { CrossTable } from "../../../parts/cross-table";
import { ReportBigTitle } from "../parts/report-title";

const CrossTableWrap = styled.div`
	margin: 0px 10px;
	padding: 0px 10px;
	max-width: 1200px;
	min-width: 1000px;
`;

export type KeyMeasurementsReportPageProps = GetKeyMeasurementsResponse;
export const KeyMeasurementsReportPage = React.memo((props: KeyMeasurementsReportPageProps) => {
	const { data, job } = props;
	const filename = React.useMemo(() => (job ? `${job.jobNum}-keyMeasurements` : undefined), [job]);
	return (
		<>
			<ReportBigTitle>集計表{job ? `（${job.jobNum}）` : ""}</ReportBigTitle>
			<CrossTableWrap>
				<CrossTable filename={filename} data={data} headers={[]} />
			</CrossTableWrap>
		</>
	);
});
