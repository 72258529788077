import * as React from "react";
import { Button } from "react-bootstrap";
import { styled } from "styled-components";

import { adCopyActivityLabels } from "../../../../../../label/ad-copy/activity";
import type { Option } from "../../../../../../react-components/lu-component/src/index";
import { AdCopyRole } from "../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { AdCopyActivityStatusOptions } from "../../../../../../server/models/ad-copy/status";
import useInfo from "../../../../hooks/use-info";
import { SubTitle } from "../../../parts/accodion-form";
import { InfoPart } from "../../../parts/info";
import type { SearchColumn, SearchValues } from "../../../parts/search";
import { Search } from "../../../parts/search";
import type { AdCopyActivityListProps } from "./ad-copy-activity-list";
import { AdCopyActivityList } from "./ad-copy-activity-list";

const NewActivityWrap = styled.div`
	margin-bottom: 30px;
`;

type Props = Omit<AdCopyActivityListProps, "accountOptions"> & {
	accounts: AccountWithoutPassword[];
	onChangeSearchValue: (name: string, value: number | string) => void;
	onSearch: () => void;
	searchValues: SearchValues;
	yearOptions: Option[];
};

export const AdCopyActivityPage = React.memo((props: Props) => {
	const {
		accounts,
		brandOptions,
		categoryOptions,
		loginAccount,
		onChangeSearchValue,
		onEditPage,
		onSearch,
		searchValues,
		yearOptions,
	} = props;

	const searchAccountOptions = React.useMemo<Option[]>(() => {
		const filteredAccounts = searchValues["brandId"]
			? accounts.filter((account) => {
					return account.brand && account.brand.includes(searchValues["brandId"].toString());
			  })
			: accounts;

		return filteredAccounts.map((account) => {
			return {
				label: account.name,
				value: account._id?.toString() ?? "",
			};
		});
	}, [accounts, searchValues]);

	const accountOptions = React.useMemo<Option[]>(() => {
		return accounts.map((account) => {
			return {
				label: account.name,
				value: account._id?.toString() ?? "",
			};
		});
	}, [accounts]);

	const searchColums: SearchColumn[] = React.useMemo(
		() => [
			{
				label: adCopyActivityLabels.brandId,
				name: "brandId",
				options: brandOptions,
				type: "select",
			},
			{
				label: adCopyActivityLabels.category,
				name: "categoryId",
				options: categoryOptions,
				type: "select",
			},
			{
				label: "ステータス",
				name: "status",
				options: AdCopyActivityStatusOptions,
				type: "select",
			},
			{
				label: "アクティビティ登録者",
				name: "accountId",
				options: searchAccountOptions,
				type: "search-select",
			},
			{
				label: adCopyActivityLabels.releaseYear,
				name: "releaseYear",
				options: yearOptions,
				type: "select",
			},
			{
				label: adCopyActivityLabels.releaseMonth,
				name: "releaseMonth",
				options: [
					{ label: "1月", value: 1 },
					{ label: "2月", value: 2 },
					{ label: "3月", value: 3 },
					{ label: "4月", value: 4 },
					{ label: "5月", value: 5 },
					{ label: "6月", value: 6 },
					{ label: "7月", value: 7 },
					{ label: "8月", value: 8 },
					{ label: "9月", value: 9 },
					{ label: "10月", value: 10 },
					{ label: "11月", value: 11 },
					{ label: "12月", value: 12 },
				],
				type: "select",
			},
		],
		[brandOptions, categoryOptions, searchAccountOptions, yearOptions],
	);

	const oshirase = useInfo();

	return (
		<div className="m-3 clearfix">
			<div className="m-4">{oshirase && <InfoPart message={oshirase.message} showValue />}</div>

			{AdCopyRole.hasNewActivity(loginAccount) && (
				<NewActivityWrap>
					<div className="h3">■アクティビティ新規作成</div>

					<div className="m-4">
						<Button onClick={() => onEditPage()} size="lg" style={{ borderWidth: "1.6px" }} variant="secondary">
							アクティビティ新規作成
						</Button>
					</div>
				</NewActivityWrap>
			)}

			<div className="h3">■アクティビティ一覧</div>

			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>検索</SubTitle>

				<Search
					columns={searchColums}
					onChangeSearchValue={onChangeSearchValue}
					onSearch={onSearch}
					searchValues={searchValues}
				/>
			</div>

			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>一覧</SubTitle>

				<AdCopyActivityList {...props} accountOptions={accountOptions} />
			</div>
		</div>
	);
});
