import * as React from "react";
import { QuotaStatusProps, QuotaStatus } from "../../../parts/quota-status";
import styled from "styled-components";

const RedDiv = styled.div`
	color: red;
`;

export type QuotaPageProps = QuotaStatusProps;
export const QuotaPage = React.memo((props: QuotaPageProps) => {
	return (
		<div className="m-3 clearfix">
			<div className="h3">■回収状況</div>
			{props.isCMI && (
				<RedDiv>
					回収数はデータクリーニング前の数値です。集計時の有効回答数は減少する場合があります。（予定数に対してショートしているセルの回収数は減少しません。）
				</RedDiv>
			)}
			<QuotaStatus {...props} />
		</div>
	);
});
