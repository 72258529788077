import { MessageInfo } from "../../../types/info";
import { ExectiveAction } from "../../../types/actions";
import { GetExectiveReportResponse } from "../../../../../server/types/request/report/exective";
import { ElementEvaluation } from "../../../../../server/models/graph/element-evaluation";

export type State = {
	loading: boolean;
	info: MessageInfo;
	evaluations: ElementEvaluation[];
} & GetExectiveReportResponse;

export const initState: State = {
	goalSalesVolume: undefined,
	data: [],
	evaluations: [],
	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
};

export const reducer = (state: State, action: ExectiveAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				...action.payload,
			};
		case "loadEvaluation":
			return {
				...state,
				evaluations: action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
