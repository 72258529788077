import * as React from "react";
import { Alert, Col } from "react-bootstrap";
import { StrongAndUnderDecorate, TextRed } from "../../../parts/font";
import styled from "styled-components";

const Section = styled.div`
	margin-top: 0.8rem;
`;

export const Message: React.FC = () => {
	return (
		<>
			{/* obon message */}
			{/*<Col sm={11}>
				<Alert variant={"secondary"}>
					<Section>
						<div>
							<StrongDecorate>【実査（調査の配信）について】 </StrongDecorate>
						</div>
						<li>
							調査にふさわしくない時期となりますので、
							<TextRed>“Test it”による「配信申請」と「承認」は、8/7（月）～8/16（水）の期間は行えません。</TextRed>
						</li>
						<li>
							<TextRed>
								お盆前に実査を行う場合は、「調査開始日時」を必ず8/6（日）までで設定し、8/6（日）中に申請者の「配信申請」と承認者の「承認」が終わるように
							</TextRed>
							してください。
							<div>
								※以下の場合は、希望の調査期間に実査が行われない、または、十分な回収が完了しませんのでご注意ください
							</div>
							<div>・調査開始日時を8/7（月）～8/16（水）に設定した場合</div>
							<div>・8/6（日）中に「配信申請」と承認者の「承認」が終わらなかった場合</div>
						</li>
					</Section>
					<Section>
						<div>
							<StrongDecorate>【SJ夏季休暇中の窓口対応について】</StrongDecorate>
						</div>
						<li>
							SJの夏季休暇中、万一システムトラブル等があっても当部が速やかにサポートできない可能性があります。ご了承の上、ご進行ください。
							<br />
							なお、システムトラブルの際は、専用アドレス（
							<a href="mailto:testit-info@cmi-testit.com">testit-info@cmi-testit.com</a>
							）にご連絡ください。システム管理会社および当部に通知されます。
						</li>
					</Section>
					<Section>
						<div>
							<StrongDecorate>【調査結果の閲覧・ダウンロードについて】 </StrongDecorate>
						</div>
						<li>8/7（月）～8/16（水）の期間も、調査結果の閲覧・ダウンロードなどは通常通り行えます。</li>
					</Section>
				</Alert>
			</Col>*/}
			{/* maitenance message */}

			{/*<<Col sm={11}>
				Alert variant={"secondary"}>
					<Section>
						<div>
							<StrongDecorate>【ゴールデンウィーク中の実査（調査の配信）について】 </StrongDecorate>
						</div>
						<li>
							<TextRedAndUnderline>
								Test itによる実査（配信申請と承認）は、2023/4/25（火）～2023/5/7（日）の期間は行えません。
							</TextRedAndUnderline>
						</li>
						<li>
							ゴールデンウィーク前は、
							<TextRedAndUnderline>
								「調査開始日時」を必ず4/24までで設定し、4/24中に申請者の「配信申請」と承認者の「承認」が終わるように
							</TextRedAndUnderline>
							してください。
						</li>
						<li>
							ゴールデンウィーク明けは、
							<TextRedAndUnderline>5/8以降の日付で、「調査開始日時」を設定し、</TextRedAndUnderline>
							「配信申請」を行ってください。
						</li>
						<div>
							※調査開始日時が4/25-5/7となっていたり、4/24中に「配信申請」と承認者の「承認」が終わらない場合は、この期間に実査は行われない、または、十分な回収が完了しません。
						</div>
					</Section>
					<Section>
						<div>
							<StrongDecorate>【調査結果の閲覧・ダウンロードについて】</StrongDecorate>{" "}
						</div>
						<li>調査結果の閲覧・ダウンロードはゴールデンウィーク中も可能です。（付与されている権限に準ずる）</li>
					</Section>
				</Alert>
			</Col>
			*/}

			{/* change action standard message */}
			<Col sm={11}>
				<Alert variant={"secondary"}>
					<div>
						2024年6月に<StrongAndUnderDecorate>アクションスタンダードの設定ルール</StrongAndUnderDecorate>
						が変更になりました。
					</div>
					<Section>
						<div>下記のアクションスタンダードが、最低限の基準になります。</div>
						<div>商品の目的に合わせてブランドホルダーがアクションスタンダードを決定してください。</div>
						<div>
							最低基準以上のアクションスタンダードを設定した際は、調査開始の実働２日前までに、CMI室MIGへご連絡ください。
						</div>
					</Section>
					<Section>
						<div>
							<TextRed>＜最低基準のアクションスタンダード＞</TextRed>
						</div>
						<div>
							<TextRed>
								戦略ターゲットG：差別性と購入意向ワンナンバースコアが現行（リニューアル時）・競合を上回る
							</TextRed>
						</div>
					</Section>
					{/*
					<div>
						<b>【実査（調査の配信）について】</b>
						<li>
							年末年始は調査にふさわしくない時期となりますので、
							<StrongAndUnderDecorate>
								Test itによる実査（配信申請と承認）は、2022/12/24（土）～2023/1/9（月）の期間行えません。
							</StrongAndUnderDecorate>
						</li>
						<li>
							年内は、
							<b>
								「調査開始日時」を必ず 2022/12/19(月)までで設定し、
								2022/12/19(月)中に申請者の「配信申請」と承認者の「承認」が終わるように
							</b>
							してください。
							<br />
						</li>
						<li>
							年明けは、<b>2023/1/10（火） 以降の日付</b>で、「調査開始日時」を設定し、「配信申請」を行ってください。
							<br />
							※調査開始日時が2022/12/20(火）-2023/1/9（月）となっていたり、2022/12/19（月）中に「配信申請」と承認者の「承認」が終わらない場合は、この期間に実査は行われない、または、十分な回収が完了しません。
						</li>
					</div>
					<br />
					<div>
						<b>【調査結果の閲覧・ダウンロードについて】</b>
						<li>
							システムメンテナンスのため、
							<StrongAndUnderDecorate>
								2022/12/24（土）～2022/12/31（土）までは、Test itにアクセスできなくなります。
							</StrongAndUnderDecorate>
						</li>
						<li>
							2023/1/1（日）～2023/1/9（月）の期間は、調査結果の閲覧・ダウンロードが可能です。（付与されている権限に準ずる）
						</li>
						<br />
						<b>
							※ <TextRed>2023/1/11（水）</TextRed>
							に新組織での権限付与を行います。
							<TextRed>2023/1/1（日）～2023/1/11（水）に限り、旧組織の権限での運用</TextRed>
							となります、ご了承ください。
						</b>
					</div>*/}
				</Alert>
			</Col>
		</>
	);
};
