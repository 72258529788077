import { MessageInfo } from "../../types/info";
import { Action } from "../../types/actions";

export type State = {
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	loading: false,
	info: { isSuccess: true, message: "" },
};

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
