import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyInvoiceResponse } from "../../../../../../server/types/request/ad-copy/invoice";
import { AdCopyInvoicePage } from "../../../../components/pages/ad-copy/invoice";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/invoice";

export const AdCopyInvoiceContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, invoiceInfo, loading } = state;

	const { jobId } = useParams<{
		jobId: string;
	}>();

	React.useEffect(() => {
		get<GetAdCopyInvoiceResponse>(`${adCopyEndpoint.invoice}/${jobId}`)
			.then((response) => {
				dispatch({
					payload: response.data,
					type: "loadInvoice",
				});
			})
			.catch((error) => {
				dispatch({
					payload: makeError(error),
					type: "changeMessageInfo",
				});
			});
	}, [jobId]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} />

			{invoiceInfo && <AdCopyInvoicePage {...invoiceInfo} />}
		</>
	);
};
