import * as React from "react";
import styled from "styled-components";
import { OAData, Column, SelectionFilter } from "../../../../../server/models/graph/oa";
import { Row, Col, FormLabel, FormControl, Button } from "react-bootstrap";
import { SingleSelect } from "../../parts/select";
import { targetGroupOptions } from "../../../../../server/models/graph/target-group";
import { conceptOptions } from "../../../../../server/models/concept";
import { ReportBigTitle } from "../report/parts/report-title";
import { MultipleSelect } from "../../parts/select";
import { SubTitle } from "../../parts/sub-title";
import { downloadData, array2CsvBlob } from "../../../lib/data";
import { Job } from "../../../../../server/models/job";

const SelectArea = styled.div`
	width: 100%;
`;
const OATable = styled.table`
	width: 100%;
	font-size: 0.9em;
	thead {
		vertical-align: top;
	}
	.form-control {
		height: 27px;
		padding: 0px;
	}
	tr.button-area td {
		text-align: right;
		padding-right: 10px;
		padding-bottom: 10px;
	}
	tr.head-section td {
		border-bottom: 1px solid #999;
		padding: 2px;
	}
	tr.head-section td {
		text-align: center;
		background-color: #ddd;
	}
	div[class$="-ValueContainer"],
	div[class$="-IndicatorsContainer"],
	div[class$="-indicatorContainer"],
	div.css-1hwfws3 {
		padding: 0px !important;
	}
	div[class$="-control"] {
		min-height: 27px;
	}
	td.data-no,
	td.data-age {
		text-align: center;
	}
	td.no {
		width: 70px;
	}
	td.age {
		min-width: 120px;
	}
	td.purchaseIntent,
	td.forMySelf {
		min-width: 160px;
	}
	td[class*="S14-"] {
		min-width: 300px;
		max-width: 350px;
	}
	td.conceptImpression {
		min-width: 400px;
	}
	tr.data-section td {
		border-bottom: 1px dotted #ddd;
	}
`;

export type OASearchTableProps = {
	data: OAData[];
	columns: Column[];
	job: Job;
};
export const OASearchTable = React.memo((props: OASearchTableProps) => {
	const { data, columns, job } = props;
	const [filterValues, setFilterValues] = React.useState<{ [key: string]: number | number[] | string | string[] }>({
		conceptType: "test1",
		target: "category",
	});
	const filename = React.useMemo(() => (job ? `${job.jobNum}-oa` : "oa"), [job]);
	const choiceMap = React.useMemo(() => {
		return columns
			.filter((column) => column.type === "select")
			.reduce(
				(a, b: SelectionFilter) => {
					return { ...a, [b.name]: new Map(b.options.map(({ value, label }) => [value, label])) };
				},
				{} as { [key: string]: Map<number | string, string> },
			);
	}, [columns]);
	const filterdData = React.useMemo(() => {
		return data.filter((d) => {
			if (filterValues["conceptType"] && filterValues["conceptType"] !== d["conceptType"]) {
				return false;
			}
			if (filterValues["target"] && !(d["target"] as any[]).includes(filterValues["target"] as any)) {
				return false;
			}
			return columns.every((column) => {
				const filterValue = filterValues[column.name];
				if (!filterValue) return true;
				const vs = d[column.name];
				if (column.type === "text") {
					if (column.like) {
						return vs.toString().includes(filterValue.toString());
					}
					return vs.toString() === filterValue.toString();
				}
				if (Array.isArray(vs)) {
					if (!vs.length) return false;
					return (filterValue as any[]).some((v: any) => (vs as any[]).includes(v));
				}
				return (filterValue as any[]).includes(vs as any);
			});
		});
	}, [data, filterValues, columns]);
	const onChangeFilter = React.useCallback(
		(name: string, value: any) => {
			const tmp = { ...filterValues };
			if (!value || (Array.isArray(value) && value.length === 0)) {
				delete tmp[name];
			} else {
				tmp[name] = value;
			}
			setFilterValues(tmp);
		},
		[filterValues],
	);
	const onDownload = React.useCallback(() => {
		const header = columns.map((column) => column.label);
		const data = filterdData.map((data) =>
			columns.map((column) => {
				const tmp = data[column.name];
				if (Array.isArray(tmp)) {
					if (column.type !== "select") return tmp.join(",");
					const choice = choiceMap[column.name];
					return (tmp as any[]).map((v: any) => choice.get(v)).join(",");
				}
				if (column.type === "select") {
					const choice = choiceMap[column.name];
					return choice.get(tmp) || tmp;
				}
				return tmp || "";
			}),
		);
		downloadData(array2CsvBlob([header, ...data]), `${filename}.csv`);
	}, [columns, filterdData, choiceMap, filename]);
	return (
		<>
			<ReportBigTitle>コンセプトの印象　自由回答集（{job.jobNum}）</ReportBigTitle>
			<Row>
				<Col md={3}>
					<FormLabel>ターゲットグループ</FormLabel>
					<SingleSelect
						noBlunkOption
						options={targetGroupOptions}
						value={filterValues["target"] as string}
						onChange={(value) => {
							onChangeFilter("target", value);
						}}
					/>
				</Col>
				<Col md={3}>
					<FormLabel>コンセプト</FormLabel>
					<SingleSelect
						noBlunkOption
						options={conceptOptions}
						value={filterValues["conceptType"] as string}
						onChange={(value) => {
							onChangeFilter("conceptType", value);
						}}
					/>
				</Col>
			</Row>
			<SubTitle>フィルター</SubTitle>
			<OATable>
				<thead>
					<tr>
						{columns.map((column) => {
							return (
								<td className={column.name} key={`search-${column.name}`}>
									<FormLabel>{column.label}</FormLabel>
									{column.type === "text" && (
										<FormControl
											name={column.name}
											value={filterValues[column.name] ? filterValues[column.name].toString() : ""}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												onChangeFilter(column.name, e.target.value);
											}}
										/>
									)}
									{column.type === "select" && (
										<SelectArea>
											<MultipleSelect
												options={column.options}
												value={(filterValues[column.name] as any) || []}
												onChange={(values) => onChangeFilter(column.name, values)}
											/>
										</SelectArea>
									)}
								</td>
							);
						})}
					</tr>
					<tr>
						<th colSpan={columns.length}>
							<SubTitle>自由回答集</SubTitle>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr className={"button-area"}>
						<td colSpan={columns.length}>
							<Button variant={"outline-secondary"} size={"sm"} onClick={onDownload}>
								Download
							</Button>
						</td>
					</tr>
					<tr className={"head-section"}>
						{columns.map((column) => (
							<td key={`head-${column.name}`}>{column.label}</td>
						))}
					</tr>
					{filterdData.map((d) => (
						<tr className={"data-section"} key={`data-${d["no"]}`}>
							{columns.map((column) => {
								const value = d[column.name];
								const choice = choiceMap[column.name];
								return (
									<td className={`data-${column.name}`} key={`data-${d["no"]}-${column.name}`}>
										{column.type === "text" && value}
										{column.type === "select" &&
											(Array.isArray(value)
												? (value as any[]).map((v: any) => choice.get(v)).join(",")
												: choice.get(value as any))}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</OATable>
		</>
	);
});
