import * as React from "react";
import { Table } from "react-bootstrap";
import { ModalButton } from "../modal-button";
import { Category } from "../../../../../../server/models/category";

export type CategoryRecognitionTableProps = {
	category: Category;
};
export const CategoryRecognitionTable = React.memo((props: CategoryRecognitionTableProps) => {
	const { category } = props;
	return (
		<ModalButton buttonText={"他ブランド認知率表"} title={`「${category.name}」他ブランド認知率表（15-69歳）`}>
			<Table>
				<thead>
					<tr>
						<th>UPIカテゴリ</th>
						<th>UPIブランド</th>
						<th>認知率</th>
					</tr>
				</thead>
				<tbody>
					{category.categoryRecognition
						.sort((a, b) => b.gAwareness - a.gAwareness)
						.map((bRecognition, index) => {
							return (
								<tr key={`brand-recognition-${index}`}>
									<td>{bRecognition.sCategoryUPI}</td>
									<td>{bRecognition.sBrandUPI2}</td>
									<td>{Math.round(bRecognition.gAwareness)}%</td>
								</tr>
							);
						})}
				</tbody>
			</Table>
		</ModalButton>
	);
});
