import * as React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";

export type ListProps = {
	list: string[];
};
export const List = React.memo((props: ListProps) => {
	const { list } = props;
	return (
		<ListGroup>
			{list.map((item, index) => (
				<ListGroupItem key={`list-item-${index}`}>
					{index + 1}.{item}
				</ListGroupItem>
			))}
		</ListGroup>
	);
});
