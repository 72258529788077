import type { AdCopyConcept } from "../../../../../../server/models/ad-copy/concept";
import type { AdCopyPreviewAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	concepts: AdCopyConcept[];
	info: MessageInfo;
	loading: boolean;
	url: string;
};

export const initState: State = {
	concepts: [],
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	url: "",
};

export const reducer = (state: State, action: AdCopyPreviewAction): State => {
	switch (action.type) {
		case "loadPreview":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return { ...state, loading: action.payload };
	}
};
