import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AdCopyActivityStatus } from "../../../../../../server/models/ad-copy/status";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyActivityStatusResponse } from "../../../../../../server/types/request/ad-copy/activity";
import { AdCopyActivityStatusPage } from "../../../../components/pages/ad-copy/activity/status";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/activity/status";

export const AdCopyActivityStatusContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading, status } = state;

	const onChange = React.useCallback((value: keyof typeof AdCopyActivityStatus) => {
		dispatch({ payload: value, type: "changeStatus" });
	}, []);

	const { activityId } = useParams<{ activityId: string }>();

	React.useEffect(() => {
		get<GetAdCopyActivityStatusResponse>(`${adCopyEndpoint.activityStatus}/${activityId}`)
			.then((response) => {
				dispatch({ payload: response.data.status, type: "loadStatus" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activityId]);

	const onSubmit = React.useCallback(() => {
		put<GetAdCopyActivityStatusResponse>(`${adCopyEndpoint.activityStatus}/${activityId}`, { status })
			.then(() => {
				dispatch({ payload: { isSuccess: true, message: "更新が完了しました。" }, type: "changeMessageInfo" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activityId, status]);

	return (
		<>
			<ConfirmBox info={info} titleLabel="更新処理" />

			<Loading loading={loading} />

			<AdCopyActivityStatusPage errors={info.errors} onChnage={onChange} onSubmit={onSubmit} status={status} />
		</>
	);
};
