import type { DetailsOfResultOutputDataType } from "./resultDetails";

export type Style = {
	className?: string;
	style?: React.CSSProperties;
};

export type OutputCellData =
	| (Style & {
			colSpan?: number;
			rowSpan?: number;
			value: number | string;
	  })
	| null;

export type OutputTHeadTrData = Style & {
	cells: OutputCellData[];
};

export type OutputTHeadData = Style & {
	trs: OutputTHeadTrData[];
};

export type OutputTBodyCellData =
	| (OutputCellData & {
			isTh?: boolean;
	  })
	| null;

export type OutputTBodyTrData = Style & {
	cells: OutputTBodyCellData[];
};

export type OutputTBodyData = Style & {
	trs: OutputTBodyTrData[];
};

export type OutputTableData = Style & {
	colStyles?: React.CSSProperties[];
	tBodies: OutputTBodyData[];
	tHead: OutputTHeadData;
};

/**
 * フロント側で表示するテーブルデータ形式の区分
 *  - keyMeasurements: クロス集計ページ
 *  - purchaseIntentions: 購入意向ONSデータ
 * - evaluationItems: 項目別評価（平均）
 * - catchCopyImpressions: キャッチコピー印象度（TOP2）
 * - catchCopyAttractions: キャッチコピー魅力度（TOP2）
 * - keyVisualEvaluations: キービジュアル評価
 */
export type TableContentType = DetailsOfResultOutputDataType | "keyMeasurements";
