import * as React from "react";
import styled from "styled-components";

import type { GetAdCopyOAReportResponse } from "../../../../../../../server/types/request/ad-copy/report/openAnswer";
import { AdCopyOASearchTable } from "./oa-search-table";

const AdCopyGraphWrapArea = styled.div`
	margin: 0px auto;
	padding: 0px 10px;
	min-width: 1000px;
`;

type Props = GetAdCopyOAReportResponse;

export const AdCopyOAReportPage = React.memo((props: Props) => {
	return (
		<AdCopyGraphWrapArea>
			<AdCopyOASearchTable {...props} />
		</AdCopyGraphWrapArea>
	);
});
