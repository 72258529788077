import type { Option } from "../../../../../../react-components/lu-component/src/index";
import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyJobWithSurveyStatus } from "../../../../../../server/models/ad-copy/job";
import { AdCopySurveyStatus } from "../../../../../../server/models/ad-copy/status";
import type { AdCopyJobListAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	accountOptions: Option[];
	activity?: AdCopyActivityWithBrand;
	info: MessageInfo;
	jobs: AdCopyJobWithSurveyStatus[];
	loading: boolean;
};

export const initState: State = {
	accountOptions: [],
	activity: undefined,
	info: {
		isSuccess: true,
		message: "",
	},
	jobs: [],
	loading: true,
};

export const reducer = (state: State, action: AdCopyJobListAction): State => {
	switch (action.type) {
		case "loadJobs":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "changeJobStatus":
			return {
				...state,
				info: {
					isSuccess: true,
					message: `ステータスを「${AdCopySurveyStatus[action.payload.surveyStatus]}」に変更しました。`,
				},
				jobs: state.jobs.map((job) => {
					if (job._id?.toString() === action.payload.jobId) {
						return {
							...job,
							status: action.payload.status,
							surveyStatus: action.payload.surveyStatus,
						};
					}

					return job;
				}),
				loading: false,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return { ...state, loading: action.payload };
	}
};
