import { AccountWithoutPassword } from "../../models/account";
import { Activity } from "../../models/activity";
import { ObjectId } from "mongodb";
import { Job } from "../../models/job";

export class Role {
	/**
	 * 管理者権限かどうか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isAdmin(account: AccountWithoutPassword): boolean {
		return account.role === "admin";
	}
	/**
	 * システム管理権限（管理者、運用管理者）の権限を持っているか。
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static hasManageSystemRole(account: AccountWithoutPassword): boolean {
		return account.role === "admin";
	}
	/**
	 * 運用管理者権限を持っているか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static hasOperarorManageRole(account: AccountWithoutPassword): boolean {
		return account.role === "admin" || account.role === "operation-manager";
	}
	/**
	 * 特権閲覧者かどうか。
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isSuperViewer(account: AccountWithoutPassword): boolean {
		return account.role === "viewer1";
	}
	/**
	 * ブランンドマネージャ、アクティビティ登録者のいずれかかどうか。
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isBrander(account: AccountWithoutPassword): boolean {
		return Role.isBrandManager(account) || Role.isBrandHolder(account);
	}
	/**
	 * ブランドマネージャー（承認者）かどうか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isBrandManager(account: AccountWithoutPassword): boolean {
		return account.role === "approver";
	}
	/**
	 * アクティビティ登録者（申請者）かどうか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isBrandHolder(account: AccountWithoutPassword): boolean {
		return account.role === "applicant";
	}
	/**
	 * 特権閲覧権限を保有しているか
	 *   1. 管理者 or 運用管理者
	 *   2. 特権閲覧者
	 * @param account
	 */
	public static hasPrivilegedBrowsingRole(account: AccountWithoutPassword): boolean {
		return Role.hasOperarorManageRole(account) || Role.isSuperViewer(account);
	}
	/**
	 * 該当のアクティビティのブランドメンバーかどうか
	 * @param {AccountWithoutPassword} account
	 * @param {string | ObjectId} brandId
	 * @returns {boolean}
	 */
	public static isBrandMember(account: AccountWithoutPassword, brandId: string | ObjectId): boolean {
		const bid = brandId.toString();
		return (
			(account.role === "approver" || account.role === "applicant") &&
			account.brand &&
			account.brand.some((b) => b.toString() === bid)
		);
	}
	/**
	 * 該当のアクティビティのブランドのブランドマネージャー（所属ブランドの承認者）かどうか
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static isActivityBrandManager(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (!activity) return false;
		const brandId = activity.brandId.toString();
		return account.role === "approver" && account.brand && account.brand.some((b) => b.toString() === brandId);
	}
	/**
	 * アクティビティのメンバーかどうか。
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static isActivityMember(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (!activity) return false;
		const accountId = account._id.toString();
		return activity.accountIds.some((aid) => aid.toString() === accountId);
	}

	/**
	 * 新規アクティビティを作れるかどうか。
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static hasNewActivity(account: AccountWithoutPassword): boolean {
		return Role.hasManageSystemRole(account) || account.role === "applicant" || account.role === "approver";
	}
	/**
	 * アクティビティのメンバー（申請者）かどうか。
	 * 承認者も申請者となれる。
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 * @param account
	 * @param activity
	 */
	public static isActivityApplicant(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (account.role !== "applicant" && account.role !== "approver") return false;
		if (!activity) return false;
		return Role.isActivityMember(account, activity);
	}
	/**
	 * アカウント編集権限を持っているか
	 *   1. 管理者
	 * @param {AccountWithoutPassword} account
	 */
	public static hasAccountManageRole(account: AccountWithoutPassword): boolean {
		return Role.hasManageSystemRole(account);
	}
	/**
	 * アカウント閲覧を持っているか
	 *   1. 管理者、運用管理者
	 * @param {AccountWithoutPassword} account
	 */
	public static hasAccountViewRole(account: AccountWithoutPassword): boolean {
		return Role.hasOperarorManageRole(account);
	}

	/**
	 * アクティビティの閲覧権限を持っているか。
	 *   1. 管理者、運用管理者
	 *   2. 特権閲覧者
	 *   3. 対象アクティビティのブランドマネージャー
	 *   4. ブランドメンバー
	 *   5. すでに公開済みのアクティビティ
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static hasActivityViewRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		// 対象のアクティビティがなければ全員閲覧可能。
		if (!activity) return true;
		if (Role.hasOperarorManageRole(account)) {
			return true;
		}
		if (Role.isSuperViewer(account)) {
			return true;
		}
		if (Role.isBrandMember(account, activity.brandId)) {
			return true;
		}
		return activity.status === "complete";
	}
	/**
	 * アクティビティの編集権限を持っているか
	 *   1. 管理者
	 *   2. 対象アクティビティのブランドマネージャー
	 *   3. ブランドメンバー
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static hasAcitivityEditRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.hasManageSystemRole(account)) return true;
		// アクティビティがない場合は、新規作成権限を持っているか。
		if (!activity) return Role.hasNewActivity(account);
		if (Role.isActivityBrandManager(account, activity)) return true;
		return Role.isActivityMember(account, activity);
	}
	/**
	 * 回収状況を閲覧できるかどうか
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static hasViewQuotaRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.hasOperarorManageRole(account)) return true;
		return !!activity && Role.isBrandMember(account, activity.brandId);
	}
	/**
	 * 回収状況を閲覧できるかどうか
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @param {Job} job
	 * @returns {boolean}
	 */
	public static hasViewQuotaAllRole(account: AccountWithoutPassword, activity: Activity, job: Job): boolean {
		if (Role.hasOperarorManageRole(account)) return true;
		return Role.hasActivityViewRole(account, activity) && job.status === "complete";
	}
	/**
	 * 回収状況をセルごとに確認できるかどうか。
	 *   1. 管理者
	 *   2. 運用管理者（保有しているブランドのみ）
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static hasViewQuotaDetailRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.hasManageSystemRole(account)) return true;
		if (!activity) return false;
		const brandId = activity.brandId.toString();
		const brands = account.brand ? account.brand.map((bid) => bid.toString()) : [];
		return Role.hasOperarorManageRole(account) && brands.includes(brandId);
	}
	/**
	 * 申請権限があるか
	 *   1. 管理者、運用管理者
	 *   2. アクティビティメンバー（申請者）
	 * @param account
	 */
	public static hasApplicationRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.hasManageSystemRole(account)) {
			return true;
		}
		return !!activity && Role.isActivityApplicant(account, activity);
	}

	/**
	 * アクティビティの承認権限を持っているか
	 *   1. 管理者
	 *   2. 対象アクティビティのブランドマネージャー
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static hasApprovalRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.isAdmin(account)) return true;
		return !!activity && Role.isActivityBrandManager(account, activity);
	}
	/**
	 * 請求書情報を閲覧できるか
	 *   1. 管理者
	 *   2. 運用管理者
	 *   3. 対象アクティビティのブランドマネージャー
	 *   4. ブランドメンバー
	 *
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static hasViewInvoiceRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.hasOperarorManageRole(account)) {
			return true;
		}
		if (Role.isActivityBrandManager(account, activity)) {
			return true;
		}
		return !!activity && Role.isBrandMember(account, activity.brandId);
	}
	/**
	 * 承認者かどうか
	 *  1. 管理者権限を保有している。
	 *  2. 承認者に設定されている
	 * @param {AccountWithoutPassword} account
	 * @param {string | ObjectId} accountId
	 * @returns {boolean}
	 */
	public static isApprover(account: AccountWithoutPassword, accountId: string | ObjectId): boolean {
		if (Role.hasManageSystemRole(account)) return true;
		return account._id.toString() === accountId.toString();
	}

	/**
	 * 追加設問やステータス変更ができるか
	 * @param {AccountWithoutPassword} account
	 * @param {Activity} activity
	 * @returns {boolean}
	 */
	public static hasCustomRole(account: AccountWithoutPassword, activity?: Activity): boolean {
		if (Role.hasManageSystemRole(account)) return true;
		if (!activity) return false;
		const brandId = activity.brandId.toString();
		const brands = account.brand ? account.brand.map((bid) => bid.toString()) : [];
		return Role.hasOperarorManageRole(account) && brands.includes(brandId);
	}
}
