import { MonitasPoint } from "@muscat/monitas-deliver-library/dist/lib/point";

/**
 * スクリーニングポイントは5問ごとに1point
 */
export class FtMonitasPoint extends MonitasPoint {
	public static getSCPoint(questionCount: number): number {
		if (questionCount === 0) return 0;
		return Math.ceil(questionCount / 5);
	}
}
