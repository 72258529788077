import * as React from "react";
import { OASearchTable } from "../../oa-serch-table";
import { GetOAReportResponse } from "../../../../../../server/types/request/report/oa";
import styled from "styled-components";

const GraphWrapArea = styled.div`
	margin: 0px auto;
	padding: 0px 10px;
	min-width: 1000px;
`;

export type OAReportPageProps = GetOAReportResponse;

export const OAReportPage = React.memo((props: OAReportPageProps) => {
	return (
		<GraphWrapArea>
			<OASearchTable {...props} />
		</GraphWrapArea>
	);
});
