import * as React from "react";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import { decimalCalc } from "../../../../../../../server/lib/aggregate/common";
import { SelectableButton } from "./selectable-button";

const isNumber = (v: any): boolean => {
	return typeof v === "number";
};

const calc = (value: number, isPercent?: boolean) => {
	if (!isNumber(value)) return undefined;
	if (isPercent) {
		return decimalCalc(value * 100, 10);
	}
	return decimalCalc(value, 10);
};

const WrapArea = styled.div`
	position: relative;
	text-align: center;
	padding: 2px;
	border: 1px solid #ddd;
`;

const ButtonArea = styled.div`
	position: absolute;
	background-color: white;
	padding: 2px;
	top: -20px;
	left: 90px;
	background-color: white;
	z-index: 20;
	border: 1px solid #ddd;
`;

export type EditableTextProps = {
	value: number;
	initValue: number;
	onChange: (value: number) => void;
	isPercent?: boolean;
	min?: number;
};

export const EditableText = React.memo((props: EditableTextProps) => {
	const { value, initValue, isPercent, onChange, min = 0 } = props;
	const [focus, setFocus] = React.useState<boolean>(false);
	const [target, setTarget] = React.useState<boolean>(false);

	const [viewValue, setViewValue] = React.useState<string>(
		value !== undefined ? calc(value, isPercent).toFixed(1) : "-",
	);
	const onChangeValue = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const vv = e.target.value;
			setViewValue(vv);
			const num = Number(vv);
			if (vv === "" || isNaN(num)) {
				return onChange(undefined);
			}
			const roundValue = decimalCalc(num, 10);
			const v = isPercent ? roundValue / 100 : roundValue;
			onChange(v);
		},
		[isPercent, onChange],
	);
	const onBlur = React.useCallback(() => {
		const num = Number(viewValue);
		setViewValue(viewValue !== "" || isNaN(num) ? num.toFixed(1) : "-");
		setFocus(false);
	}, [viewValue]);
	const events = React.useMemo(
		() => [
			{
				label: "補整する",
				event: () => {
					setTarget(false);
					setFocus(true);
				},
			},
			{
				label: "初期値から変更しない",
				event: () => {
					setTarget(false);
					const v = isPercent ? initValue * 100 : initValue;
					setViewValue(v.toFixed(1));
					onChange(initValue);
				},
			},
		],
		[onChange, initValue, isPercent],
	);
	const onClick = React.useCallback(() => {
		if (!focus && !target) {
			setTarget(true);
		}
	}, [focus, target]);
	const style = React.useMemo(() => {
		if (viewValue === "-") {
			return { backgroundColor: "#ddd" };
		}
		return {};
	}, [viewValue]);
	if (!focus) {
		return (
			<WrapArea onClick={onClick} style={style}>
				{target && (
					<ButtonArea>
						<SelectableButton events={events} onClose={() => setTarget(false)} />
					</ButtonArea>
				)}
				<>{viewValue}</>
			</WrapArea>
		);
	}
	return (
		<FormControl type={"number"} step={"0.1"} min={min} value={viewValue} onChange={onChangeValue} onBlur={onBlur} />
	);
});
