import styled from "styled-components";

export const AdCopySelectWrap = styled.div`
	.react-select-container {
		&--invalid {
			.react-select__control {
				border-color: #dc3545;

				&--isfocused {
					border-color: #dc3545;
					box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
				}
			}
		}

		.react-select__control {
			background-color: white;
			border-color: #ced4da;
			border-radius: 0.25rem;
			min-height: calc(1.5em + 0.75rem + 2px);
			transition:
				border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;

			&--is-focused {
				color: #495057;
				background-color: white;
				border-color: #80bdff;
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
			}
		}

		.react-select__menu {
			.react-select__option {
				color: #212529;
				&--is-focused {
					background-color: #f8f9fa;
				}

				&--is-selected {
					background-color: #e9ecef;
				}
			}
		}

		.react-select__indicator {
			padding: 0 5px;
		}
	}
`;

export type AdCopySelectOption = {
	label: string;
	value: any;
};
