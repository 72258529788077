import * as React from "react";
import { CP } from "../../../../../../../server/models/cp";
import { Category } from "../../../../../../../server/models/category";
import { Brand } from "../../../../../../../server/models/brand";

import { CPSetting } from "./setting";
import { CPParameterSetting } from "./parameter";
import { SalesVolumeConstValue } from "../../../../../../../server/types/request/report/simulation";
import styled from "styled-components";
import { ActivityWithBrand } from "../../../../../../../server/models/activity";
import { CorrectionValue } from "../../../../../../../server/models/simulation-value";
import { ErrorObject } from "../../../../../../../server/types/error";

const WrapArea = styled.div`
	margin: 0.8rem;
`;
export type CPPageProps = {
	cp: Partial<CP>;
	errors?: ErrorObject;
	categories: Category[];

	brands: Brand[];
	onChange: <K extends keyof CP>(key: K, value: CP[K]) => void;
	onSubmit: () => void;
	onSubmitCPCorrectionValues: () => void;
	constValue: SalesVolumeConstValue;
	activity: ActivityWithBrand;
	hasEditRole: boolean;
	maxRecognition?: number;
	onClear: () => void;
};

export const CPPage = React.memo<CPPageProps>(
	({
		cp,
		categories,
		brands,
		onChange,
		errors,
		onSubmit,
		constValue,
		activity,
		hasEditRole,
		maxRecognition,
		onSubmitCPCorrectionValues,
		onClear,
	}) => {
		const onChangeRatio = React.useCallback(
			<K extends keyof CorrectionValue>(name: K, value: CorrectionValue[K]) => {
				console.log({ ...(cp.correctionValues ?? {}), [name]: value });
				onChange("correctionValues", { ...(cp.correctionValues ?? {}), [name]: value } as any);
			},
			[cp, onChange],
		);
		return (
			<WrapArea>
				<CPSetting {...{ cp, categories, brands, onChange, errors, onSubmit, activity, hasEditRole, onClear }} />
				<CPParameterSetting
					onSubmit={onSubmitCPCorrectionValues}
					hasEditRole={hasEditRole}
					cp={cp}
					constValue={constValue}
					maxRecognition={maxRecognition}
					onChangeRatio={onChangeRatio}
				/>
			</WrapArea>
		);
	},
);
