import * as React from "react";
import { Row, Col } from "react-bootstrap";

import { SingleSelect, SelectOption } from "../select";
import { getErrorMessage } from "../../../lib/error";
import { ErrorObject } from "../../../../../server/types/error";
export type RangeValue = {
	from: number;
	to: number;
};
export type RangeSelectProps = {
	value: RangeValue;
	onChange: (value: RangeValue) => void;
	fromOptions: SelectOption[];
	toOptions: SelectOption[];
	errors?: ErrorObject;
};

export const RangeSelect = React.memo((props: RangeSelectProps) => {
	const { value, onChange, fromOptions, toOptions, errors } = props;
	const onChangeSelect = React.useCallback(
		(name: keyof RangeValue, selected: number) => {
			console.log(selected);
			onChange({ ...value, [name]: selected });
		},
		[onChange, value],
	);
	const filterFromOptions = React.useMemo(() => {
		if (!value.to) {
			return fromOptions;
		}
		return fromOptions.filter((c) => !c.value || c.value <= value.to);
	}, [fromOptions, value.to]);
	const filterToOptions = React.useMemo(() => {
		if (!value.from) {
			return toOptions;
		}
		return toOptions.filter((c) => !c.value || c.value >= value.from);
	}, [toOptions, value.from]);
	return (
		<Row>
			<Col md={5}>
				<SingleSelect
					value={value.from}
					options={filterFromOptions}
					onChange={(selected) => onChangeSelect("from", selected ? Number(selected) : undefined)}
					error={getErrorMessage("from", errors)}
				/>
			</Col>
			<Col md={2} style={{ textAlign: "center" }}>
				〜
			</Col>
			<Col md={5}>
				<SingleSelect
					value={value.to}
					options={filterToOptions}
					onChange={(selected) => onChangeSelect("to", selected ? Number(selected) : undefined)}
					error={getErrorMessage("to", errors)}
				/>
			</Col>
		</Row>
	);
});
