import { AdCopyTargetGroup, adCopyTargetGroupMap } from "../enquete";
import { Option } from "@muscat/types/dist/lib/common";

export const AdCopyOADataLabelMap = {
	num: "S/N#",
	age: "年齢",
	ageGroup: "年代",
	purchaseIntent: "購入意向",
	differentiation: "差別性（他の商品とは違っている）",
	forSelf: "自分向け（自分向けの商品だと思う）",
	informationSearchIntent: "情報検索意向（この商品についてもっと知りたい）",
	/** メインコピーのみ */
	catchCopyImpression: "キャッチコピー印象度",
	catchCopyAttractiveness: "キャッチコピー魅力度",
	impressionBeforePrice: "印象",
	dislikePoints: "気に入らなかった点",
	targetGroups: "ターゲットグループ",
	conceptType: "コピー・訴求",
} as const;

export type AdCopyOADataName = keyof typeof AdCopyOADataLabelMap;

export type AdCopyOaCellValue = number[] | string[] | number | string;

export type AdCopyOAData = {
	[key in AdCopyOADataName]?: AdCopyOaCellValue;
};

export type AdCopyOAColumn = {
	label: string;
	name: AdCopyOADataName;
	options?: Option[];
	type: "select" | "text";
};

/**
 * 以下フロント側で使う
 */
export type AdCopyOATableData = {
	oaColumns: AdCopyOAColumn[];
	oaData: AdCopyOAData[];
};

export type AdCopyOABaseFilter = {
	label: string;
	name: AdCopyOADataName;
};

export type AdCopyOASelectionFilter = AdCopyOABaseFilter & {
	type: "select";
	options: Option[];
};

export type AdCopyOATextFilter = AdCopyOABaseFilter & {
	type: "text";
	like?: boolean;
};

export type Column = AdCopyOASelectionFilter | AdCopyOATextFilter;

export const adCopyTargetGroupOptions = Object.entries(adCopyTargetGroupMap)
	.filter(([value, _label]) => value !== "categoryUser")
	.map(([value, label]) => {
		return { label, value } as { label: string; value: Exclude<AdCopyTargetGroup, "categoryUser"> };
	});
