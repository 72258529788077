import * as React from "react";
import { Card } from "react-bootstrap";

import type { AccountWithoutPassword } from "../../../../../server/models/account";
import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint } from "../../../routes/endpoint";
import { InTrialMessage } from "../../parts/ad-copy/in-trial-message";
import { ButtonLink } from "../../parts/button-link";

type Props = {
	account: AccountWithoutPassword;
};

export const TopPage = ({ account }: Props) => {
	return (
		<Card className="border-0 mx-auto my-5 py-5" style={{ maxWidth: "540px" }}>
			<ButtonLink size="lg" to={clientEndpoint.activity} variant="outline-secondary">
				1.コンセプトテスト
			</ButtonLink>

			<ButtonLink className="mt-4" size="lg" to={adCopyClientEndpoint.activity} variant="outline-secondary">
				2.コピーテスト
			</ButtonLink>

			<InTrialMessage account={account} />
		</Card>
	);
};
