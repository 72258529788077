import * as React from "react";
import { Button, Modal } from "react-bootstrap";

export type ModalButtonProps = {
	buttonText: React.ReactNode;
	title: React.ReactNode;
	children: React.ReactNode;
};
export const ModalButton = React.memo((props: ModalButtonProps) => {
	const { buttonText, title, children } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const onClick = React.useCallback(() => {
		setShow(true);
	}, []);
	const onClose = React.useCallback(() => {
		setShow(false);
	}, []);
	return (
		<>
			<Button size={"sm"} variant={"dark"} onClick={onClick} style={{ margin: "5px" }}>
				{buttonText}
			</Button>
			<Modal show={show} size={"lg"} onHide={onClose}>
				<Modal.Header closeButton>{title}</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				<Modal.Footer>
					<Button onClick={onClose} variant={"outline-secondary"}>
						閉じる
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
