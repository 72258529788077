import * as React from "react";

import type { OutputTHeadData } from "../../../../../../../server/models/ad-copy/output/outputTableData";

type Props = {
	data: OutputTHeadData;
};

export const OutputThead = React.memo((props: Props) => {
	const { data } = props;

	return (
		<thead className={data.className} style={data.style}>
			{data.trs.map((tr, trIndex) => (
				<tr key={trIndex} className={tr.className} style={tr.style}>
					{tr.cells.map(
						(th, thIndex) =>
							th != null && (
								<th key={thIndex} className={th.className} colSpan={th.colSpan} rowSpan={th.rowSpan} style={th.style}>
									{th.value}
								</th>
							),
					)}
				</tr>
			))}
		</thead>
	);
});
