import * as React from "react";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { reducer, initState } from "../../../reducers/job/application";
import { ApplicationPage } from "../../../components/pages/approval";
import { Application } from "../../../../../server/models/application";
import { get, makeError, post, put } from "../../../lib/request";
import {
	GetApplicationResponse,
	CreateApplicationResponse,
	CancelApplicationResponse,
} from "../../../../../server/types/request/application";
import { useParams, useNavigate } from "react-router-dom";
import { endpoint } from "../../../../../server/router/endpoint";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export const ApplicationContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, application, accountOptions, overEstimateAgreement } = state;
	const { jobId, activityId } = useParams<{ jobId: string; activityId: string }>();
	const navigate = useNavigate();
	React.useEffect(() => {
		get<GetApplicationResponse>(`${endpoint.application}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadApplication", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	const onChange = React.useCallback(
		(name: keyof Application, value: Application[keyof Application]) =>
			dispatch({ type: "changeApplication", payload: { name, value } }),
		[],
	);
	const onChangeApprover = React.useCallback(
		(index: number, value: string) => dispatch({ type: "changeApprover", payload: { index, value } }),
		[],
	);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		post<CreateApplicationResponse>(`${endpoint.application}/${jobId}`, application)
			.then((response) => {
				dispatch({ type: "updateApplication", payload: response.data.application });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [application, jobId]);
	const onCancel = React.useCallback(() => {
		put<CancelApplicationResponse>(`${endpoint.application}/${jobId}`, {})
			.then((response) => {
				dispatch({ type: "cancelApplication", payload: response.data.application });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	const onClose = React.useCallback(() => navigate(`/activity/${activityId}/job`), [activityId, navigate]);
	const canCancel = React.useMemo(() => {
		if ((!application && !application._id) || application.cancel) return false;
		return loginAccount._id.toString() === application.accountId.toString();
	}, [loginAccount, application]);
	const onAgreeOverEstimate = React.useCallback(() => dispatch({ type: "changeAgreement", payload: true }), []);
	const onCancelOverEstimate = React.useCallback(() => navigate(-1), [navigate]); // キャンセルの場合はページを戻る。
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"処理"} onClose={onClose} />
			<ApplicationPage
				errors={info.errors}
				onSubmit={onSubmit}
				application={application}
				brandholders={accountOptions}
				onChange={onChange}
				onChangeApprover={onChangeApprover}
				onCancel={canCancel ? onCancel : undefined}
				overEstimateAgreement={overEstimateAgreement}
				onAgreeOverEstimate={onAgreeOverEstimate}
				onCancelOverEstimate={onCancelOverEstimate}
			/>
		</>
	);
};
