import React, { memo } from "react";

import { Info } from "../../../../../server/models/info";

import { ReadOnlyInfoContent } from "./read-only-info-content";

type Props = {
	info: Info;
};

const _ReadOnlyInfo = (props: Props) => {
	const { info } = props;

	return (
		<>
			<div style={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
				<div className="h4" style={{ borderBottom: "1px solid #ddd" }}>
					{info.title}
				</div>
				<div>
					{new Date(info.timestamp).toLocaleString("ja-JP", {
						timeZone: "Asia/Tokyo",
						day: "numeric",
						month: "short",
						year: "numeric",
						hour: "numeric",
						minute: "2-digit",
					})}{" "}
					更新
				</div>
			</div>
			<ReadOnlyInfoContent content={info.content} />
		</>
	);
};

export const ReadOnlyInfo = memo(_ReadOnlyInfo);
