import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { ErrorResponse } from "../../../server/types/request";
import { MessageInfo } from "../types/info";
import { downloadData } from "./data";
import { ErrorObject } from "@muscat/types/dist/models/api/api";

const meta = document.querySelector('meta[name="csrf-token"]');
if (meta) {
	axios.defaults.headers.common = {
		"X-Requested-With": "XMLHttpRequest",
		"X-CSRF-TOKEN": meta.getAttribute("content"),
	};
}

export const makeErrorBlob = async (error: AxiosError<Blob>, expandObjects?: boolean): Promise<MessageInfo> => {
	const fileReader = new FileReader();
	const result = await new Promise<ErrorResponse>((resolve, reject) => {
		fileReader.onerror = () => {
			fileReader.abort();
			reject();
		};

		fileReader.onload = () => {
			resolve(JSON.parse(fileReader.result.toString()));
		};
		fileReader.readAsText(error.response.data);
	});
	if (result.message) {
		const { message, errors } = result;
		if (expandObjects) {
			return {
				message: message + (errors ? "\n" + Object.values(errors).join("\n") : ""),
				isSuccess: false,
			};
		}
		return { message, errors, isSuccess: false };
	}
	return {
		message: "通信エラーが発生しました。ページをリロードしてください",
		isSuccess: false,
	};
};

function extractErrorMessages(errorObject: ErrorObject, errorString: string = ""): string {
	const errorMessages = errorString.split("\n").filter((errorMessage) => errorMessage);

	function accumulateErrors(errorObj: ErrorObject) {
		if (errorObj === undefined) {
			return errorMessages;
		}
		Object.values(errorObj).forEach((errorMessage) => {
			if (typeof errorMessage === "string") {
				// uniqueだったら追加する
				if (!errorMessages.includes(errorMessage)) {
					errorMessages.push(errorMessage);
				}
			} else if (typeof errorMessage === "object" && errorMessage !== null) {
				accumulateErrors(errorMessage);
			}
		});
	}

	accumulateErrors(errorObject);

	return [...new Set(errorMessages)].join("\n");
}

export const makeError = (
	error: AxiosError<ErrorResponse>,
	expandObjects?: boolean,
	expandAnalyticsError?: boolean,
): MessageInfo => {
	if (error && error.response && error.response.data) {
		const { message, errors } = error.response.data;
		if (expandObjects) {
			return {
				message: message + (errors ? "\n" + Object.values(errors).join("\n") : ""),
				isSuccess: false,
			};
		}

		if (expandAnalyticsError) {
			const extractedMessages = extractErrorMessages(errors, message);
			return { message: extractedMessages, errors, isSuccess: false };
		}

		return { message, errors, isSuccess: false };
	}
	return {
		message: "通信エラーが発生しました。ページをリロードしてください",
		isSuccess: false,
	};
};

export const post = async <T>(url: string, data?: any, options?: AxiosRequestConfig) => {
	return await axios.post<T>(url, data, options);
};
export const postFormData = async <T>(url: string, data: FormData, options?: AxiosRequestConfig) => {
	data.append("_csrf", meta.getAttribute("content"));
	return await axios.post<T>(url, data, {
		...(options ? options : {}),
		headers: {
			"content-type": "multipart/form-data",
		},
	});
};

export const makeQueies = (params: { [key: string]: any }) => {
	return Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join("&");
};
export const get = async <T>(url: string, params?: { [key: string]: any }, options?: AxiosRequestConfig) => {
	// IE対応。getだとcacheされるので適当なrandを入れる。
	const queries = "?" + makeQueies({ ...params, c: Math.random() });
	return await axios.get<T>(`${url}${queries}`, options);
};

export const put = async <T>(url: string, data?: any, options?: AxiosRequestConfig) => {
	return await axios.put<T>(url, data, options);
};
export const remove = async <T>(url: string, options?: AxiosRequestConfig) => {
	return await axios.delete<T>(url, options);
};

export const makeDwnloadImage = async (url: string, data: any, filename: string) => {
	const response = await axios.post(url, data, { responseType: "blob" });
	downloadData(new Blob([response.data]), filename);
	/*/
	if (window.navigator.msSaveBlob) {
		window.navigator.msSaveBlob(blob, filename);
		//window.navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
	/*/
};

export const fileDownload = async (url: string, filename: string) => {
	const response = await axios.get(url, { responseType: "blob" });
	downloadData(new Blob([response.data]), filename);
	/*/
	if (window.navigator.msSaveBlob) {
		window.navigator.msSaveBlob(blob, filename);
		//window.navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
	/*/
};

const getFileName = (contentDisposition: string) => {
	const matches = contentDisposition.match(/attachment; filename=(.+\..+)$/);
	if (matches) {
		return matches[1];
	}
	return undefined;
};

export const fileDownloadStream = async (url: string, defaultName?: string): Promise<void> => {
	const response = await axios.get(url, { responseType: "blob" });
	const blob = new Blob([response.data], {
		type: response.data.type,
	});
	const filename = getFileName(response.headers["content-disposition"]) || defaultName;
	downloadData(blob, filename);
};
