import * as React from "react";
import { Button, Card } from "react-bootstrap";

import { Info } from "../../../../../../server/models/info";

import { InfoEditor, InfoEditorFooter, PastInfo } from "../../../parts/info";
import { useNavigate } from "react-router-dom";

export type InfoEditPageProps = {
	title: string;
	content: string;
	pastInfos: Info[];
	onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onContentChange: (content: string) => void;
	onCreateNewInfo: () => void;
	onClear: () => void;
	onCopy: (content: string) => void;
	onReset: () => void;
	onSinglePastInfoDelete: (id: string) => void;
};

export const InfoEditPage = React.memo((props: InfoEditPageProps) => {
	const {
		title,
		content,
		pastInfos,
		onTitleChange,
		onContentChange,
		onCreateNewInfo,
		onClear,
		onCopy,
		onReset,
		onSinglePastInfoDelete,
	} = props;
	const navigate = useNavigate();
	const handleClickBack = React.useCallback(() => navigate(-1), [navigate]);
	return (
		<>
			<div className="clearfix">
				<p className="h3 float-start">■お知らせ設定</p>
				<p className="col-md-auto float-end">
					<Button variant="outline-secondary" onClick={handleClickBack}>
						戻る
					</Button>
				</p>
			</div>
			<Card>
				<Card.Body>
					<InfoEditor content={content} onContentChange={onContentChange} onTitleChange={onTitleChange} title={title} />
				</Card.Body>
				<Card.Footer>
					<InfoEditorFooter onCreateNewInfo={onCreateNewInfo} onClear={onClear} onReset={onReset} />
				</Card.Footer>
			</Card>

			<p className="h3 mt-5 mb-4">■更新履歴</p>

			{pastInfos.length === 0 && <p>過去のお知らせはありません。</p>}
			{pastInfos.map((info) => (
				<PastInfo key={String(info._id)} onDelete={onSinglePastInfoDelete} info={info} onCopy={onCopy} />
			))}
		</>
	);
});
