import React, { memo } from "react";
import ReactQuill from "react-quill";

type Props = {
	content: string;
};

const _ReadOnlyInfoContent = (props: Props) => {
	const { content } = props;

	return (
		<>
			<ReactQuill value={content} theme="bubble" readOnly={true} />
		</>
	);
};

export const ReadOnlyInfoContent = memo(_ReadOnlyInfoContent);
