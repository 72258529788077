import * as React from "react";
import { ConceptEvaluationItem } from "../../../../../../../server/models/graph/concept-evaluation";
import { ConceptEvaluation } from "../../../../parts/graph/concept-evaluation";
import { ConceptType } from "../../../../../../../server/models/concept";
import { ReportTitle } from "../../parts/report-title";
import styled from "styled-components";

const GraphWrap = styled.div`
	margin-bottom: 30px;
`;

export type ConceptEvaluationGraphsProps = {
	filename?: string;
	data: ConceptEvaluationItem[];
};

export const ConceptEvaluationGraphs = React.memo((props: ConceptEvaluationGraphsProps) => {
	const { data, filename } = props;
	return (
		<>
			<ReportTitle>印象に残った点</ReportTitle>
			{data.map((item) => (
				<GraphWrap key={`element-evaluation-${item.target}-${item.conceptType}`}>
					<ConceptEvaluation
						label={ConceptType[item.conceptType]}
						sampleSize={item.sampleSize}
						items={item.items}
						norm={item.norm}
						width={1000}
						filename={filename}
						// No.5 REPサンプルのセンテンス分析結果は削除
						// hideDiff={item.target === "general"}
					/>
				</GraphWrap>
			))}
		</>
	);
});
