import * as React from "react";
import { Col, Row } from "react-bootstrap";

export const AdCopyContains = React.memo((props: { children: React.ReactNode; field: React.ReactNode }) => {
	return (
		<Row className="mt-3">
			<Col className="text-end" md={3}>
				{props.field}
			</Col>

			<Col>{props.children}</Col>
		</Row>
	);
});
