import * as React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { LogoWithDropdown } from "../logo-with-dropdown";
// import { hasViewAdCopyBrands } from "../../../../../server/lib/ad-copy/brand";

export type NavigationBarProps = {
	jobAction?: {
		onJobEditPage: () => void;
		onConceptPage: () => void;
		onPreviewPage: () => void;
	};
	onJobSearchPage?: () => void;
	onJobPage?: () => void;
	onActivityPage: () => void;
	onLogout?: () => void; // アクティビティ一覧への導線がある場合
	onJobDownloadPage?: () => void; // 案件DLページの導線がある場合
	onAggregatePage?: () => void;
	onMenuPage: () => void;
	account: AccountWithoutPassword;
};

export const NavigationBar = (props: NavigationBarProps): JSX.Element => {
	const {
		jobAction,
		onLogout,
		account,
		onActivityPage,
		onJobPage,
		onJobDownloadPage,
		onJobSearchPage,
		onAggregatePage,
		onMenuPage,
	} = props;
	// const viewAdCopy = React.useMemo(() => hasViewAdCopyBrands(account), [account]);
	return (
		<Navbar bg={"dark"} variant="dark" expand="lg" fixed="top" className="px-3">
			{/* {viewAdCopy && ( */}
			<LogoWithDropdown title="Test it コンセプトテスト" />
			{/* )} */}
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav" style={{ fontSize: 10.8 }}>
				<Nav className="me-auto">
					<Nav.Link onClick={onActivityPage}>アクティビティ一覧</Nav.Link>
					{
						// No.15
						onJobSearchPage && <Nav.Link onClick={onJobSearchPage}>ジョブ検索</Nav.Link>
					}
					{onAggregatePage && <Nav.Link onClick={onAggregatePage}>集計一覧</Nav.Link>}
					{onJobPage && <Nav.Link onClick={onJobPage}>ジョブ一覧に戻る</Nav.Link>}
					{jobAction && (
						<NavDropdown title={"ジョブ設定"} id={"job"}>
							<NavDropdown.Item onClick={jobAction.onJobEditPage}>基本設定</NavDropdown.Item>
							<NavDropdown.Item onClick={jobAction.onConceptPage}>コンセプト設定</NavDropdown.Item>
							<NavDropdown.Item onClick={jobAction.onPreviewPage}>プレビュー</NavDropdown.Item>
						</NavDropdown>
					)}
				</Nav>
				{/*
				<Nav.Link href="#home">
					<FontAwesomeIcon icon={faBell} color="lightyellow" className="h5" />
					<Badge pill variant="danger">
						3
					</Badge>
					</Nav.Link>
				*/}
				<Nav>
					{onJobDownloadPage && <Nav.Link onClick={onJobDownloadPage}>ジョブDL</Nav.Link>}

					<Nav.Link onClick={onMenuPage}>
						<i className="bi bi-gear" style={{ fontSize: 12.8 }} />
					</Nav.Link>

					<Navbar.Text style={{ marginLeft: "20px", marginRight: "10px" }}>ログイン名: {account.name}さん</Navbar.Text>
					<Nav.Link onClick={onLogout}>Logout</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
