/* eslint-disable react/jsx-newline */

import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyReportOutlineResBody } from "../../../../../../server/types/request/ad-copy/report/outline";
import { AdCopyConceptPanel } from "../../../../components/pages/ad-copy/report/outline/concept-panel";
import { AdCopyReportBigTitle } from "../../../../components/pages/ad-copy/report/parts/report-title";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/report/outline";

export const AdCopyConceptReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading, originJobOutline, outline } = state;

	const { jobId } = useParams<{
		jobId: string;
	}>();

	React.useEffect(() => {
		get<GetAdCopyReportOutlineResBody>(`${adCopyEndpoint.outline}/${jobId}`)
			.then((response) => {
				dispatch({
					payload: response.data,
					type: "loadData",
				});
			})
			.catch((error) => {
				dispatch({
					payload: makeError(error),
					type: "changeMessageInfo",
				});
			});
	}, [jobId]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} />

			{[outline, originJobOutline].map(
				(outline, i) =>
					outline != null && (
						<React.Fragment key={i}>
							<AdCopyReportBigTitle>
								{i === 0 ? "テストの呈示素材" : "前回の呈示素材"}（{outline.job.jobNum}）
							</AdCopyReportBigTitle>

							<AdCopyConceptPanel concepts={outline.concepts} />
						</React.Fragment>
					),
			)}
		</>
	);
};
