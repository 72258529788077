import * as React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
	buttonText: React.ReactNode;
	children: React.ReactNode;
	title: React.ReactNode;
};

export const AdCopyModalButton = React.memo((props: Props) => {
	const { buttonText, children, title } = props;

	const [show, setShow] = React.useState(false);

	const onClick = React.useCallback(() => {
		setShow(true);
	}, []);

	const onClose = React.useCallback(() => {
		setShow(false);
	}, []);

	return (
		<>
			<Button onClick={onClick} size="sm" style={{ margin: "5px" }} variant="dark">
				{buttonText}
			</Button>

			<Modal onHide={onClose} show={show} size="lg">
				<Modal.Header closeButton>{title}</Modal.Header>

				<Modal.Body>{children}</Modal.Body>

				<Modal.Footer>
					<Button onClick={onClose} variant="outline-secondary">
						閉じる
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
