import { CategoryGroup } from "../models/activity";
import { Category } from "../models/category";

/**
 * -- warning --
 * ノームができたら調整が必要。
 * ノームを持っているかの判定
 * @param {Category} category
 * @param {CategoryGroup} categoryGroup
 * @returns {boolean}
 */
export const hasNorm = (category: Category, categoryGroup: CategoryGroup): boolean => {
	if (!categoryGroup) return true;
	const { price } = categoryGroup;
	if (category.name === "口紅・リップグロス" && price && price.label === "3000～") return false;
	if (category.name === "シャンプー・コンディショナー" && price && price.label === "1500～") return false;
	if (category.name === "シャンプー・コンディショナー" && price && price.label === "1500～") return false;
	if (category.name === "男性　洗顔料") return false;
	return true;
};

/**
 * -- warning --
 * ノームができたら調整が必要。
 * カテゴリターゲットを設定できるかの判定。
 * @param {Category} category
 * @param {CategoryGroup} categoryGroup
 * @returns {boolean}
 */
export const canSettingCategoryTarget = (category: Category, categoryGroup: CategoryGroup): boolean => {
	if (!categoryGroup) return true;
	const { price } = categoryGroup;
	if (category.name === "シートマスク" && price && price.label === "3000～") return false;
	if (category.name === "パック・マスク（シートマスク除く）" && price && price.label === "4000～") return false;
	if (category.name === "BBクリーム" && price && price.label === "4000～") return false;
	if (category.name === "マスカラ" && price && price.label === "3000～") return false;
	if (category.name === "アイシャドー" && price && price.label === "3500～") return false;
	if (category.name === "アイライナー" && price && price.label === "4000～") return false;
	if (category.name === "アイブロー（眉）" && price && price.label === "2000～") return false;
	return true;
};
