import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopySkinAttributeExcludeOtherOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopySkinAttributeCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	return (
		<Row>
			<FormGroup as={Col} md={6}>
				<AccordionForm
					defaultShow={strategyGroup.skinAttribute != null && 0 < strategyGroup.skinAttribute.length}
					eventKey="skinAttribute"
					title={adCopyStrategyTargetGroupLabels.skinAttribute ?? ""}
				>
					<Checkboxes
						checkeds={strategyGroup.skinAttribute ?? []}
						choices={adCopySkinAttributeExcludeOtherOptions as Choice[]}
						name="skinAttribute"
						onChange={(values) => {
							onChange("skinAttribute", values);
						}}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
