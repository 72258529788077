/**
 * コンセプト調査とコピー調査の共通モデル
 */
export enum Gender {
	male = "男性",
	female = "女性",
}

export interface Age {
	from: number;
	to: number;
}
export interface Price {
	from?: number;
	to?: number;
	label?: string;
}

export type SurveyChoice = {
	value: number;
	label: string;
};
