import * as React from "react";

import type { OutputTBodyData } from "../../../../../../../server/models/ad-copy/output/outputTableData";

type Props = {
	data: OutputTBodyData;
};

export const OutputTbody = React.memo((props: Props) => {
	const { data } = props;

	return (
		<tbody className={data.className} style={data.style}>
			{data.trs.map((tr, trIndex) => (
				<tr key={trIndex} className={tr.className} style={tr.style}>
					{tr.cells.map(
						(cell, cellIndex) =>
							cell != null &&
							(cell.isTh ? (
								<th
									key={cellIndex}
									className={cell.className}
									colSpan={cell.colSpan}
									rowSpan={cell.rowSpan}
									style={cell.style}
								>
									{cell.value}
								</th>
							) : (
								<td
									key={cellIndex}
									className={cell.className}
									colSpan={cell.colSpan}
									rowSpan={cell.rowSpan}
									style={cell.style}
								>
									{cell.value}
								</td>
							)),
					)}
				</tr>
			))}
		</tbody>
	);
});
