/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Alert, Badge, Col, Form, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import { AdCopyRole } from "../../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../../server/models/ad-copy/activity";
import type { AdCopyApplication } from "../../../../../../../server/models/ad-copy/application";
import type { AdCopyConcept } from "../../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import { AdCopyApproveStatus } from "../../../../../../../server/models/ad-copy/status";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../lib/error";
import { AdCopyJobDetail } from "../../../../parts/ad-copy/job-detail";
import { AdCopyContains } from "../../../../parts/ad-copy/job-detail/contains";
import { ConfirmButton } from "../../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../../parts/font";

type Props = {
	accounts: AccountWithoutPassword[];
	activity: AdCopyActivityWithBrand | undefined;
	application: AdCopyApplication;
	concepts: AdCopyConcept[];
	errors?: ErrorObject;
	estimate: string | undefined;
	evaluationItem?: string[];
	job: AdCopyJob | undefined;
	loginAccount: AccountWithoutPassword;
	onApprove: () => void;
	onReject: (rejectMessage: string) => void;
};

export const AdCopyApprovePage = React.memo((props: Props) => {
	const {
		accounts,
		activity,
		application,
		concepts,
		errors,
		estimate,
		evaluationItem,
		job,
		loginAccount,
		onApprove,
		onReject,
	} = props;

	// 却下理由のみのなのでこのコンポーネント内のstateで管理。
	const [rejectMessage, setRejectMessage] = React.useState("");

	const mapAccount = React.useMemo(() => {
		return accounts.reduce((a, b) => {
			a.set(b._id?.toString(), b.name);

			return a;
		}, new Map<string, string>());
	}, [accounts]);

	const approveStatus = React.useMemo(() => {
		for (const ap of application.approve) {
			if (!ap.status) {
				return "申請中";
			}

			if (ap.status === "reject") {
				return AdCopyApproveStatus[ap.status];
			}
		}

		return AdCopyApproveStatus.approve;
	}, [application]);

	const canSubmit = React.useMemo(() => {
		if (approveStatus !== "申請中") {
			return false;
		}

		const target = application.approve.find((ap) => !ap.status);

		return AdCopyRole.isApprover(loginAccount, target?.approver);
	}, [loginAccount, approveStatus, application]);

	const isLastApprove = React.useMemo(() => {
		if (application.approve.length === 1) {
			return true;
		}
		const aps = application.approve.concat();

		aps.pop();

		return aps.every((ap) => ap.status === "approve");
	}, [application]);

	const agreementMessage = React.useMemo(() => {
		const messgage = isLastApprove
			? "承認すると、予定開始時間に調査が開始されます。よろしいですか。"
			: "承認します。よろしいですか。";

		if (estimate != null && estimate.split("\n").length >= 3) {
			return (
				<>
					{messgage}

					<br />

					<br />

					<TextRed>
						調査実施費用が100万円を超える可能性があります。
						<br />
						稟議書が承認されていることをご確認ください。
					</TextRed>
				</>
			);
		}

		return messgage;
	}, [estimate, isLastApprove]);

	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■承認画面</div>
				</Col>
			</Row>

			<Row>
				<Col className="mb-3" md={12}>
					{activity && job && (
						<AdCopyJobDetail
							activity={activity}
							concepts={concepts}
							estimate={estimate}
							evaluationItem={evaluationItem}
							job={job}
						/>
					)}

					<Row>
						<Col md={12}>
							<div className="h4">■申請・承認情報</div>

							<AdCopyContains field="申請メッセージ：">{application.applicationMessage}</AdCopyContains>

							<AdCopyContains field="申請者（申請日時）：">
								{mapAccount.get(application.accountId.toString())}（{application.applicationDate}）
							</AdCopyContains>

							{application.approve.map((ap, index) => {
								const num = index + 1;

								return (
									<AdCopyContains key={`approve-${index}`} field={`Approver ${num}（${num}次承認者）`}>
										<>
											{mapAccount.get(ap.approver.toString())}

											{ap.status && <Badge bg="primary">{AdCopyApproveStatus[ap.status]}</Badge>}

											{ap.approver.toString() === loginAccount._id?.toString() && <Badge bg="danger">あなた</Badge>}
										</>
									</AdCopyContains>
								);
							})}

							{canSubmit && (
								<AdCopyContains field="却下理由（却下の場合必須）：">
									<Form.Control
										as="textarea"
										isInvalid={!!errors && !!errors["rejectMessage"]}
										onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRejectMessage(e.target.value)}
										rows={5}
										value={rejectMessage}
									/>

									{!!errors && !!errors["rejectMessage"] && (
										<Feedback type="invalid">{getErrorMessage("rejectMessage", errors)}</Feedback>
									)}
								</AdCopyContains>
							)}

							{!!application.rejectMessage && (
								<AdCopyContains field="却下理由：">{application.rejectMessage}</AdCopyContains>
							)}
						</Col>
					</Row>

					{canSubmit ? (
						<Row className="mt-5">
							<Col className="text-center">
								<ConfirmButton
									body={agreementMessage}
									buttonText="承認する"
									onOk={onApprove}
									style={{ marginRight: "5px" }}
									title="承認"
									variant="outline-secondary"
								/>

								<ConfirmButton
									body="却下します。よろしいですか。"
									buttonText="却下する"
									onOk={() => onReject(rejectMessage)}
									title="申請却下"
									variant="outline-secondary"
								/>
							</Col>
						</Row>
					) : (
						<Col className="text-center" md={{ offset: 2, span: 8 }}>
							<Alert
								style={{ marginTop: "10px" }}
								variant={
									approveStatus === "申請中" || approveStatus === AdCopyApproveStatus.approve ? "secondary" : "warning"
								}
							>
								{approveStatus}
							</Alert>
						</Col>
					)}
				</Col>
			</Row>
		</div>
	);
});
