import * as React from "react";
import { ToggleButtonGroup, ToggleButton, ButtonToolbar } from "react-bootstrap";

export type ButtonSwitchProps = {
	name: string;
	checked: boolean;
	enableText?: string;
	disableText?: string;
	onChange: (checked: boolean) => void;
};
export const ButtonSwitch = React.memo((props: ButtonSwitchProps) => {
	const { name, checked, enableText, disableText, onChange } = props;
	const id = React.useId();

	return (
		<ButtonToolbar id={id}>
			<ToggleButtonGroup
				name={name}
				type="radio"
				value={checked.toString()}
				onChange={(checked: string) => onChange(checked.toLowerCase() === "true")}
			>
				<ToggleButton id={`${id}-enable`} variant={"outline-dark"} value={true.toString()}>
					{enableText || "あり"}
				</ToggleButton>
				<ToggleButton id={`${id}-disable`} variant={"outline-dark"} value={false.toString()}>
					{disableText || "なし"}
				</ToggleButton>
			</ToggleButtonGroup>
		</ButtonToolbar>
	);
});
