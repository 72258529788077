import * as React from "react";
import { Accordion, Card } from "react-bootstrap";

import { Info } from "../../../../../server/models/info";

import { ReadOnlyInfo } from "./read-only-info";
import { AccordionIcon } from "./accordion-icon";
import { InfoAccordionToggle } from "./info-accordion-toggle";

export type InfoPartProps = {
	message: Info;
	showValue?: boolean;
};

// constants
const eventKey = "info-accordion";

export const InfoPart = React.memo((props: InfoPartProps) => {
	const { message, showValue } = props;

	// states
	const [show, setShow] = React.useState(showValue ?? false);

	// handlers
	const announcementClick = React.useCallback(() => {
		setShow(!show);
	}, [show]);

	return (
		<>
			<Card>
				<Accordion defaultActiveKey={eventKey}>
					<InfoAccordionToggle eventKey={eventKey} onClick={announcementClick}>
						<AccordionIcon isShow={show} className="me-3" />
						<span className="h4">お知らせ</span>
					</InfoAccordionToggle>
					<Accordion.Collapse eventKey={eventKey}>
						<Card.Body>
							<ReadOnlyInfo info={message} />
						</Card.Body>
					</Accordion.Collapse>
				</Accordion>
			</Card>
		</>
	);
});
