import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { Confirm } from "../../../../../react-components/lu-component/src/index";

export type ConfirmButtonProps = {
	title: string;
	body: string | JSX.Element | JSX.Element[];
	onCancel?: () => void;
	onOk: () => void;
};

export const ConfirmDropdownItem = React.memo((props: ConfirmButtonProps) => {
	const { title, body, onCancel, onOk } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const onClose = React.useCallback(() => {
		setShow(false);
		if (onCancel) onCancel();
	}, [onCancel]);
	const onOkClick = React.useCallback(() => {
		setShow(false);
		onOk();
	}, [onOk]);
	const onOpen = React.useCallback(() => setShow(true), []);
	return (
		<>
			<Dropdown.Item onClick={onOpen}>{title}</Dropdown.Item>
			<Confirm
				show={show}
				onCancel={onClose}
				onOk={onOkClick}
				title={title}
				body={body}
				buttonText={"はい"}
				variant={"secondary"}
			/>
		</>
	);
});
