import * as React from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { EditableArea, EditableAreaFunc } from "../edit-area";
import { WrapArea } from "../wrap-area";

export type EditableText = {
	isHalfwith?: boolean;
	isNumber?: boolean;
	label?: string;
	value: string;
	placeholder?: string;
	hasRow?: boolean;
	onChange: (value: string) => void;
	isFocus: boolean;
	validate?: (value: string) => string;
};

export const EditableText = React.memo((props: EditableText) => {
	const { onChange, hasRow, placeholder, label, isNumber, isFocus, validate, isHalfwith } = props;
	const [value, setValue] = React.useState<string>(props.value);
	React.useEffect(() => setValue(props.value), [props.value]);
	const onTextChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			let v = e.target.value;
			if (isHalfwith) {
				v = v.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
					return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
				});
			}
			if (isNumber && isNaN(Number(v))) {
				return alert("半角数値で入力してください。");
			}
			setValue(v);
		},
		[isHalfwith, isNumber],
	);
	const ref = React.createRef<EditableAreaFunc>();
	const onSave = React.useCallback(() => {
		if (validate) {
			const message = validate(value);
			if (message) {
				alert(message);
				setValue(props.value);
				return;
			}
		}
		onChange(value);
		ref.current.close();
	}, [value, onChange, ref, validate, props.value]);
	const onClose = React.useCallback(() => setValue(props.value), [props.value]);
	const formProps = React.useMemo(() => {
		if (!hasRow) {
			return {
				onKeyDown: (e: React.KeyboardEvent) => e.keyCode === 13 && onSave(),
			};
		}
		const matches = value ? value.match(/\n/g) : undefined;
		return {
			as: "textarea" as React.ElementType,
			rows: matches && matches.length > 2 ? matches.length : 2,
		};
	}, [hasRow, value, onSave]);
	const editChildren = React.useMemo(
		() => (
			<>
				{label && <FormLabel> {label}</FormLabel>}
				<FormControl
					placeholder={placeholder || ""}
					onChange={onTextChange}
					value={value}
					onBlur={onSave}
					{...formProps}
				/>
			</>
		),
		[label, placeholder, onTextChange, value, onSave, formProps],
	);
	const viewChildren = React.useMemo(
		() => (
			<WrapArea style={{ wordWrap: "break-word", color: value ? "inherit" : "#ddd" }}>
				{label ? `${label}：` : ""}
				{value || placeholder || "　"}
			</WrapArea>
		),
		[value, placeholder, label],
	);
	return (
		<FormGroup>
			<EditableArea
				ref={ref}
				isFocus={isFocus}
				onClose={onClose}
				editChildren={editChildren}
				viewChildren={viewChildren}
			/>
		</FormGroup>
	);
});
