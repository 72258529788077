import { TabSetting, TabViewer } from "../../../../../../react-components/lu-component/src/index";
import * as React from "react";
import { ConceptKey } from "../../../../../../server/models/concept";
import { GetSimulationResponse } from "../../../../../../server/types/request/report/simulation";
import styled from "styled-components";
import { CorrectionValue } from "../../../../../../server/models/simulation-value";
import { SimulationOne } from "./simulation-one";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { ReportBigTitle } from "../parts/report-title";
import { CPPage, CPPageProps } from "./cp";
import { CP } from "../../../../../../server/models/cp";
import { Role } from "../../../../../../server/lib/permission/role";
import { clientEndpoint } from "../../../../routes/endpoint";
import { getAnalyticsVersion } from "../../../../lib/action-standard";
import { ErrorObject } from "../../../../../../server/types/error";
import { OverlayTrigger, Popover } from "react-bootstrap";

const AreaWrap = styled.div`
	width: 1500px;
	margin: 0px auto;
`;
const AlertRed = styled.span`
	display: inline-block;
	color: red;
	font-size: 12px;
`;

const ExplainMovieArea = styled.span`
	display: inline-block;
	margin-left: 1rem;
	color: red;
	a {
		padding: 0 0.3rem;
		color: red;
		font-weight: bold;
		text-decoration: underline;
	}
	a:hover {
		color: #c5061f;
	}
`;

export type SimulationPageProps = GetSimulationResponse & {
	errors?: ErrorObject;
	loginAccount: AccountWithoutPassword;
	onChangeRatio: <K extends keyof CorrectionValue>(target: ConceptKey, name: K, value: CorrectionValue[K]) => void;
	// simulation
	onSimulation: (conceptType: "test1" | "test2") => void;
	onSimulationSave: (conceptType: "test1" | "test2") => void;
	// cp
	onChangeCP: CPPageProps["onChange"];
	onSubmitCP: CPPageProps["onSubmit"];
	onSubmitCPCorrectionValues: CPPageProps["onSubmitCPCorrectionValues"];

	confirmMessage: string | undefined;
	setChanged: (changed: boolean) => void;
	onClearCP: () => void;
	refetch: () => void;
};

export const SimulationPage = React.memo((props: SimulationPageProps) => {
	const {
		loginAccount,
		data,
		onChangeRatio,
		goalSalesVolume,
		constValue,
		job,
		activity,
		oneNumberScoreNorm,
		oneNumberScoreData,
		onSimulation,
		onSimulationSave,
		evaluations,
		maxRecognition,
		cpMaxRecognition,
		cp,
		categories,
		brands,
		onChangeCP,
		onSubmitCP,
		onClearCP,
		hasCurrentProduct,
		onSubmitCPCorrectionValues,
		errors,
		setChanged,
		confirmMessage,
		refetch,
	} = props;
	const { test1, test2 } = data;
	const hasEditRole = React.useMemo(
		() =>
			// 管理者 or ブランドメンバー or ブランドマネージャーは対象
			Role.isAdmin(loginAccount) ||
			Role.isActivityMember(loginAccount, activity) ||
			Role.isActivityBrandManager(loginAccount, activity),
		[activity, loginAccount],
	);
	const canChangeCP = React.useMemo(() => {
		if (job == null) return false;
		const version = getAnalyticsVersion(job.endDatetime);
		if (version === "v1" || version === "v2" || version === "v3") return false;
		return true;
	}, [job]);
	const tabs = React.useMemo<TabSetting[]>(() => {
		const tmp: TabSetting[] = [];
		// 現行品がある場合のみ設定可能

		if (test1) {
			tmp.push({
				key: "test1",
				title: "テスト品1",
				children: (
					<SimulationOne
						hasEditRole={hasEditRole}
						evaluations={evaluations}
						target={"test1"}
						job={job}
						activity={activity}
						data={test1}
						goalSalesVolume={goalSalesVolume}
						constValue={constValue}
						oneNumberScoreNorm={oneNumberScoreNorm}
						oneNumberScoreData={oneNumberScoreData}
						onSimulation={() => onSimulation("test1")}
						onSimulationSave={() => onSimulationSave("test1")}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => onChangeRatio("test1", name, value)}
						cp={cp as CP}
					/>
				),
			});
		}
		if (test2) {
			tmp.push({
				key: "test2",
				title: "テスト品2",
				children: (
					<SimulationOne
						hasEditRole={hasEditRole}
						evaluations={evaluations}
						target={"test2"}
						job={job}
						activity={activity}
						data={test2}
						goalSalesVolume={goalSalesVolume}
						constValue={constValue}
						oneNumberScoreNorm={oneNumberScoreNorm}
						oneNumberScoreData={oneNumberScoreData}
						onSimulation={() => onSimulation("test2")}
						onSimulationSave={() => onSimulationSave("test2")}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => onChangeRatio("test2", name, value)}
						cp={cp as CP}
					/>
				),
			});
		}
		if (hasCurrentProduct && canChangeCP) {
			tmp.push({
				key: "cp",
				title: "CP実績補正設定",
				children: (
					<CPPage
						hasEditRole={hasEditRole}
						maxRecognition={cpMaxRecognition}
						activity={activity}
						cp={cp ?? {}}
						categories={categories}
						brands={brands}
						onChange={onChangeCP}
						onSubmit={onSubmitCP}
						onClear={onClearCP}
						onSubmitCPCorrectionValues={onSubmitCPCorrectionValues}
						constValue={constValue}
						errors={errors}
					/>
				),
			});
		}
		return tmp;
	}, [
		test1,
		test2,
		hasCurrentProduct,
		canChangeCP,
		hasEditRole,
		evaluations,
		job,
		activity,
		goalSalesVolume,
		constValue,
		oneNumberScoreNorm,
		oneNumberScoreData,
		maxRecognition,
		cp,
		onSimulation,
		onSimulationSave,
		onChangeRatio,
		cpMaxRecognition,
		categories,
		brands,
		onChangeCP,
		onSubmitCP,
		onClearCP,
		onSubmitCPCorrectionValues,
		errors,
	]);
	return (
		<AreaWrap>
			<ReportBigTitle>
				補整＆提案フォーマット<AlertRed>（補整値は、アクティビテイメンバーと承認者のみ入力可能です）</AlertRed>
				{job ? `（${job.jobNum}）` : ""}
				{canChangeCP && (
					<ExplainMovieArea>
						需要予測の補整説明動画リンクは
						<OverlayTrigger
							placement={"right"}
							overlay={
								<Popover id={`tooltip-explain`} style={{ maxWidth: "400px" }}>
									<Popover.Body>
										動画を視聴する際のパスワードは「{process.env.SIMULATION_MOVIE_PASSWORD ?? ""}」です
									</Popover.Body>
								</Popover>
							}
						>
							<a href={clientEndpoint.simulationExplain} rel="noopener noreferror noreferrer" target="_blank">
								こちら
							</a>
						</OverlayTrigger>
						をクリック
					</ExplainMovieArea>
				)}
			</ReportBigTitle>

			{tabs.length > 0 && (
				<TabViewer
					id={"resultByTarget"}
					tabs={tabs}
					confirm={{
						title: "保存確認",
						body: confirmMessage,
					}}
					changed={!!confirmMessage}
					onChangeTab={() => {
						setChanged(false);
						refetch();
					}}
				/>
			)}
		</AreaWrap>
	);
});
