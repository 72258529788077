import * as React from "react";
import { FormGroup, FormLabel, Col, Row, Button } from "react-bootstrap";

export type FileInputProps = {
	label?: string;
	onUpload: (value: File) => void;
};
export const FileInput = React.memo((props: FileInputProps) => {
	const { label, onUpload } = props;
	const inputRef = React.createRef<HTMLInputElement>();
	const [file, setFile] = React.useState<File>(undefined);
	const onInputChange = React.useCallback(() => {
		if (inputRef.current && inputRef.current.files) {
			const { files } = inputRef.current;
			const file = files.item(0);
			setFile(file);
		} else {
			setFile(undefined);
		}
	}, [inputRef]);
	const onClick = React.useCallback(() => {
		onUpload(file);
		setFile(undefined);
		inputRef.current.value = null;
	}, [onUpload, file, inputRef]);
	return (
		<FormGroup as={Row}>
			{label && (
				<Col xs={12}>
					<FormLabel>{label}</FormLabel>
				</Col>
			)}
			<Col xs={10}>
				<div>
					<input
						type="file"
						className="form-control"
						aria-describedby="inputGroupFileAddon01"
						ref={inputRef}
						onChange={onInputChange}
						id="inputGroupFile01"
					/>
				</div>
			</Col>
			<Col xs={1}>
				<Button variant={"outline-secondary"} onClick={onClick} disabled={file === undefined}>
					Upload
				</Button>
			</Col>
		</FormGroup>
	);
});
