import * as React from "react";
import { Badge, Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { ErrorObject } from "../../../../../../../../react-components/lu-component/src/index";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { SubTitle } from "../../../../../parts/accodion-form";
import type { AdCopyRangeValue } from "../../../../../parts/ad-copy/range";
import { AdCopyRangeSelect } from "../../../../../parts/ad-copy/range";

const key = "age";

type Key = typeof key;

type Value = TrueAdCopyStrategyGroup[Key];

type Props = {
	errors: ErrorObject | undefined;
	onChange: (name: Key, value: Value) => void;
	value: Value;
};

export const AdCopyAgeSelect = React.memo((props: Props) => {
	const { errors, onChange, value } = props;

	return (
		<Row>
			<FormGroup as={Col} md={5} style={{ marginBottom: "20px" }}>
				<SubTitle>
					{adCopyStrategyTargetGroupLabels[key]}

					<Badge bg="danger" pill style={{ marginLeft: "10px" }}>
						必須
					</Badge>
				</SubTitle>

				<div style={{ marginLeft: "15px" }}>
					<AdCopyRangeSelect
						errors={errors && key in errors && typeof errors[key] === "object" ? errors[key] : undefined}
						fromOptions={[
							{ label: "15歳", value: 15 },
							{ label: "20歳", value: 20 },
							{ label: "25歳", value: 25 },
							{ label: "30歳", value: 30 },
							{ label: "35歳", value: 35 },
							{ label: "40歳", value: 40 },
							{ label: "45歳", value: 45 },
							{ label: "50歳", value: 50 },
							{ label: "55歳", value: 55 },
							{ label: "60歳", value: 60 },
							{ label: "65歳", value: 65 },
						]}
						onChange={(value) => {
							onChange(key, value);
						}}
						toOptions={[
							{ label: "19歳", value: 19 },
							{ label: "24歳", value: 24 },
							{ label: "29歳", value: 29 },
							{ label: "34歳", value: 34 },
							{ label: "39歳", value: 39 },
							{ label: "44歳", value: 44 },
							{ label: "49歳", value: 49 },
							{ label: "54歳", value: 54 },
							{ label: "59歳", value: 59 },
							{ label: "64歳", value: 64 },
							{ label: "69歳", value: 69 },
						]}
						value={value as AdCopyRangeValue}
					/>
				</div>
			</FormGroup>
		</Row>
	);
});
