import * as React from "react";

export type ErrorPageProps = {
	text: string;
};
export const ErrorPage = React.memo((props: ErrorPageProps) => {
	return (
		<div className={"m-3"}>
			<h1>{props.text}</h1>
		</div>
	);
});
