export enum JobStatus {
	progress = "進行中",
	complete = "調査完了",
	stopping = "休止中",
	deleted = "削除済",
}

export const JobStatusOptions = Object.entries(JobStatus)
	.filter(([value, label]) => {
		if (label === JobStatus.deleted) return false;
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum SurveyStatus {
	deleted = "削除済",
	conceptSetting = "コンセプト未完成",
	beforeApplication = "申請前",
	stopping = "調査中止",
	applying = "申請中",
	applicationReject = "申請却下",
	waitingToStart = "調査開始待ち",
	progress = "回収中",
	complete = "調査完了",
}

export const surveyStatusOptions = Object.entries(SurveyStatus)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum ActivityStatus {
	progress = "非公開",
	complete = "公開",
	deleted = "削除済",
}

export const ActivityStatusOptions = Object.entries(ActivityStatus)
	.filter(([value, label]) => {
		if (label === ActivityStatus.deleted) return false;
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum ApproveStatus {
	approve = "承認",
	reject = "却下",
}
