import * as React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Confirm } from "../confirm";

export type TabSetting = {
	key: string;
	title: string;
	children: JSX.Element | JSX.Element[];
};
export type TabViewerProps = {
	id: string; // 複数タブが存在する場合はID指定が必要。
	tabs: TabSetting[];
	confirm?: { title: string; body: string };
	changed?: boolean;
	onChangeTab?: () => void;
};

export const TabViewer = React.memo((props: TabViewerProps) => {
	const { id, tabs, confirm, changed, onChangeTab } = props;
	const [targetKey, setTargetKey] = React.useState<string>(tabs.length ? tabs[0].key : "");
	const [temporaryTabChange, setTemporaryTabChange] = React.useState<string | undefined>();
	const onSelect = React.useCallback(
		(k: string) => {
			if (confirm && changed) return setTemporaryTabChange(k);
			setTargetKey(k);
		},
		[confirm, changed],
	);
	const onCancel = React.useCallback(() => setTemporaryTabChange(undefined), []);
	const onOk = React.useCallback(() => {
		setTargetKey(temporaryTabChange);
		setTemporaryTabChange(undefined);
		if (onChangeTab) onChangeTab();
	}, [onChangeTab, temporaryTabChange]);
	return (
		<>
			{!!confirm && (
				<Confirm
					show={!!temporaryTabChange}
					onCancel={onCancel}
					onOk={onOk}
					title={confirm.title}
					body={confirm.body}
					buttonText={"はい"}
					cancelText={"いいえ"}
					variant={"secondary"}
				/>
			)}
			<Tabs id={id} activeKey={targetKey} onSelect={onSelect}>
				{tabs.map((tab) => (
					<Tab key={`tab-${tab.key}`} eventKey={tab.key} title={tab.title}>
						{targetKey === tab.key && tab.children}
					</Tab>
				))}
			</Tabs>
		</>
	);
});
