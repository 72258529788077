import * as React from "react";
import { SimilarProductInformation } from "../../../../../../../../server/models/cp";
import { Category } from "../../../../../../../../server/models/category";
import { Brand } from "../../../../../../../../server/models/brand";
import { SelectRow, TextRow } from "../../../../../parts/input-row";

import { similarProductParameterLabels } from "../../../../../../../../label/cp";
import { castString } from "../../../../../../lib/cast";
import { AlertText } from "../../../../../parts/font";
import { getMaxRecognition, getRecognitionByCategoryAndBrand } from "../../../../../../../../server/lib/common";
import { BrandRecognitionTable } from "../../../../../parts/recognition-table/brand";
import { CategoryRecognitionTable } from "../../../../../parts/recognition-table/category";
import { ObjectId } from "mongodb";
import { ErrorObject } from "../../../../../../../../server/types/error";

export type CPPageProps = {
	similarProductInfo: Partial<SimilarProductInformation>;
	errors?: ErrorObject;
	categories: Category[];
	brands: Brand[];
	disabled?: boolean;
	onChange: (value: Partial<SimilarProductInformation>) => void;
};

const findById = <T extends { _id?: ObjectId | string }>(
	id: ObjectId | string | undefined,
	values: T[],
): T | undefined => (id ? values.find((v) => v._id && v._id.toString() === id) : undefined);
const makeOptions = <T extends { _id?: ObjectId | string; name: string }>(values: T[]) =>
	values.map(({ _id, name }) => ({ value: _id.toString(), label: name }));

export const SimilarProductInfoSetting = React.memo<CPPageProps>(
	({ similarProductInfo, categories, brands, onChange, errors, disabled }) => {
		console.log(errors);
		const categoryOptions = React.useMemo(() => makeOptions(categories), [categories]);
		const brandOptions = React.useMemo(() => makeOptions(brands), [brands]);
		const brand = React.useMemo(
			() => findById(similarProductInfo.brandId, brands),
			[brands, similarProductInfo.brandId],
		);
		const category = React.useMemo(() => similarProductInfo.category, [similarProductInfo.category]);
		const handleChangeCategory = React.useCallback(
			(value: string) => {
				const category = categories.find((category) => category._id.toString() === value);
				const ret = { ...similarProductInfo, category };
				if (ret.brandId && ret.category) {
					const recognition = getRecognitionByCategoryAndBrand(category, brand);
					if (recognition) ret.recognition = recognition;
				}
				onChange(ret);
			},
			[categories, onChange, brand, similarProductInfo],
		);
		const handleChangeBrand = React.useCallback(
			(value: string) => {
				const brand = brands.find((brand) => brand._id.toString() === value);
				const ret = { ...similarProductInfo, brandId: brand._id };
				if (ret.brandId && ret.category) {
					const recognition = getRecognitionByCategoryAndBrand(category, brand);
					if (recognition) ret.recognition = recognition;
				}
				onChange(ret);
			},
			[brands, category, onChange, similarProductInfo],
		);
		const handleChangeRecognition = React.useCallback(
			(value: string) => {
				const v = Number(value);
				if (isNaN(v)) return;
				onChange({ ...similarProductInfo, recognition: v });
			},
			[onChange, similarProductInfo],
		);
		const maxRate = React.useMemo(() => {
			return getMaxRecognition(similarProductInfo, categories, brands);
		}, [similarProductInfo, categories, brands]);
		return (
			<>
				<SelectRow
					id="brandId"
					name={"brandId"}
					rowName={similarProductParameterLabels.brandId}
					options={brandOptions}
					value={castString(similarProductInfo.brandId)}
					onChange={handleChangeBrand}
					errors={errors}
					disabled={disabled}
				/>
				<SelectRow
					id="categoryId"
					name={"categoryId"}
					rowName={similarProductParameterLabels.category}
					options={categoryOptions}
					value={castString(similarProductInfo.category ? similarProductInfo.category._id : undefined)}
					onChange={handleChangeCategory}
					errors={errors}
					disabled={disabled}
				/>
				<TextRow
					id="recognition"
					type="number"
					name="recognition"
					rowName={
						<div>
							日本人（当該性の）15～69歳における
							<br />
							商品認知率(%)<AlertText>（{maxRate}％以下の整数で入力）</AlertText>
						</div>
					}
					value={similarProductInfo.recognition ? similarProductInfo.recognition.toString() : ""}
					onChange={handleChangeRecognition}
					errors={errors}
					subText={
						<>
							{brand && <BrandRecognitionTable brand={brand} />}
							{category && <CategoryRecognitionTable category={category} />}
						</>
					}
					disabled={disabled}
				/>
			</>
		);
	},
);
