import * as React from "react";
import { Col, Row } from "react-bootstrap";

import type { ErrorObject } from "../../../../../../server/types/error";
import { getErrorMessage } from "../../../../lib/error";
import type { AdCopySelectOption } from "../select";
import { AdCopySingleSelect } from "../select/single-select";

export type AdCopyRangeValue = {
	from: number;
	to: number;
};

type Props = {
	errors?: ErrorObject;
	fromOptions: AdCopySelectOption[];
	onChange: (value: AdCopyRangeValue) => void;
	toOptions: AdCopySelectOption[];
	value: AdCopyRangeValue;
};

export const AdCopyRangeSelect = React.memo((props: Props) => {
	const { errors, fromOptions, onChange, toOptions, value } = props;

	const onChangeSelect = React.useCallback(
		(name: keyof AdCopyRangeValue, selected: number) => {
			console.log(selected);
			onChange({ ...value, [name]: selected });
		},
		[onChange, value],
	);

	const filterFromOptions = React.useMemo(() => {
		if (!value.to) {
			return fromOptions;
		}

		return fromOptions.filter((c) => !c.value || c.value <= value.to);
	}, [fromOptions, value.to]);

	const filterToOptions = React.useMemo(() => {
		if (!value.from) {
			return toOptions;
		}

		return toOptions.filter((c) => !c.value || c.value >= value.from);
	}, [toOptions, value.from]);

	return (
		<Row>
			<Col md={5}>
				<AdCopySingleSelect
					error={getErrorMessage("from", errors)}
					onChange={(selected) => onChangeSelect("from", selected ? Number(selected) : undefined)}
					options={filterFromOptions}
					value={value.from}
				/>
			</Col>

			<Col md={2} style={{ textAlign: "center" }}>
				〜
			</Col>

			<Col md={5}>
				<AdCopySingleSelect
					error={getErrorMessage("to", errors)}
					onChange={(selected) => onChangeSelect("to", selected ? Number(selected) : undefined)}
					options={filterToOptions}
					value={value.to}
				/>
			</Col>
		</Row>
	);
});
