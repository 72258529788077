import * as React from "react";
import { SuccessPasswordResetPage } from "../../components/pages/success-password-reset";
import { useNavigate } from "react-router-dom";
import { clientEndpoint } from "../../routes/endpoint";

export const SuccessPasswordResetContainer = () => {
	const navigate = useNavigate();
	const changeLocation = React.useCallback(() => {
		navigate(clientEndpoint.login);
	}, [navigate]);
	return <SuccessPasswordResetPage changeLocation={changeLocation} />;
};
