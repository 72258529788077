import React from "react";
import { Button, useAccordionButton } from "react-bootstrap";

export type AccordionFormToggleProps = {
	eventKey: string;
	children: React.ReactElement | React.ReactElement[];
	onClick?: () => void;
};
export const AccordionFormToggle = React.memo((props: AccordionFormToggleProps) => {
	const { eventKey, children } = props;
	const decoratedOnClick = useAccordionButton(eventKey);

	return (
		<Button type="button" variant={"light"} size={"sm"} onClick={decoratedOnClick}>
			{children}
		</Button>
	);
});
