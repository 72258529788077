import * as React from "react";
import styled from "styled-components";

import type { AdCopyQuotaStatusProps } from "./quota-status";
import { AdCopyQuotaStatus } from "./quota-status";

const RedDiv = styled.div`
	color: red;
`;

type Props = AdCopyQuotaStatusProps;

export const AdCopyQuotaPage = React.memo((props: Props) => {
	return (
		<div className="m-3 clearfix">
			<div className="h3">■回収状況</div>

			{props.isCMI && (
				<RedDiv>
					回収数はデータクリーニング前の数値です。集計時の有効回答数は減少する場合があります。（予定数に対してショートしているセルの回収数は減少しません。）
				</RedDiv>
			)}

			<AdCopyQuotaStatus {...props} />
		</div>
	);
});
