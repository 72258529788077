import * as React from "react";
import { Button, Col } from "react-bootstrap";

import type { AdCopyConceptKey } from "../../../../../../../../server/models/ad-copy/concept";

type Props = {
	conceptType: "test2" | "test3";
	onCopy: (original: AdCopyConceptKey, copyTo: AdCopyConceptKey) => void;
};

export const AdCopyTest1CopyButton = React.memo((props: Props) => {
	const { conceptType, onCopy } = props;

	return (
		<Col md={3}>
			<Button
				onClick={() => {
					onCopy("test1", conceptType);
				}}
				variant="outline-secondary"
			>
				テスト品1からコピー
			</Button>
		</Col>
	);
});
