import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { AdCopyReportDownloadAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	info: MessageInfo;
	job: AdCopyJob;
	loading: boolean;
};

export const initState: State = {
	info: {
		isSuccess: true,
		message: "",
	},
	job: undefined,
	loading: true,
};

export const reducer = (state: State, action: AdCopyReportDownloadAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "loadData":
			return {
				...state,
				job: action.payload.job,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
