import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import type { AdCopyConcept } from "../../../../../../../server/models/ad-copy/concept";
import { AdCopyConceptPreview } from "./concept-preview";

type Props = {
	concepts: AdCopyConcept[];
	onEditPage: () => void;
};

export const AdCopyConceptPreviewPage = React.memo((props: Props) => {
	const { concepts, onEditPage } = props;

	return (
		<>
			<div className="m-3">
				<div className="h3">■プレビュー</div>
			</div>

			<AdCopyConceptPreview concepts={concepts} />

			<Row style={{ marginTop: "30px" }}>
				<Col md={{ offset: 2, span: 8 }}>
					<Col md={{ offset: 3, span: 3 }}>
						<Button className="w-100" onClick={onEditPage} variant="secondary">
							編集ページに戻る
						</Button>
					</Col>
				</Col>
			</Row>
		</>
	);
});
