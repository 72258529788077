import * as React from "react";
import { SubTitle, InputArea } from "../common";
import { InputText } from "../../input";
import { CrossAggregate } from "../../../../../../server/models/cross-aggregate";
import { TargetGroup } from "../../../../../../server/models/graph/target-group";
import { MultipleSelect, SingleSelect } from "../../select";
import { Option } from "../../../../../../server/types/request";
import { ConceptKey } from "../../../../../../server/models/concept";
import { getErrorMessage } from "../../../../lib/error";
import { Activity } from "../../../../../../server/models/activity";
import styled from "styled-components";
import { Conditions } from "../conditions";
import { ErrorObject } from "../../../../../../server/types/error";

const Container = styled.div`
	margin-bottom: 16px;
`;

const ConditionArea = styled.div`
	margin-left: 32px;
	margin-top: calc(8px + 0.75em);

	.inner {
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		padding-bottom: 8px;
		padding-left: 16px;
		padding-right: 8px;
		padding-top: calc(8px + 0.75em);
		position: relative;

		td:nth-child(2) {
			cursor: defauly;
			padding: 0 8px;
			user-select: none;
		}

		.title {
			background-color: white;
			left: 8px;
			position: absolute;
			padding: 0 8px;
			top: -0.75em;
		}
	}
`;

export type AggregatePropertyProps = {
	crossAggregate: Partial<CrossAggregate>;
	onChange: <K extends keyof CrossAggregate>(name: K, value: CrossAggregate[K]) => void;
	errors?: ErrorObject;
	targetGroupOptions: Option[];
	conceptOptions: Option[];
	activity: Activity;
};

export const AggregateProperty = React.memo((props: AggregatePropertyProps) => {
	const { crossAggregate, onChange, targetGroupOptions, conceptOptions, errors, activity } = props;
	const { name, target, concepts } = crossAggregate;
	const onChangeName = React.useCallback((value: string) => onChange("name", value), [onChange]);
	const onChangeTarget = React.useCallback((value: TargetGroup) => onChange("target", value), [onChange]);
	const onChangeConcepts = React.useCallback(
		(value: ConceptKey[]) => {
			onChange("concepts", value);
		},
		[onChange],
	);

	return (
		<Container>
			<SubTitle>集計名称</SubTitle>
			<InputArea>
				<InputText value={name} onChange={onChangeName} error={getErrorMessage("name", errors)} />
			</InputArea>
			<SubTitle>対象ターゲット</SubTitle>
			<InputArea>
				<SingleSelect
					options={targetGroupOptions}
					value={target || ""}
					onChange={onChangeTarget}
					error={getErrorMessage("target", errors)}
				/>
				{target && (
					<ConditionArea>
						<div className="inner">
							<span className="title">条件</span>
							<Conditions target={target} activity={activity} />
						</div>
					</ConditionArea>
				)}
			</InputArea>
			<SubTitle>対象コンセプト</SubTitle>
			<InputArea>
				<MultipleSelect
					options={conceptOptions}
					value={concepts || []}
					onChange={onChangeConcepts}
					error={getErrorMessage("concepts", errors)}
				/>
			</InputArea>
		</Container>
	);
});
