import * as React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import { JobWithSurveyStatus } from "../../../../../../../server/models/job";

import { Column } from "../../../../../../../react-components/lu-component/src/types/column";
import { Data, ErrorObject } from "../../../../../../../react-components/lu-component/src/components/form-input-groups";
import { EventMenu, EventItem } from "../../../../../../../react-components/lu-component/src/components/event-menu";

type JobSearchListTableProps = {
	columns: Column[][];
	errors?: ErrorObject;
	idName?: string;
	data: Data[];
	events?: EventItem[];
	eventHeader?: string;
	searches: { [key in keyof JobWithSurveyStatus]?: JobWithSurveyStatus[key] };
};

const PreArea = styled.div`
	max-width: 300px;
	white-space: pre;
	scroll-x: unset;
	overflow-x: scroll;
	overflow-y: hidden;
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const JobSearchListTable = React.memo((props: JobSearchListTableProps) => {
	const { columns, data, events, errors, idName = "_id", eventHeader = "", searches } = props;

	const header = React.useMemo(() => {
		return (
			<thead>
				<tr>
					{columns.map((cols, index) => {
						if (cols.every((col) => col.hide)) {
							return <React.Fragment key={`header-field-${index}`} />;
						}
						return (
							<th key={`header-field-${index}`}>
								{cols.map((col) => {
									if (col.hide) {
										return <React.Fragment key={`header-field-${index}-${col.name}`} />;
									}
									return <div key={`header-field-${index}-${col.name}`}>{col.label}</div>;
								})}
							</th>
						);
					})}
					{events && <th style={{ width: "200px" }}>{eventHeader}</th>}
				</tr>
			</thead>
		);
	}, [columns, eventHeader, events]);

	const body = React.useMemo(() => {
		return (
			<tbody>
				{data.map((d, dataIndex) => {
					const id = idName in d ? d[idName] : dataIndex.toString();
					return (
						<tr key={`datarow-${id}-${dataIndex}`}>
							{columns.map((cols, index) => {
								if (cols.every((col) => col.hide)) {
									return <React.Fragment key={`data-${id}-${dataIndex}-${index}`} />;
								}

								return (
									<td key={`data-${id}-${dataIndex}-${index}`}>
										{cols.map((col) => {
											if (col.hide) {
												return <React.Fragment key={`data-${id}-${dataIndex}-${col.name}`} />;
											}
											if (col.type === "events") {
												return (
													<EventMenu
														key={`data-${id}-${dataIndex}-${col.name}`}
														columns={columns}
														events={col.events}
														data={d}
														id={id}
														errors={errors}
													/>
												);
											}
											let value = col.name in d ? d[col.name] : "";
											if (value && col.type === "select") {
												const tmp = col.options.find((op) => op.value === value);
												if (tmp) value = tmp.label;
											} else if (col.type === "multiple-select" && value && Array.isArray(value)) {
												const filtered = col.options.filter((op) => value.includes(op.value)).map((op) => op.label);
												value = filtered.join(",");
											} else if (col.type === "checkbox") {
												if (value === true) {
													value = `${col.label}：●`;
												} else {
													value = `${col.label}：×`;
												}
											} else if (col.type === "custom") {
												value = col.view ? col.view(d) : value;
											}
											if (col.type === "textarea" || col.type === "readonly") {
												return (
													<PreArea key={`data-${id}-${dataIndex}-${index}-${col.name}`} className={"wrap-area"}>
														{value}
													</PreArea>
												);
											}
											if (col.name === "jobNum" && searches.jobNum) {
												const searchVal: string = searches.jobNum;
												const startIdx: number = value.indexOf(searchVal);
												const endIdx: number = startIdx + searches.jobNum.length;
												if (startIdx >= 0 && endIdx >= 0) {
													return (
														<div key={`data-${id}-${dataIndex}-${index}-${col.name}`}>
															{value.slice(0, startIdx)}
															<span style={{ backgroundColor: "yellow" }}>{value.slice(startIdx, endIdx)}</span>
															{value.slice(endIdx, value.length)}
														</div>
													);
												}
											}
											return <div key={`data-${id}-${dataIndex}-${index}-${col.name}`}>{value}</div>;
										})}
									</td>
								);
							})}
							{events && (
								<td>
									<EventMenu events={events} columns={columns} data={d} id={id} errors={errors} />
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		);
	}, [columns, data, errors, events, idName, searches.jobNum]);

	return (
		<div>
			<Table>
				{header}
				{body}
			</Table>
		</div>
	);
});
