import { AdCopyConceptKey } from "../concept";
import { AggregateTargetGroup, AxisNameType } from "./questionData";

export type OneNumberScoreData = {
	axisName: AxisNameType;
	conceptType: AdCopyConceptKey;
	score: number;
	target: AggregateTargetGroup;
	quename: string;
};
