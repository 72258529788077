import { EventItem } from "../components/event-menu";
import { BetweenInputOption } from "../components/between-input";

export type InputType = "text" | "datetime" | "datetimepicker" | "textarea" | "checkbox" | "readonly";

export type BaseColumn<T> = {
	name: T extends string ? string : keyof T;
	label: React.ReactNode;
	col?: number;
	hide?: boolean;
	isShow?: (value: any) => boolean;
};

export type TextColumn<T> = BaseColumn<T> & {
	placeholder?: string;
	type: InputType;
	disabled?: boolean;
};

export type Option = {
	label: string;
	value: string | number;
};

export type SelectColumn<T> = BaseColumn<T> & {
	type: "select";
	options: Option[];
};
export type MultipeSelectColumn<T> = BaseColumn<T> & {
	type: "multiple-select";
	options: Option[];
};

export type SwitchColumn<T> = BaseColumn<T> & {
	type: "switch";
	enableText?: string;
	disableText?: string;
};
export type CustomColumn<T> = BaseColumn<T> & {
	type: "custom";
	children?:
		| JSX.Element
		| JSX.Element[]
		| React.ReactText
		| ((data: any) => JSX.Element | JSX.Element[] | React.ReactText);
	view?: (value: any) => JSX.Element | JSX.Element[] | React.ReactText;
};

// input系はstringになるのですべてstringで定義し直し。
export type Choice = {
	label: string;
	value: string | number | boolean;
};

export type GroupChoice = {
	id: string;
	label: string;
	choices: Choice[];
};

export type GroupCheckboxColumn<T> = BaseColumn<T> & {
	type: "groupCheckbox";
	groups: GroupChoice[];
};

export type ChoiceColumn<T> = BaseColumn<T> & {
	type: "checkboxes" | "radios";
	name: string;
	choices: Choice[];
	inline?: boolean;
	disabled?: boolean;
};

export type LabelColumn<T> = BaseColumn<T> & {
	type: "label";
};

export type EventColumn<T> = BaseColumn<T> & {
	type: "events";
	events: EventItem[];
};

export type BetweenDateColumn<T> = BaseColumn<T> & {
	type: "between-date";
};
export type BetweenNumberColumn<T> = BaseColumn<T> & {
	type: "between-number";
	option?: BetweenInputOption;
};

export type Column<T = string> =
	| SelectColumn<T>
	| CustomColumn<T>
	| TextColumn<T>
	| GroupCheckboxColumn<T>
	| SwitchColumn<T>
	| ChoiceColumn<T>
	| MultipeSelectColumn<T>
	| LabelColumn<T>
	| EventColumn<T>
	| BetweenDateColumn<T>
	| BetweenNumberColumn<T>;
