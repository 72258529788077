import { InvoiceAction } from "../../../types/actions";
import { ActivityWithBrand } from "../../../../../server/models/activity";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { Invoice } from "../../../../../server/models/invoice";
import { MessageInfo } from "../../../types/info";
import { Job } from "../../../../../server/models/job";

export type State = {
	invoiceInfo?: {
		activity: ActivityWithBrand;
		job: Job;
		account: AccountWithoutPassword;
		invoice: Invoice;
	};
	errorMessage?: string;
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: InvoiceAction): State => {
	switch (action.type) {
		case "loadInvoice":
			return {
				...state,
				loading: false,
				invoiceInfo: action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
