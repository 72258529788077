/**
 * ターゲットの区分
 */
export const adCopyTargetGroupMap = {
	categoryUser: "カテゴリユーザー",
	strategyTarget: "戦略ターゲット",
	brandUser: "当該カテゴリ×ブランドユーザー",
} as const;

export const adCopyTargetGroupKeyMap = {
	categoryUser: "categoryUser",
	strategyTarget: "strategyTarget",
	brandUser: "brandUser",
} as const;

export type AdCopyTargetGroup = keyof typeof adCopyTargetGroupMap;
export const adCopyTargetGroupKeys = Object.keys(adCopyTargetGroupMap) as AdCopyTargetGroup[];
