import styled from "styled-components";

export const Title = styled.div`
	margin: 10px;
	border-left: 10px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding: 10px;
	width: 100%;
	font-size: 1.1em;
`;
