import * as React from "react";
import { decimalCalc } from "../../../../../../../server/lib/aggregate/common";
import { ActivityWithBrand } from "../../../../../../../server/models/activity";
import { ConceptKey, ConceptType } from "../../../../../../../server/models/concept";
import { OneNumberScoreData, OneNumberScoreNorm } from "../../../../../../../server/models/graph/demand-forecast";
import { ElementEvaluation } from "../../../../../../../server/models/graph/element-evaluation";
import { NormType } from "../../../../../../../server/models/graph/norm";
import { Job } from "../../../../../../../server/models/job";
import { doJudge, judgeNorm, normJudgeRank } from "../../../../../lib/action-standard";
import { Judge } from "../../exective-report/action-standard/action-standard-table";
import { CP } from "../../../../../../../server/models/cp";

export type NormRawProps = {
	baseConcept: "test1" | "test2";
	targetConcept: ConceptKey;
	evaluations: ElementEvaluation[];
	oneNumberScoreNorm: OneNumberScoreNorm;
	oneNumberScoreData: OneNumberScoreData[];
	job: Job;
};

type NormResult = {
	sampleSize: number | "-";
	score: number | "-";
	rank: number | NormType | "-";
	judge: Judge | "-";
	novelty: number | "-";
	originality: number | "-";
};

const getValue = <T extends object, K extends keyof T>(key: K, obj?: T): T[K] | "-" => {
	if (!obj || !(key in obj)) return "-";
	return obj[key];
};

const fixed = (input: number | string, decimalPoint = 1): string => {
	if (typeof input === "string") return input;
	return input.toFixed(decimalPoint);
};

const isTest = (targetConcept: ConceptKey): targetConcept is "test1" | "test2" => {
	return targetConcept === "test1" || targetConcept === "test2";
};

const NormRaw = React.memo((props: NormRawProps) => {
	const { baseConcept, targetConcept, oneNumberScoreNorm, oneNumberScoreData, job, evaluations } = props;
	const categroyResult = React.useMemo(() => {
		const category = oneNumberScoreData.find((ons) => {
			return ons.target === "category" && ons.conceptType === targetConcept;
		});
		const evaluation = evaluations.find((ev) => ev.conceptType === targetConcept && ev.target === "category");
		const result: NormResult = {
			sampleSize: getValue("sampleSize", category),
			score: getValue("score", category),
			rank: "-",
			judge: "-",
			novelty: evaluation?.evaluvationItem.find((ev) => ev.label === "新しさを感じる").val ?? "-",
			originality: evaluation?.evaluvationItem.find((ev) => ev.label === "他の商品とは違っている").val ?? "-",
		};
		if (!category || !oneNumberScoreNorm) return result;
		if (baseConcept === targetConcept) {
			result.rank = judgeNorm(category.score, oneNumberScoreNorm);
			result.judge = normJudgeRank(result.rank, job.endDatetime) ? "〇" : "×";
			return result;
		}
		const base = oneNumberScoreData.find((ons) => {
			return ons.target === "category" && ons.conceptType === baseConcept;
		});
		result.rank = decimalCalc(base.score - category.score, 10);
		result.judge = base.score > category.score ? "〇" : "×";
		return result;
	}, [oneNumberScoreData, evaluations, oneNumberScoreNorm, baseConcept, targetConcept, job.endDatetime]);
	const strategyResult = React.useMemo(() => {
		const strategy = oneNumberScoreData.find((ons) => {
			return ons.target === "strategy" && ons.conceptType === targetConcept;
		});
		const evaluation = evaluations.find((ev) => ev.conceptType === targetConcept && ev.target === "strategy");
		const result: NormResult = {
			sampleSize: getValue("sampleSize", strategy),
			score: getValue("score", strategy),
			rank: "-",
			judge: "-",
			novelty: evaluation?.evaluvationItem.find((ev) => ev.label === "新しさを感じる").val ?? "-",
			originality: evaluation?.evaluvationItem.find((ev) => ev.label === "他の商品とは違っている").val ?? "-",
		};
		if (!strategy || result.sampleSize === "-") return result;
		if (baseConcept === targetConcept) return result;
		const base = oneNumberScoreData.find((ons) => {
			return ons.target === "strategy" && ons.conceptType === baseConcept;
		});
		result.rank = decimalCalc(base.score - strategy.score, 10);
		/**
		 * No.8 エグゼクティブサマリーで戦略ターゲットGがn30未満であればＡＳ判定しない
		 */
		if (doJudge(result.sampleSize, job.endDatetime)) {
			result.judge = result.rank > 0 ? "〇" : "×";
		}
		return result;
	}, [baseConcept, targetConcept, oneNumberScoreData, job, evaluations]);
	return (
		<tr>
			<td className="label-area">{ConceptType[targetConcept]}</td>
			<td>{categroyResult.sampleSize}</td>
			<td>{fixed(categroyResult.score)}</td>
			<td className={typeof categroyResult.rank !== "string" && categroyResult.rank < 0 ? "minus" : "plus"}>
				{fixed(categroyResult.rank)}
			</td>
			{/* カテゴリターゲット：「対 競合品比較」「対 現行品比較」はAS判定しない  */}
			<td className={!isTest(targetConcept) || categroyResult.judge === "-" ? "" : "action-standard"}>
				{!isTest(targetConcept) ? "-" : fixed(categroyResult.judge)}
			</td>
			{/* 独自性 */}
			<td>{fixed(categroyResult.novelty, 2)}</td>
			<td>{fixed(categroyResult.originality, 2)}</td>

			<td>{strategyResult.sampleSize}</td>
			<td>{fixed(strategyResult.score)}</td>
			<td className={typeof strategyResult.score !== "string" && strategyResult.score < 0 ? "minus" : "plus"}>
				{fixed(strategyResult.rank)}
			</td>
			<td className={strategyResult.judge === "-" ? "" : "action-standard"}>{fixed(strategyResult.judge)}</td>
			<td>{fixed(strategyResult.novelty, 2)}</td>
			<td>{fixed(strategyResult.originality, 2)}</td>
		</tr>
	);
});

export type NormTableProps = {
	target: "test1" | "test2";
	oneNumberScoreNorm: OneNumberScoreNorm;
	evaluations: ElementEvaluation[];
	oneNumberScoreData: OneNumberScoreData[];
	activity: ActivityWithBrand;
	job: Job;
	cp?: CP;
};

export const NormTable = React.memo((props: NormTableProps) => {
	const { oneNumberScoreNorm, oneNumberScoreData, evaluations, target, job, cp } = props;
	const normData = React.useMemo(() => {
		return evaluations.find((ev) => ev.conceptType === "norm");
	}, [evaluations]);
	return (
		<table className={"result-table norm"}>
			<thead>
				<tr>
					<th></th>
					<th colSpan={6}>カテゴリターゲットグループ</th>
					<th colSpan={6}>戦略ターゲットグループ</th>
				</tr>
				<tr>
					<th></th>
					<th>n数</th>
					<th>購入意向(%)</th>
					<th>
						ランク
						<br />
						／リフト
					</th>
					<th className={"action-standard"}>AS判定</th>
					<th>新規性</th>
					<th>差別性</th>
					<th>n数</th>
					<th>購入意向(%)</th>
					<th>リフト</th>
					<th className={"action-standard"}>AS判定</th>
					<th>新規性</th>
					<th>差別性</th>
				</tr>
			</thead>
			<tbody>
				<NormRaw
					targetConcept={target}
					baseConcept={target}
					evaluations={evaluations}
					oneNumberScoreData={oneNumberScoreData}
					oneNumberScoreNorm={oneNumberScoreNorm}
					job={job}
				/>
				<NormRaw
					targetConcept={"benchmark"}
					baseConcept={target}
					evaluations={evaluations}
					oneNumberScoreData={oneNumberScoreData}
					oneNumberScoreNorm={oneNumberScoreNorm}
					job={job}
				/>
				{(!cp || (cp._id && cp.type === "currentProduct")) && (
					<NormRaw
						targetConcept={"currentProduct"}
						baseConcept={target}
						evaluations={evaluations}
						oneNumberScoreData={oneNumberScoreData}
						oneNumberScoreNorm={oneNumberScoreNorm}
						job={job}
					/>
				)}
				<tr>
					<td className="label-area">ノーム</td>
					<td colSpan={4}>-</td>
					<td>{fixed(normData?.evaluvationItem.find((ev) => ev.label === "新しさを感じる").val ?? "-", 2)}</td>
					<td>{fixed(normData?.evaluvationItem.find((ev) => ev.label === "他の商品とは違っている").val ?? "-", 2)}</td>
					<td colSpan={6}>-</td>
				</tr>
			</tbody>
		</table>
	);
});
