import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ActivityWithBrand } from "../../../../../server/models/activity";
import { Job } from "../../../../../server/models/job";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { Invoice } from "../../../../../server/models/invoice";

const Contains = React.memo((props: { field: string; children: React.ReactNode; classAdd?: string }) => {
	return (
		<Row className="mt-3">
			<Col md={3} className={"text-end" + (props.classAdd ? " " + props.classAdd : "")}>
				{props.field}
			</Col>
			<Col>{props.children}</Col>
		</Row>
	);
});

export type InvoiceSetting = {
	activity: ActivityWithBrand;
	job: Job;
	account: AccountWithoutPassword;
	invoice: Invoice;
};

export const InvoicePage = React.memo((props: InvoiceSetting) => {
	const { activity, job, invoice, account } = props;
	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■ご請求詳細　※請求書は別途届きます。</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} className="mb-3">
					<Row className="m-3">
						<Col md={12}>
							<div className="h4">・アクティビティ情報</div>
							<Contains field="ブランド：">{activity.brand.name}</Contains>
							<Contains field="カテゴリ：">{activity.category.name}</Contains>
						</Col>
					</Row>
					<Row className="m-3">
						<Col md={12}>
							<div className="h4">・ジョブ情報</div>
							<Contains field="ID：">{job.jobNum}</Contains>
							<Contains field="入力担当者：">{account.name}</Contains>
						</Col>
					</Row>
					<Row className="m-3">
						<Col md={12}>
							<div className="h4">・請求情報</div>
							<Contains field="請求ID：">{invoice._id.toString()}</Contains>
							<Contains field="SC計（税抜き）：">{invoice.sc.toLocaleString()}円</Contains>
							<Contains field="本調査計（税抜き）：">{invoice.complete.toLocaleString()}円</Contains>
							<Contains field="集計費（税抜き）：">{(invoice.aggregationCost || 0).toLocaleString()}円</Contains>
							<hr />
							<Contains field="税抜き計：" classAdd="fw-bold">
								{(invoice.sc + invoice.complete + (invoice.aggregationCost || 0)).toLocaleString()}円
							</Contains>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
});
