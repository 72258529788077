import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "change":
			return { ...state, text: action.payload, searched: undefined };
		case "clear":
			return { text: "" };
		case "page":
			if (!state.searched) return state;
			return {
				...state,
				searched: { ...state.searched, index: action.payload },
			};
		case "search":
			return { ...state, searched: action.payload };
	}
};
