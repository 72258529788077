import * as React from "react";
import Select from "react-select";
import styled from "styled-components";

export type SelectOption = { value: string | number; label: string };
export type MultipleSelectProps = {
	name: string;
	onChange: (values: (string | number)[]) => void;
	options: SelectOption[];
	values?: (string | number)[];
	isInvalid?: boolean;
};

export const Wrap = styled.div`
	& div.invalid {
		& div {
			border-color: red;
		}
	}
`;
export const MultipleSelect = React.memo((props: MultipleSelectProps) => {
	const { name, onChange, options, values, isInvalid } = props;
	const selecteds = React.useMemo(() => {
		if (!values || !values.length) return [];
		return options.filter((option) => values.includes(option.value));
	}, [values, options]);
	const onChangeSelect = React.useCallback(
		(selecteds: SelectOption[]) => {
			onChange(selecteds ? selecteds.map((selected) => selected.value) : []);
		},
		[onChange],
	);
	return (
		<Wrap>
			<Select
				name={name}
				options={options}
				onChange={onChangeSelect}
				value={selecteds}
				isMulti
				{...(isInvalid ? { className: "invalid" } : {})}
			/>
		</Wrap>
	);
});
