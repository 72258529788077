import { MessageInfo } from "../../../types/info";
import { AggregateCreateAction } from "../../../types/actions";
import { type Qtype } from "@muscat/types";
import { AggregateJob } from "@analytics/types";
import { CrossAggregate } from "../../../../../server/models/cross-aggregate";
import { Option } from "../../../../../server/types/request";
import { Activity } from "../../../../../server/models/activity";
import { EditableAggregateJob } from "../../../types/aggregate";
import { Question as AggregateQuestion } from "@bleu/core";
import { AggregateTargetQuestionType } from "@brix/core";
import { createAggregateJob, isDefinedUpdatedVersion } from "../../../lib/data";
import { GetAggregateConfigResponse } from "../../../../../server/types/request/aggregate";
import { Error as LUError } from "@lu/request";

export type State = AggregateJob & {
	aggregateConfig: GetAggregateConfigResponse | undefined;

	activity: Activity;
	crossAggregate: Partial<CrossAggregate>;
	loading: boolean;
	info: MessageInfo;
	questions: Array<AggregateQuestion<AggregateTargetQuestionType>>;
	qMap: Map<string, Qtype>;
	title: string;
	prompt: boolean;

	// options
	targetGroupOptions: Option[];
	conceptOptions: Option[];

	// new create aggregate component
	aggregateJob: EditableAggregateJob | undefined;
	aggregateQuestions: Array<AggregateQuestion<AggregateTargetQuestionType>>;
	aggregateId: string;
	editorError: LUError | undefined;
};

export const initState: State = {
	aggregateConfig: undefined,
	activity: undefined,
	crossAggregate: {},
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
	prompt: false,
	jobNo: undefined,
	enqueteId: "",
	selectionGroups: [],
	axis: [],
	questions: [],
	title: "",
	qMap: undefined,

	targetGroupOptions: [],
	conceptOptions: [],

	aggregateJob: undefined,
	aggregateQuestions: [],
	aggregateId: "",
	editorError: undefined,

	targetQuestions: [],
	additionalQuestions: [],
};

export const reducer = (state: State, action: AggregateCreateAction): State => {
	switch (action.type) {
		case "loadAggreagteJob":
			return {
				...state,
				...(action.payload.aggregateJob && { aggregateJob: action.payload.aggregateJob }),

				...action.payload.aggregateJob,
				...(action.payload.config && {
					title: action.payload.config.title,
					activity: action.payload.config.activity,
					crossAggregate: action.payload.config.crossAggregate,
					targetGroupOptions: action.payload.config.targetGroupOptions,
					conceptOptions: action.payload.config.conceptOptions,
					aggregateConfig: action.payload.config,
				}),

				loading: false,
			};
		case "updateQuestions":
			return {
				...state,
				aggregateQuestions: action.payload,
			};
		case "updateAggregateId":
			return {
				...state,
				aggregateId: action.payload,
			};
		case "editorError":
			return {
				...state,
				editorError: action.payload,
			};
		case "upsertAggregate":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: "集計設定を保存しました。",
				},
				prompt: false,
			};
		case "changeCrossAggregate":
			return {
				...state,
				crossAggregate: {
					...state.crossAggregate,
					[action.payload.name]: action.payload.value,
				},
				prompt: true,
			};
		case "updateAggregateJob":
			console.log("action.payload updateAggregateJob", action.payload);
			return {
				...state,
				aggregateJob: action.payload,
				prompt: true,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "createEmptyAggregateJob": {
			const { jobId, questions } = action.payload;
			const baseAggregateJob = createAggregateJob({ _id: jobId.toString() }, questions);

			if (!isDefinedUpdatedVersion(state.aggregateJob?.jobId)) {
				return {
					...state,
					aggregateJob: baseAggregateJob,
					questions,
				};
			}
			return {
				...state,
				aggregateJob: baseAggregateJob,
				loading: false,
				questions,
			};
		}
		default:
			return state;
	}
};
