/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

import type { AdCopySku } from "../../../../../../../../server/models/ad-copy/concept";
import { downloadData } from "../../../../../../lib/data";
import { get } from "../../../../../../lib/request";

const AdCopyContains = React.memo((props: { children: React.ReactNode; field: React.ReactNode }) => {
	return (
		<div
			className="mt-3"
			style={{
				textAlign: "left",
			}}
		>
			{props.field}
			{props.children}
		</div>
	);
});

const ConceptArea = styled.div`
	text-align: center;
	& img {
		width: 100%;
		max-width: 500px;
	}
	padding-bottom: 10px;
	margin-bottom: 10px;

	img.thumbnail:hover {
		cursor: pointer;
	}
`;

type Props = {
	label: string;
	skuArray: AdCopySku[];
	url: string;
};

export const AdCopyModalImage = React.memo((props: Props) => {
	const [show, setShow] = React.useState(false);

	const { label, skuArray, url } = props;

	const onDownload = React.useCallback(() => {
		get<ArrayBuffer>(url, {}, { responseType: "arraybuffer" }).then((response) => {
			const blob = new Blob([response.data], { type: "image/png" });

			downloadData(blob, `${label}.png`);
		});
	}, [url, label]);

	return (
		<>
			<div>{label}</div>

			<ConceptArea>
				<img
					className="thumbnail"
					onClick={() => {
						setShow(true);
					}}
					src={url}
				/>

				{skuArray.map((sku, i) => (
					<AdCopyContains key={i} field={`${sku.name}`}>
						<div style={{ whiteSpace: "pre" }}>
							{sku.price}円（税抜）／{sku.includeTaxPrice}円（税込）
						</div>
					</AdCopyContains>
				))}
			</ConceptArea>

			<Modal
				onHide={() => {
					setShow(false);
				}}
				show={show}
			>
				<Modal.Header closeButton>
					<Modal.Title>{label}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<ConceptArea>
						<img src={url} />
					</ConceptArea>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={onDownload} variant="secondary">
						download
					</Button>

					<Button
						onClick={() => {
							setShow(false);
						}}
						variant="outline-secondary"
					>
						close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
