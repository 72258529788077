import * as React from "react";
import { FormGroup, FormLabel, Col, Row, Button, Alert } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { ImageInfo } from "../../../../../server/models/concept";
import { ResizableImage } from "../resizable/resizable-image";

export type ImageResizableInputProps = {
	label?: React.ReactNode;
	data?: ImageInfo;
	error?: string;
	id: string;
	onChange: (file: File) => void;
	onResized: (width: number) => void;
	onDelete: () => void;
	maxHeight?: number;
	maxWidth?: number;
};

export const ImageResizableInput = React.memo((props: ImageResizableInputProps) => {
	const { label, data, onChange, onDelete, onResized, error, id, maxHeight = 250, maxWidth = 300 } = props;
	const [filename, setFilename] = React.useState<string>("");
	const onInputChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (!e.target && !e.target.files) {
				setFilename("");
				onChange(undefined);
			} else {
				const file = e.target.files.item(0);
				setFilename(file.name);
				onChange(file);
			}
		},
		[onChange],
	);
	const onDeleteClick = React.useCallback(() => {
		setFilename("");
		onDelete();
	}, [onDelete]);
	return (
		<FormGroup as={Row}>
			{label && (
				<Col md={12}>
					<FormLabel>{label}</FormLabel>
				</Col>
			)}
			<Col md={11}>
				<Row>
					{!!error && (
						<Col md={12}>
							<Feedback type={"invalid"} style={{ display: "block" }}>
								{error}
							</Feedback>
						</Col>
					)}
					<Col md={12}>
						<input
							type="file"
							className="form-control"
							aria-describedby="inputGroupFileAddon01"
							onChange={onInputChange}
							accept="image/*"
							id="inputGroupFile01"
						/>
						<label htmlFor="inputGroupFile01">{filename}</label>
					</Col>
					<Col md={12}>
						{data && data.url ? (
							<div id={id} style={{ width: `${maxWidth}px`, height: `${maxHeight}px` }}>
								<ResizableImage
									coordinate={{ x: 0, y: 0 }}
									parentId={id}
									src={data.url}
									width={data.width}
									onResized={onResized}
								/>
							</div>
						) : (
							<Alert variant={"dark"}>設定なし</Alert>
						)}
					</Col>
				</Row>
			</Col>
			<Col md={1}>
				{data && data.url && (
					<Button variant={"danger"} onClick={onDeleteClick}>
						×
					</Button>
				)}
			</Col>
		</FormGroup>
	);
});
