import * as React from "react";
import { Modal, Button, ButtonProps } from "react-bootstrap";

export type ConfirmProps = {
	show: boolean;
	onCancel: () => void;
	onOk: () => void;
	variant?: ButtonProps["variant"];
	buttonText: string;
	cancelText?: string;
	title: string;
	body: string | JSX.Element | JSX.Element[];
};
export const Confirm = React.memo((props: ConfirmProps) => {
	const { variant, buttonText, cancelText, title, body, onCancel, onOk, show } = props;
	return (
		<>
			<Modal show={show} onHide={onCancel}>
				<Modal.Header>{title}</Modal.Header>
				<Modal.Body>{body}</Modal.Body>
				<Modal.Footer style={{ textAlign: "right" }}>
					<Button variant={variant || "outline-danger"} onClick={onOk}>
						{buttonText}
					</Button>
					<Button variant={"outline-secondary"} onClick={onCancel}>
						{cancelText ?? "キャンセル"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
