import * as React from "react";
import Select from "react-select";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import classNames from "classnames";

const SelectWrap = styled.div`
	.react-select-container {
		&--invalid {
			.react-select__control {
				border-color: #dc3545;

				&--isfocused {
					border-color: #dc3545;
					box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
				}
			}
		}

		.react-select__control {
			background-color: white;
			border-color: #ced4da;
			border-radius: 0.25rem;
			min-height: calc(1.5em + 0.75rem + 2px);
			transition:
				border-color 0.15s ease-in-out,
				box-shadow 0.15s ease-in-out;

			&--is-focused {
				color: #495057;
				background-color: white;
				border-color: #80bdff;
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
			}
		}

		.react-select__menu {
			.react-select__option {
				color: #212529;
				&--is-focused {
					background-color: #f8f9fa;
				}

				&--is-selected {
					background-color: #e9ecef;
				}
			}
		}

		.react-select__indicator {
			padding: 0 5px;
		}
	}
`;

export type SelectOption = { label: string; value: any };
export type SingleSelectProps = {
	noBlunkOption?: boolean;
	value: any;
	onChange: (value: any) => void;
	options: SelectOption[];
	error?: string;
	isInvalid?: boolean;
};

export const SingleSelect = React.memo((props: SingleSelectProps) => {
	const { value, onChange, options, error } = props;
	const selected = options.find((opt) => opt.value == value);
	const onChangeSelect = React.useCallback(
		(selected: SelectOption) => {
			onChange(selected.value);
		},
		[onChange],
	);
	return (
		<SelectWrap>
			<Select
				// isInvalid={isInvalid || !!error}
				isSearchable={false}
				value={value && selected ? { value: value, label: selected.label } : undefined}
				onChange={onChangeSelect}
				options={options}
				className={classNames("react-select-container", { "react-select-container--invalid": error })}
				classNamePrefix="react-select"
				placeholder="選択してください"
			/>
			{!!error && <FormControl.Feedback type={"invalid"}>{error}</FormControl.Feedback>}
		</SelectWrap>
	);
});

export type MultipleSelectProps = {
	value: (number | string)[];
	onChange: (value: (number | string)[]) => void;
	options: SelectOption[];
	error?: string;
};
export const MultipleSelect = React.memo((props: MultipleSelectProps) => {
	const { value, onChange, options, error } = props;

	const selected = React.useMemo(() => {
		return options.filter((c) => value.includes(c.value));
	}, [value, options]);

	const onChangeSelect = React.useCallback(
		(selected: SelectOption[]) => {
			onChange(selected ? selected.map((s) => s.value) : []);
		},
		[onChange],
	);
	return (
		<SelectWrap>
			<Select
				isMulti
				isSearchable={false}
				value={selected}
				onChange={onChangeSelect}
				options={options}
				className={classNames("react-select-container", { "react-select-container--invalid": !!error })}
				classNamePrefix="react-select"
				placeholder="選択してください"
			/>
			{error && (
				<FormControl.Feedback type={"invalid"} style={{ display: "block" }}>
					{error}
				</FormControl.Feedback>
			)}
		</SelectWrap>
	);
});
