import * as React from "react";

export type GoalTableProps = {
	goalSalesVolume: number;
};
export const GoalTable = React.memo((props: GoalTableProps) => {
	const { goalSalesVolume } = props;
	const _goal50 = React.useMemo(() => (goalSalesVolume * 10 * 5) / 100, [goalSalesVolume]);
	return (
		<table className={"result-table"} style={{ width: "56%", display: "inline-table" }}>
			<thead>
				<tr>
					<th>判定基準</th>
					<th>目標値(万個)</th>
					{/*
					<th>下限値(万個)</th>
					<th>目標対比(％)</th>
					*/}
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className={"label-area"}>目標値</td>
					<td>{goalSalesVolume.toFixed(1)}</td>
					{/*<td>{goal50.toFixed(1)}</td>
					<td className={"minus"}>-50.0</td>
					 */}
				</tr>
			</tbody>
		</table>
	);
});
