import { Labels } from ".";
import { Job } from "../server/models/job";

export const jobLabels: Labels<Job> = {
	_id: "ジョブ",
	activityId: "アクティビティ",
	jobNum: "ジョブNo",
	accountId: "担当者",
	startDatetime: "開始日時",
	endDatetime: "終了日時",
	hasTwoTest: "自社テスト品",
	hasCurrentProduct: "現行品の有無",
	hasBenchmark: "競合品の有無",
	benchmarkName: "競合品製品名",
	hasStrategicTarget: "戦略ターゲットGの有無",
	retestJobId: "再調査",
	status: "ステータス",
	method: "調査手法",
	enqueteId: "アンケートID",
};
