import * as React from "react";
import { LogoutPage } from "../../components/pages/logout";
import { post } from "../../lib/request";
import { endpoint } from "../../../../server/router/endpoint";

export const LogoutContainter = () => {
	const changeLocation = React.useCallback(() => location.replace("/login"), []);
	const [isLogout, setIslogout] = React.useState<boolean>(false);
	React.useEffect(() => {
		post(endpoint.logout, {}).finally(() => {
			setIslogout(true);
		});
	}, []);
	return isLogout && <LogoutPage changeLocation={changeLocation} />;
};
