import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type { GetAdCopyReportOutlineResBody } from "../../../../../../server/types/request/ad-copy/report/outline";
import { AdCopyOutline } from "../../../../components/pages/ad-copy/report/outline";
import { AdCopyReportBigTitle } from "../../../../components/pages/ad-copy/report/parts/report-title";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/report/outline";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";

export const AdCopyOutlineContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading, originJobOutline, outline } = state;

	const { activityId, jobId } = useParams<{
		activityId: string;
		jobId: string;
	}>();

	React.useEffect(() => {
		get<GetAdCopyReportOutlineResBody>(`${adCopyEndpoint.outline}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadData" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onViewQuotaPage = React.useCallback(
		(jobId: string) => {
			window.open(replaceEndpointUrl(adCopyClientEndpoint.quota, { activityId: activityId ?? "", jobId }));
		},
		[activityId],
	);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} />

			{outline && (
				<>
					<AdCopyReportBigTitle>テストの概要（{outline.job.jobNum}）</AdCopyReportBigTitle>

					<AdCopyOutline {...outline} onViewQuotaPage={onViewQuotaPage} />
				</>
			)}

			{originJobOutline && (
				<>
					<AdCopyReportBigTitle>前回のテスト概要（{originJobOutline.job.jobNum}）</AdCopyReportBigTitle>

					<AdCopyOutline {...originJobOutline} onViewQuotaPage={onViewQuotaPage} />
				</>
			)}
		</>
	);
};
