import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { AdCopyConcept } from "../../../../../../server/models/ad-copy/concept";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type { PreviewAdCopyConceptResponse } from "../../../../../../server/types/request/ad-copy/concept";
import { AdCopyConceptPreviewPage } from "../../../../components/pages/ad-copy/concept/preview";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError } from "../../../../lib/request";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";
import type { MessageInfo } from "../../../../types/info";

export const AdCopyConceptPreviewContainer = () => {
	const [concepts, setConcepts] = React.useState<AdCopyConcept[]>([]);

	const [info, setInfo] = React.useState<MessageInfo>({ isSuccess: true, message: "" });

	const { activityId, jobId } = useParams<{
		activityId: string;
		jobId: string;
	}>();

	const navigate = useNavigate();

	React.useEffect(() => {
		get<PreviewAdCopyConceptResponse>(`${adCopyEndpoint.conceptPreview}/${jobId}`)
			.then((response) => {
				setConcepts(response.data.concepts);
			})
			.catch((error) => {
				setInfo(makeError(error));
			});
	}, [jobId]);

	const onEditPage = React.useCallback(() => {
		navigate(replaceEndpointUrl(adCopyClientEndpoint.concept, { activityId: activityId ?? "", jobId: jobId ?? "" }));
	}, [activityId, jobId, navigate]);

	return (
		<>
			<ConfirmBox info={info} titleLabel="取得処理" />

			<AdCopyConceptPreviewPage concepts={concepts} onEditPage={onEditPage} />
		</>
	);
};
