import * as React from "react";

import { adCopyActivityLabels } from "../../../../../../../../label/ad-copy/activity";
import {
	type AdCopyActivityWithBrand,
	AdCopyGrp,
	AdCopyProductType,
	AdCopySalesScale,
} from "../../../../../../../../server/models/ad-copy/activity";
import { composeTargetCategoryBrandsString } from "../../../../../../lib/ad-copy/activity";
import {
	AdCopyOutlineCard,
	AdCopyOutlinePanel,
	AdCopyOutlinePanelBody,
	AdCopyOutlinePanelTitle,
	AdCopyOutlineTable,
} from "..";
import { AdCopyActivityPanelRow } from "./panel-row";

type Props = {
	activity: AdCopyActivityWithBrand;
};

export const AdCopyActivityPanel = React.memo((props: Props) => {
	const { activity } = props;

	const targetCategoryBrandsString = React.useMemo(() => composeTargetCategoryBrandsString(activity), [activity]);

	return (
		<AdCopyOutlinePanel className="panel">
			<AdCopyOutlinePanelTitle>アクティビティ情報</AdCopyOutlinePanelTitle>

			<AdCopyOutlinePanelBody>
				<AdCopyOutlineCard width={370}>
					<AdCopyOutlineTable>
						<tbody>
							{/* ブランド */}
							<AdCopyActivityPanelRow label={adCopyActivityLabels.brandId} value={activity.brand.name} />

							{/* 新規ブランドの場合、ブランド名 */}
							{activity.brandName != null && (
								<AdCopyActivityPanelRow label={adCopyActivityLabels.brandName ?? ""} value={activity.brandName} />
							)}

							{/* カテゴリ */}
							<AdCopyActivityPanelRow
								label={adCopyActivityLabels.category}
								value={activity.category.name.replace("（", "\n（")}
							/>

							{/* ターゲットカテゴリブランド */}
							{targetCategoryBrandsString && (
								<AdCopyActivityPanelRow
									label={adCopyActivityLabels.targetCategoryBrandValues ?? ""}
									value={targetCategoryBrandsString}
								/>
							)}

							{/* 上市/コピー使用開始時期 */}
							<AdCopyActivityPanelRow
								label="上市/コピー使用開始時期"
								value={`${activity.releaseYear}年${activity.releaseMonth}月`}
							/>
						</tbody>
					</AdCopyOutlineTable>
				</AdCopyOutlineCard>

				<AdCopyOutlineCard width={300}>
					<AdCopyOutlineTable>
						<tbody>
							{/* 開発区分 */}
							<AdCopyActivityPanelRow
								label={adCopyActivityLabels.productType}
								value={AdCopyProductType[activity.productType]}
							/>

							{/* 売り上げ規模 */}
							<AdCopyActivityPanelRow
								label={adCopyActivityLabels.salesScale}
								value={AdCopySalesScale[activity.salesScale]}
							/>

							{/* TVCM規模（GRP) */}
							<AdCopyActivityPanelRow label={adCopyActivityLabels.grp} value={AdCopyGrp[activity.grp]} />
						</tbody>
					</AdCopyOutlineTable>
				</AdCopyOutlineCard>

				<AdCopyOutlineCard width={300}>
					<AdCopyOutlineTable>
						<tbody>
							{/* 日本人（当該性の）15～69歳における想定商品認知率(%) */}
							<AdCopyActivityPanelRow
								label={"日本人（当該性の）15～69歳\nにおける想定商品認知率(%)"}
								value={`${activity.goalRecognitionRate}％`}
							/>

							{/* 目標お客さま購買 */}
							<AdCopyActivityPanelRow label="目標お客さま購買" value={`${activity.goalSalesVolume}万個`} />
						</tbody>
					</AdCopyOutlineTable>
				</AdCopyOutlineCard>
			</AdCopyOutlinePanelBody>
		</AdCopyOutlinePanel>
	);
});
