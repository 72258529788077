import * as React from "react";
import { Col, Row, FormControl, Alert, Button } from "react-bootstrap";
import { Concept, ConceptKey, Sku } from "../../../../../../../server/models/concept";
import { MultipleText, ErrorObject } from "../../../../../../../react-components/lu-component/src/index";
import { MultipleSkuForm } from "../../../../parts/multiple-sku-form";
import Feedback from "react-bootstrap/Feedback";
import { ImageResizableInput } from "../../../../parts/image-resizable-input";
import { sentenceConfig, sentenceTotalConfig } from "./static";
import { replaceBreakAndSpace } from "../../../../../../../server/lib/common";
import { Help } from "../../../../parts/help";
import { RetestConcepts } from "../../../../../../../server/types/request/concept";
import { ConceptViewer } from "./concept-viewer";
import styled from "styled-components";

export type ConceptFormFunc = {
	setTaxIncluded: (type: ConceptKey, index: number) => void;
	onChangeConcept: (type: ConceptKey, name: keyof Concept, value: Concept[keyof Concept]) => void;
	onChangeSku: (type: ConceptKey, index: number, name: keyof Sku, value: Sku[keyof Sku]) => void;
	onAddSku: (type: ConceptKey, index: number) => void;
	onDeleteSku: (type: ConceptKey, index: number) => void;
	onChangeFile: (type: ConceptKey, target: "logo" | "package", file: File) => void;
	onResized: (type: ConceptKey, target: "logo" | "package", width: number) => void;
	onDeleteFile: (type: ConceptKey, target: "logo" | "package") => void;
	onCopy: (original: ConceptKey, copyTo: ConceptKey) => void;
	onRetestCopy: (original: ConceptKey, copyTo: ConceptKey) => void;
};

export type ConceptFormProps = {
	retestConcepts?: RetestConcepts;
	concept: Concept;
	errors?: ErrorObject;
} & ConceptFormFunc;

export type CountableTextareaProps = {
	error?: string;
	onChange: (value: string) => void;
	label: string;
	value: string;
};
export const CountableTextarea = React.memo((props: CountableTextareaProps) => {
	const { label, value, onChange, error } = props;
	const style = React.useMemo<React.CSSProperties>(() => {
		const length = value.length;
		const { min, max } = sentenceConfig;
		if (min && min > length) {
			return { fontWeight: "bold", color: "blue" };
		} else if (max && max < length) {
			return { fontWeight: "bold", color: "red" };
		}
		return {};
	}, [value]);
	const length = React.useMemo(() => {
		return replaceBreakAndSpace(value).length;
	}, [value]);
	return (
		<>
			<Row>
				<Col>{label}</Col>
			</Row>
			<Row>
				<Col md={8}>
					<FormControl
						as="textarea"
						value={value}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
						style={{ fontWeight: "bold", margin: "10px 0px" }}
					/>
					{error && (
						<Feedback type={"invalid"} style={{ display: "block", whiteSpace: "pre-wrap" }}>
							{error}
						</Feedback>
					)}
				</Col>
				<Col md={1} style={style}>
					{length}
				</Col>
			</Row>
		</>
	);
});

const FormBlock = styled.div`
	display: flex;
	column-gap: 2rem;
`;

export const ConceptForm = React.memo((props: ConceptFormProps) => {
	const {
		concept,
		errors,
		retestConcepts,
		onChangeConcept,
		setTaxIncluded,
		onChangeFile,
		onResized,
		onDeleteFile,
		onChangeSku,
		onDeleteSku,
		onAddSku,
		onCopy,
		onRetestCopy,
	} = props;
	const onChangeBoldArea = React.useCallback(
		(index: number, sentence: string) => {
			onChangeConcept(
				concept.type,
				"sentences",
				concept.sentences.map((s, i) => {
					if (i === index) {
						return { sentence, bold: true };
					}
					return s;
				}),
			);
		},
		[onChangeConcept, concept.type, concept.sentences],
	);
	const onChangeSentences = React.useCallback(
		(values: string[]) => {
			onChangeConcept(
				concept.type,
				"sentences",
				concept.sentences
					.filter((s) => !!s.bold)
					.concat(
						values.map((v) => {
							return { sentence: v || "" };
						}),
					),
			);
		},
		[concept.type, onChangeConcept, concept.sentences],
	);
	const CountView = React.useMemo(() => {
		const length = concept.sentences.reduce((a, b) => a + replaceBreakAndSpace(b.sentence).length, 0);
		const { min, max } = sentenceTotalConfig;
		if (length > max) {
			return (
				<span style={{ fontWeight: "bold", color: "red" }}>
					{length}（合計{max}文字以下で入力してください。）
				</span>
			);
		} else if (length < min) {
			return (
				<span style={{ fontWeight: "bold", color: "blue" }}>
					{length}（合計{min}文字以上で入力してください。）
				</span>
			);
		}
		return <span>{length}</span>;
	}, [concept]);
	const sentenceErrors = React.useMemo<{ [key: string]: string } | string | undefined>(() => {
		if (!errors || !errors["sentences"]) return undefined;
		if (typeof errors["sentences"] === "string") return errors["sentences"];
		if (typeof errors["sentences"] === "object") {
			return Object.entries(errors["sentences"]).reduce((a, [key, errors]) => {
				const message =
					typeof errors === "object" && errors["sentence"] ? errors["sentence"].toString() : errors.toString();
				return { ...a, [key]: message };
			}, {});
		}
		return undefined;
	}, [errors]);
	const multipleSentenceErrors = React.useMemo<ErrorObject | undefined>(() => {
		if (!errors || !errors["sentences"] || typeof errors["sentences"] === "string") return undefined;
		if (typeof errors["sentences"] === "object") {
			return Object.entries(errors["sentences"]).reduce((a, [key, errors]) => {
				const k = Number(key) - 3;
				const message =
					typeof errors === "object" && errors["sentence"] ? errors["sentence"].toString() : errors.toString();
				return { ...a, [k]: message };
			}, {});
		}
		return undefined;
	}, [errors]);
	return (
		<FormBlock>
			<div className="my-3">
				{/*No.10 同一Jobないでテスト品のコンセプトをコピーできるようにする。*/}
				{(concept.type === "test2" || !!retestConcepts) && (
					<Row style={{ marginBottom: "10px" }}>
						{concept.type === "test2" && (
							<Col md={3}>
								<Button variant={"outline-secondary"} onClick={() => onCopy("test1", "test2")}>
									テスト品1からコピー
								</Button>
							</Col>
						)}
						{!!retestConcepts && !!retestConcepts.test1 && (
							<Col md={3}>
								<Button variant={"outline-secondary"} onClick={() => onRetestCopy("test1", concept.type)}>
									【過去調査】テスト品1からコピー
								</Button>
							</Col>
						)}
						{!!retestConcepts && !!retestConcepts.test2 && (
							<Col md={3}>
								<Button variant={"outline-secondary"} onClick={() => onRetestCopy("test2", concept.type)}>
									【過去調査】テスト品2からコピー
								</Button>
							</Col>
						)}
					</Row>
				)}
				<Row>
					<Col md={12}>
						<ImageResizableInput
							error={
								errors && errors["logo"]
									? typeof errors["logo"] === "object" && errors["logo"]["url"]
										? errors["logo"]["url"].toString()
										: "画像をアップロードしてください"
									: undefined
							}
							id={`${concept.type}-logo-resize-area`}
							label={
								"ロゴマーク（1MB以下で登録してください）アップロードした画像の右下にカーソルを合わせ、⇔を動かすことで画像の大きさを調整できます"
							}
							data={concept.logo}
							onChange={(file) => onChangeFile(concept.type, "logo", file)}
							onResized={(width) => onResized(concept.type, "logo", width)}
							onDelete={() => onDeleteFile(concept.type, "logo")}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col md={11} className={"m-3"}>
						<Alert variant={"warning"}>
							<li>
								「印象に残った点」用のコンセプト要素数（「キーメッセージ」「ブランド名・商品名」含む）は
								<b>
									{sentenceConfig.minCount}〜{sentenceConfig.maxCount}
								</b>
								で設定してください。（{sentenceConfig.indicationCount}要素までが望ましい）
							</li>
							<li>
								1要素あたり
								<b>
									{sentenceConfig.min}〜{sentenceConfig.max}
								</b>
								文字で設定してください。
							</li>
							<li>
								文字数の合計は
								<b>
									{sentenceTotalConfig.min}〜{sentenceTotalConfig.max}
								</b>
								文字で設定してください。（{sentenceTotalConfig.indication}文字程度が望ましい。）
							</li>
						</Alert>
					</Col>
				</Row>
				<Row>
					<Col md={{ offset: 2, span: 9 }}>
						現在の文字数：
						{CountView}
					</Col>
					{sentenceErrors && typeof sentenceErrors === "string" && (
						<Col md={12}>
							<Feedback type={"invalid"} style={{ display: "block", whiteSpace: "pre-wrap" }}>
								{sentenceErrors}
							</Feedback>
						</Col>
					)}
				</Row>
				<CountableTextarea
					error={
						sentenceErrors && typeof sentenceErrors === "object" && sentenceErrors[0] ? sentenceErrors[0] : undefined
					}
					label={"キーメッセージ　要素１"}
					value={concept.sentences[0].sentence || ""}
					onChange={(v) => onChangeBoldArea(0, v)}
				/>
				<CountableTextarea
					error={
						sentenceErrors && typeof sentenceErrors === "object" && sentenceErrors[1] ? sentenceErrors[1] : undefined
					}
					label={"キーメッセージ　要素2（任意）"}
					value={concept.sentences[1].sentence || ""}
					onChange={(v) => onChangeBoldArea(1, v)}
				/>
				<CountableTextarea
					error={
						sentenceErrors && typeof sentenceErrors === "object" && sentenceErrors[2] ? sentenceErrors[2] : undefined
					}
					label={"ブランド名・商品名（商品名が決まっていない場合、カテゴリ名）"}
					value={concept.sentences[2].sentence || ""}
					onChange={(v) => onChangeBoldArea(2, v)}
				/>
				<Row>
					<Col md={12}>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col md={3}>コンセプト本文</Col>
				</Row>
				<Row>
					<Col md={12}>
						<MultipleText
							errors={multipleSentenceErrors}
							stepTitle={{ prefix: "要素" }}
							sentenceOptions={{ ...sentenceConfig }}
							hasRow
							hasAddEvent
							values={concept.sentences.filter((s) => !s.bold).map((s) => s.sentence)}
							onChange={onChangeSentences}
							max={sentenceConfig.maxCount - 2}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<ImageResizableInput
							error={
								errors && errors["package"]
									? typeof errors["package"] === "object" && errors["package"]["url"]
										? errors["package"]["url"].toString()
										: "画像を選択してください。"
									: undefined
							}
							label={
								<>
									パッケージ画像（任意）※アップロードした画像の右下にカーソルを合わせ、⇔を動かすことで画像の大きさを調整できます。
									<Help
										id={"package"}
										explain={
											<>
												パッケージ画像は、規定に合致している場合のみ、任意で挿入します。規定はガイドラインをご確認ください。
												<br />
												設定する場合は、全コンセプトに設定してください。1MB以下で登録してください。
											</>
										}
									/>
								</>
							}
							id={`${concept.type}-package-resize-area`}
							data={concept.package}
							onChange={(file) => onChangeFile(concept.type, "package", file)}
							onResized={(width) => onResized(concept.type, "package", width)}
							onDelete={() => onDeleteFile(concept.type, "package")}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						価格・容量設定
						<Help id={"sku"} explain={"ここで提示するSKUが、ポテンシャル需要予測の対象になります。"} />
					</Col>
					{errors && errors["sku"] && typeof errors["sku"] === "string" && (
						<Col md={10} className={"m-3"}>
							<Alert variant={"danger"}>{errors["sku"]}</Alert>
						</Col>
					)}
					<Col md={11}>
						<Alert variant={"warning"}>
							SKU説明欄には、
							<br />
							【1SKUのみの場合:容量】
							<br />
							【複数SKUの場合: SKU種類＋容量　（例：マイルドタイプ　60mL）】
							<br />
						</Alert>
					</Col>
					<Col md={12}>
						<MultipleSkuForm
							errors={errors && errors["sku"] && typeof errors["sku"] !== "string" ? errors["sku"] : undefined}
							skuItems={concept.sku}
							onChangeSku={(...arg) => onChangeSku(concept.type, ...arg)}
							onAddSku={(index) => onAddSku(concept.type, index)}
							onDeleteSku={(index) => onDeleteSku(concept.type, index)}
							setTaxIncluded={(index) => setTaxIncluded(concept.type, index)}
						/>
					</Col>
				</Row>
			</div>
			<ConceptViewer concept={concept} />
		</FormBlock>
	);
});
