import * as React from "react";
import { reducer, initState } from "../../../reducers/report/download";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { get, fileDownloadStream, makeError } from "../../../lib/request";
import { useParams } from "react-router-dom";
import { ReportDownloadPage, DownloadType } from "../../../components/pages/report/download";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { GetJobResponse } from "../../../../../server/types/request/job";
import { endpoint } from "../../../../../server/router/endpoint";

export const DownloadReportContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info } = state;
	const { jobId } = useParams<{ jobId: string }>();
	const onDownload = React.useCallback(
		(type: DownloadType) => {
			dispatch({ type: "changeLoading", payload: true });
			fileDownloadStream(`/api/download/${type}/${jobId}`, `${type}.csv`)
				.then(() => {
					dispatch({ type: "changeLoading", payload: false });
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		},
		[jobId],
	);
	React.useEffect(() => {
		get<GetJobResponse>(`${endpoint.jobDetail}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	const { job } = state;
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<ReportDownloadPage onDownload={onDownload} loginAccount={loginAccount} job={job} />
		</>
	);
};
