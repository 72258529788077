import * as React from "react";
import { FormControl } from "react-bootstrap";
import { reducer } from "./reducer";
import { initState } from "./state";

import { faChevronLeft, faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export type TextSearchProps = {
	children: React.ReactNode;
};

const SeachArea = styled.div`
	.active {
		background-color: yellow;
	}
	position: relative;
`;
const SearchInputArea = styled.div`
	position: fixed;
	right: 20px;
	border: 1px solid #ddd;
	padding: 1px;
	z-index: 100;
	background: white;
	input {
		width: 180px;
		display: inline-block;
		border: none;
		border-right: 1px solid #ddd;
	}
`;

const ResultArea = styled.div`
	width: 45px;
	text-align: center;
	display: inline-block;
`;

const IconButton = styled.div`
	display: inline-block;
	width: 30px;
	height: 30px;
	padding: 3px;
	text-align: center;
	cursor: pointer;
	&:hover {
		background-color: #ddd;
	}
`;

const ButtonArea = styled.div`
	display: inline-block;
`;

const scrollElem = (elem: Element) => {
	const top = elem.getBoundingClientRect().top + window.scrollY - 100;
	console.log(top);
	window.scrollTo({
		top,
		behavior: "smooth",
	});
};
const clearFilterd = (elems: NodeListOf<Element>) => {
	Array.from(elems).forEach((elem) => elem.classList.remove("active"));
};

export const TextSearch = React.memo<TextSearchProps>(({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const ref = React.useRef<HTMLDivElement>(null);
	const handleClear = React.useCallback(() => {
		dispatch({ type: "clear" });
		clearFilterd(ref.current.querySelectorAll("table div"));
	}, [ref]);
	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			dispatch({ type: "change", payload: e.target.value ?? "" });
			if (state.searched) clearFilterd(ref.current.querySelectorAll("table div"));
		},
		[state.searched, ref],
	);
	const handleNext = React.useCallback(() => {
		if (!state.searched || state.searched.targets.length === 0) return;
		const payload = state.searched.index === state.searched.targets.length - 1 ? 0 : state.searched.index + 1;
		dispatch({ type: "page", payload });
		scrollElem(state.searched.targets[payload]);
	}, [state.searched]);
	const handlePrev = React.useCallback(() => {
		if (!state.searched || state.searched.targets.length === 0) return;
		const payload = state.searched.index === 0 ? state.searched.targets.length - 1 : state.searched.index - 1;
		dispatch({ type: "page", payload });
		scrollElem(state.searched.targets[payload]);
	}, [state.searched]);
	const handleSearch = React.useCallback(() => {
		if (!ref && !ref.current) return console.log("none");
		if (!state.text) return handleClear();
		if (state.searched && state.text === state.searched.text) return handleNext();
		const filters = Array.from(ref.current.querySelectorAll("table div")).filter((elem) => {
			if (elem.childElementCount || RegExp(state.text).test(elem.textContent) === false) {
				elem.classList.remove("active");
				return false;
			}
			elem.classList.add("active");
			return true;
		});
		console.log(filters);
		dispatch({ type: "search", payload: { targets: filters, index: filters.length ? 0 : -1, text: state.text } });
		if (filters.length === 0) return;
		scrollElem(filters[0]);
	}, [state.text, state.searched, handleClear, handleNext]);
	const handleSearchEnter = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.nativeEvent.isComposing || e.key !== "Enter") return;
			handleSearch();
		},
		[handleSearch],
	);
	console.log(state.searched);
	return (
		<SeachArea className="text-seach">
			<SearchInputArea>
				<FormControl
					placeholder="ページ内検索..."
					onChange={onChange}
					value={state.text}
					onKeyDown={handleSearchEnter}
				/>
				<IconButton onClick={handleSearch}>
					<FontAwesomeIcon icon={faSearch} />
				</IconButton>
				<ResultArea>
					{state.searched && (
						<>
							{state.searched.index + 1} / {state.searched.targets.length}
						</>
					)}
				</ResultArea>
				<ButtonArea>
					<IconButton onClick={handlePrev}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</IconButton>
					<IconButton onClick={handleNext}>
						<FontAwesomeIcon icon={faChevronRight} />
					</IconButton>
					<IconButton onClick={handleClear}>×</IconButton>
				</ButtonArea>
			</SearchInputArea>
			<div ref={ref} className="text-seach-area">
				{children}
			</div>
		</SeachArea>
	);
});
