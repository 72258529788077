import type { BetweenDateValues } from "../../../../../../react-components/lu-component/src/index";
import type { AdCopyJobDownloadAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	filteredValue: BetweenDateValues;
	info: MessageInfo;
	loading: boolean;
};

export const initState: State = {
	filteredValue: { from: undefined, to: undefined },
	info: {
		isSuccess: true,
		message: "",
	},
	loading: false,
};

export const reducer = (state: State, action: AdCopyJobDownloadAction): State => {
	switch (action.type) {
		case "changeFiltered":
			return {
				...state,
				filteredValue: action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
