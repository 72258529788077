import { AggregationLabel } from "@analytics/types";
import { GetAggregateResponse } from "../../../../../server/types/request/aggregate";
import { AggregateViewAction } from "../../../types/actions";
import { MessageInfo } from "../../../types/info";
import { Cross } from "@brix/core";

export type State = GetAggregateResponse & {
	loading: boolean;
	info: MessageInfo;
	crossData: Cross[];
	label: AggregationLabel;
};

export const initState: State = {
	crossAggregate: undefined,
	label: undefined,
	crossData: [],
	data: [],
	loading: true,
	info: { isSuccess: true, message: "" },
};

export const reducer = (state: State, action: AggregateViewAction): State => {
	switch (action.type) {
		case "convertedCrosses":
			return {
				...state,
				crossData: action.payload,
				loading: false,
			};
		case "loadAggreagteJob":
			return {
				...state,
				...action.payload,
				loading: false,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
