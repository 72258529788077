import * as React from "react";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../label/ad-copy/activity";
import { Gender } from "../../../../../../../server/models/shared";
import type { Category } from "../../../../../../../server/models/category";

type Props = {
	category: Category;
};

export const AdCopyGeneralConditions = React.memo((props: Props) => {
	const { category } = props;

	return (
		<table>
			<tbody>
				<tr>
					<td>性別</td>

					<td>：</td>

					<td>{Gender[category.gender]}</td>
				</tr>

				<tr>
					<td>年齢</td>

					<td>：</td>

					<td>15～69歳</td>
				</tr>

				<tr>
					<td>{adCopyStrategyTargetGroupLabels.category}</td>

					<td>：</td>

					<td>{category.name}</td>
				</tr>
			</tbody>
		</table>
	);
});
