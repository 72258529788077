import * as React from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyPreviewResponse } from "../../../../../server/types/request/ad-copy/preview";
import { AdCopyPreviewPage } from "../../../components/pages/ad-copy/preview";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { get, makeError } from "../../../lib/request";
import { initState, reducer } from "../../../reducers/ad-copy/job/preview";

export const AdCopyPreviewContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading, ...previewPageProps } = state;

	const { jobId } = useParams<{ jobId: string }>();

	React.useEffect(() => {
		get<GetAdCopyPreviewResponse>(`${adCopyEndpoint.preview}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadPreview" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} />

			<AdCopyPreviewPage {...previewPageProps} />
		</>
	);
};
