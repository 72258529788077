import * as React from "react";
import styled from "styled-components";
import { GraphCard } from "..";
import { ElementEvaluation } from "../../../../../../../server/models/graph/element-evaluation";
import { ReportTitle } from "../../parts/report-title";
import { ImpressionEvaluationGraph } from "./graph";
import { Job } from "../../../../../../../server/models/job";
import { getAnalyticsVersion, overTagetVersion } from "../../../../../lib/action-standard";

export type ImpressionEvaluationProps = {
	job: Job;
	evaluations: ElementEvaluation[];
};

export const TitleBox = styled.div`
	font-size: 0.9em;
	border: 1px solid #aaa;
	display: flex;
	align-items: center;
	width: 90px;
	height: 90px;
	padding: 5px;
	text-align: center;
	div {
		text-align: center;
		width: 100%;
	}
`;
export const Small = styled.span`
	font-size: 0.8rem;
	font-weight: normal;
`;

export const ImpressionEvaluation = React.memo((props: ImpressionEvaluationProps) => {
	const overVersin5 = React.useMemo(
		() => overTagetVersion(getAnalyticsVersion(props.job.endDatetime), "v5"),
		[props.job],
	);
	const impressionEvaluationItems = React.useMemo(() => {
		if (overVersin5) return ["他の商品とは違っている", "新しさを感じる"];
		return ["新しさを感じる", "他の商品とは違っている"];
	}, [overVersin5]);
	const evaluations = React.useMemo(() => {
		const map = props.evaluations.reduce((a, b) => {
			if (b.target === "general") return a;
			if (!a.has(b.target)) a.set(b.target, []);
			const tmp = a.get(b.target) ?? [];
			tmp.push(b);
			return a;
		}, new Map<"strategy" | "category", ElementEvaluation[]>());
		return Array.from(map) as ["strategy" | "category", ElementEvaluation[]][];
	}, [props.evaluations]);
	if (evaluations.length === 0) return <></>;
	return (
		<>
			<ReportTitle>
				印象評価　（{overVersin5 ? "差別性・新規性評価" : "新規性・差別性評価"}） <Small>*5段階評価の平均値</Small>
			</ReportTitle>
			{impressionEvaluationItems.map((item, index) => {
				return (
					<GraphCard key={`impression-evaluation-item-${index}`}>
						{" "}
						<div>
							<b>{item}</b>
						</div>
						{evaluations.map(([target, evs]) => {
							return (
								<table key={`impression-evaluation-${index}-${target}`}>
									<tbody>
										<tr>
											<td>
												<TitleBox>
													{target === "category" ? (
														<div>
															カテゴリ
															<br />
															ターゲット
															<br />
															グループ
														</div>
													) : (
														<div>
															戦略
															<br />
															ターゲット
															<br />
															グループ
														</div>
													)}
												</TitleBox>
											</td>
											<td>
												<ImpressionEvaluationGraph target={target} evaluationItemLabel={item} evaluations={evs} />
											</td>
										</tr>
									</tbody>
								</table>
							);
						})}
					</GraphCard>
				);
			})}
		</>
	);
});
