import React, { memo } from "react";
import { Button } from "react-bootstrap";

type Props = {
	onCreateNewInfo: () => void;
	onClear: () => void;
	onReset: () => void;
};

const _InfoEditorFooter = (props: Props) => {
	const { onCreateNewInfo, onClear, onReset } = props;

	return (
		<div className="d-flex justify-content-between">
			<div>
				<Button variant="outline-secondary" onClick={onClear} className="me-2">
					クリア
				</Button>
				<Button variant="outline-secondary" onClick={onReset}>
					リセット
				</Button>
			</div>
			<div>
				<Button variant="secondary" onClick={onCreateNewInfo}>
					更新
				</Button>
			</div>
		</div>
	);
};

export const InfoEditorFooter = memo(_InfoEditorFooter);
