import * as React from "react";
import { OutlinePanel, OutlinePanelBody, OutlineSubTitle, IndentArea, OutlineTable } from "../../";
import styled from "styled-components";
import { Sample } from "../../../../../../../../server/models/graph/outline";
import { ConceptType, ConceptKey } from "../../../../../../../../server/models/concept";
import { targetGroup } from "../../../../../../../../server/models/graph/target-group";
import { Button } from "react-bootstrap";
import { jobLabels } from "../../../../../../../../label/job";
import { Job } from "../../../../../../../../server/models/job";

const SampleSizeTable = styled.table`
	tr td:not(:last-child),
	tr th:not(:last-child) {
		border-right: 1px solid #999;
	}
	td,
	th {
		padding: 2px 10px;
		border-bottom: 1px solid #999;
		text-align: right;
		min-width: 80px;
	}
	th.names-area {
		text-align: center;
	}
	td.names-area {
		text-align: left;
	}
	td.missing-sample {
		color: red;
		font-weight: bold;
	}
`;
const TargetPanelRow = React.memo((props: { value: string; label: string }) => {
	const { label, value } = props;
	return (
		<tr className={"panel-category"}>
			<td>□</td>
			<td>{label}</td>
			<td className={"colon"}>：</td>
			<td>{value}</td>
		</tr>
	);
});

export type SamplePanelProps = {
	samples: Sample[];
	job: Job;
	benchmarkName?: string;
	onViewQuotaPage: () => void;
};
export const SamplePanel = React.memo((props: SamplePanelProps) => {
	const { samples, job, onViewQuotaPage, benchmarkName } = props;
	const keyMap = React.useMemo<Set<ConceptKey>>(() => {
		const tmp = samples.reduce((a, b) => {
			// 再テストの場合は現行品・競合品は調査対象として概要では表示しない。
			const tmp = Object.keys(b.sampleSize);
			const keys = job.retestJobId ? tmp.filter((v) => v !== "benchmark" && v !== "currentProduct") : tmp;
			return a.concat(keys);
		}, []);
		return new Set(tmp);
	}, [samples, job]);
	const keys = React.useMemo<ConceptKey[]>(() => {
		return Array.from(keyMap);
	}, [keyMap]);
	return (
		<>
			<OutlinePanel className={"panel"}>
				<OutlinePanelBody>
					<OutlineSubTitle>
						□ 有効サンプルサイズ
						<Button variant={"outline-secondary"} size={"sm"} onClick={onViewQuotaPage} style={{ marginLeft: "10px" }}>
							詳細確認
						</Button>
					</OutlineSubTitle>
					<IndentArea indent={3}>
						<SampleSizeTable>
							<thead>
								<tr className={"bordered"}>
									<th></th>
									{keys.map((key, index) => (
										<th className={"names-area"} key={`head-${index}`}>
											{ConceptType[key]}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{samples.map((sample) => (
									<tr key={`sample-size-${sample.target}`} className={"bordered"}>
										<td className={"names-area"}>{targetGroup[sample.target]}</td>
										{keys.map((key, index) => (
											<td
												key={`sample-size-${sample.target}-${index}`}
												className={sample.sampleSize[key] && sample.sampleSize[key] < 30 ? "missing-sample" : ""}
											>
												{sample.sampleSize[key] ? sample.sampleSize[key] + "s" : "-"}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</SampleSizeTable>
					</IndentArea>
				</OutlinePanelBody>
			</OutlinePanel>
			<OutlinePanel className={"panel"}>
				<OutlinePanelBody>
					<OutlineTable>
						<tbody>
							<TargetPanelRow label={jobLabels.hasTwoTest} value={keyMap.has("test2") ? "2案" : "1案"} />
							<TargetPanelRow
								label={"競合商品"}
								value={keyMap.has("benchmark") ? `あり（${benchmarkName || job.benchmarkName}）` : "なし"}
							/>
							<TargetPanelRow label={"現行商品"} value={keyMap.has("currentProduct") ? "あり" : "なし"} />
							<TargetPanelRow label={"実査日程"} value={`${job.startDatetime} 〜 ${job.endDatetime}`} />
						</tbody>
					</OutlineTable>
				</OutlinePanelBody>
			</OutlinePanel>
		</>
	);
});
