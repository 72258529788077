import type { Option } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyActivity } from "../../../../../../server/models/ad-copy/activity";
import type { SearchValues } from "../../../../components/parts/search";
import type { AdCopyActivityListAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	accounts: AccountWithoutPassword[];
	activities: (AdCopyActivity & { canDelete: boolean })[];
	brandOptions: Option[];
	categoryOptions: Option[];
	info: MessageInfo;
	loading: boolean;
	searches: SearchValues;
	yearOptions: Option[];
};

export const initState: State = {
	accounts: [],
	activities: [],
	brandOptions: [],
	categoryOptions: [],
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	searches: {},
	yearOptions: [],
};

const changeSearch = ({
	accounts,
	name,
	searchValues,
	value,
}: {
	accounts: AccountWithoutPassword[];
	name: string;
	searchValues: SearchValues;
	value: number | string;
}) => {
	if (value) {
		const tmp = { ...searchValues, [name]: value };

		if (name === "brandId" && searchValues["accountId"]) {
			const target = accounts.find((account) => account._id === searchValues["accountId"]);

			if (target && target.brand && !target.brand.includes(value.toString())) {
				delete tmp["accountId"];
			}
		}

		return tmp;
	}

	const tmp = { ...searchValues };

	delete tmp[name];

	return tmp;
};

export const reducer = (state: State, action: AdCopyActivityListAction): State => {
	switch (action.type) {
		case "loadActivity":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "deleteActivity":
			return {
				...state,
				activities: state.activities.filter((activity) => {
					return activity._id?.toString() !== action.payload;
				}),
				info: {
					isSuccess: true,
					message: `削除が完了しました。`,
				},
				loading: false,
			};

		case "setActivity":
			return {
				...state,
				activities: action.payload,
				loading: false,
			};

		case "changeSearch":
			return {
				...state,
				searches: changeSearch({
					accounts: state.accounts,
					name: action.payload.name,
					searchValues: state.searches,
					value: action.payload.value,
				}),
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
