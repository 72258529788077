import type { OutputTableData } from "./outputTableData";
import { QuestionData } from "./questionData";

export const summaryOfResultTypeMap = {
	// annualPurchase: "年間購買個数",
	differentiationAndONS: "差別性、情報検索意向、購買意向ONS",
};
export type SummaryOfResultOutputDataType = keyof typeof summaryOfResultTypeMap;
export type SummaryOfResultQuestionData = {
	[key in SummaryOfResultOutputDataType]: QuestionData[];
};
export type SummaryOfResultOutputData = {
	[key in SummaryOfResultOutputDataType]: OutputTableData;
};

export const detailsOfResultTypeMap = {
	purchaseIntentions: "価格呈示前使用意向、購入意向",
	evaluationItems: "項目別評価（平均）",
	catchCopyImpressions: "キャッチコピー印象度（TOP2）",
	catchCopyAttractions: "キャッチコピー魅力度（TOP2）",
	keyVisualEvaluations: "キービジュアル評価",
};

export type DetailsOfResultOutputDataType = keyof typeof detailsOfResultTypeMap;
export type DetailsOfResultQuestionData = {
	[key in Exclude<DetailsOfResultOutputDataType, "keyVisualEvaluations">]: QuestionData[];
} & {
	keyVisualEvaluations?: QuestionData[];
};
export type DetailsOfResultOutputData = {
	[key in Exclude<DetailsOfResultOutputDataType, "keyVisualEvaluations">]: OutputTableData;
} & {
	keyVisualEvaluations?: OutputTableData;
};

export type ResultDetailsQuestionData = SummaryOfResultQuestionData & DetailsOfResultQuestionData;

export type ResultDetailsOutputData = SummaryOfResultOutputData & DetailsOfResultOutputData;
