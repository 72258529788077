import type { ObjectId } from "mongodb";
import * as React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import { adCopyActivityLabels } from "../../../../../../label/ad-copy/activity";
import type { Option } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyActivity } from "../../../../../../server/models/ad-copy/activity";
import { AdCopyActivityRow } from "./ad-copy-activity-row";

const ListTableWrap = styled.div`
	& table td {
		max-width: 200px;
		vertical-align: middle;
	}
`;

export type AdCopyActivityListProps = {
	accountOptions: Option[];
	activities: (AdCopyActivity & { canDelete: boolean })[];
	brandOptions: Option[];
	categoryOptions: Option[];
	loginAccount: AccountWithoutPassword;
	onDeleteActivity: (id: ObjectId | string) => void;
	onEditPage: (id?: ObjectId | string) => void;
	onJobListPage: (id: ObjectId | string) => void;
	onStatusPage: (id: ObjectId | string) => void;
};

export const AdCopyActivityList = React.memo((props: AdCopyActivityListProps) => {
	const { accountOptions, activities, brandOptions, ...activityRowProps } = props;

	const mapAccounts = React.useMemo<Record<string, string>>(() => {
		return accountOptions.reduce((a, b) => {
			return { ...a, [b.value]: b.label };
		}, {});
	}, [accountOptions]);

	const mapBrands = React.useMemo<Record<string, string>>(() => {
		return brandOptions.reduce((a, b) => {
			return { ...a, [b.value]: b.label };
		}, {});
	}, [brandOptions]);

	const ActivityRows = React.useMemo(() => {
		return activities.map((activity) => (
			<AdCopyActivityRow
				key={`activity-${activity._id}`}
				activity={activity}
				mapAccounts={mapAccounts}
				mapBrands={mapBrands}
				{...activityRowProps}
			/>
		));
	}, [activities, mapAccounts, mapBrands, activityRowProps]);

	return (
		<ListTableWrap>
			<Table>
				<thead>
					<tr className="text-center">
						<th>{adCopyActivityLabels.no}</th>

						<th style={{ width: "15em" }}>アクティビティ登録者</th>

						<th>{adCopyActivityLabels.brandId}</th>

						<th style={{ width: "14em" }}>{adCopyActivityLabels.category}</th>

						<th style={{ width: "6em" }}>上市時期</th>

						<th style={{ width: "12em" }}>{adCopyActivityLabels.productType}</th>

						<th style={{ width: "8em" }}>メモ</th>

						<th style={{ width: "12em" }} />

						<th style={{ width: "5.5em" }}>ステータス</th>
					</tr>
				</thead>

				<tbody>{ActivityRows}</tbody>
			</Table>
		</ListTableWrap>
	);
});
