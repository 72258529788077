import * as React from "react";
import { Button, Modal, ButtonProps } from "react-bootstrap";
import { Column } from "../../types/column";
import { CustomForm } from "../custom-form";
import { Data, ErrorObject } from "../form-input-groups";

export type EditFormProps = {
	doUpdate?: boolean;
	onCloseEvent?: () => void;
	size?: ButtonProps["size"];
	data?: Data;
	errors?: ErrorObject;
	columns: Column[][];
	variant?: ButtonProps["variant"];
	onSubmit: (data: Data, cb?: () => void) => void;
};
export const EditForm = React.memo((props: EditFormProps) => {
	const { doUpdate, columns, onSubmit, data, size, errors, onCloseEvent, variant } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const onClose = React.useCallback(() => {
		if (onCloseEvent) onCloseEvent();
		setShow(false);
	}, [onCloseEvent]);
	const buttonText = React.useMemo(() => (doUpdate ? "更新" : "新規作成"), [doUpdate]);
	const buttonVariant = React.useMemo(
		() => (variant || doUpdate ? "outline-success" : "outline-primary"),
		[doUpdate, variant],
	);
	const onSubmitClick = React.useCallback(
		(data: Data) => {
			onSubmit(data, () => setShow(false));
		},
		[onSubmit],
	);
	return (
		<>
			<Button size={size} style={{ margin: "3px" }} variant={buttonVariant} onClick={() => setShow(true)}>
				{buttonText}
			</Button>
			<Modal size="lg" show={show} onHide={onClose}>
				<Modal.Header closeButton>
					<Modal.Title>{buttonText}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<CustomForm
						errors={errors}
						data={data}
						columns={columns}
						onSubmit={onSubmitClick}
						buttonText={buttonText}
						variant={buttonVariant}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
});
