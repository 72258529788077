import "react-resizable/css/styles.css";

import * as React from "react";
import { Alert, Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { ResizableBox } from "react-resizable";

import { adCopyMb } from "../../../../lib/ad-copy/common";

const minWidth = 10;
const maxWidth = 1000;

type Props = {
	disabled?: boolean;
	error: string | undefined;
	imageUrl: string;
	label: React.ReactNode;
	// NOTE: とりあえず10MB固定。このコンポーネントをほかのところでも使うなら number とか optional にする
	maxMb: 10;
	onChangeImageUrl: (value: string) => void;
	onMbLimitError: () => void;
};

export const ImageResizer = React.memo((props: Props) => {
	const { disabled, error, imageUrl, label, maxMb, onChangeImageUrl, onMbLimitError } = props;

	const [dimensions, setDimensions] = React.useState({ height: 300, width: 300 });
	const [aspectRatio, setAspectRatio] = React.useState(1);

	React.useEffect(() => {
		const img = new Image();

		img.src = imageUrl;

		img.onload = () => {
			const width = img.width > maxWidth ? maxWidth : img.width;
			const height = img.width > maxWidth ? (maxWidth / img.width) * img.height : img.height;

			setAspectRatio(width / height);
			setDimensions({ height, width });
		};
	}, [imageUrl]);

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (file) {
			if (maxMb * adCopyMb < file.size) {
				onMbLimitError();

				return;
			}

			const reader = new FileReader();

			reader.onload = (event) => {
				const newImageUrl = event.target?.result as string;

				onChangeImageUrl(newImageUrl);

				const img = new Image();

				img.src = newImageUrl;

				img.onload = () => {
					let width = img.width;
					let height = img.height;

					if (width > maxWidth) {
						height = (maxWidth / width) * height;
						width = maxWidth;
					}

					setAspectRatio(width / height);
					setDimensions({ height, width });

					handleResize(width, height, newImageUrl);
				};
			};

			reader.readAsDataURL(file);
		}
	};

	const handleResize = (width: number, height: number, url: string = imageUrl) => {
		if (!url) {
			return;
		}

		const img = new Image();

		img.src = url;

		img.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");

			if (!ctx) {
				return;
			}

			canvas.width = width;
			canvas.height = height;

			ctx.drawImage(img, 0, 0, width, height);

			canvas.toBlob((blob) => {
				if (blob) {
					const resizedUrl = URL.createObjectURL(blob);

					onChangeImageUrl(resizedUrl);
				}
			}, "image/png");
		};
	};

	return (
		<FormGroup as={Row}>
			{label && (
				<Col md={12}>
					<FormLabel>{label}</FormLabel>
				</Col>
			)}

			<Col md={11}>
				<Row>
					{!!error && (
						<Col md={12}>
							<Feedback style={{ display: "block" }} type="invalid">
								{error}
							</Feedback>
						</Col>
					)}

					<Col md={12} style={{ marginBottom: "12px" }}>
						<input
							accept="image/*"
							className="form-control"
							disabled={disabled}
							onChange={handleImageChange}
							style={disabled ? { opacity: 0.5 } : undefined}
							type="file"
						/>
					</Col>

					<Col md={12}>
						{imageUrl ? (
							<div>
								<ResizableBox
									height={dimensions.height}
									lockAspectRatio
									maxConstraints={[maxWidth, maxWidth / aspectRatio]}
									minConstraints={[minWidth, minWidth / aspectRatio]}
									onResize={(e, data) => {
										setDimensions({ height: data.size.height, width: data.size.width });
									}}
									onResizeStop={(e, data) => {
										handleResize(data.size.width, data.size.height);
									}}
									resizeHandles={disabled ? [] : ["se"]}
									width={dimensions.width}
								>
									<img alt="image" src={imageUrl} style={{ height: "100%", width: "100%" }} />
								</ResizableBox>
							</div>
						) : (
							<Alert variant="dark">設定なし</Alert>
						)}
					</Col>
				</Row>
			</Col>

			<Col md={1}>
				{imageUrl && (
					<Button
						disabled={disabled}
						onClick={() => {
							onChangeImageUrl("");
						}}
						variant="danger"
					>
						×
					</Button>
				)}
			</Col>
		</FormGroup>
	);
});
