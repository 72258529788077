import * as React from "react";
import { Concept } from "../../../../../server/models/concept";
import { ConceptPreview } from "../concept/preview";
import { FloatingMenu } from "../../parts/floating-menu";
import styled from "styled-components";

const Iframe = styled.iframe`
	width: 100%;
	border: 0px;
	height: 600px;
`;

export type PreviewProps = {
	concepts: Concept[];
	url: string;
};
export const PreviewPage = React.memo((props: PreviewProps) => {
	const { concepts, url } = props;
	const surveyPreviewRef = React.useRef<HTMLDivElement>();
	const conceptPreviewRef = React.useRef<HTMLDivElement>();
	const onSurveyPreview = React.useCallback(() => {
		if (!conceptPreviewRef.current) return;
		window.scroll(0, surveyPreviewRef.current.offsetTop);
	}, [surveyPreviewRef]);
	const onConceptPreview = React.useCallback(() => {
		if (!conceptPreviewRef.current) return;
		window.scroll(0, conceptPreviewRef.current.offsetTop);
	}, [conceptPreviewRef]);
	const menus = React.useMemo(
		() => [
			{ label: "調査画面プレビュー", event: onSurveyPreview },
			{ label: "コンセプトプレビュー", event: onConceptPreview },
		],
		[onSurveyPreview, onConceptPreview],
	);
	return (
		<>
			<FloatingMenu menus={menus} />
			<div className="m-3" id={"survey-preview"} ref={surveyPreviewRef}>
				<div className="h3">■調査画面プレビュー</div>
			</div>
			<Iframe src={url} />
			<div className="m-3" id={"concept-preview"} ref={conceptPreviewRef}>
				<div className="h3">■コンセプトプレビュー</div>
			</div>
			<ConceptPreview concepts={concepts} />
		</>
	);
});
