import * as React from "react";
import { reducer, initState } from "../../../reducers/report/exective";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { get, makeError } from "../../../lib/request";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { useNavigate, useParams } from "react-router-dom";
import { GetExectiveReportResponse } from "../../../../../server/types/request/report/exective";
import { ExectiveReportPage } from "../../../components/pages/report/exective-report";

import { clientEndpoint } from "../../../routes/endpoint";
import { ReportBigTitle } from "../../../components/pages/report/parts/report-title";
import { GetEvaluationReportResponse } from "../../../../../server/types/request/report/evaluation";

export const ExectiveReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, goalSalesVolume, data, evaluations } = state;
	const navigate = useNavigate();
	const { jobId, activityId } = useParams<{
		jobId: string;
		activityId: string;
	}>();
	React.useEffect(() => {
		Promise.all([
			get<GetExectiveReportResponse>(`${endpoint.exectiveReport}/${jobId}`)
				.then((response) => {
					dispatch({ type: "loadData", payload: response.data });
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				}),
			get<GetEvaluationReportResponse>(`${endpoint.evaluationReport}/${jobId}`)
				.then((response) => {
					dispatch({ type: "loadEvaluation", payload: response.data.data });
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				}),
		]).then(() => {
			dispatch({ type: "changeLoading", payload: false });
		});
	}, [jobId]);
	const onSimulationPage = React.useCallback(
		() => navigate(replaceEndpointUrl(clientEndpoint.simulationReport, { jobId, activityId })),
		[activityId, jobId, navigate],
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			{goalSalesVolume &&
				data.map((d, index) => {
					return (
						<React.Fragment key={`exective-${index}`}>
							<ReportBigTitle>
								{index === 0 ? "エグゼクティブサマリー" : "前回テストのエグゼクティブサマリー"}（{d.job.jobNum}）
							</ReportBigTitle>
							<ExectiveReportPage
								{...d}
								goalSalesVolume={goalSalesVolume}
								onSimulationPage={onSimulationPage}
								evaluations={evaluations}
							/>
						</React.Fragment>
					);
				})}
		</>
	);
};
