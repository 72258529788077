import { JobWithSurveyStatus } from "../../../../../server/models/job";
import { MessageInfo } from "../../../types/info";
import { JobAllAction } from "../../../types/actions";
import { SurveyStatus } from "../../../../../server/models/status";
import { GetJobAllResponse } from "../../../../../server/types/request/job";

export type State = GetJobAllResponse & {
	searches: { [key in keyof JobWithSurveyStatus]?: JobWithSurveyStatus[key] };
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	searches: {},
	data: [],
	accountOptions: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: JobAllAction): State => {
	switch (action.type) {
		case "loadJobs":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeSearches":
			return {
				...state,
				searches: { ...state.searches, [action.payload.name]: action.payload.value },
			};
		case "deleteJob":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: `削除が完了しました。`,
				},
				data: state.data
					.map((d) => {
						const jobs = d.jobs.filter((job) => {
							return job._id.toString() !== action.payload;
						});
						return { ...d, jobs };
					})
					.filter(({ jobs }) => jobs.length > 0),
			};
		case "changeJobStatus":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: `ステータスを「${SurveyStatus[action.payload.surveyStatus]}」に変更しました。`,
				},
				data: state.data.map((d) => {
					const jobs = d.jobs.map((job) => {
						if (job._id.toString() === action.payload.jobId) {
							return {
								...job,
								status: action.payload.status,
								surveyStatus: action.payload.surveyStatus,
							};
						}
						return job;
					});
					return { ...d, jobs };
				}),
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
