/*/
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

export const colors = scaleOrdinal(schemeCategory10).range();
/*/

import { ConceptLabel } from "../../../../../../server/models/concept";

export const colors = ["red", "blue", "green", "yellow", "black"];

export const evaluationColors: { [key in ConceptLabel | "ノーム"]: string } = {
	テスト品1: "red",
	テスト品2: "blue",
	競合品: "green",
	現行品: "darkorange",
	ノーム: "#955629",
};
