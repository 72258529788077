import * as React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import type { EventItem } from "../../../../../../../../react-components/lu-component/src/components/event-menu";
import { EventMenu } from "../../../../../../../../react-components/lu-component/src/components/event-menu";
import type {
	Data,
	ErrorObject,
} from "../../../../../../../../react-components/lu-component/src/components/form-input-groups";
import type { Column } from "../../../../../../../../react-components/lu-component/src/types/column";
import type { AdCopyJobWithSurveyStatus } from "../../../../../../../../server/models/ad-copy/job";

type Props = {
	columns: Column[][];
	data: Data[];
	errors?: ErrorObject;
	eventHeader?: string;
	events?: EventItem[];
	idName?: string;
	searches: { [key in keyof AdCopyJobWithSurveyStatus]?: AdCopyJobWithSurveyStatus[key] };
};

const PreArea = styled.div`
	max-width: 300px;
	white-space: pre;
	overflow-x: scroll;
	overflow-y: hidden;
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const AdCopyJobSearchListTable = React.memo((props: Props) => {
	const { columns, data, errors, eventHeader = "", events, idName = "_id", searches } = props;

	const header = React.useMemo(() => {
		return (
			<thead>
				<tr>
					{columns.map((cols, index) => {
						if (cols.every((col) => col.hide)) {
							return <React.Fragment key={`header-field-${index}`} />;
						}

						return (
							<th key={`header-field-${index}`}>
								{cols.map((col) => {
									if (col.hide) {
										return <React.Fragment key={`header-field-${index}-${col.name}`} />;
									}

									return <div key={`header-field-${index}-${col.name}`}>{col.label}</div>;
								})}
							</th>
						);
					})}

					{events && <th style={{ width: "200px" }}>{eventHeader}</th>}
				</tr>
			</thead>
		);
	}, [columns, eventHeader, events]);

	const body = React.useMemo(() => {
		return (
			<tbody>
				{data.map((d, dataIndex) => {
					const id = idName in d ? d[idName] : dataIndex.toString();

					return (
						<tr key={`datarow-${id}-${dataIndex}`}>
							{columns.map((cols, index) => {
								if (cols.every((col) => col.hide)) {
									return <React.Fragment key={`data-${id}-${dataIndex}-${index}`} />;
								}

								return (
									<td key={`data-${id}-${dataIndex}-${index}`}>
										{cols.map((col) => {
											if (col.hide) {
												return <React.Fragment key={`data-${id}-${dataIndex}-${col.name}`} />;
											}

											if (col.type === "events") {
												return (
													<EventMenu
														key={`data-${id}-${dataIndex}-${col.name}`}
														columns={columns}
														data={d}
														errors={errors}
														events={col.events}
														id={id}
													/>
												);
											}
											let value = col.name in d ? d[col.name] : "";

											if (value && col.type === "select") {
												const tmp = col.options.find((op) => op.value === value);

												if (tmp) {
													value = tmp.label;
												}
											} else if (col.type === "multiple-select" && value && Array.isArray(value)) {
												const filtered = col.options.filter((op) => value.includes(op.value)).map((op) => op.label);

												value = filtered.join(",");
											} else if (col.type === "checkbox") {
												if (value === true) {
													value = `${col.label}：●`;
												} else {
													value = `${col.label}：×`;
												}
											} else if (col.type === "custom") {
												value = col.view ? col.view(d) : value;
											}

											if (col.type === "textarea" || col.type === "readonly") {
												return (
													<PreArea key={`data-${id}-${dataIndex}-${index}-${col.name}`} className="wrap-area">
														{value}
													</PreArea>
												);
											}

											if (col.name === "jobNum" && searches.jobNum) {
												const searchVal: string = searches.jobNum;
												const startIdx: number = value.indexOf(searchVal);
												const endIdx: number = startIdx + searches.jobNum.length;

												if (startIdx >= 0 && endIdx >= 0) {
													return (
														<div key={`data-${id}-${dataIndex}-${index}-${col.name}`}>
															{value.slice(0, startIdx)}

															<span style={{ backgroundColor: "yellow" }}>{value.slice(startIdx, endIdx)}</span>

															{value.slice(endIdx, value.length)}
														</div>
													);
												}
											}

											return <div key={`data-${id}-${dataIndex}-${index}-${col.name}`}>{value}</div>;
										})}
									</td>
								);
							})}

							{events && (
								<td>
									<EventMenu columns={columns} data={d} errors={errors} events={events} id={id} />
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		);
	}, [columns, data, errors, events, idName, searches.jobNum]);

	return (
		<div>
			<Table>
				{header}

				{body}
			</Table>
		</div>
	);
});
