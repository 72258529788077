import * as React from "react";
import { Row, Col, Button, FormControl, Alert } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { NoMenuNavigationBar } from "../../parts/nomenu-navigation-bar";
import { getErrorMessage } from "../../../lib/error";
import { ErrorObject } from "../../../../../server/types/error";

export type PasswordResetMailPageProps = {
	onSubmit: (email: string) => void;
	errors?: ErrorObject;
};
export const PasswordResetMailPage = React.memo((props: PasswordResetMailPageProps) => {
	const { onSubmit, errors } = props;
	const [email, setEmail] = React.useState<string>("");
	const isDisabled = React.useMemo(() => !email, [email]);
	return (
		<>
			<NoMenuNavigationBar />
			<div className="m-3 clearfix">
				<div className="h3">■パスワード変更URL送付先</div>
				<Row className="mt-3">
					<Col md={{ span: 8, offset: 2 }}>
						<Alert variant={"info"}>
							登録されているメールアドレス宛に変更用URLを送ります。
							<br />
							メールアドレスを入力してください。
						</Alert>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col md={3} className={"text-end"}>
						パスワード変更メール送り先：
					</Col>
					<Col md={5}>
						<FormControl
							placeholder="****@example.com"
							value={email}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
						/>
						{!!errors && !!errors["status"] && (
							<Feedback type={"invalid"}>{getErrorMessage("status", errors)}</Feedback>
						)}
					</Col>
				</Row>
				<Row className="mt-3">
					<Col md={{ span: 2, offset: 4 }}>
						<Button className="w-100" onClick={() => onSubmit(email)} disabled={isDisabled}>
							メール送信
						</Button>
					</Col>
				</Row>
			</div>
		</>
	);
});
