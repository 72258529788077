import * as React from "react";
import { Table, Button } from "react-bootstrap";
import { SearchColumn, Search, SearchValues } from "../../parts/search";
import { Activity, ProductType } from "../../../../../server/models/activity";
import { Option } from "../../../../../react-components/lu-component/src/index";
import { ActivityStatus, ActivityStatusOptions } from "../../../../../server/models/status";
import { ObjectId } from "mongodb";
import { activityLabels } from "../../../../../label/activity";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import styled from "styled-components";
import { Role } from "../../../../../server/lib/permission/role";
import { SubTitle } from "../../parts/accodion-form";
import { ConfirmButton } from "../../parts/confirm-dropdown-button";
import { TextRed } from "../../parts/font";
import useInfo from "../../../hooks/use-info";
import { InfoPart } from "../../parts/info";

const ListTableWrap = styled.div`
	& table td {
		max-width: 200px;
		vertical-align: middle;
	}
`;
const NewActivityWrap = styled.div`
	margin-bottom: 30px;
`;

export type ActivityRowProps = {
	activity: Activity & { canDelete: boolean };
	loginAccount: AccountWithoutPassword;
	mapAccounts: { [key: string]: string };
	mapBrands: { [key: string]: string };
	onEditPage: (id?: string | ObjectId) => void;
	onStatusPage: (id: string | ObjectId) => void;
	onJobListPage: (id: string | ObjectId) => void;
	onDeleteActivity: (id: string | ObjectId) => void;
};

export const ActivityRow = React.memo((props: ActivityRowProps) => {
	const { activity, mapAccounts, mapBrands, onEditPage, onJobListPage, onStatusPage, loginAccount, onDeleteActivity } =
		props;
	const { _id, no, accountIds, category, status, releaseYear, releaseMonth, brandId, productType, memo, canDelete } =
		activity;
	const userName = React.useMemo(() => {
		return accountIds.map((accountId) => mapAccounts[accountId.toString()] || "").join(",");
	}, [mapAccounts, accountIds]);
	const hasActivityChangeStatusRole = Role.hasCustomRole(loginAccount, activity);
	/**
	 * 削除可能
	 * - システム
	 * - 申請者
	 * - アクティビティメンバ
	 */
	const hasDeleteActivityRole = React.useMemo(() => {
		if (Role.hasOperarorManageRole(loginAccount)) return true;
		if (Role.isActivityMember(loginAccount, activity)) return true;
		return false;
	}, [loginAccount, activity]);
	return (
		<tr>
			<td>{no}</td>
			<td style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{userName}</td>
			<td>{mapBrands[brandId.toString()]}</td>
			<td>{category.name}</td>
			<td>
				{releaseYear}年
				<br />
				{releaseMonth}月
			</td>
			<td>{ProductType[productType]}</td>
			<td style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{memo || ""}</td>
			<td style={{ textAlign: "center" }}>
				<Button
					size={"sm"}
					variant={"outline-secondary"}
					style={{ marginRight: "10px" }}
					onClick={() => onEditPage(_id)}
					className="w-100"
				>
					アクティビティ更新
				</Button>
				<Button className="w-100 mt-2" size={"sm"} variant={"outline-secondary"} onClick={() => onJobListPage(_id)}>
					ジョブ一覧
				</Button>
			</td>
			<td style={{ textAlign: "center" }}>
				{ActivityStatus[status]}
				{hasActivityChangeStatusRole && (
					<>
						<br />
						<Button
							size={"sm"}
							style={{ marginLeft: "10px" }}
							variant={"outline-secondary"}
							onClick={() => onStatusPage(_id)}
						>
							変更
						</Button>
					</>
				)}
				{hasDeleteActivityRole && (
					<>
						<br />
						{canDelete && (
							<ConfirmButton
								size={"sm"}
								style={{ marginLeft: "10px", marginTop: "5px" }}
								variant={"outline-secondary"}
								buttonText="削除"
								title={"アクティビティ削除"}
								body={
									<>
										<div>アクティビティ No.{no} を削除してよろしいでしょうか。</div>
										<div>
											<TextRed>※一度削除すると、データ復旧できませんので再度ご確認ください。</TextRed>
										</div>
									</>
								}
								onOk={() => onDeleteActivity(_id)}
							/>
						)}
					</>
				)}
			</td>
		</tr>
	);
});

export type ActivityListProps = {
	activities: (Activity & { canDelete: boolean })[];
	loginAccount: AccountWithoutPassword;
	accountOptions: Option[];
	categoryOptions: Option[];
	brandOptions: Option[];
	onEditPage: (id?: string | ObjectId) => void;
	onStatusPage: (id: string | ObjectId) => void;
	onJobListPage: (id: string | ObjectId) => void;
	onDeleteActivity: (id: string | ObjectId) => void;
};
export const ActivityList = React.memo((props: ActivityListProps) => {
	const { accountOptions, activities, brandOptions, ...activityRowProps } = props;
	const mapAccounts = React.useMemo<{ [key: string]: string }>(() => {
		return accountOptions.reduce((a, b) => {
			return { ...a, [b.value]: b.label };
		}, {});
	}, [accountOptions]);
	const mapBrands = React.useMemo<{ [key: string]: string }>(() => {
		return brandOptions.reduce((a, b) => {
			return { ...a, [b.value]: b.label };
		}, {});
	}, [brandOptions]);
	const ActivityRows = React.useMemo(() => {
		return activities.map((activity) => (
			<ActivityRow
				key={`activity-${activity._id}`}
				activity={activity}
				mapAccounts={mapAccounts}
				mapBrands={mapBrands}
				{...activityRowProps}
			/>
		));
	}, [activities, mapAccounts, mapBrands, activityRowProps]);
	return (
		<ListTableWrap>
			<Table>
				<thead>
					<tr className="text-center">
						<th>{activityLabels.no}</th>
						<th style={{ width: "15em" }}>
							アクティビティ登録者
							{/*<br />
							（アクティビティメンバー）
							*/}
						</th>
						<th>{activityLabels.brandId}</th>
						<th style={{ width: "14em" }}>{activityLabels.category}</th>
						<th style={{ width: "6em" }}>上市時期</th>
						<th style={{ width: "12em" }}>{activityLabels.productType}</th>
						<th style={{ width: "8em" }}>メモ</th>
						<th style={{ width: "12em" }}></th>
						<th style={{ width: "5.5em" }}>ステータス</th>
					</tr>
				</thead>
				<tbody>{ActivityRows}</tbody>
			</Table>
		</ListTableWrap>
	);
});

export type ActivityPageProps = Omit<ActivityListProps, "accountOptions"> & {
	accounts: AccountWithoutPassword[];
	searchValues: SearchValues;
	yearOptions: Option[];
	onChangeSearchValue: (name: string, value: string | number) => void;
	onSearch: () => void;
};

export const ActivityPage = React.memo((props: ActivityPageProps) => {
	const {
		accounts,
		categoryOptions,
		brandOptions,
		onSearch,
		onEditPage,
		searchValues,
		onChangeSearchValue,
		yearOptions,
	} = props;
	const searchAccountOptions = React.useMemo<Option[]>(() => {
		const filteredAccounts = searchValues["brandId"]
			? accounts.filter((account) => {
					return account.brand && account.brand.includes(searchValues["brandId"].toString());
			  })
			: accounts;
		return filteredAccounts.map((account) => {
			return {
				value: account._id.toString(),
				label: account.name,
			};
		});
	}, [accounts, searchValues]);
	const accountOptions = React.useMemo<Option[]>(() => {
		return accounts.map((account) => {
			return {
				value: account._id.toString(),
				label: account.name,
			};
		});
	}, [accounts]);
	const searchColums: SearchColumn[] = React.useMemo(
		() => [
			{
				name: "brandId",
				type: "select",
				label: activityLabels.brandId,
				options: brandOptions,
			},
			{
				name: "categoryId",
				type: "select",
				label: activityLabels.category,
				options: categoryOptions,
			},
			{
				name: "status",
				label: "ステータス",
				type: "select",
				options: ActivityStatusOptions,
			},
			{
				name: "accountId",
				label: "アクティビティ登録者",
				type: "search-select",
				options: searchAccountOptions,
			},
			{
				name: "releaseYear",
				label: "上市時期（年）",
				type: "select",
				options: yearOptions,
			},
			{
				name: "releaseMonth",
				label: "上市時期（月）",
				type: "select",
				options: [
					{ value: 1, label: "1月" },
					{ value: 2, label: "2月" },
					{ value: 3, label: "3月" },
					{ value: 4, label: "4月" },
					{ value: 5, label: "5月" },
					{ value: 6, label: "6月" },
					{ value: 7, label: "7月" },
					{ value: 8, label: "8月" },
					{ value: 9, label: "9月" },
					{ value: 10, label: "10月" },
					{ value: 11, label: "11月" },
					{ value: 12, label: "12月" },
				],
			},
		],
		[brandOptions, categoryOptions, searchAccountOptions, yearOptions],
	);

	const oshirase = useInfo();

	return (
		<div className="m-3 clearfix">
			<div className="m-4">{oshirase && <InfoPart message={oshirase.message} showValue={true} />}</div>
			{Role.hasNewActivity(props.loginAccount) && (
				<NewActivityWrap>
					<div className="h3">■アクティビティ新規作成</div>
					<div className="m-4">
						<Button variant="secondary" onClick={() => onEditPage()} size={"lg"} style={{ borderWidth: "1.6px" }}>
							アクティビティ新規作成
						</Button>
					</div>
				</NewActivityWrap>
			)}
			<div className="h3">■アクティビティ一覧</div>
			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>検索</SubTitle>
				<Search
					columns={searchColums}
					onSearch={onSearch}
					searchValues={searchValues}
					onChangeSearchValue={onChangeSearchValue}
				/>
			</div>
			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>一覧</SubTitle>
				<ActivityList {...props} accountOptions={accountOptions} />
			</div>
		</div>
	);
});
