import * as React from "react";
import { Row, Col, Button, ButtonProps } from "react-bootstrap";
import { Column } from "../../types/column";
import { Data, FormInputGroups, ErrorObject } from "../form-input-groups";

export type RegisterProps = {
	columns: Column[][];
	onSubmit: (data: Data) => void;
	buttonText: string;
	errors?: ErrorObject;
	variant?: ButtonProps["variant"];
	data?: Data;
};

const makeData = (data: Data, columns: Column[][]) => {
	// idは固定で入れ込む。ない場合は変更対象にならない。
	const tmp: Data = data["_id"] ? { _id: data["_id"] } : {};
	for (const colGroup of columns) {
		for (const col of colGroup) {
			if (col.type === "readonly") continue;
			if (data[col.name] !== undefined) {
				tmp[col.name] = data[col.name];
			}
		}
	}
	return tmp;
};
export const CustomForm = React.memo((props: RegisterProps) => {
	const { variant, buttonText, columns, onSubmit, errors } = props;
	const [data, setData] = React.useState<Data>(props.data ? makeData(props.data, columns) : {});
	const onChange = React.useCallback(
		(name: string, value: any) => {
			setData({ ...data, [name]: value });
		},
		[data],
	);
	const onSubmitClick = React.useCallback(() => {
		onSubmit(data);
	}, [data, onSubmit]);
	return (
		<>
			<FormInputGroups columns={columns} data={data} onChange={onChange} errors={errors} />
			<Row>
				<Col md={{ offset: 1, span: 3 }}>
					<Button className="w-100" variant={variant || "primary"} onClick={onSubmitClick}>
						{buttonText}
					</Button>
				</Col>
			</Row>
		</>
	);
});
