import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, IconDefinition, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

import styled from "styled-components";

const MenuArea = styled.div<{ width: number }>`
	background-color: #f7f7f7;
	position: fixed;
	width: ${({ width }) => width}px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	display: block;
	z-index: 1;
`;
const Menus = styled.div<{ top?: number }>`
	${({ top }) => (top ? `margin-top: ${top}px;` : "")}
`;
const ContentArea = styled.div<{ left: number; top?: number }>`
	margin-left: ${({ left }) => left}px;
	${({ top }) => `margin-top: ${top}px;`}
`;
const MenuItem = styled.div`
	&:hover {
		background-color: #e8e8e8;
	}
	cursor: pointer;
`;
const IconButton = styled.div`
	margin: 2px 7px;
	padding: 5px;
	border-radius: 30px;
	width: 30px;
	text-align: center;
	&:hover {
		background-color: #eee;
	}
	display: inline-block;
	cursor: pointer;
`;

export type SideMenuItemType = "button" | "popUp";

export type SideMenuItem = {
	icon: IconDefinition;
	label: string;
	onClick: () => void;
	type?: SideMenuItemType;
};
export type SideMenuProps = {
	top?: number;
	items: SideMenuItem[];
	children: React.ReactNode;
};

export const SideMenuWidthContext = React.createContext(200);

export const SideMenuPage = React.memo((props: SideMenuProps) => {
	const { items, children, top } = props;
	const [show, setShow] = React.useState(true);
	const width = React.useMemo(() => {
		return show ? 200 : 45;
	}, [show]);
	return (
		<SideMenuWidthContext.Provider value={width}>
			<MenuArea width={width}>
				<Menus top={top}>
					<IconButton onClick={() => setShow((show) => !show)}>
						<FontAwesomeIcon icon={show ? faChevronLeft : faChevronRight} />
					</IconButton>
					{items.map((item, index) => (
						<MenuItem key={`side-menu-${index}`} onClick={() => item.onClick()}>
							{!show && (
								<OverlayTrigger
									placement={"right"}
									overlay={
										<Popover id="popover-basic">
											<Popover.Body>
												{item.label}
												{item.type === "popUp" && (
													<FontAwesomeIcon size="xs" className="mx-1" icon={faExternalLinkAlt} />
												)}
											</Popover.Body>
										</Popover>
									}
								>
									<div>
										{(!item.type || item.type === "popUp") && (
											<IconButton>
												{" "}
												<FontAwesomeIcon icon={item.icon} />{" "}
											</IconButton>
										)}
										{item.type === "button" && (
											<Button variant={"secondary"} className="mx-2">
												<FontAwesomeIcon icon={item.icon} style={{ color: "#FFF" }} />
											</Button>
										)}
									</div>
								</OverlayTrigger>
							)}

							{show && (
								<>
									{!item.type && (
										<>
											<IconButton>
												<FontAwesomeIcon icon={item.icon} />
											</IconButton>
											{item.label}
										</>
									)}

									{item.type === "popUp" && (
										<>
											<IconButton>
												<FontAwesomeIcon icon={item.icon} />
											</IconButton>
											{item.label}
											<FontAwesomeIcon size="xs" className="mx-1" icon={faExternalLinkAlt} />
										</>
									)}

									{item.type === "button" && (
										<>
											<Button variant="secondary" style={{ width: "90%", alignItems: "left" }} className="mx-2">
												{item.label}
											</Button>
										</>
									)}
								</>
							)}
						</MenuItem>
					))}
				</Menus>
			</MenuArea>
			<ContentArea left={width} top={top} className="mb-5">
				{children}
			</ContentArea>
		</SideMenuWidthContext.Provider>
	);
});
