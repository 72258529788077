/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Alert, Badge, Col, Form, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import type { ErrorObject, Option } from "../../../../../../react-components/lu-component/src/index";
import { Confirm } from "../../../../../../react-components/lu-component/src/index";
import type { AdCopyApplication } from "../../../../../../server/models/ad-copy/application";
import { getErrorMessage } from "../../../../lib/error";
import { ConfirmButton } from "../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../parts/font";
import { SingleSelect } from "../../../parts/select";

type Props = {
	application: AdCopyApplication;
	brandholders: Option[];
	errors?: ErrorObject;
	onAgreeOverEstimate: () => void;
	onCancel?: () => void;
	onCancelOverEstimate: () => void;
	onChange: (name: keyof AdCopyApplication, value: AdCopyApplication[keyof AdCopyApplication]) => void;
	onChangeApprover: (index: number, value: string) => void;
	onSubmit: () => void;
	overEstimateAgreement: boolean;
};

export const AdCopyApplicationPage = React.memo((props: Props) => {
	const {
		application,
		brandholders,
		errors,
		onAgreeOverEstimate,
		onCancel,
		onCancelOverEstimate,
		onChange,
		onChangeApprover,
		onSubmit,
		overEstimateAgreement,
	} = props;

	const brands1 = React.useMemo(() => {
		const approver =
			application.approve[1] && application.approve[1].approver ? application.approve[1].approver : undefined;

		return brandholders.filter((bh) => {
			return bh.value !== approver;
		});
	}, [brandholders, application]);

	const brands2 = React.useMemo(() => {
		const approver =
			application.approve[0] && application.approve[0].approver ? application.approve[0].approver : undefined;

		return brandholders.filter((bh) => {
			return bh.value !== approver;
		});
	}, [brandholders, application]);

	return (
		<div className="m-3 clearfix">
			<Confirm
				body={
					<TextRed>
						調査実施費用が100万円を超える可能性があります。
						<br />
						稟議書が承認されていることをご確認ください。
					</TextRed>
				}
				buttonText="はい"
				onCancel={onCancelOverEstimate}
				onOk={onAgreeOverEstimate}
				show={!overEstimateAgreement}
				title="見積もり金額"
				variant="secondary"
			/>

			<Row>
				<Col md={12}>
					<div className="h3 float-start">■配信申請</div>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<Row className="mt-2">
						<Col md={2}>
							<div className="text-end">
								Approver 1（1次承認者）
								<Badge bg="danger">必須</Badge>
							</div>
						</Col>

						<Col md={3}>
							<SingleSelect
								error={
									!!errors && !!errors["approve"]
										? typeof errors["approve"] === "string"
											? errors["approve"]
											: typeof errors["approve"][0] === "string"
											? errors["approve"][0]
											: errors["approve"][0]["approver"].toString()
										: undefined
								}
								onChange={(selected) => {
									onChangeApprover(0, selected ? selected.toString() : "");
								}}
								options={brands1}
								value={
									application.approve[0] && application.approve[0].approver
										? application.approve[0].approver.toString()
										: ""
								}
							/>
						</Col>
					</Row>

					<Row className="mt-2">
						<Col md={2}>
							<div className="text-end">Approver 2（2次承認者）</div>
						</Col>

						<Col md={3}>
							<SingleSelect
								error={
									!!errors && !!errors["approve"] && typeof errors["approve"] === "object" && !!errors["approve"][1]
										? typeof errors["approve"][1] === "string"
											? errors["approve"][1]
											: errors["approve"][1]["approver"].toString()
										: undefined
								}
								onChange={(selected) => {
									onChangeApprover(1, selected ? selected.toString() : undefined);
								}}
								options={brands2}
								value={
									application.approve[1] && application.approve[1].approver
										? application.approve[1].approver.toString()
										: ""
								}
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<Row className="mt-3">
						<Col md={2}>
							<div className="text-end">申請メッセージ</div>
						</Col>

						<Col md={5}>
							<Form.Control
								as="textarea"
								isInvalid={!!errors && !!errors["applicationMessage"]}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange("applicationMessage", e.target.value)}
								rows={5}
								value={application.applicationMessage}
							/>

							{!!errors && !!errors["applicationMessage"] && (
								<Feedback type="invalid">{getErrorMessage("applicationMessage", errors)}</Feedback>
							)}
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="mt-5">
				<Col className="text-center">
					{application.applicationDate && !application.cancel ? (
						<Alert variant="secondary">
							{application.applicationDate}にすでに申請済みです。
							{
								// No.11 申請したジョブ
								!!onCancel && (
									<ConfirmButton
										body="申請を取り消します。よろしいですか。"
										buttonText="申請を取り消す"
										onOk={onCancel}
										title="申請取消"
										variant="secondary"
									/>
								)
							}
						</Alert>
					) : (
						<ConfirmButton
							body="申請します。よろしいですか。"
							buttonText="申請する"
							onOk={onSubmit}
							title="配信申請"
							variant="secondary"
						/>
					)}
				</Col>
			</Row>
		</div>
	);
});
