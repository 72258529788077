import * as React from "react";
import styled from "styled-components";

import type { OutputTableData } from "../../../../../../../server/models/ad-copy/output/outputTableData";
import { OutputTbody } from "./output-tbody";
import { OutputThead } from "./output-thead";

const StyledTable = styled.table<{
	stickyThead: boolean;
	tdMinWidth?: string;
}>`
	td,
	th {
		border-top: 1px dotted #888;
		border-right: 1px dotted #888;
		padding: 3px;
	}

	td {
		min-width: ${({ tdMinWidth }) => tdMinWidth};
	}

	border-collapse: separate;
	border-spacing: 0;

	thead {
		${({ stickyThead }) =>
			stickyThead
				? `
		position: sticky;
		top: 44px;
		background-color: white;
		`
				: ""}
	}
`;

type Props = {
	data: OutputTableData;
	stickyThead?: boolean;
	tableRef?: React.RefObject<HTMLTableElement>;
	tdMinWidth?: string;
};

export const OutputTable = React.memo((props: Props) => {
	const { data, stickyThead = false, tableRef, tdMinWidth } = props;

	return (
		<StyledTable
			ref={tableRef}
			className={data.className}
			stickyThead={stickyThead}
			style={data.style}
			tdMinWidth={tdMinWidth}
		>
			<OutputThead data={data.tHead} />

			{data.tBodies.map((tBody, tBodyIndex) => (
				<OutputTbody key={tBodyIndex} data={tBody} />
			))}
		</StyledTable>
	);
});
