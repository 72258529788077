import * as React from "react";
import { reducer, initState } from "../../../reducers/report/outline";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { Outline } from "../../../components/pages/report/outline";
import { get, makeError } from "../../../lib/request";
import { GetOutlineResponse } from "../../../../../server/types/request/report/outline";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { useParams } from "react-router-dom";
import { clientEndpoint } from "../../../routes/endpoint";
import { ReportBigTitle } from "../../../components/pages/report/parts/report-title";

export const OutlineContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, activity, data } = state;
	const { jobId, activityId } = useParams<{
		jobId: string;
		activityId: string;
	}>();
	React.useEffect(() => {
		get<GetOutlineResponse>(`${endpoint.outline}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	const onViewQuotaPage = React.useCallback(
		(jobId: string) => {
			window.open(replaceEndpointUrl(clientEndpoint.quota, { jobId, activityId }));
		},
		[activityId],
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			{!!activity &&
				data.map((d, index) => {
					return (
						<React.Fragment key={`outline-${index}`}>
							<ReportBigTitle>
								{index === 0 ? "テストの概要" : "前回のテスト概要"}（{d.job.jobNum}）
							</ReportBigTitle>
							<Outline {...d} activity={activity} onViewQuotaPage={onViewQuotaPage} />
						</React.Fragment>
					);
				})}
		</>
	);
};
