/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import {
	AdCopySensitiveSkin,
	adCopySensitiveSkinFrequencyRankOptions,
} from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopySensitiveSkinFrequencyCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	const shouldHide =
		strategyGroup.sensitiveSkin == null ||
		strategyGroup.sensitiveSkin.length === 0 ||
		strategyGroup.sensitiveSkin.includes(
			AdCopySensitiveSkin.敏感肌ではない.toString() as keyof typeof AdCopySensitiveSkin,
		);

	return (
		<Row>
			<FormGroup as={Col} md={6}>
				<AccordionForm
					defaultShow={strategyGroup.sensitiveSkinFrequency != null && 0 < strategyGroup.sensitiveSkinFrequency.length}
					eventKey="sensitiveSkinFrequency"
					title={adCopyStrategyTargetGroupLabels.sensitiveSkinFrequency ?? ""}
				>
					{shouldHide ? (
						<></>
					) : (
						<Checkboxes
							checkeds={strategyGroup.sensitiveSkinFrequency ?? []}
							choices={adCopySensitiveSkinFrequencyRankOptions as Choice[]}
							name="sensitiveSkinFrequency"
							onChange={(value) => {
								onChange("sensitiveSkinFrequency", value);
							}}
						/>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
