import { PageConfig } from "@muscat/types";
import {
	areaAdCopyQuename,
	competitorEvaluationAdCopyQuename,
	evaluationAdCopyQuename,
	genderAdCopyQuename,
	marriedAdCopyQuename,
	screeningQuestionQueCount,
} from "../enquete/config";

/**
 * 以下の設問Q番号は固定の計算になるので、そのまま利用する。
 */
const staticQuenames = [
	"S1", // 同意設問
	"S2", // 職業除外
	screeningQuestionQueCount,
	genderAdCopyQuename,
	marriedAdCopyQuename,
	areaAdCopyQuename,
] as const;

/**
 * Matrixの可変の設問：：：項目＝ページ数になる
 * - 選択肢グループが2つ以上ある場合：4p
 * - グループが一つ：3p
 */
const variableQuenames = [evaluationAdCopyQuename, competitorEvaluationAdCopyQuename] as const;
/**
 * コスト計算用の集計設問の作成。以下の計算は設問通り
 * - qCount=0
 * - SCの指定の設問=S1～S6
 * - 評価設問（可変の設問）
 * @returns {PageConfig[]}
 */
export const makeQuestionnaireForCost = (questionnaire: PageConfig[]): PageConfig[] => {
	return questionnaire.reduce<PageConfig[]>((a, b) => {
		if ("grouping" in b) return [...a, b];
		return [
			...a,
			{
				...b,
				questions: b.questions.map(({ queCount, ...q }) => {
					if (queCount === 0 || staticQuenames.includes(q.quename as any)) return { ...q, queCount };
					if (q.type === "MT" && variableQuenames.includes(q.quename as any)) {
						return { ...q, queCount: q.childQuestionGroups.length >= 2 ? 4 : 3 };
					}
					return q;
				}),
			},
		];
	}, []);
};
