import * as React from "react";
import { reducer, initState } from "../../../reducers/report/result-by-target";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { get, makeError } from "../../../lib/request";
import { endpoint } from "../../../../../server/router/endpoint";
import { useParams } from "react-router-dom";
import { GetResultByTargetResponse } from "../../../../../server/types/request/report/result-by-target";
import { ResultByTargeReporttPage } from "../../../components/pages/report/result-by-target";
import { RetestMenu } from "../../../components/pages/report/common";
import { ReportBigTitle } from "../../../components/pages/report/parts/report-title";

export const ResultByTargetReportContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, ...targetProps } = state;
	const { jobId, activityId } = useParams<{
		jobId: string;
		activityId: string;
	}>();
	React.useEffect(() => {
		get<GetResultByTargetResponse>(`${endpoint.resutByTargetReport}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	return (
		<>
			<Loading loading={loading} />
			{targetProps.job && targetProps.job.retestJobId && (
				<RetestMenu activityId={activityId} jobId={targetProps.job.retestJobId.toString()} page={"result-by-target"} />
			)}
			<ConfirmBox info={info} />
			<ReportBigTitle>結果の詳細{targetProps.job ? `（${targetProps.job.jobNum}）` : ""}</ReportBigTitle>
			{targetProps.data && <ResultByTargeReporttPage {...targetProps} />}
		</>
	);
};
