import * as React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Account, AccountWithoutPassword, AccountOptions } from "../../../../../server/models/account";
import {
	Column,
	ListTable,
	EventItem,
	EditForm,
	FormInputGroups,
	Option,
	FileInput,
} from "../../../../../react-components/lu-component/src/index";

import { accountLabels } from "../../../../../label/account";
import { Role } from "../../../../../server/lib/permission/role";
import { ErrorObject } from "../../../../../server/types/error";
import { useNavigate } from "react-router-dom";

export type AccountPageProps = {
	loginAccount: AccountWithoutPassword;
	brandList: Option[];
	errors?: ErrorObject;
	accounts: AccountWithoutPassword[];
	onClose: () => void;
	onCreate: (account: Account, cb?: () => void) => void;
	onUpdate: (account: AccountWithoutPassword & { password?: string }, cb?: () => void) => void;
	onDownload: () => void;
	onUpload: (file: File) => void;
};

export const AccountPage = React.memo((props: AccountPageProps) => {
	const { loginAccount, accounts, onUpdate, onCreate, brandList, onUpload, onDownload, errors, onClose } = props;
	const navigate = useNavigate();
	const columns: Column<Account>[][] = React.useMemo(
		() => [
			[
				{
					name: "code",
					label: accountLabels.code,
					type: "text",
					placeholder: "9999",
					col: 7,
				},
			],
			[
				{
					name: "name",
					label: accountLabels.name,
					type: "text",
					placeholder: "氏名",
					col: 7,
				},
			],
			[
				{
					name: "email",
					label: accountLabels.email,
					type: "text",
					placeholder: "test@test.jp",
				},
			],
			[
				{
					name: "brand",
					label: accountLabels.brand,
					type: "multiple-select",
					options: brandList,
				},
			],
			[
				{
					name: "role",
					label: accountLabels.role,
					type: "select",
					options: AccountOptions,
					col: 5,
				},
			],
			[
				{
					name: "password",
					label: accountLabels.password,
					type: "text",
					hide: true,
				},
			],
			[
				{
					name: "isActive",
					label: accountLabels.isActive,
					type: "checkbox",
				},
			],
		],
		[brandList],
	);
	const searchColumns: Column<Account>[][] = React.useMemo(
		() => [
			[
				{
					name: "code",
					label: "ID",
					type: "text",
					col: 3,
				},
				{
					name: "name",
					label: "氏名",
					type: "text",
					col: 3,
				},
				{
					name: "brand",
					label: "担当ブランド",
					type: "select",
					options: brandList,
					col: 3,
				},
			],
		],
		[brandList],
	);
	const [searches, setSearches] = React.useState<{ [key in keyof AccountWithoutPassword]?: string }>({});
	const hasAccountManageRole = React.useMemo(() => Role.hasAccountManageRole(loginAccount), [loginAccount]);
	const events = React.useMemo<EventItem[]>(
		() =>
			hasAccountManageRole
				? [
						{
							type: "edit",
							text: "編集",
							action: onUpdate,
							onCloseEvent: onClose,
						},
				  ]
				: [],
		[hasAccountManageRole, onClose, onUpdate],
	);
	const onChangeSearch = React.useCallback(
		(name: keyof AccountWithoutPassword, value: any) => {
			if (value === "" && name in searches) {
				delete searches[name];
				setSearches({ ...searches });
			} else {
				setSearches({ ...searches, [name]: value });
			}
		},
		[searches],
	);
	const filteredAccounts = React.useMemo(() => {
		const s = Object.entries(searches);
		if (s.length === 0) return accounts;
		return accounts.filter((account) => {
			return s.every(([key, val]) => {
				const target = account[key as keyof AccountWithoutPassword];
				if (key === "bland") {
					return target === val;
				}
				if (!target) return false;
				return target.toString().includes(val);
			});
		}, []);
	}, [accounts, searches]);
	const handleClickBack = React.useCallback(() => navigate(-1), [navigate]);
	return (
		<div className="m-3 clearfix">
			{hasAccountManageRole && (
				<>
					<div className="clearfix">
						<div className="h3 float-start">■アカウント一括更新</div>
						<div className="col-md-auto float-end">
							<Button variant="outline-secondary" onClick={handleClickBack}>
								戻る
							</Button>
						</div>
					</div>
					<Row className={"m-3"}>
						<Col md={10}>
							<FileInput onUpload={onUpload} label={"csvアップロード"} />
						</Col>
					</Row>
				</>
			)}
			<div className="h3">■アカウント一覧</div>
			<Row>
				<Col md={12}>
					<Card>
						<Card.Header>検索</Card.Header>
						<Card.Body>
							<FormInputGroups columns={searchColumns} data={searches} onChange={onChangeSearch} />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className={"m-3"}>
				<Col md={{ offset: 8, span: 2 }}>
					<Button variant={"outline-secondary"} onClick={onDownload}>
						csvダウンロード
					</Button>
				</Col>
				{hasAccountManageRole && (
					<Col md={2}>
						<EditForm
							errors={errors}
							columns={columns}
							onSubmit={onCreate}
							data={{ isActive: true }}
							onCloseEvent={onClose}
						/>
					</Col>
				)}
			</Row>
			<ListTable errors={errors} columns={columns} data={filteredAccounts} {...(events.length ? { events } : {})} />
		</div>
	);
});
