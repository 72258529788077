import type { Quota } from "@muscat/types";
import * as React from "react";
import { Table } from "react-bootstrap";

import { adCopyQuotaCodeMap } from "../../../../../../../../server/lib/ad-copy/enquete/config";
import { type AdCopyTargetGroup, adCopyTargetGroupMap } from "../../../../../../../../server/models/ad-copy/enquete";
import { AdCopyQuotaCategory } from "./quota-category";

const QuotaCodeReg = {
	brandUser: new RegExp(`^${adCopyQuotaCodeMap.brandUser}\\d+$`),
	categoryUser: new RegExp(`^[${adCopyQuotaCodeMap.categoryUser}]\\d{3}-\\d{3}$`),
	strategy: new RegExp(`^${adCopyQuotaCodeMap.strategy}\\d+$`),
} as const;

export type AdCopyQuotaStatusProps = {
	isCMI: boolean;
	quotas: Quota[];
};

export const AdCopyQuotaStatus = React.memo((props: AdCopyQuotaStatusProps) => {
	const { isCMI, quotas } = props;

	const categorizedQuotas = React.useMemo(() => {
		const tmp: { [group in AdCopyTargetGroup]: Quota[] } = {
			categoryUser: [],

			strategyTarget: [],

			brandUser: [],
		};

		quotas.forEach((q) => {
			if (QuotaCodeReg.categoryUser.test(q.code)) {
				tmp.categoryUser.push(q);
			}

			if (QuotaCodeReg.strategy.test(q.code)) {
				tmp.strategyTarget.push(q);
			}

			if (QuotaCodeReg.brandUser.test(q.code)) {
				tmp.brandUser.push(q);
			}
		});

		return tmp;
	}, [quotas]);

	return (
		<Table>
			<thead>
				<tr>
					<th>No</th>

					<th>名前</th>

					<th>回収予定数</th>

					<th>回収数</th>

					<th>回収率</th>
				</tr>
			</thead>

			<tbody>
				{/* カテゴリユーザー */}
				{/* NOTE: 需要予測なしの場合、つまりブランドユーザーセルが無い場合は非表示にする */}
				{0 < categorizedQuotas.brandUser.length && (
					<AdCopyQuotaCategory
						isCMI={isCMI}
						quotas={categorizedQuotas.categoryUser}
						title={adCopyTargetGroupMap.categoryUser}
					/>
				)}

				{/* 戦略ターゲット */}
				<AdCopyQuotaCategory
					isCMI={isCMI}
					quotas={categorizedQuotas.strategyTarget}
					title={adCopyTargetGroupMap.strategyTarget}
				/>

				{/* 当該カテゴリ×ブランドユーザー */}
				<AdCopyQuotaCategory
					isCMI={isCMI}
					quotas={categorizedQuotas.brandUser}
					title={adCopyTargetGroupMap.brandUser}
				/>
			</tbody>
		</Table>
	);
});
