import * as React from "react";
import { Button, Dropdown, ButtonProps } from "react-bootstrap";
import { ConfirmButton } from "../confirm-button";
import { EditForm } from "../edit-form";
import { ErrorObject, Data } from "../form-input-groups";
import { Column } from "../../types/column";

export type CustomEvent = {
	type: "custom";
	text: string;
	variant?: ButtonProps["variant"];
	action: (id: string) => void;
};
export type CustomEventPullDown = {
	type: "pulldown";
	text?: string;
	variant?: ButtonProps["variant"];
	actions: {
		text: string;
		action: (id: string) => void;
	}[];
};
export type EditEvent = {
	type: "edit";
	text: string;
	variant?: ButtonProps["variant"];
	onCloseEvent?: () => void;
	action: (data: Data, cb?: () => void) => void;
};
export type DeleteEvent = {
	type: "delete";
	text: string;
	variant?: ButtonProps["variant"];
	action: (id: string) => void;
};
export type EventItem = CustomEventPullDown | CustomEvent | EditEvent | DeleteEvent;

export type EventMenuProps = {
	events: EventItem[];
	id: string;
	errors?: ErrorObject;
	columns?: Column[][];
	data?: Data;
};
export const EventMenu = React.memo((props: EventMenuProps) => {
	const { events, id, errors, columns, data } = props;
	return (
		<>
			{events.map((event, eventIndex) => (
				<React.Fragment key={`datarow-${id}-${eventIndex}`}>
					{event.type === "edit" && !!columns && !!data && (
						<EditForm
							doUpdate
							columns={columns}
							data={data}
							onSubmit={event.action}
							errors={errors}
							onCloseEvent={event.onCloseEvent}
							variant={event.variant}
						/>
					)}
					{event.type === "delete" && (
						<ConfirmButton
							variant={event.variant}
							buttonText={event.text}
							title={`${event.text}の実行`}
							body={`${event.text}をすると元には戻せません。よろしいですか？`}
							onOk={() => event.action(id)}
						/>
					)}
					{event.type === "custom" && (
						<Button
							style={{ margin: "3px" }}
							key={`data-${id}-event-${eventIndex}`}
							variant={event.variant || "secondary"}
							onClick={() => event.action(id)}
						>
							{event.text}
						</Button>
					)}
					{event.type === "pulldown" && (
						<Dropdown>
							<Dropdown.Toggle id={`dropdown-${id}`} variant={event.variant || "secondary"}>
								{event.text || "設定"}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{event.actions.map((e, index) => (
									<Dropdown.Item style={{ fontSize: "90%" }} key={`dropdown-${index}`} onClick={() => e.action(id)}>
										{e.text}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					)}
				</React.Fragment>
			))}
		</>
	);
});
