import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyApplication } from "../../../../../../server/models/ad-copy/application";
import type { AdCopyConcept } from "../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { AdCopyApproveAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	accounts: AccountWithoutPassword[];
	activity?: AdCopyActivityWithBrand;
	application?: AdCopyApplication;
	concepts: AdCopyConcept[];
	estimate?: string;
	evaluationItem?: string[];
	info: MessageInfo;
	job?: AdCopyJob;
	loading: boolean;
};

export const initState: State = {
	accounts: [],
	concepts: [],
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
};

export const reducer = (state: State, action: AdCopyApproveAction): State => {
	switch (action.type) {
		case "loadApplication":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "UpdateApplication":
			return {
				...state,
				application: action.payload,
				info: {
					isSuccess: true,
					message: action.payload.rejectMessage ? "却下しました。" : "承認が完了しました。",
				},
				loading: false,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
