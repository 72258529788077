import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import {
	type AdCopyActivityStatus,
	AdCopyActivityStatusOptions,
} from "../../../../../../../server/models/ad-copy/status";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../lib/error";
import { SingleSelect } from "../../../../parts/select";

type Props = {
	errors?: ErrorObject;
	onChnage: (status: keyof typeof AdCopyActivityStatus) => void;
	onSubmit: () => void;
	status?: keyof typeof AdCopyActivityStatus;
};

export const AdCopyActivityStatusPage = React.memo((props: Props) => {
	const { errors, onChnage, onSubmit, status } = props;

	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■アクティビティステータス更新</div>
				</Col>
			</Row>

			<Row>
				<Col className="mb-3" md={12}>
					<Row className="m-3">
						<Col md={12}>
							<Row className="mt-3">
								<Col className="text-end" md={3}>
									ステータス更新：
								</Col>

								<Col>
									<SingleSelect
										error={getErrorMessage("status", errors)}
										onChange={(value) => {
											onChnage(value as keyof typeof AdCopyActivityStatus);
										}}
										options={AdCopyActivityStatusOptions}
										value={status ? status.toString() : ""}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="mt-5">
				<Col className="text-center">
					<Button onClick={onSubmit} variant="secondary">
						変更する
					</Button>
				</Col>
			</Row>
		</div>
	);
});
