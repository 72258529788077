import React from "react";
import { Card, useAccordionButton } from "react-bootstrap";

export type InfoAccordionToggle = {
	eventKey: string;
	children: React.ReactElement | React.ReactElement[];
	onClick?: () => void;
};
export const InfoAccordionToggle = React.memo((props: InfoAccordionToggle) => {
	const { eventKey, children, onClick } = props;
	const decoratedOnClick = useAccordionButton(eventKey, () => {
		onClick();
	});
	return (
		<Card.Header onClick={decoratedOnClick} style={{ cursor: "pointer" }}>
			{children}
		</Card.Header>
	);
});
