export const adCopyEndpoint = {
	laodActivity: "/api/ad-copy/load_activity",
	activity: "/api/ad-copy/activity",
	activityMember: "/api/ad-copy/activity-member",
	activityProgressStatus: "/api/ad-copy/activity-progress-status",
	activityStatus: "/api/ad-copy/activity-status",

	job: "/api/ad-copy/activity/:activityId/job",
	jobAll: "/api/ad-copy/job/all",
	jobSearch: "/api/ad-copy/job/search",
	jobDownload: "/api/ad-copy/job-download",
	jobStatus: "/api/ad-copy/job-status",
	jobDetail: "/api/ad-copy/job-detail",
	jobRoot: "/api/ad-copy/job",
	loadJob: "/api/ad-copy/activity/:activityId/load_job",

	question: "/api/ad-copy/question",

	application: "/api/ad-copy/application",
	checkApplication: "/api/ad-copy/check_application",
	approve: "/api/ad-copy/approve",

	concept: "/api/ad-copy/concept",
	conceptPreview: "/api/ad-copy/concept_preview",
	conceptImage: "/api/ad-copy/concept_image",

	staticImage: "/api/ad-copy/images",
	enquetePreview: "/api/ad-copy/enquete-preview",
	preview: "/api/ad-copy/preview",
	enqueteTest: "/api/ad-copy/enquete-test",

	quota: "/api/ad-copy/quota",
	invoice: "/api/ad-copy/invoice",

	// レポートページ
	outline: "/api/ad-copy/report/outline",
	exectiveReport: "/api/ad-copy/report/exective",
	evaluationReport: "/api/ad-copy/report/evaluation",
	conceptEvaluationReport: "/api/ad-copy/report/concept-evaluation",
	oaReport: "/api/ad-copy/report/oa",
	keyMeasurementsReport: "/api/ad-copy/report/key-measurements",
	// KeyMeasurementsDownload: "/api/ad-copy/report/key-measurements/download",
	resultDetails: "/api/ad-copy/report/result-details",
	simulation: "/api/ad-copy/simulation",
	cp: "/api/ad-copy/simulation/cp",
	cpCorrectionValues: "/api/ad-copy/simulation/cp-correctionValues",

	downloadRawdata: "/api/ad-copy/download/rawdata",
	downloadQuestionSheet: "/api/ad-copy/download/question-sheet",
} as const;

export type AdCopyServerEndpoint = keyof typeof adCopyEndpoint;
export type AdCopyServerEndpointPath = (typeof adCopyEndpoint)[AdCopyServerEndpoint];
