import { Labels } from ".";
import {
	Activity,
	CategoryGroup,
	NoSettingStrategyGroup,
	StrategyGroup,
	StrategyGroupCategory,
} from "../server/models/activity";

export const activityLabels: Labels<Activity> = {
	_id: "アクティビティID",
	no: "No",
	accountIds: "アクティビティ登録者", //（アクティビティメンバー）",
	brandId: "ブランド",
	category: "カテゴリ",
	status: "ステータス",
	releaseYear: "上市時期（年）",
	releaseMonth: "上市時期（月）",
	salesScale: "売り上げ規模",
	grp: "TVCM規模（GRP)",
	goalRecognitionRate: "日本人（当該性の）15～69歳における想定商品認知率(%)",
	goalSalesVolume: "発売後12か月の目標お客さま購買（万個）",
	categoryTargetGroup: "カテゴリターゲットグループ",
	strategyTargetGroup: "戦略ターゲットグループ",
	productType: "開発区分",
	memo: "アクティビティ名　補足メモ",
};

export const categoryTargetGroupLabels: Labels<CategoryGroup> = {
	price: "価格帯（税抜き）",
	age: "年齢",
	appearance: "市場における出現率",
};

export const strategyTargetGroupLabels: Labels<StrategyGroup> = {
	age: "年齢",
	married: "未既婚",
	children: "子ども年齢（同居）",
	occupation: "職業",
	skinAttribute: "肌悩み",
	sensitiveSkin: "敏感肌",
	categoryOrAnd: "条件",
	category: "過去１年購入&使用カテゴリ",
	channel: "購入チャネル",
	fragrance: "無香/賦香（制汗剤のみ）",
	foundationType: "ファンデーションタイプ（リキッド）",
	facewashFunction: "洗顔機能",
};

export const strategyGroupCategoryLabels: Labels<StrategyGroupCategory> = {
	surveyChoice: "調査選択肢（カテゴリ）",
	category: "過去1年購入&使用カテゴリ",
	price: "価格帯（税抜き）",
	beautyType: "カテゴリタイプ（スキンケアのみ）",
	usedBrand: "過去1年購入＆使用ブランド",
	unusedBrand: "過去1年非購入ブランド",
};

export const NoSettingStrategyGroupLabels: Labels<NoSettingStrategyGroup> = {
	reasons: "戦略ターゲットを設定しない理由",
	specifically: "その他",
};
