import * as React from "react";
import { JobStatus } from "../../../../../server/models/status";
import { reducer, initState } from "../../../reducers/job/status";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { put, get, makeError } from "../../../lib/request";
import { endpoint } from "../../../../../server/router/endpoint";
import { useParams } from "react-router-dom";
import { PutJobStatusResponse } from "../../../../../server/types/request/job";
import { JobStatusPage } from "../../../components/pages/job/status";
import { ConfirmBox } from "../../../components/parts/confirm-box";

export const JobStatusContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, status } = state;
	const onChange = React.useCallback(
		(value: keyof typeof JobStatus) => dispatch({ type: "changeStatus", payload: value }),
		[],
	);
	const { jobId } = useParams<{ jobId: string }>();
	React.useEffect(() => {
		get<PutJobStatusResponse>(`${endpoint.jobStatus}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadStatus", payload: response.data.status });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	const onSubmit = React.useCallback(() => {
		put<PutJobStatusResponse>(`${endpoint.jobStatus}/${jobId}`, { status })
			.then(() => {
				dispatch({ type: "changeMessageInfo", payload: { isSuccess: true, message: "更新が完了しました。" } });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId, status]);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel={"更新処理"} />
			<JobStatusPage status={status} onChnage={onChange} onSubmit={onSubmit} errors={info.errors} />
		</>
	);
};
