import * as React from "react";
import { Activity } from "../../../../../../../../server/models/activity";
import { OutlinePanel, OutlinePanelBody, OutlineSubTitle, OutlineCard, IndentArea, ChildPanel } from "../..";
import styled from "styled-components";
import { canSettingCategoryTarget, hasNorm } from "../../../../../../../../server/lib/norm";
import { GeneralConditions } from "../../../../../parts/conditions/general";
import { CategoryConditions } from "../../../../../parts/conditions/categroy";
import { StrategyConditions } from "../../../../../parts/conditions/strategy";

const TextRedDiv = styled.div`
	color: red;
	font-weight: bold;
`;

export type TargetPanelProps = {
	activity: Activity;
	hasStrategyGroup: boolean;
};

export const TargetPanel = React.memo((props: TargetPanelProps) => {
	const { hasStrategyGroup, activity } = props;
	const { categoryTargetGroup, strategyTargetGroup, category } = activity;
	const message = React.useMemo(() => {
		if (!categoryTargetGroup) return "ノームがないため、カテゴリターゲットＧを設定できません。";
		if (!canSettingCategoryTarget(category, categoryTargetGroup)) {
			return "（この価格帯は出現率が低いため、カテゴリターゲットグループを設定できません。）";
		}
		if (!hasNorm(category, categoryTargetGroup)) {
			return "ノームがないため、購入意向のノーム判定はできません。";
		}
		return undefined;
	}, [category, categoryTargetGroup]);
	return (
		<OutlinePanel className={"panel"}>
			<OutlinePanelBody>
				<OutlineSubTitle>□ 対象者条件</OutlineSubTitle>
				<ChildPanel>
					<OutlineCard width={370}>
						<IndentArea>
							【REPサンプル】
							<IndentArea indent={0.7}>
								<GeneralConditions gender={category.gender} />
							</IndentArea>
						</IndentArea>
					</OutlineCard>
				</ChildPanel>
				<ChildPanel>
					<OutlineCard width={370}>
						<IndentArea>
							【カテゴリターゲット】
							{categoryTargetGroup ? (
								<IndentArea indent={0.7}>
									<CategoryConditions categoryTargetGroup={categoryTargetGroup} />
									{!!message && <TextRedDiv>{message}</TextRedDiv>}
								</IndentArea>
							) : (
								<IndentArea>なし</IndentArea>
							)}
						</IndentArea>
					</OutlineCard>
					<OutlineCard width={600}>
						<IndentArea>
							【戦略ターゲット】
							{hasStrategyGroup && strategyTargetGroup ? (
								<StrategyConditions strategyTargetGroup={strategyTargetGroup} />
							) : (
								<IndentArea>なし</IndentArea>
							)}
						</IndentArea>
					</OutlineCard>
				</ChildPanel>
			</OutlinePanelBody>
		</OutlinePanel>
	);
});
