/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import styled from "styled-components";

import { type ErrorObject, MultipleText } from "../../../../../../../../react-components/lu-component/src/index";
import { type AdCopyConceptKey, maxSizeOfBodyCopies } from "../../../../../../../../server/models/ad-copy/concept";
import type { RetestAdCopyConcepts } from "../../../../../../../../server/types/request/ad-copy/concept";
import type { AdCopyConceptAction } from "../../../../../../types/actions";
import type { TrueAdCopyConcept, TrueAdCopySku } from "../../../../../../types/ad-copy/concept";
import { Help } from "../../../../../parts/help";
import { AdCopyCountableTextarea } from "./countable-textarea";
import { AdCopyConceptImageInput } from "./image-input";
import { AdCopyMultipleSkuForm } from "./multiple-sku-form";
import { AdCopyRetestCopyButton } from "./retest-copy-button";
import { AdCopyTest1CopyButton } from "./test1-copy-button";

const AlertMessage = styled.span`
	color: red;
`;

export type AdCopyConceptFormFunc = {
	dispatch: React.Dispatch<AdCopyConceptAction>;
	onAddSku: (type: AdCopyConceptKey, index: number) => void;
	onChangeConcept: <T extends keyof TrueAdCopyConcept>(
		type: AdCopyConceptKey,
		name: T,
		value: TrueAdCopyConcept[T],
	) => void;
	onChangeSku: <T extends keyof TrueAdCopySku>(
		type: AdCopyConceptKey,
		index: number,
		name: T,
		value: TrueAdCopySku[T],
	) => void;
	onCopy: (original: AdCopyConceptKey, copyTo: AdCopyConceptKey) => void;
	onDeleteSku: (type: AdCopyConceptKey, index: number) => void;
	onRetestCopy: (original: AdCopyConceptKey, copyTo: AdCopyConceptKey) => void;
	setTaxIncluded: (type: AdCopyConceptKey, index: number) => void;
};

type Props = AdCopyConceptFormFunc & {
	concept: TrueAdCopyConcept;
	errors: ErrorObject | undefined;
	retestConcepts: RetestAdCopyConcepts | undefined;
};

const FormBlock = styled.div`
	display: flex;
	column-gap: 2rem;
`;

export const AdCopyConceptForm = React.memo((props: Props) => {
	const {
		concept,
		dispatch,
		errors,
		onAddSku,
		onChangeConcept,
		onChangeSku,
		onCopy,
		onDeleteSku,
		onRetestCopy,
		retestConcepts,
		setTaxIncluded,
	} = props;

	// 再調査、かつ、競合品のタブ内フォームは disabled にする
	const disabled = !!retestConcepts && concept.type === "benchmark";

	const onChangeCatchCopy = React.useCallback(
		(value: string) => {
			onChangeConcept(concept.type, "catchCopy", value);
		},
		[concept.type, onChangeConcept],
	);

	const onChangeBodyCopies = React.useCallback(
		(values: string[]) => {
			onChangeConcept(concept.type, "bodyCopies", values);
		},
		[concept.type, onChangeConcept],
	);

	const onChangeImageUrl = React.useCallback(
		(value: string) => {
			onChangeConcept(concept.type, "imageUrl", value);
		},
		[concept.type, onChangeConcept],
	);

	const catchCopyError = React.useMemo(
		() => (typeof errors?.catchCopy === "string" ? errors.catchCopy : undefined),
		[errors],
	);

	const bodyCopiesError = React.useMemo(() => errors?.bodyCopies, [errors]);

	return (
		<FormBlock>
			<div className="my-3">
				{/* コピー機能 */}
				{!disabled && (concept.type === "test2" || concept.type === "test3" || !!retestConcepts) && (
					<Row style={{ marginBottom: "10px" }}>
						{concept.type === "test2" && <AdCopyTest1CopyButton conceptType="test2" onCopy={onCopy} />}

						{concept.type === "test3" && <AdCopyTest1CopyButton conceptType="test3" onCopy={onCopy} />}

						{!!retestConcepts?.test1 && (
							<AdCopyRetestCopyButton concept={concept} conceptType="test1" onRetestCopy={onRetestCopy} />
						)}

						{!!retestConcepts?.test2 && (
							<AdCopyRetestCopyButton concept={concept} conceptType="test2" onRetestCopy={onRetestCopy} />
						)}

						{!!retestConcepts?.test3 && (
							<AdCopyRetestCopyButton concept={concept} conceptType="test3" onRetestCopy={onRetestCopy} />
						)}
					</Row>
				)}

				{/* 呈示素材登録 */}
				<Row>
					<Col md={12}>
						<AdCopyConceptImageInput
							disabled={disabled}
							dispatch={dispatch}
							errors={errors}
							imageUrl={concept.imageUrl}
							onChangeImageUrl={onChangeImageUrl}
						/>
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<hr />
					</Col>
				</Row>

				{/* メインコピー */}
				{/* キャッチコピー */}
				<Row style={{ marginBottom: "20px" }}>
					<Col>
						<Row md={5} style={{ fontSize: "16px", fontWeight: "bold" }}>
							コピーの魅力度・印象度
						</Row>

						<Row md={3} style={{ fontSize: "14px" }}>
							下記に入力するコピーのセンテンス単位で、印象度、魅力度を聴取します。
						</Row>
					</Col>
				</Row>

				<AdCopyCountableTextarea
					disabled={disabled}
					error={catchCopyError}
					label={
						<>
							メインコピー
							<AlertMessage>　※必須</AlertMessage>
						</>
					}
					onChange={onChangeCatchCopy}
					value={concept.catchCopy}
				/>

				{/* その他コピー */}
				{/* ボディコピー */}
				<Row>
					<Col md={3}>その他コピー（任意）</Col>

					{typeof bodyCopiesError === "string" && (
						<Col md={12}>
							<Feedback style={{ display: "block", whiteSpace: "pre-wrap" }} type="invalid">
								{bodyCopiesError}
							</Feedback>
						</Col>
					)}
				</Row>

				<Row>
					<Col md={12}>
						<MultipleText
							disabled={disabled}
							hasAddEvent
							hasRow
							max={maxSizeOfBodyCopies}
							onChange={onChangeBodyCopies}
							values={concept.bodyCopies ?? []}
						/>
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<hr />
					</Col>
				</Row>

				<Row>
					<Col md={3}>
						価格・容量設定
						<Help explain="ここで提示するSKUが、ポテンシャル需要予測の対象になります。" id="sku" />
					</Col>

					{errors?.["sku"] && typeof errors["sku"] === "string" && (
						<Col className="m-3" md={10}>
							<Alert variant="danger">{errors["sku"]}</Alert>
						</Col>
					)}

					<Col md={11}>
						<Alert variant="warning">
							SKU説明欄には、
							<br />
							【1SKUのみの場合:容量】
							<br />
							【複数SKUの場合: SKU種類＋容量　（例：マイルドタイプ　60mL）】
							<br />
						</Alert>
					</Col>

					<Col md={12}>
						<AdCopyMultipleSkuForm
							disabled={disabled}
							errors={errors?.["sku"] && typeof errors["sku"] !== "string" ? errors["sku"] : undefined}
							onAddSku={(index) => {
								onAddSku(concept.type, index);
							}}
							onChangeSku={(...arg) => {
								onChangeSku(concept.type, ...arg);
							}}
							onDeleteSku={(index) => {
								onDeleteSku(concept.type, index);
							}}
							setTaxIncluded={(index) => {
								setTaxIncluded(concept.type, index);
							}}
							skuItems={concept.sku}
						/>
					</Col>
				</Row>
			</div>
		</FormBlock>
	);
});
