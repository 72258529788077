import * as React from "react";
import { decimalCalc } from "../../../../../../../../server/lib/aggregate/common";
import { SellPotential } from "../../../../../../../../server/models/graph/demand-forecast";
import { isDefined } from "../../../../../../lib/data";
import { calcDiffPercent } from "../../common";

export type ResultTableRowProps = {
	name: keyof SellPotential | "goal";
	label: string;
	initValue: number;
	targetValue: number;
	correctionValue?: number;
};
export const ResultTableRow = React.memo((props: ResultTableRowProps) => {
	const { name, initValue, targetValue, label, correctionValue } = props;
	const diff = React.useMemo(() => calcDiffPercent(initValue, targetValue), [initValue, targetValue]);
	const noChange = React.useMemo(() => !isDefined(targetValue), [targetValue]);
	const hasCorrectionValue = React.useMemo(() => isDefined(correctionValue), [correctionValue]);
	const diffCorrectionValue = React.useMemo(() => {
		if (isDefined(targetValue)) {
			return calcDiffPercent(targetValue, correctionValue);
		}
		return calcDiffPercent(initValue, correctionValue);
	}, [initValue, targetValue, correctionValue]);
	return (
		<tr>
			<td className={name}>{label}</td>
			<td>{decimalCalc(initValue, 10).toFixed(1)}</td>
			<td>{noChange ? "-" : decimalCalc(targetValue, 10).toFixed(1)}</td>
			{name === "goal" ? (
				<td></td>
			) : (
				<td className={diff < 0 ? "minus" : "plus"}>{noChange ? "-" : diff.toFixed(1)}</td>
			)}
			{hasCorrectionValue && (
				<>
					<td>{decimalCalc(correctionValue, 10).toFixed(1)}</td>
					{name === "goal" ? (
						<td></td>
					) : (
						<td className={diffCorrectionValue < 0 ? "minus" : "plus"}>
							{isNaN(diffCorrectionValue) ? "-" : diffCorrectionValue.toFixed(1)}
						</td>
					)}
				</>
			)}
		</tr>
	);
});

export type SellPotentialValue = { label: string; sellPotential: SellPotential };
export type ResultTableProps = {
	sellPotentials: SellPotentialValue[];
	goalSalesVolume: number;
};

export const ResultTable = React.memo((props: ResultTableProps) => {
	const { sellPotentials, goalSalesVolume } = props;
	const _goal50Percent = React.useMemo(() => (goalSalesVolume * 10 * 5) / 100, [goalSalesVolume]);
	const initValues = React.useMemo(() => sellPotentials[0].sellPotential, [sellPotentials]);
	const targetValues = React.useMemo(() => sellPotentials[1].sellPotential, [sellPotentials]);
	const correctionValues = React.useMemo(() => sellPotentials[2]?.sellPotential, [sellPotentials]);
	const hasCP = React.useMemo(() => !!correctionValues, [correctionValues]);
	return (
		<table className={"result-table"}>
			<thead>
				<tr>
					<th rowSpan={2} className="subtitle">
						推定値
					</th>
					<th rowSpan={2}>初期値(万個)</th>
					<th colSpan={2}>①変数補整</th>
					{hasCP && <th colSpan={2}>②実績補整</th>}
				</tr>
				<tr>
					<th>補整値(万個)</th>
					<th>補整率(%)</th>
					{hasCP && (
						<>
							<th>補整値(万個)</th>
							<th>補整率(%)</th>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				<ResultTableRow
					name={"trial"}
					label={"トライアル"}
					initValue={initValues.trial}
					targetValue={targetValues.trial}
					correctionValue={correctionValues?.trial}
				/>
				<ResultTableRow
					name={"repeat"}
					label={"リピート"}
					initValue={initValues.repeat}
					targetValue={targetValues.repeat}
					correctionValue={correctionValues?.repeat}
				/>
				<ResultTableRow
					name={"localOther"}
					label={"ローカルその他"}
					initValue={initValues.localOther}
					targetValue={targetValues.localOther}
					correctionValue={correctionValues?.localOther}
				/>
				<ResultTableRow
					name={"inbound"}
					label={"インバウンド"}
					initValue={initValues.inbound}
					targetValue={targetValues.inbound}
					correctionValue={correctionValues?.inbound}
				/>
				<ResultTableRow
					name={"total"}
					label={"合計"}
					initValue={initValues.total}
					targetValue={targetValues.total}
					correctionValue={correctionValues?.total}
				/>
				<ResultTableRow
					name={"goal"}
					label={"目標対比(%)"}
					initValue={(initValues.total / goalSalesVolume) * 100 - 100}
					targetValue={isDefined(targetValues.total) ? (targetValues.total / goalSalesVolume) * 100 - 100 : undefined}
					correctionValue={
						isDefined(correctionValues?.total) ? (correctionValues.total / goalSalesVolume) * 100 - 100 : undefined
					}
				/>
				{/*
				<tr className={"action-standard"}>
					<td>AS判定</td>
					<td>{initValues.total > goal50Percent ? "〇" : "×"}</td>
					<td>{isDefined(targetValues.total) ? (targetValues.total > goal50Percent ? "〇" : "×") : "-"}</td>
					<td className={"none-value"}></td>
				</tr>
				*/}
			</tbody>
		</table>
	);
});
