/* eslint-disable react/jsx-no-useless-fragment */

import type { Quota } from "@muscat/types";
import * as React from "react";

type Props = {
	isCMI: boolean;
	quotas: Quota[];
	title: string;
};

export const AdCopyQuotaCategory = React.memo((props: Props) => {
	const { isCMI, quotas, title } = props;

	const planTotal = React.useMemo(() => {
		return quotas.reduce((a, b) => a + b.plan, 0);
	}, [quotas]);

	const countTotal = React.useMemo(() => {
		return quotas.reduce((a, b) => a + b.count, 0);
	}, [quotas]);

	if (quotas.length === 0) {
		return <></>;
	}

	return (
		<>
			<tr>
				<th colSpan={5} style={{ backgroundColor: "#ddd" }}>
					{title}
				</th>
			</tr>

			{isCMI &&
				quotas.map((quota) => {
					const rate = Math.floor((quota.count / quota.plan) * 1000) / 10;

					return (
						<tr key={`quota-${quota.code}`}>
							<td>{quota.code}</td>

							<td>{quota.name}</td>

							<td>{quota.plan}</td>

							<td>{quota.count}</td>

							<td style={{ color: rate >= 100 ? "blue" : "red" }}>{rate}</td>
						</tr>
					);
				})}

			<tr>
				<td colSpan={2} style={{ textAlign: "center" }}>
					合計
				</td>

				<td>{planTotal}</td>

				<td>{countTotal}</td>

				<td>{Math.floor((countTotal / planTotal) * 1000) / 10}</td>
			</tr>
		</>
	);
});
