import * as React from "react";
import { FormGroup, FormCheck, Col } from "react-bootstrap";
import { Choice } from "../../types/column";

export type CheckboxesProps = {
	inline?: boolean;
	choices: Choice[];
	checkeds: Choice["value"][];
	name: string;
	onChange: (checkeds: Choice["value"][]) => void;
	exclusive?: Choice["value"][];
};
export const Checkboxes = React.memo((props: CheckboxesProps) => {
	const { checkeds, onChange, choices, name, inline, exclusive } = props;
	const onChangeCheckbox = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, value: Choice["value"]) => {
			const targetChecked = e.target.checked;
			if (targetChecked) {
				if (exclusive && exclusive.indexOf(value) !== -1) {
					onChange([value]);
				} else {
					const target = checkeds.concat(value);
					onChange(exclusive ? target.filter((v) => exclusive.indexOf(v) === -1) : target);
				}
			} else {
				onChange(checkeds.filter((ch) => ch !== value));
			}
		},
		[checkeds, onChange, exclusive],
	);
	return (
		<FormGroup as={Col} className="position-relative w-100 px-3">
			{choices.map((choice) => (
				<FormCheck
					type={"checkbox"}
					key={`checkbox-${choice.value}`}
					label={choice.label}
					value={choice.value.toString()}
					checked={checkeds.includes(choice.value)}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCheckbox(e, choice.value)}
					name={name}
					inline={inline || false}
					style={inline ? { paddingRight: "10px" } : {}}
				/>
			))}
		</FormGroup>
	);
});
