import * as React from "react";
import styled from "styled-components";

import { adCopyRoot } from "../../../../routes/adCopyEndpoint";
import { FloatingMenu } from "../../../parts/floating-menu";

export const AdCopyGraphWrapArea = styled.div`
	margin: 0px auto;
	padding: 0px 10px;
	max-width: 1200px;
	min-width: 1000px;
`;

type Props = {
	activityId: string;
	jobId: string;
	page: "key-mesurements" | "oa" | "result-by-target";
};

export const AdCopyRetestMenu = React.memo((props: Props) => {
	const { activityId, jobId, page } = props;

	const onRetest = React.useCallback(() => {
		const url = `${adCopyRoot}/report/${activityId}/${jobId}/${page}` as const;

		window.open(url);
	}, [page, activityId, jobId]);

	const menus = React.useMemo(
		() => [
			{
				event: onRetest,
				label: "前回調査を開く",
			},
		],
		[onRetest],
	);

	return <FloatingMenu menus={menus} />;
});
