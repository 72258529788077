import { AdCopyConceptTypeMap } from "../../../../../../server/models/ad-copy/concept";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { UploadAdCopyImageResponse } from "../../../../../../server/types/request/ad-copy/image";
import { conceptImageMaxMb } from "../../../../components/pages/ad-copy/concept/create/concept-form/image-input";
import { adCopyMb } from "../../../../lib/ad-copy/common";
import { makeError, postFormData, remove } from "../../../../lib/request";
import type { TrueAdCopyConcept } from "../../../../types/ad-copy/concept";
import type { MessageInfo } from "../../../../types/info";

export const postImages = async ({
	concepts,
	jobId,
}: {
	concepts: TrueAdCopyConcept[];
	jobId: string;
}): Promise<{ concepts: TrueAdCopyConcept[]; isSuccess: true } | { isSuccess: false; messageInfo: MessageInfo }> => {
	const postImageResults = await Promise.all(concepts.map((concept) => postImage({ concept, jobId })));

	if (postImageResults.every((result) => result.isSuccess)) {
		return {
			concepts: postImageResults
				.filter((result): result is { concept: TrueAdCopyConcept; isSuccess: true } => result.isSuccess)
				.map((result) => result.concept),
			isSuccess: true,
		};
	}

	return {
		isSuccess: false,
		messageInfo: postImageResults.find(
			(result): result is { isSuccess: false; messageInfo: MessageInfo } => !result.isSuccess,
		)!.messageInfo,
	};
};

const postImage = async ({
	concept,
	jobId,
}: {
	concept: TrueAdCopyConcept;
	jobId: string;
}): Promise<{ concept: TrueAdCopyConcept; isSuccess: true } | { isSuccess: false; messageInfo: MessageInfo }> => {
	if (concept.imageUrl) {
		const blob = await fetch(concept.imageUrl).then((r) => r.blob());

		if (conceptImageMaxMb * adCopyMb < blob.size) {
			const message = `アップロードするファイルのサイズは${conceptImageMaxMb}MBまでです（${
				AdCopyConceptTypeMap[concept.type]
			}: ${(blob.size / adCopyMb).toFixed(2)}MB）。`;

			return {
				isSuccess: false,
				messageInfo: { isSuccess: false, message },
			};
		}

		const formData = new FormData();

		formData.append("type", concept.type);
		formData.append("file", blob);

		try {
			const response = await postFormData<UploadAdCopyImageResponse>(
				`${adCopyEndpoint.conceptImage}/${jobId}`,
				formData,
			);

			return {
				concept: {
					...concept,
					imageUrl: `${response.data.url}`,
				},
				isSuccess: true,
			};
		} catch (error: any) {
			return {
				isSuccess: false,
				messageInfo: makeError(error),
			};
		}
	}

	try {
		await remove<{}>(`${adCopyEndpoint.conceptImage}/${jobId}/${concept.type}`);

		return {
			concept,
			isSuccess: true,
		};
	} catch (error: any) {
		return {
			isSuccess: false,
			messageInfo: makeError(error),
		};
	}
};
