import { MessageInfo } from "../../../types/info";
import { OutlineAction } from "../../../types/actions";
import { GetOutlineResponse } from "../../../../../server/types/request/report/outline";

export type State = {
	loading: boolean;
	info: MessageInfo;
} & GetOutlineResponse;

export const initState: State = {
	activity: undefined,
	data: [],

	loading: true,
	info: {
		isSuccess: true,
		message: "",
	},
};

export const reducer = (state: State, action: OutlineAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
