import * as React from "react";
import { OutlinePanelTitle, OutlineTable, OutlinePanel, OutlineCard, OutlinePanelBody } from "..";
import { ActivityWithBrand, SalesScale, Grp, ProductType } from "../../../../../../../server/models/activity";
import { activityLabels } from "../../../../../../../label/activity";

const ActivityPanelRow = React.memo((props: { value: string; label: string }) => {
	const { label, value } = props;
	return (
		<tr className={"panel-category"}>
			<td>□</td>
			<td style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{label}</td>
			<td className={"colon"}>：</td>
			<td>
				<div style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{value}</div>
			</td>
		</tr>
	);
});

export type ActivityPanelProps = {
	activity: ActivityWithBrand;
};

export const ActivityPanel = React.memo((props: ActivityPanelProps) => {
	const { activity } = props;
	return (
		<OutlinePanel className={"panel"}>
			<OutlinePanelTitle>アクティビティ情報</OutlinePanelTitle>
			<OutlinePanelBody>
				<OutlineCard width={370}>
					<OutlineTable>
						<tbody>
							<ActivityPanelRow label={activityLabels.brandId} value={activity.brand.name} />
							<ActivityPanelRow label={activityLabels.category} value={activity.category.name.replace("（", "\n（")} />
							<ActivityPanelRow label={"上市時期"} value={`${activity.releaseYear}年${activity.releaseMonth}月`} />
						</tbody>
					</OutlineTable>
				</OutlineCard>
				<OutlineCard width={300}>
					<OutlineTable>
						<tbody>
							<ActivityPanelRow label={activityLabels.productType} value={ProductType[activity.productType]} />
							<ActivityPanelRow label={activityLabels.salesScale} value={SalesScale[activity.salesScale]} />
							<ActivityPanelRow label={activityLabels.grp} value={Grp[activity.grp]} />
						</tbody>
					</OutlineTable>
				</OutlineCard>
				<OutlineCard width={300}>
					<OutlineTable>
						<tbody>
							<ActivityPanelRow
								label={"日本人（当該性の）15～69歳\nにおける想定商品認知率(%)"}
								value={`${activity.goalRecognitionRate}％`}
							/>
							<ActivityPanelRow label={"目標お客さま購買"} value={`${activity.goalSalesVolume}万個`} />
						</tbody>
					</OutlineTable>
				</OutlineCard>
			</OutlinePanelBody>
		</OutlinePanel>
	);
});
