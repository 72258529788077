import { JobWithSurveyStatus } from "../../../../../server/models/job";
import { Option } from "../../../../../react-components/lu-component/src/index";
import { MessageInfo } from "../../../types/info";
import { JobListAction } from "../../../types/actions";
import { SurveyStatus } from "../../../../../server/models/status";
import { ActivityWithBrand } from "../../../../../server/models/activity";

export type State = {
	jobs: JobWithSurveyStatus[];
	accountOptions: Option[];
	activity: ActivityWithBrand;
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	jobs: [],
	accountOptions: [],
	activity: undefined,
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: JobListAction): State => {
	switch (action.type) {
		case "loadJobs":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeJobStatus":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: `ステータスを「${SurveyStatus[action.payload.surveyStatus]}」に変更しました。`,
				},
				jobs: state.jobs.map((job) => {
					if (job._id.toString() === action.payload.jobId) {
						return {
							...job,
							status: action.payload.status,
							surveyStatus: action.payload.surveyStatus,
						};
					}
					return job;
				}),
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
