import { ObjectId } from "mongodb";

/** 競合品は独立quotaセルでないため、除外 */
export const adCopyConceptTypeMapForQuota = {
	test1: "テスト品1",
	test2: "テスト品2",
	test3: "テスト品3",
	currentProduct: "現行品",
} as const;

/** 競合品コンセプトを含める */
export const AdCopyConceptTypeMap = {
	...adCopyConceptTypeMapForQuota,
	benchmark: "競合品",
} as const;

export type AdCopyConceptKey = keyof typeof AdCopyConceptTypeMap;
export const adCopyConceptKeys = Object.keys(AdCopyConceptTypeMap) as AdCopyConceptKey[];

export type AdCopyConceptKeyForQuota = Exclude<AdCopyConceptKey, "benchmark">;

export type AdCopyTestOnlyConceptKey = "test1" | "test2" | "test3";
export type AdCopyConceptLabel = (typeof AdCopyConceptTypeMap)[AdCopyConceptKey];

export const adCopyConceptOptions = Object.entries(AdCopyConceptTypeMap).map(([value, label]) => {
	return { label, value } as { label: string; value: AdCopyConceptKey };
});

export type AdCopyConcept = {
	_id?: ObjectId | string;
	jobId: ObjectId | string;
	type: keyof typeof AdCopyConceptTypeMap;
	catchCopy: string;
	/** ボディコピーは最大4つ */
	bodyCopies?: string[];
	sku: AdCopySku[]; // sku
	imageUrl: string; //刺激画像URL
};

export interface AdCopySku {
	name: string;
	price: number;
	includeTaxPrice: number;
}

/** ボディコピーは最大４つまで */
export const maxSizeOfBodyCopies = 4;
