import * as React from "react";
import { CP } from "../../../../../../../../server/models/cp";
import { Col, Row, Button } from "react-bootstrap";

import { CPSalesVolumeDataTable } from "./sales-volume";
import { ReportTitle } from "../../../parts/report-title";
import styled from "styled-components";
import { SalesVolumeConstValue } from "../../../../../../../../server/types/request/report/simulation";
import { UserTable } from "../../result/user-table";
import { CorrectionValueSetting } from "../../correction-value";
import { CorrectionValue } from "../../../../../../../../server/models/simulation-value";
import { makeInboundRate, makeLocalOtherRate } from "../../../../../../../../server/lib/common";

const LabelArea = styled.div`
	margin: 10px;
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 0.8rem;
`;

const SubLabelArea = styled.div`
	font-weight: bold;
`;

const TableArea = styled.div`
	table {
		margin: 13px 0;
	}
`;

export type CPParameterSettingProps = {
	cp: Partial<CP>;
	constValue: SalesVolumeConstValue;
	hasEditRole: boolean;
	maxRecognition: number;
	onSubmit: () => void;
	onChangeRatio: <K extends keyof CorrectionValue>(name: K, value: CorrectionValue[K]) => void;
};

export const CPParameterSetting = React.memo<CPParameterSettingProps>(
	({ cp, constValue, hasEditRole, maxRecognition, onChangeRatio, onSubmit }) => {
		const salesVolume = React.useMemo(() => cp?.salesVolumes?.test1, [cp]);
		const initLocalRatio = React.useMemo(
			() => (salesVolume ? makeLocalOtherRate(salesVolume) : undefined),
			[salesVolume],
		);
		const initInboundRatio = React.useMemo(
			() => (salesVolume ? makeInboundRate(salesVolume) : undefined),
			[salesVolume],
		);
		return (
			<>
				<Row>
					<Col md={12}>
						<ReportTitle>現行品/類似品の変数補整</ReportTitle>
					</Col>
				</Row>
				{!!cp && !!cp.salesVolumes ? (
					<>
						<Row style={{ marginBottom: "3rem" }}>
							<Col md={12}>
								<TableArea>
									{/* sales volume variables */}
									<CorrectionValueSetting
										maxRecognition={maxRecognition}
										value={cp.salesVolumes["test1"]}
										correctionValue={cp.correctionValues ?? {}}
										hasEditRole={hasEditRole}
										initLocalRatio={initLocalRatio}
										initInboundRatio={initInboundRatio}
										onChangeRatio={onChangeRatio}
									/>
								</TableArea>
							</Col>
							{hasEditRole && (
								<Col md={{ span: 2, offset: 3 }}>
									{/*block*/}
									<Button variant="secondary" onClick={onSubmit}>
										推定値 算出
									</Button>
								</Col>
							)}
						</Row>
						{!!cp.correctionValues && !!cp.correctionValues.result && (
							<>
								<Row style={{ marginBottom: "2rem" }}>
									<Col md={12}>
										{/* category user table */}
										<UserTable salesVolumePrediction={cp.salesVolumes["test1"]} constValue={constValue} />
									</Col>
								</Row>
								{/* current product sales volume */}
								<Row>
									<Col md={3}>
										<SubLabelArea>テスト品1</SubLabelArea>
										<CPSalesVolumeDataTable data={cp.correctionValues.result.test1} />
									</Col>
									{!!cp.correctionValues.result.test2 && (
										<Col md={3}>
											<SubLabelArea>テスト品2</SubLabelArea>
											<CPSalesVolumeDataTable data={cp.correctionValues.result.test2} />
										</Col>
									)}
								</Row>
							</>
						)}
					</>
				) : (
					<Row>
						<Col md={10}>
							<LabelArea>未設定</LabelArea>
						</Col>
					</Row>
				)}
			</>
		);
	},
);
