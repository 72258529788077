import * as React from "react";
import styled from "styled-components";

import type { GetAdCopyKeyMeasurementsResponse } from "../../../../../types/ad-copy/report";
import { AdCopyCrossTable } from "../../../../parts/ad-copy/cross-table";
import { AdCopyReportBigTitle } from "../parts/report-title";

const CrossTableWrap = styled.div`
	margin: 0px 10px;
	padding: 0px 10px;
	max-width: 1200px;
	min-width: 1000px;
`;

type Props = {
	data: GetAdCopyKeyMeasurementsResponse["data"];
	job: GetAdCopyKeyMeasurementsResponse["job"] | undefined;
};

export const AdCopyKeyMeasurementsReportPage = React.memo((props: Props) => {
	const { data, job } = props;

	const filename = job ? `${job.jobNum}-keyMeasurements` : undefined;

	return (
		<>
			<AdCopyReportBigTitle>集計表{job ? `（${job.jobNum}）` : ""}</AdCopyReportBigTitle>

			<CrossTableWrap>
				<AdCopyCrossTable data={data} filename={filename} headers={[]} />
			</CrossTableWrap>
		</>
	);
});
