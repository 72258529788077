import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type {
	GetAdCopyApprovalResponse,
	UpdateAdCopyApprovalRequest,
	UpdateAdCopyApprovalResponse,
} from "../../../../../../server/types/request/ad-copy/approval";
import { AdCopyApprovePage } from "../../../../components/pages/ad-copy/approval/approve";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError, post } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/approve";
import { adCopyRoot } from "../../../../routes/adCopyEndpoint";

export const AdCopyApproveContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { accounts, activity, application, concepts, estimate, evaluationItem, info, job, loading } = state;

	const { activityId, jobId } = useParams<{ activityId: string; jobId: string }>();

	const navigate = useNavigate();

	React.useEffect(() => {
		get<GetAdCopyApprovalResponse>(`${adCopyEndpoint.approve}/${jobId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadApplication" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [jobId]);

	const onSubmit = React.useCallback(
		(data: UpdateAdCopyApprovalRequest) => {
			dispatch({ payload: true, type: "changeLoading" });

			post<UpdateAdCopyApprovalResponse>(`${adCopyEndpoint.approve}/${jobId}`, data)
				.then((response) => {
					dispatch({ payload: response.data.application, type: "UpdateApplication" });
				})
				.catch((error) => {
					dispatch({ payload: makeError(error), type: "changeMessageInfo" });
				});
		},
		[jobId],
	);

	const onApprove = React.useCallback(() => {
		onSubmit({ status: "approve" });
	}, [onSubmit]);

	const onReject = React.useCallback(
		(rejectMessage: string) => {
			onSubmit({ rejectMessage, status: "reject" });
		},
		[onSubmit],
	);

	const onClose = React.useCallback(() => {
		navigate(`${adCopyRoot}/activity/${activityId}/job`);
	}, [activityId, navigate]);

	return (
		<>
			<Loading loading={loading} text="processing..." />

			<ConfirmBox info={info} onClose={onClose} titleLabel="処理" />

			{application && (
				<AdCopyApprovePage
					accounts={accounts}
					activity={activity}
					application={application}
					concepts={concepts}
					errors={info.errors}
					estimate={estimate}
					evaluationItem={evaluationItem}
					job={job}
					loginAccount={loginAccount}
					onApprove={onApprove}
					onReject={onReject}
				/>
			)}
		</>
	);
};
