import React from "react";
import { unstable_usePrompt } from "react-router-dom";

type Props = {
	message: string;
	when: boolean;
};

/**
 * @deprecated
 */
export const Prompt: React.FC<Props> = ({ message, when }) => {
	unstable_usePrompt({
		message,
		when,
	});

	return <></>;
};
