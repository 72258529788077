import type { ObjectId } from "mongodb";
import * as React from "react";
import { Button } from "react-bootstrap";

import { AdCopyRole } from "../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { type AdCopyActivity, AdCopyProductType } from "../../../../../../server/models/ad-copy/activity";
import { AdCopyActivityStatus } from "../../../../../../server/models/ad-copy/status";
import { ConfirmButton } from "../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../parts/font";

type Props = {
	activity: AdCopyActivity & { canDelete: boolean };
	loginAccount: AccountWithoutPassword;
	mapAccounts: Record<string, string>;
	mapBrands: Record<string, string>;
	onDeleteActivity: (id: ObjectId | string) => void;
	onEditPage: (id?: ObjectId | string) => void;
	onJobListPage: (id: ObjectId | string) => void;
	onStatusPage: (id: ObjectId | string) => void;
};

export const AdCopyActivityRow = React.memo((props: Props) => {
	const { activity, loginAccount, mapAccounts, mapBrands, onDeleteActivity, onEditPage, onJobListPage, onStatusPage } =
		props;

	const { _id, accountIds, brandId, canDelete, category, memo, no, productType, releaseMonth, releaseYear, status } =
		activity;

	const userName = React.useMemo(() => {
		return accountIds.map((accountId) => mapAccounts[accountId.toString()] || "").join(",");
	}, [mapAccounts, accountIds]);

	const hasActivityChangeStatusRole = AdCopyRole.hasCustomRole(loginAccount, activity);

	/**
	 * 削除可能
	 * - システム
	 * - 申請者
	 * - アクティビティメンバ
	 */
	const hasDeleteActivityRole = React.useMemo(() => {
		if (AdCopyRole.hasOperarorManageRole(loginAccount)) {
			return true;
		}

		if (AdCopyRole.isActivityMember(loginAccount, activity)) {
			return true;
		}

		return false;
	}, [loginAccount, activity]);

	return (
		<tr>
			<td>{no}</td>

			<td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{userName}</td>

			<td>{mapBrands[brandId.toString()]}</td>

			<td>{category.name}</td>

			<td>
				{releaseYear}年
				<br />
				{releaseMonth}月
			</td>

			<td>{AdCopyProductType[productType]}</td>

			<td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{memo || ""}</td>

			<td style={{ textAlign: "center" }}>
				<Button
					className="w-100"
					onClick={() => onEditPage(_id)}
					size="sm"
					style={{ marginRight: "10px" }}
					variant="outline-secondary"
				>
					アクティビティ更新
				</Button>

				<Button className="w-100 mt-2" onClick={() => onJobListPage(_id ?? "")} size="sm" variant="outline-secondary">
					ジョブ一覧
				</Button>
			</td>

			<td style={{ textAlign: "center" }}>
				{AdCopyActivityStatus[status]}

				{hasActivityChangeStatusRole && (
					<>
						<br />

						<Button
							onClick={() => onStatusPage(_id ?? "")}
							size="sm"
							style={{ marginLeft: "10px" }}
							variant="outline-secondary"
						>
							変更
						</Button>
					</>
				)}

				{hasDeleteActivityRole && (
					<>
						<br />

						{canDelete && (
							<ConfirmButton
								body={
									<>
										<div>アクティビティ No.{no} を削除してよろしいでしょうか。</div>

										<div>
											<TextRed>※一度削除すると、データ復旧できませんので再度ご確認ください。</TextRed>
										</div>
									</>
								}
								buttonText="削除"
								onOk={() => onDeleteActivity(_id ?? "")}
								size="sm"
								style={{ marginLeft: "10px", marginTop: "5px" }}
								title="アクティビティ削除"
								variant="outline-secondary"
							/>
						)}
					</>
				)}
			</td>
		</tr>
	);
});
