import * as React from "react";

type Props = {
	label: string;
	value: string;
};

export const AdCopyActivityPanelRow = React.memo((props: Props) => {
	const { label, value } = props;

	return (
		<tr className="panel-category">
			<td>□</td>

			<td style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{label}</td>

			<td className="colon">：</td>

			<td>
				<div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{value}</div>
			</td>
		</tr>
	);
});
