import * as React from "react";
import { Button } from "react-bootstrap";

import { adCopyJobLabels } from "../../../../../../../../../label/ad-copy/job";
import type { AdCopyActivity } from "../../../../../../../../../server/models/ad-copy/activity";
import { AdCopyConceptTypeMap } from "../../../../../../../../../server/models/ad-copy/concept";
import type { AdCopyJob } from "../../../../../../../../../server/models/ad-copy/job";
import type { AdCopySampleSizesByType } from "../../../../../../../../../server/types/request/ad-copy/report/outline";
import {
	AdCopyIndentArea,
	AdCopyOutlinePanel,
	AdCopyOutlinePanelBody,
	AdCopyOutlineSubTitle,
	AdCopyOutlineTable,
} from "../..";
import { orderedConceptTypes } from "../../constants";
import { AdCopySampleSizeTable, AdCopyTargetPanelRow } from "./parts";
import { AdCopySampleSizeRow } from "./sample-size-row";

type Props = {
	activity: AdCopyActivity;
	benchmarkName?: string;
	job: AdCopyJob;
	onViewQuotaPage: () => void;
	sampleSizesByType: AdCopySampleSizesByType;
};

export const AdCopySamplePanel = React.memo((props: Props) => {
	const { activity, benchmarkName, job, onViewQuotaPage, sampleSizesByType } = props;

	const filteredOrderedConceptTypes = React.useMemo(
		() =>
			orderedConceptTypes.filter((conceptType) =>
				Object.values(sampleSizesByType).some((sampleSizes) => sampleSizes[conceptType] != null),
			),
		[sampleSizesByType],
	);

	return (
		<>
			<AdCopyOutlinePanel className="panel">
				<AdCopyOutlinePanelBody>
					<AdCopyOutlineSubTitle>
						□ 有効サンプルサイズ
						<Button onClick={onViewQuotaPage} size="sm" style={{ marginLeft: "10px" }} variant="outline-secondary">
							詳細確認
						</Button>
					</AdCopyOutlineSubTitle>

					<AdCopyIndentArea indent={3}>
						<AdCopySampleSizeTable>
							<thead>
								<tr className="bordered">
									<th />

									{filteredOrderedConceptTypes.map((conceptType) => (
										<th key={conceptType} className="names-area">
											{AdCopyConceptTypeMap[conceptType]}
										</th>
									))}
								</tr>
							</thead>

							<tbody>
								{/* カテゴリユーザー */}
								{/* NOTE: 需要予測しない場合は非表示 */}
								{activity.targetCategoryBrandValues != null && 0 < activity.targetCategoryBrandValues.length && (
									<AdCopySampleSizeRow
										filteredOrderedConceptTypes={filteredOrderedConceptTypes}
										sampleSizes={sampleSizesByType.categoryUser}
										targetGroupType="categoryUser"
									/>
								)}

								{/* 戦略ターゲット */}
								<AdCopySampleSizeRow
									filteredOrderedConceptTypes={filteredOrderedConceptTypes}
									sampleSizes={sampleSizesByType.strategyTarget}
									targetGroupType="strategyTarget"
								/>

								{/* ブランドユーザー */}
								{sampleSizesByType.brandUser != null && (
									<AdCopySampleSizeRow
										filteredOrderedConceptTypes={filteredOrderedConceptTypes}
										sampleSizes={sampleSizesByType.brandUser}
										targetGroupType="brandUser"
									/>
								)}
							</tbody>
						</AdCopySampleSizeTable>
					</AdCopyIndentArea>
				</AdCopyOutlinePanelBody>
			</AdCopyOutlinePanel>

			<AdCopyOutlinePanel className="panel">
				<AdCopyOutlinePanelBody>
					<AdCopyOutlineTable>
						<tbody>
							<AdCopyTargetPanelRow label={adCopyJobLabels.numOfTests} value={`${job.numOfTests}案`} />

							<AdCopyTargetPanelRow label="現行商品" value={job.hasCurrentProduct ? "あり" : "なし"} />

							<AdCopyTargetPanelRow
								label="競合商品"
								value={job.hasBenchmark ? `あり（${benchmarkName || job.benchmarkName}）` : "なし"}
							/>

							<AdCopyTargetPanelRow label="キービジュアル（KV）の有無：" value={job.hasKeyVisual ? "あり" : "なし"} />

							<AdCopyTargetPanelRow label="実査日程" value={`${job.startDatetime} 〜 ${job.endDatetime}`} />
						</tbody>
					</AdCopyOutlineTable>
				</AdCopyOutlinePanelBody>
			</AdCopyOutlinePanel>
		</>
	);
});
