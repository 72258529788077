import * as React from "react";
import { Modal, Button, ButtonProps } from "react-bootstrap";

export type SwitchConfirmEvent = {
	label: string;
	variant?: ButtonProps["variant"];
	func: (...arg: any) => void;
};

export type SwitchConfirmProps = {
	title: string;
	body: string;
	show: boolean;
	events: SwitchConfirmEvent[];
};

export const SwitchConfirm = React.memo((props: SwitchConfirmProps) => {
	const { show, title, body, events } = props;
	return (
		<Modal show={show}>
			<Modal.Header>
				<div style={{ textAlign: "left" }}>{title}</div>
			</Modal.Header>
			<Modal.Body>
				<div style={{ whiteSpace: "pre-wrap" }}>{body}</div>
			</Modal.Body>
			<Modal.Footer style={{ textAlign: "right" }}>
				{events.map((event, index) => {
					return (
						<Button key={`event-${index}`} variant={event.variant || "secondary"} onClick={event.func}>
							{event.label}
						</Button>
					);
				})}
			</Modal.Footer>
		</Modal>
	);
});
