import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import { AdCopyRole } from "../../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../../server/models/account";
import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import { AdCopyGraphWrapArea } from "../common";
import { AdCopyReportBigTitle } from "../parts/report-title";

export type AdCopyDownloadType = "question-sheet" | "rawdata";

type Props = {
	job: AdCopyJob;
	loginAccount: AccountWithoutPassword;
	onDownload: (type: AdCopyDownloadType) => void;
};

export const AdCopyReportDownloadPage = React.memo((props: Props) => {
	const { job, loginAccount, onDownload } = props;

	const onRawdataDownload = React.useCallback(() => {
		onDownload("rawdata");
	}, [onDownload]);

	const onQuestionSheetDownload = React.useCallback(() => {
		onDownload("question-sheet");
	}, [onDownload]);

	const canDownloadRawdata = React.useMemo(() => AdCopyRole.hasOperarorManageRole(loginAccount), [loginAccount]);

	return (
		<>
			<AdCopyReportBigTitle>データダウンロード{job ? `（${job.jobNum}）` : ""}</AdCopyReportBigTitle>

			<AdCopyGraphWrapArea>
				<Row>
					{canDownloadRawdata && (
						<Col md={3}>
							<Button onClick={onRawdataDownload} variant="outline-secondary">
								ローデータDownload
							</Button>
						</Col>
					)}

					<Col md={3}>
						<Button onClick={onQuestionSheetDownload} variant="outline-secondary">
							質問票Download
						</Button>
					</Col>
				</Row>
			</AdCopyGraphWrapArea>
		</>
	);
});
