import * as React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { SingleSelect } from "../../../parts/select";
import { JobStatus, JobStatusOptions } from "../../../../../../server/models/status";
import { getErrorMessage } from "../../../../lib/error";
import { ErrorObject } from "../../../../../../server/types/error";

export type JobStatusPageProps = {
	status: keyof typeof JobStatus;
	onChnage: (status: keyof typeof JobStatus) => void;
	onSubmit: () => void;
	errors?: ErrorObject;
};

export const JobStatusPage = React.memo((props: JobStatusPageProps) => {
	const { status, onChnage, onSubmit, errors } = props;
	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■Jobステータス更新</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} className="mb-3">
					<Row className="m-3">
						<Col md={12}>
							<Row className="mt-3">
								<Col md={3} className={"text-end"}>
									ステータス更新：
								</Col>
								<Col>
									<SingleSelect
										options={JobStatusOptions}
										value={status ? status.toString() : ""}
										onChange={(value) => {
											onChnage(value as keyof typeof JobStatus);
										}}
										error={getErrorMessage("status", errors)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="mt-5">
				<Col className="text-center">
					<Button variant="secondary" onClick={onSubmit}>
						変更する
					</Button>
				</Col>
			</Row>
		</div>
	);
});
