import * as React from "react";

import { OneNumberScoreData } from "../../../../../../../../server/models/graph/demand-forecast";
import { BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";
import { ConceptType } from "../../../../../../../../server/models/concept";
import styled from "styled-components";

export type OneNumberScoreGraphProps = {
	data: OneNumberScoreData[];
	max: number;
};
const GraphArea = styled.div`
	position: relative;
`;
const LabelArea = styled.div<{ right: number }>`
	position: absolute;
	top: -3px;
	right: ${(props: { right: number }) => props.right}px;
`;

const barSize = 20;
export const OneNumberScoreDetailGraph = React.memo((props: OneNumberScoreGraphProps) => {
	const { data, max } = props;
	const barData = React.useMemo(() => {
		return data.map((d) => {
			return {
				label: `${ConceptType[d.conceptType]}（${d.sampleSize}s）`,
				/*/
				{
					text: `${ConceptType[d.conceptType]}（${d.sampleSize}s）`,
					fill: d.sampleSize < 30 ? "red" : "black",
				},
				/*/
				val: d.score,
			};
		});
	}, [data]);
	return (
		<GraphArea>
			<LabelArea right={0}>（上限）</LabelArea>
			<LabelArea right={275}>（下限）</LabelArea>
			<BarChart
				data={barData}
				layout={"vertical"}
				height={(barSize + 5) * 2 * barData.length + 30}
				width={430}
				margin={{ top: 5, right: 30, left: 70, bottom: 5 }}
				barSize={barSize}
			>
				<XAxis type={"number"} domain={[0, max]} orientation={"top"} />
				<YAxis
					type={"category"}
					dataKey={"label"}
					tick={(props) => {
						console.log(props);
						const { payload, width, height, x, y, textAnchor, stroke } = props;
						const match = payload.value.toString().match(/（(\d+)s）$/);
						const fill = match && match[1] < 30 ? "red" : props.fill;
						return (
							<text {...{ width, height, x, y, textAnchor, stroke, fill }}>
								<tspan x={x} dy={"0.355em"}>
									{payload.value}
								</tspan>
							</text>
						);
					}}
				/>
				<Bar dataKey={"val"} fill={"#aaa"}>
					<LabelList dataKey={"val"} position={"right"} formatter={(v: unknown) => `${Number(v).toFixed(1)}`} />
				</Bar>
			</BarChart>
		</GraphArea>
	);
});
