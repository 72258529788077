import * as React from "react";
import { DemandForecast } from "../../../../../../../server/models/graph/demand-forecast";
import { ReportTitleArea, ReportTitleRect, ReportTitleText } from "../../parts/report-title";
import { StockedBarGraph, StandardLine, StokedBarGroup } from "../../../../parts/graph/stocked-bar-graph";
import { ConceptType } from "../../../../../../../server/models/concept";
import styled from "styled-components";
import { Job } from "../../../../../../../server/models/job";
import { getAnalyticsVersion } from "../../../../../lib/action-standard";
import { TextRed } from "../../../../parts/font";

const ReportTable = styled.table`
	td,
	th {
		border: 1px solid #888;
		padding: 5px;
		text-align: center;
	}
	th {
		background-color: #ddd;
	}
`;

export type PotentialDemandProps = {
	demandForecast: DemandForecast[];
	goalSalesVolume: number;
	job: Job;
};

export const PotentialDemand = React.memo((props: PotentialDemandProps) => {
	const { demandForecast, goalSalesVolume, job /*, jobCode, salesVolumePrediction*/ } = props;
	const viewhalfLine = React.useMemo(() => {
		const version = getAnalyticsVersion(job.endDatetime);
		return version === "v1" || version === "v2";
	}, [job.endDatetime]);
	const data = React.useMemo<StokedBarGroup[]>(() => {
		return demandForecast.map((df) => {
			return {
				label: ConceptType[df.conceptType],
				total: df.sellPotential.total,
				bars: [
					/*/
					{
						label: "トライアル",
						value: df.sellPotential.trial,
						color: "red",
					},
					{
						label: "リピート",
						value: df.sellPotential.repeat,
						color: "aqua",
					},
					{
						label: "ローカルその他",
						value: df.sellPotential.localOther,
						color: "yellow",
					},
					{
						label: "インバウンド",
						value: df.sellPotential.inbound,
						color: "grey",
					},
					/*/
					{
						label: "トライアル",
						value: df.sellPotential.trial,
						color: "#f56302",
					},
					{
						label: "リピート",
						value: df.sellPotential.repeat,
						color: "#ffc000",
					},
					{
						label: "ローカルその他",
						value: df.sellPotential.localOther,
						color: "#83db4d",
					},
					{
						label: "インバウンド",
						value: df.sellPotential.inbound,
						color: "#6492e3",
					},
				],
			};
		});
	}, [demandForecast]);
	const goal50percent = React.useMemo(() => (goalSalesVolume * 10 * 5) / 100, [goalSalesVolume]);
	const lines = React.useMemo<StandardLine[]>(() => {
		const tmp: any[] = [
			{
				value: goalSalesVolume,
				color: "red",
				label: "目標値",
				width: 2,
			},
		];
		if (viewhalfLine) {
			tmp.push({
				value: goal50percent,
				color: "red",
				label: "△50%ライン",
				dashed: true,
				width: 2,
			});
		}
		return tmp;
	}, [goalSalesVolume, goal50percent, viewhalfLine]);
	const graphBase = React.useMemo(() => {
		const tmp = Math.max(...data.map((d) => d.bars.reduce((a, b) => a + b.value, 0)), goalSalesVolume);
		if (tmp < 10) {
			const max = Math.floor(tmp + 1);
			const step = Math.floor((max / 10) * 100) / 100;
			return { max, step };
		}
		const max = Math.ceil(Math.round(tmp / 10) + 1) * 10;
		let step = Math.ceil(max / 10);
		if (step > 5 && step < 10) {
			step = 10;
		} else if (step % 10 !== 0) {
			step = Math.ceil(step / 10) * 10;
		}
		return { max, step };
	}, [data, goalSalesVolume]);
	const test1 = React.useMemo(() => demandForecast.find((df) => df.conceptType === "test1"), [demandForecast]);
	const test2 = React.useMemo(() => demandForecast.find((df) => df.conceptType === "test2"), [demandForecast]);
	return (
		<>
			<ReportTitleArea>
				<ReportTitleRect />
				<ReportTitleText>ポテンシャル需要（発売後12か月お客さま購買個数）</ReportTitleText>
				<div>
					<TextRed>※ 変数補正前・CP実績補正前の初期値</TextRed>
				</div>
			</ReportTitleArea>

			<ReportTable>
				<thead>
					<tr>
						<th></th>
						<th>{ConceptType.test1}</th>
						{!!test2 && <th>{ConceptType.test2}</th>}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>目標値</td>
						<td colSpan={test2 ? 2 : 1}>{goalSalesVolume.toFixed(1)}</td>
					</tr>
					{viewhalfLine && (
						<tr>
							<td>△50%ライン</td>
							<td colSpan={test2 ? 2 : 1}>{goal50percent.toFixed(1)}</td>
						</tr>
					)}
					<tr>
						<td>調査結果</td>
						{test1 && <td>{test1.sellPotential.total.toFixed(1)}</td>}
						{test2 && <td>{test2.sellPotential.total.toFixed(1)}</td>}
					</tr>
				</tbody>
			</ReportTable>
			<StockedBarGraph
				width={540}
				lines={lines}
				barGroups={data}
				max={graphBase.max}
				step={graphBase.step}
				unit={"（年間数量：単位　万個）"}
			/>
		</>
	);
});
