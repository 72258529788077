import * as React from "react";
import { reducer, initState } from "../../../reducers/job/invoice";
import { Loading } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { InvoicePage } from "../../../components/pages/invoice";
import { GetInvoiceResponse } from "../../../../../server/types/request/invoice";
import { get, makeError } from "../../../lib/request";
import { useParams } from "react-router-dom";
import { endpoint } from "../../../../../server/router/endpoint";

export const InvoiceContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, invoiceInfo } = state;
	const { jobId } = useParams<{ jobId: string }>();
	React.useEffect(() => {
		get<GetInvoiceResponse>(`${endpoint.invoice}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadInvoice", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			{invoiceInfo && <InvoicePage {...invoiceInfo} />}
		</>
	);
};
