import * as React from "react";
import { AggregateProperty, AggregatePropertyProps } from "./aggregate-property";
import { CrossAggregate } from "../../../../../server/models/cross-aggregate";
import { Option } from "../../../../../server/types/request";
import { Activity } from "../../../../../server/models/activity";
import { ErrorObject } from "../../../../../server/types/error";
import { AggregateJobEditor } from "@brix/editor";
import { type Question } from "@bleu/core";
import { type AggregateTargetQuestionType } from "@brix/core";
import { EditableAggregateJob } from "../../../types/aggregate";
import { Error as LUError } from "@lu/request";

export type AggregateSettingProps = {
	activity: Activity;
	aggregateQuestions: Question<AggregateTargetQuestionType>[];
	vertical?: boolean;
	aggregateJob: EditableAggregateJob;
	crossAggregate: Partial<CrossAggregate>;
	errors?: ErrorObject;
	onChangeAggregate: AggregatePropertyProps["onChange"];
	handleUpdate: (payload: EditableAggregateJob) => void;
	targetGroupOptions: Option[];
	conceptOptions: Option[];
	editorError: LUError | undefined;
};
export const AggregateSetting = React.memo((props: AggregateSettingProps) => {
	const {
		crossAggregate,
		handleUpdate,
		aggregateJob,
		aggregateQuestions,
		onChangeAggregate,
		conceptOptions,
		targetGroupOptions,
		activity,
		errors,
		editorError,
	} = props;

	return (
		<>
			<AggregateJobEditor
				questions={aggregateQuestions}
				aggregateJob={aggregateJob}
				variants={{
					controlSecondary: "outline-primary",
				}}
				onUpdate={handleUpdate}
				error={editorError}
			>
				<AggregateProperty
					activity={activity}
					crossAggregate={crossAggregate}
					onChange={onChangeAggregate}
					conceptOptions={conceptOptions}
					targetGroupOptions={targetGroupOptions}
					errors={errors}
				/>
			</AggregateJobEditor>
		</>
	);
});
