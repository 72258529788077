import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";

type Props = {
	className?: string;
	isShow: boolean;
};

const _AccordionIcon = (props: Props) => {
	const { className, isShow } = props;

	return (
		<FontAwesomeIcon
			className={className}
			color="#aaa"
			icon={isShow ? faChevronDown : faChevronRight}
			size="sm"
			style={{ width: "0.875em" }}
		/>
	);
};

export const AccordionIcon = memo(_AccordionIcon);
