import * as React from "react";
import { Navbar } from "react-bootstrap";

export const NoMenuNavigationBar = () => {
	return (
		<Navbar bg={"dark"} variant="dark" expand="lg" className="px-3">
			<Navbar.Brand href="#home" className="fw-bold">
				Test it
			</Navbar.Brand>
		</Navbar>
	);
};
