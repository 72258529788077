import * as React from "react";
import { endpoint } from "../../../../server/router/endpoint";
import { get } from "../../lib/request";
import { GetProgressStatusResponse } from "../../../../server/types/request/activity";
import { useParams, Navigate } from "react-router-dom";
import { clientEndpoint } from "../../routes/endpoint";

export type ActivityProgressProps = {
	children: (isEditalbe: boolean) => React.ReactNode;
};
export const ActivityProgress = React.memo((props: ActivityProgressProps) => {
	const { children } = props;
	const [requested, setRequested] = React.useState<boolean>(false);
	const [isEditalbe, setIsEditalbe] = React.useState<boolean>();
	const { activityId } = useParams<{ activityId: string }>();
	React.useEffect(() => {
		if (activityId === "new") {
			setRequested(true);
			setIsEditalbe(true);
			return;
		}
		get<GetProgressStatusResponse>(`${endpoint.activityProgressStatus}/${activityId}`)
			.then((response) => {
				setIsEditalbe(response.data.status === "preparation");
				setRequested(true);
			})
			.catch((err) => {
				console.error(err);
				setRequested(true);
			});
	}, [activityId]);
	return (
		<>
			{requested &&
				(isEditalbe !== undefined ? (
					children(isEditalbe)
				) : (
					// 認証がない場合はloginへ遷移
					<Navigate to={clientEndpoint.permissionDenied} />
				))}
		</>
	);
});
