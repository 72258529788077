import * as React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Job } from "../../../../../../server/models/job";
import { ActivityWithBrand } from "../../../../../../server/models/activity";
import { type SimpleQuestion } from "@muscat/types";
import { Concept } from "../../../../../../server/models/concept";
import { JobDetail } from "../../../parts/job-detail";

export type JobDetailPageProps = {
	activity: ActivityWithBrand;
	job: Job;
	evaluationItem?: string[];
	question?: SimpleQuestion;
	concepts: Concept[];
	estimate: string;
	onJobList: () => void;
};

export const JobDetailPage = React.memo((props: JobDetailPageProps) => {
	const { onJobList, ...jobDetailProps } = props;
	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■ジョブ詳細</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} className="mb-3">
					<JobDetail {...jobDetailProps} />
				</Col>
			</Row>
			<Row className="mt-5">
				<Col className="text-center">
					<Button variant="outline-secondary" onClick={onJobList}>
						ジョブ一覧に戻る
					</Button>
				</Col>
			</Row>
		</div>
	);
});
