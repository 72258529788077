import * as React from "react";
import { PasswordResetMailPage } from "../../components/pages/password-reset-mail";
import { useNavigate } from "react-router-dom";
import { post, makeError } from "../../lib/request";
import { endpoint } from "../../../../server/router/endpoint";
import { reducer, initState } from "../../reducers/password-reset";
import { Loading } from "../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../components/parts/confirm-box";
import { clientEndpoint } from "../../routes/endpoint";

export const PasswordResetMailContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info } = state;
	const navigate = useNavigate();
	const onSubmit = React.useCallback(
		(email: string) => {
			dispatch({ type: "changeLoading", payload: true });
			post<{}>(`${endpoint.passwordReset}`, { email })
				.then(() => {
					navigate(clientEndpoint.successPasswordResetMail);
				})
				.catch((error) => {
					dispatch({ type: "changeMessageInfo", payload: makeError(error) });
				});
		},
		[navigate],
	);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} />
			<PasswordResetMailPage onSubmit={onSubmit} />
		</>
	);
};
