import classNames from "classnames";
import * as React from "react";
import { FormControl } from "react-bootstrap";
import Select from "react-select";

import { type AdCopySelectOption, AdCopySelectWrap } from ".";

type Props = Pick<React.ComponentProps<Select>, "placeholder"> & {
	error?: string;
	onChange: (value: (number | string)[]) => void;
	options: AdCopySelectOption[];
	value: (number | string)[];
};

export const AdCopyMultipleSelect = React.memo((props: Props) => {
	const { error, onChange, options, placeholder = "選択してください", value } = props;

	const selected = React.useMemo(() => options.filter((c) => value.includes(c.value)), [value, options]);

	const onChangeSelect = React.useCallback(
		(selected: AdCopySelectOption[]) => {
			onChange(selected ? selected.map((s) => s.value) : []);
		},
		[onChange],
	);

	return (
		<AdCopySelectWrap>
			<Select
				className={classNames("react-select-container", { "react-select-container--invalid": !!error })}
				classNamePrefix="react-select"
				isMulti
				isSearchable={false}
				onChange={onChangeSelect as React.ComponentProps<Select>["onChange"]}
				options={options}
				placeholder={placeholder}
				value={selected}
			/>

			{error && (
				<FormControl.Feedback style={{ display: "block" }} type="invalid">
					{error}
				</FormControl.Feedback>
			)}
		</AdCopySelectWrap>
	);
});
