import * as React from "react";
import styled from "styled-components";
import { Job } from "../../../../../../server/models/job";
import { ActivityWithBrand } from "../../../../../../server/models/activity";
import { ActivityPanel } from "./activity-panel";
import { TestPanel } from "./test-panel";
import { ConceptPanel } from "./concept-panel";
import { Concept } from "../../../../../../server/models/concept";
import { Sample } from "../../../../../../server/models/graph/outline";
import { GraphWrapArea } from "../common";

export const OutlinePanelBody = styled.div`
	padding: 0 1em;
`;
export const IndentArea = styled.div<{ indent?: number }>`
	margin-left: ${({ indent }) => indent || 2}em;
`;

export const OutlinePanelTitle = styled.div`
	font-weight: bold;
	text-decoration: underline;
	margin-bottom: 10px;
`;
export const OutlinePanel = styled.div`
	//border: 1px solid;
	padding: 10px;
`;
export const OutlineSubTitle = styled.div`
	margin-bottom: 0.3em;
`;
export const ChildPanel = styled.div`
	margin-bottom: 0.8em;
`;
export const OutlineCard = styled.div<{ width: number }>`
	display: inline-block;
	vertical-align: top;
	width: ${({ width }) => width}px;
`;

export const OutlineTable = styled.table`
	margin: 0px auto;
	margin-left: 0px;
	& td.colon {
		text-align: right;
	}
	& td {
		vertical-align: top;
	}
	& tr.sub-title td {
		padding-top: 5px;
		border-bottom: 1px solid #ddd;
	}
	& tr.strategy-category-head td,
	tr.bordered td,
	tr.bordered th {
		border-bottom: 1px solid #ddd;
	}
`;

export type OutlineProps = {
	activity: ActivityWithBrand;
	job: Job;
	concepts: Concept[];
	samples: Sample[];
	benchmarkName?: string;
	onViewQuotaPage: (jobId: string) => void;
};
export const Outline = React.memo((props: OutlineProps) => {
	const { activity, job, concepts, samples, onViewQuotaPage, benchmarkName } = props;
	const onViewQuota = React.useCallback(() => onViewQuotaPage(job._id.toString()), [onViewQuotaPage, job]);
	return (
		<GraphWrapArea style={{ minWidth: "1180px" }}>
			<ActivityPanel activity={activity} />
			<TestPanel
				activity={activity}
				job={job}
				samples={samples}
				onViewQuotaPage={onViewQuota}
				benchmarkName={benchmarkName}
			/>
			<ConceptPanel concepts={concepts} />
		</GraphWrapArea>
	);
});
