import { JobWithSurveyStatus } from "../../../../../server/models/job";
import { SurveyStatus } from "../../../../../server/models/status";
import { GetJobSearchResponse } from "../../../../../server/types/request/job";

import { MessageInfo } from "../../../types/info";
import { JobSearchAction } from "../../../types/actions";

type State = GetJobSearchResponse & {
	searches: { [key in keyof JobWithSurveyStatus]?: JobWithSurveyStatus[key] };
	loading: boolean;
	info: MessageInfo;
	result: "empty" | "non-empty" | "initial";
};

export const initState: State = {
	searches: {},
	data: [],
	accountOptions: [],
	loading: false,
	info: {
		message: "",
		isSuccess: true,
	},
	result: "initial",
};

export const reducer = (state: State, action: JobSearchAction): State => {
	switch (action.type) {
		case "changeSearches": {
			const searches = { ...state.searches, [action.payload.name]: action.payload.value };

			if (action.payload.value === "") {
				delete searches[action.payload.name];
			}

			return {
				...state,
				searches,
			};
		}

		case "setJob":
			return {
				...state,
				...action.payload,
				result: action.payload.data.length === 0 ? "empty" : "non-empty",
				loading: false,
			};

		case "deleteJob":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: `削除が完了しました。`,
				},
				data: state.data
					.map((d) => {
						const jobs = d.jobs.filter((job) => {
							return job._id.toString() !== action.payload;
						});
						return { ...d, jobs };
					})
					.filter(({ jobs }) => jobs.length > 0),
			};

		case "changeJobStatus":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: `ステータスを「${SurveyStatus[action.payload.surveyStatus]}」に変更しました。`,
				},
				data: state.data.map((d) => {
					const jobs = d.jobs.map((job) => {
						if (job._id.toString() === action.payload.jobId) {
							return {
								...job,
								status: action.payload.status,
								surveyStatus: action.payload.surveyStatus,
							};
						}
						return job;
					});
					return { ...d, jobs };
				}),
			};

		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};

		case "changeLoading":
			return { ...state, loading: action.payload };

		default:
			return state;
	}
};
