import type { Quota } from "@muscat/types";

import type { AdCopyQuotaAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	info: MessageInfo;
	loading: boolean;
	quotas: Quota[];
};

export const initState: State = {
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	quotas: [],
};

export const reducer = (state: State, action: AdCopyQuotaAction): State => {
	switch (action.type) {
		case "setQuotas":
			return {
				...state,
				loading: false,
				quotas: action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
