import * as React from "react";
import { decimalCalc } from "../../../../../../../../server/lib/aggregate/common";

export type GoalTableProps = {
	cpCorrectionValue: number;
};

export const CPCorrectionValueTable = React.memo((props: GoalTableProps) => {
	const { cpCorrectionValue } = props;
	const correctionValue = React.useMemo(
		() => decimalCalc(cpCorrectionValue, Math.pow(10, 3)).toFixed(3),
		[cpCorrectionValue],
	);
	return (
		<table className={"correct-table"} style={{ width: "20%", display: "inline-table", marginLeft: "10%" }}>
			<thead>
				<tr>
					<th>実数補正係数</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{correctionValue}</td>
				</tr>
			</tbody>
		</table>
	);
});
