import { ObjectId } from "mongodb";
import { JobStatus, SurveyStatus } from "./status";

export enum SurveyMethod {
	"concept-assessment" = "コンセプトアセスメントテスト",
}
export const SurveyMethodOptions = Object.entries(SurveyMethod)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

// jobのmodelを仮定義
export type Job = {
	_id?: ObjectId | string; //ジョブID
	activityId: ObjectId | string;
	jobNum: string; // 20JPTC0001のような形になる。
	// jobNum: number; //アクティビティ毎に振られるjob番号のautoincrement
	accountId: ObjectId | string; //作成者
	startDatetime: string; // 開始日
	endDatetime: string; // 終了日
	hasTwoTest: boolean; // テスト品が2品かどうかのフラグ
	hasCurrentProduct: boolean; // 現行品の有無
	hasBenchmark: boolean; // 競合品の有無
	benchmarkName?: string; // 競合品製品名
	hasStrategicTarget: boolean; //戦略ターゲットGの有無
	retestJobId?: string | ObjectId; // 再テスト調査
	status: keyof typeof JobStatus;
	method: keyof typeof SurveyMethod; // 調査手法
	enqueteId: string; //muscatのアンケートID
	createdAt?: string;
	updatedAt?: string;
	inspection?: Inspection;
};

export type Inspection = {
	accountId: ObjectId | string; //承認者
	inspectDatetime: string;
};

export type JobWithSurveyStatus = Job & {
	surveyStatus: keyof typeof SurveyStatus; // 表示されるジョブのステータス。
};
