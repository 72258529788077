import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faEdit } from "@fortawesome/free-solid-svg-icons";

export const EditableAreaWrap = styled.div`
	margin: 5px;
	position: relative;
	border-bottom: 1px solid #ddd;
	& input,
	textarea {
		margin-bottom: 6px;
	}
`;

export type EditableAreaFunc = {
	close: () => void;
	open: () => void;
};
export type EditableAreaProps = {
	editChildren: React.ReactNode;
	viewChildren: React.ReactNode;
	onClose?: () => void;
	onOpen?: () => void;
	isFocus: boolean;
};
export const EditableArea = React.forwardRef((props: EditableAreaProps, ref?: React.Ref<EditableAreaFunc>) => {
	const { editChildren, viewChildren, isFocus } = props;
	const [editMode, setEditMode] = React.useState<boolean>(false);
	const onClose = React.useCallback(() => {
		setEditMode(false);
		if (props.onClose) props.onClose();
	}, [props]);
	const onOpen = React.useCallback(() => {
		setEditMode(true);
		if (props.onOpen) props.onOpen();
	}, [props]);
	React.useImperativeHandle(
		ref,
		() => ({
			close: () => setEditMode(false),
			open: () => setEditMode(true),
		}),
		[],
	);
	return (
		<EditableAreaWrap onClick={() => !editMode && isFocus && onOpen()} onFocus={() => !editMode && isFocus && onOpen()}>
			{editMode && isFocus && (
				<>
					<FontAwesomeIcon
						icon={faTimesCircle}
						onClick={onClose}
						style={{
							position: "absolute",
							right: "3px",
							top: "2px",
							zIndex: 10,
						}}
					/>
					{editChildren}
				</>
			)}
			{!editMode && isFocus && (
				<>
					<FontAwesomeIcon tabIndex={0} icon={faEdit} onClick={onOpen} style={{ float: "right" }} />
					{viewChildren}
				</>
			)}
			{!isFocus && viewChildren}
		</EditableAreaWrap>
	);
});
