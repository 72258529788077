import * as React from "react";
import { type Choice } from "@muscat/types";
import { Button, FormControl, FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import styled from "styled-components";
import Feedback from "react-bootstrap/esm/Feedback";
import { getErrorMessage } from "../../../../../lib/error";
import { ErrorObject } from "../../../../../../../server/types/error";

const ChoiceTable = styled.table`
	width: 100%;
	th,
	td {
		padding: 5px;
		text-align: center;
		button {
			margin: 3px;
		}
	}
	tr {
		border-bottom: 1px solid #ddd;
	}
`;
const ButtonArea = styled.div`
	text-align: right;
	padding: 10px 100px 10px 10px;
`;

export type ChoiceSettingProps = {
	choices: Choice[];
	type: "S" | "M";
	errors?: ErrorObject | string;
	onAddChoice: (index: number) => void;
	onDeleteChoice: (index: number) => void;
	onChangeChoice: (index: number, name: keyof Choice, value: Choice[keyof Choice]) => void;
};

export const ChoiceSetting = React.memo((props: ChoiceSettingProps) => {
	const { choices, onChangeChoice, onAddChoice, onDeleteChoice, type, errors } = props;
	const onAdd = React.useCallback(
		(index: number) => {
			onAddChoice(index);
		},
		[onAddChoice],
	);
	return (
		<FormGroup>
			<FormLabel>選択肢設定</FormLabel>
			{!!errors && typeof errors === "string" && (
				<Feedback type={"invalid"} style={{ display: "block" }}>
					{errors}
				</Feedback>
			)}
			<ChoiceTable>
				<thead>
					<tr>
						<th>No.</th>
						<th>選択肢名称</th>
						{type === "M" && <th>排他</th>}
						<th></th>
					</tr>
				</thead>
				<tbody>
					{choices.map((choice, index) => (
						<tr key={`choice-${choice.value}`}>
							<td>{choice.value}</td>
							<td>
								<FormControl
									as={"textarea"}
									value={choice.text}
									onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
										onChangeChoice(index, "text", e.target.value)
									}
									isInvalid={
										!!errors &&
										typeof errors === "object" &&
										!!errors[index] &&
										typeof errors[index] === "object" &&
										!!(errors[index] as ErrorObject)["text"]
									}
								/>
								{!!errors &&
									typeof errors === "object" &&
									!!errors[index] &&
									typeof errors[index] === "object" &&
									!!(errors[index] as ErrorObject)["text"] && (
										<Feedback type={"invalid"} style={{ display: "block" }}>
											{getErrorMessage("text", errors, index)}
										</Feedback>
									)}
							</td>
							{type === "M" && (
								<td>
									<FormCheck
										checked={choice.exclusive}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											onChangeChoice(index, "exclusive", e.target.checked)
										}
									/>
								</td>
							)}
							<td>
								<Button size={"sm"} variant={"secondary"} onClick={() => onAdd(index)}>
									+
								</Button>
								<Button size={"sm"} variant={"danger"} onClick={() => onDeleteChoice(index)}>
									×
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</ChoiceTable>
			<ButtonArea>
				<Button size={"sm"} variant={"secondary"} onClick={() => onAdd(choices.length)}>
					選択肢追加
				</Button>
			</ButtonArea>
		</FormGroup>
	);
});
