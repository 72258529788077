/**
 * shared server & frontend
 */

import { AccountWithoutPassword } from "../../models/account";
import { Brand } from "../../models/brand";

// 毎度展開しているのはつらいので、定義しておく。
const targetBrands = getAvailableAdCopyNBrands();
const useAdCopyBrandsFilter = evaluateUseAdCopyBrandsFilter();

/**
 * @param {Brand[]} brands
 * @returns {Brand[]}
 * @example
 * USE_AD_COPY_BRANDS_FILTER === false -> return brands
 * AD_COPY_BRANDS.length === 0 -> return brands
 */
export const filterAdCopyBrands = (brands: Brand[]): Brand[] => {
	if (!useAdCopyBrandsFilter) return brands;
	if (targetBrands.length === 0) return brands;
	return brands.filter((brand) => targetBrands.includes(brand.nBrand));
};

/**
 * USE_AD_COPY_BRANDS_FILTER の取得
 * @returns {boolean}
 * @example
 * USE_AD_COPY_BRANDS_FILTER === true -> true
 * Other than above -> false
 */
export function evaluateUseAdCopyBrandsFilter(): boolean {
	const useAdCopyBrandsFilter = process.env.USE_AD_COPY_BRANDS_FILTER;
	console.log("[USE_AD_COPY_BRANDS_FILTER]", process.env.USE_AD_COPY_BRANDS_FILTER);
	if (!useAdCopyBrandsFilter) {
		return false;
	}
	const normalizedValue = useAdCopyBrandsFilter.toLowerCase();
	if (normalizedValue === "true") {
		return true;
	} else if (normalizedValue === "false") {
		return false;
	}
	return false;
}

/**
 * 環境変数からコピーテストで利用可能なnBrandsを取得
 * - AD_COPY_BRANDS
 * @returns {number[]}
 */
export function getAvailableAdCopyNBrands(): number[] {
	console.log("[AD_COPY_BRANDS]", process.env.AD_COPY_BRANDS);
	return (process.env.AD_COPY_BRANDS ?? "").split(",").map(Number);
}

/**
 * @param {AccountWithoutPassword} account
 * @returns {boolean}
 * @example
 * USE_AD_COPY_BRANDS_FILTER === false -> true
 * account.viewAdCopyBrands > 0 -> true
 * Other than above -> false
 */
export function hasViewAdCopyBrands(account: AccountWithoutPassword): boolean {
	if (!useAdCopyBrandsFilter) return true;
	return account.viewAdCopyBrands?.length > 0;
}
