import * as React from "react";
import { FormGroup, FormCheck, Col } from "react-bootstrap";
import { Choice } from "../../types/column";

export type RadiosProps = {
	checkOff?: boolean;
	inline?: boolean;
	choices: Choice[];
	checked: Choice["value"];
	name: string;
	disabled?: boolean;
	onChange: (checkeds: Choice["value"]) => void;
};
export const Radios = React.memo((props: RadiosProps) => {
	const { checked, onChange, choices, inline, checkOff, name, disabled } = props;
	const onChangeRadio = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, value: Choice["value"]) => {
			if (e.target.checked) {
				onChange(value);
			}
		},
		[onChange],
	);
	const onClick = React.useCallback(
		(value: Choice["value"]) => {
			console.log(value, checked);
			if (checkOff && value === checked) {
				onChange(undefined);
			}
		},
		[onChange, checkOff, checked],
	);

	return (
		<FormGroup as={Col} className="position-relative w-100 px-3">
			{choices.map((choice) => (
				<FormCheck
					type={"radio"}
					key={`checkbox-${choice.value}`}
					label={choice.label}
					value={choice.value.toString()}
					checked={checked === choice.value}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeRadio(e, choice.value)}
					onClick={() => onClick(choice.value)}
					name={name}
					inline={inline || false}
					style={inline ? { paddingRight: "10px" } : {}}
					disabled={disabled}
				/>
			))}
		</FormGroup>
	);
});
