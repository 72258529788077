import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "../components/pages/error";
import { LoginContainter } from "../containers/login";
import { WithAuth } from "../containers/auth";
import { AccountContariner } from "../containers/account";
import { ActivityListContainer } from "../containers/activity/list";
import { ActivityCreateContainer } from "../containers/activity/create";
import { JobCreateContariner } from "../containers/job/create";
import { JobListContainer } from "../containers/job/list";
import { JobAllContainer } from "../containers/job/all";
import { JobSearchContainer } from "../containers/job/search";
import { ConceptContainer } from "../containers/concept/create";
import { ConceptPreviewContainer } from "../containers/concept/preview";
import { QuotaContainer } from "../containers/job/quota";
import { ApplicationContainer } from "../containers/job/application";
import { ApproveContainer } from "../containers/job/approve";
import { JobStatusContainer } from "../containers/job/status";
import { ActivityStatusContainer } from "../containers/activity/status";
import { PasswordResetMailContainer } from "../containers/password-reset-mail";
import { PasswordResetContainer } from "../containers/password-reset";
import { MessagePage } from "../components/pages/message-page";
import { SuccessPasswordResetContainer } from "../containers/success-password-reset";
import { LogoutContainter } from "../containers/logout";
import { PreviewContainer } from "../containers/preview";
import { InvoiceContainer } from "../containers/job/invoice";
import { JobDownloadContainer } from "../containers/job/download";
import { clientEndpoint } from "./endpoint";
import { OutlineContainer } from "../containers/report/outline";
import { ExectiveReportContainer } from "../containers/report/exective";
import { OAReportContainer } from "../containers/report/oa";
import { ResultByTargetReportContainer } from "../containers/report/result-by-target";
import { KeyMeasurementsReportContainer } from "../containers/report/key-measurements";
import { JobQuestionContainer } from "../containers/job/question";
import { ActivityMemberContainer } from "../containers/activity/member";
import { ActivityProgress } from "../containers/activity-progress";
import { JobDetailContainer } from "../containers/job/detail";
import { DownloadReportContainer } from "../containers/report/download";
import { SimulationContainer } from "../containers/report/simulation";
import { AggreagteCreateContainer } from "../containers/aggregate/create";
import { AggreagteContainer } from "../containers/aggregate/list";
import { AggregateViewConinar } from "../containers/aggregate/view";
import { InfoEditContainer } from "../containers/info/edit";
import { ConceptReportContainer } from "../containers/report/concept";

// トップページ
import { TopPage } from "../components/pages/top-page";
import { TopPageWithAuth } from "../containers/top-page-auth";

import { MenuPage } from "../components/pages/menu";

// ad-copy
import { adCopyClientEndpoint } from "./adCopyEndpoint";
import { AdCopyWithAuth } from "../containers/ad-copy/auth";
import { AdCopyActivityListContainer } from "../containers/ad-copy/activity/list";
import { AdCopyActivityProgress } from "../containers/ad-copy/activity-progress";
import { AdCopyActivityCreateContainer } from "../containers/ad-copy/activity/create";
import { AdCopyActivityMemberContainer } from "../containers/ad-copy/activity/member";
import { AdCopyActivityStatusContainer } from "../containers/ad-copy/activity/status";
import { AdCopyJobListContainer } from "../containers/ad-copy/job/list";
import { AdCopyJobCreateContariner } from "../containers/ad-copy/job/create";
import { AdCopyJobSearchContainer } from "../containers/ad-copy/job/search";
import { AdCopyJobDetailContainer } from "../containers/ad-copy/job/detail";
import { AdCopyJobDownloadContainer } from "../containers/ad-copy/job/download";
import { AdCopyConceptContainer } from "../containers/ad-copy/concept/create";
import { AdCopyPreviewContainer } from "../containers/ad-copy/preview";
import { AdCopyConceptPreviewContainer } from "../containers/ad-copy/concept/preview";
import { AdCopyApplicationContainer } from "../containers/ad-copy/job/application";
import { AdCopyApproveContainer } from "../containers/ad-copy/job/approve";
import { AdCopyQuotaContainer } from "../containers/ad-copy/job/quota";
import { AdCopyOutlineContainer } from "../containers/ad-copy/report/outline";
import { AdCopyKeyMeasurementsReportContainer } from "../containers/ad-copy/report/key-measurements";
import { AdCopyResultByTargetReportContainer } from "../containers/ad-copy/report/result-by-target";
import { AdCopyOAReportContainer } from "../containers/ad-copy/report/oa";
import { AdCopyDownloadReportContainer } from "../containers/ad-copy/report/download";
import { AdCopyConceptReportContainer } from "../containers/ad-copy/report/concept";
import { AdCopyInvoiceContainer } from "../containers/ad-copy/job/invoice";

export const router = createBrowserRouter([
	// login logout
	{ path: clientEndpoint.login, element: <LoginContainter /> },
	{ path: clientEndpoint.logout, element: <LogoutContainter /> },
	// password reset
	{ path: clientEndpoint.passwordResetMail, element: <PasswordResetMailContainer /> },
	{ path: clientEndpoint.passwordReset, element: <PasswordResetContainer /> },
	{ path: clientEndpoint.successPasswordReset, element: <SuccessPasswordResetContainer /> },
	{
		path: clientEndpoint.successPasswordResetMail,
		element: (
			<MessagePage>
				パスワード変更URLを送りました。\nメールに記載のURLよりパスワードのへ変更をお願いします。
			</MessagePage>
		),
	},
	{
		path: clientEndpoint.infoEditPage,
		element: (
			<TopPageWithAuth>
				<InfoEditContainer />
			</TopPageWithAuth>
		),
	},
	// account
	{
		path: clientEndpoint.account,
		element: <TopPageWithAuth>{(account) => <AccountContariner {...account} />}</TopPageWithAuth>,
	},
	// activity
	{
		path: clientEndpoint.activity,
		element: <WithAuth>{(account) => <ActivityListContainer {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.activityCreate,
		element: (
			<WithAuth>
				{(account) => (
					<ActivityProgress>
						{(isEditable) =>
							isEditable ? <ActivityCreateContainer {...account} /> : <ActivityMemberContainer {...account} />
						}
					</ActivityProgress>
				)}
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.activityStatus,
		element: (
			<WithAuth>
				<ActivityStatusContainer />
			</WithAuth>
		),
	},
	// job
	{
		path: clientEndpoint.job,
		element: <WithAuth>{(account) => <JobListContainer {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.jobAll,
		element: <WithAuth>{(account) => <JobAllContainer {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.jobSearch,
		element: <WithAuth>{(account) => <JobSearchContainer {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.jobDownload,
		element: (
			<WithAuth>
				<JobDownloadContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.jobCreate,
		element: <WithAuth>{(account) => <JobCreateContariner {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.jobCreateRetest,
		element: <WithAuth>{(account) => <JobCreateContariner {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.jobDetail,
		element: (
			<WithAuth>
				<JobDetailContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.jobStatus,
		element: (
			<WithAuth>
				<JobStatusContainer />
			</WithAuth>
		),
	},
	// 追加設問
	{
		path: clientEndpoint.question,
		element: (
			<WithAuth>
				<JobQuestionContainer />
			</WithAuth>
		),
	},
	// invoice
	{
		path: clientEndpoint.invoice,
		element: (
			<WithAuth>
				<InvoiceContainer />
			</WithAuth>
		),
	},
	// concept
	{
		path: clientEndpoint.concept,
		element: (
			<WithAuth>
				<ConceptContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.conceptPreview,
		element: (
			<WithAuth>
				<ConceptPreviewContainer />
			</WithAuth>
		),
	},
	// quota
	{
		path: clientEndpoint.quota,
		element: <WithAuth>{(account) => <QuotaContainer {...account} />}</WithAuth>,
	},
	// application approval
	{
		path: clientEndpoint.application,
		element: <WithAuth>{(account) => <ApplicationContainer {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.approval,
		element: <WithAuth>{(account) => <ApproveContainer {...account} />}</WithAuth>,
	},
	// preview
	{
		path: clientEndpoint.preview,
		element: (
			<WithAuth>
				<PreviewContainer />
			</WithAuth>
		),
	},
	// report
	{
		path: clientEndpoint.outline,
		element: (
			<WithAuth isReportPage>
				<OutlineContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.exectiveReport,
		element: (
			<WithAuth isReportPage>
				<ExectiveReportContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.resultByTargetReport,
		element: (
			<WithAuth isReportPage>
				<ResultByTargetReportContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.downloadReport,
		element: <WithAuth isReportPage>{(account) => <DownloadReportContainer {...account} />}</WithAuth>,
	},
	{
		path: clientEndpoint.conceptReport,
		element: (
			<WithAuth doNotUseTab>
				<ConceptReportContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.oaReport,
		element: (
			<WithAuth isReportPage>
				<OAReportContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.keyMeasurementsReport,
		element: (
			<WithAuth isReportPage>
				<KeyMeasurementsReportContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.simulationReport,
		element: <WithAuth isReportPage>{(account) => <SimulationContainer {...account} />}</WithAuth>,
	},
	// aggregate
	{
		path: clientEndpoint.aggregate,
		element: (
			<WithAuth>
				<AggreagteContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.aggtegateCreate,
		element: (
			<WithAuth>
				<AggreagteCreateContainer />
			</WithAuth>
		),
	},
	{
		path: clientEndpoint.aggtegateView,
		element: (
			<WithAuth>
				<AggregateViewConinar />
			</WithAuth>
		),
	},
	{ path: clientEndpoint.invalidUrl, element: <MessagePage>無効なURLです。</MessagePage> },
	{ path: clientEndpoint.permissionDenied, element: <ErrorPage text="Permission Denied" /> },

	// ad-copy

	// activity
	{
		path: adCopyClientEndpoint.activity,
		element: <AdCopyWithAuth>{(account) => <AdCopyActivityListContainer {...account} />}</AdCopyWithAuth>,
	},
	{
		path: adCopyClientEndpoint.activityCreate,
		element: (
			<AdCopyWithAuth>
				{(account) => (
					<AdCopyActivityProgress>
						{(isEditable) =>
							isEditable ? (
								<AdCopyActivityCreateContainer {...account} />
							) : (
								<AdCopyActivityMemberContainer {...account} />
							)
						}
					</AdCopyActivityProgress>
				)}
			</AdCopyWithAuth>
		),
	},
	{
		path: adCopyClientEndpoint.activityStatus,
		element: (
			<AdCopyWithAuth>
				<AdCopyActivityStatusContainer />
			</AdCopyWithAuth>
		),
	},
	// job
	{
		path: adCopyClientEndpoint.job,
		element: <AdCopyWithAuth>{(account) => <AdCopyJobListContainer {...account} />}</AdCopyWithAuth>,
	},
	{
		path: adCopyClientEndpoint.jobSearch,
		element: <AdCopyWithAuth>{(account) => <AdCopyJobSearchContainer {...account} />}</AdCopyWithAuth>,
	},
	{
		path: adCopyClientEndpoint.jobDownload,
		element: (
			<AdCopyWithAuth>
				<AdCopyJobDownloadContainer />
			</AdCopyWithAuth>
		),
	},
	{
		path: adCopyClientEndpoint.jobCreate,
		element: (
			<AdCopyWithAuth>
				<AdCopyJobCreateContariner />
			</AdCopyWithAuth>
		),
	},
	{
		path: adCopyClientEndpoint.jobCreateRetest,
		element: (
			<AdCopyWithAuth>
				<AdCopyJobCreateContariner />
			</AdCopyWithAuth>
		),
	},
	{
		path: adCopyClientEndpoint.jobDetail,
		element: (
			<AdCopyWithAuth>
				<AdCopyJobDetailContainer />
			</AdCopyWithAuth>
		),
	},
	// invoice
	{
		path: adCopyClientEndpoint.invoice,
		element: (
			<AdCopyWithAuth>
				<AdCopyInvoiceContainer />
			</AdCopyWithAuth>
		),
	},
	// concept
	{
		path: adCopyClientEndpoint.concept,
		element: (
			<AdCopyWithAuth>
				<AdCopyConceptContainer />
			</AdCopyWithAuth>
		),
	},
	{
		path: adCopyClientEndpoint.conceptPreview,
		element: (
			<AdCopyWithAuth>
				<AdCopyConceptPreviewContainer />
			</AdCopyWithAuth>
		),
	},
	// quota
	{
		path: adCopyClientEndpoint.quota,
		element: <AdCopyWithAuth>{(account) => <AdCopyQuotaContainer {...account} />}</AdCopyWithAuth>,
	},
	// application approval
	{
		path: adCopyClientEndpoint.application,
		element: <AdCopyWithAuth>{(account) => <AdCopyApplicationContainer {...account} />}</AdCopyWithAuth>,
	},
	{
		path: adCopyClientEndpoint.approval,
		element: <AdCopyWithAuth>{(account) => <AdCopyApproveContainer {...account} />}</AdCopyWithAuth>,
	},
	// preview
	{
		path: adCopyClientEndpoint.preview,
		element: (
			<AdCopyWithAuth>
				<AdCopyPreviewContainer />
			</AdCopyWithAuth>
		),
	},

	/**
	 * report
	 */
	// テストの概要
	{
		path: adCopyClientEndpoint.outline,
		element: (
			<AdCopyWithAuth isReportPage>
				<AdCopyOutlineContainer />
			</AdCopyWithAuth>
		),
	},
	// 結果の詳細
	{
		path: adCopyClientEndpoint.resultByTargetReport,
		element: (
			<AdCopyWithAuth isReportPage>
				<AdCopyResultByTargetReportContainer />
			</AdCopyWithAuth>
		),
	},
	// ダウンロード
	{
		path: adCopyClientEndpoint.downloadReport,
		element: (
			<AdCopyWithAuth isReportPage>{(account) => <AdCopyDownloadReportContainer {...account} />}</AdCopyWithAuth>
		),
	},
	// 提示コンセプト
	{
		path: adCopyClientEndpoint.conceptReport,
		element: (
			<AdCopyWithAuth doNotUseTab>
				<AdCopyConceptReportContainer />
			</AdCopyWithAuth>
		),
	},
	// OA
	{
		path: adCopyClientEndpoint.oaReport,
		element: (
			<AdCopyWithAuth isReportPage>
				<AdCopyOAReportContainer />
			</AdCopyWithAuth>
		),
	},
	// 集計表
	{
		path: adCopyClientEndpoint.keyMeasurementsReport,
		element: (
			<AdCopyWithAuth isReportPage>
				<AdCopyKeyMeasurementsReportContainer />
			</AdCopyWithAuth>
		),
	},

	// トップページ
	{
		path: "/",
		element: (
			<TopPageWithAuth>
				{/* {(account) => (hasViewAdCopyBrands(account) ? <TopPage /> : <Navigate to={clientEndpoint.activity} />)} */}
				{(account) => <TopPage account={account} />}
			</TopPageWithAuth>
		),
	},
	// メニューページ
	{
		path: clientEndpoint.menu,
		element: <TopPageWithAuth>{(account) => <MenuPage {...account} />}</TopPageWithAuth>,
	},

	// 404
	{ path: "*", element: <ErrorPage text="Not Found" /> },
]);
