import * as React from "react";
import styled from "styled-components";

const ReportTitleText = styled.div`
	display: inline-block;
	padding-left: 10px;
	font-weight: bold;
	font-size: 1.3em;
	vertical-align: middle;
`;

const ReportBigTitleText = styled.div`
	display: inline-block;
	padding-left: 10px;
	font-weight: bold;
	font-size: 1.4em;
	vertical-align: middle;
`;

const ReportTitleRect = styled.div`
	display: inline-block;
	height: 20px;
	width: 20px;
	background-color: #bbb;
	vertical-align: middle;
`;

const ReportTitleArea = styled.div`
	margin: 20px 0px 30px 0px;
	position: relative;
`;

export const AdCopyReportTitle = React.memo((props: React.PropsWithChildren) => {
	const { children } = props;

	return (
		<ReportTitleArea>
			<ReportTitleRect />

			<ReportTitleText>{children}</ReportTitleText>
		</ReportTitleArea>
	);
});

export const AdCopyReportBigTitle = React.memo((props: React.PropsWithChildren) => {
	const { children } = props;

	return (
		<ReportTitleArea style={{ borderBottom: "1px solid #ddd", padding: "5px 0px 10px" }}>
			<ReportBigTitleText>{children}</ReportBigTitleText>
		</ReportTitleArea>
	);
});
