import styled from "styled-components";

type Props = {
	sideMenuWidth: number;
	thirdRowTop?: string;
};

export const AdCopyCrossTableElement = styled.table<Props>`
	border-collapse: separate;
	border: 1px solid #aaa;
	th,
	td {
		padding: 3px;
	}
	th {
		border: 1px solid #aaa;
		text-align: center;
	}
	td {
		border: 1px dotted #aaa;
	}
	td {
		min-width: 70px;
	}
	tr.total-row {
		border-top: 1px solid #aaa;
		border-bottom: 1px solid #aaa;
		td {
			text-align: right;
		}
	}
	.plus {
		color: blue;
	}
	.minus {
		color: red;
	}
	.number {
		text-align: right;
	}
	td,
	th {
		border-right: 1px solid #aaa;
	}

	th.sticky-row-1,
	td.sticky-row-1 {
		min-width: 230px;
		position: sticky;
		top: 45px;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}

	th.sticky-row-2,
	td.sticky-row-2 {
		position: sticky;
		top: 73px;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}

	th.sticky-row-3,
	td.sticky-row-3 {
		position: sticky;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}

	th.sticky-col-1,
	td.sticky-col-1 {
		position: sticky;
		left: ${({ sideMenuWidth }) => sideMenuWidth - 0.3}px;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}

	th.sticky-col-2,
	td.sticky-col-2 {
		position: sticky;
		left: ${({ sideMenuWidth }) => sideMenuWidth + 72.3}px;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}

	th.sticky-col-3,
	td.sticky-col-3 {
		position: sticky;
		left: ${({ sideMenuWidth }) => sideMenuWidth + 143.3}px;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}
	th.sticky-col-4,
	td.sticky-col-4 {
		position: sticky;
		left: ${({ sideMenuWidth }) => sideMenuWidth + 214.8}px;
		background-color: lightgray;
		&:before {
			content: "";
			position: absolute;
			top: -2.5px;
			left: -2.5px;
			width: calc(100% + 5px);
			height: calc(100% + 5px);
			border: 2.5px solid white;
		}
	}
	td.sticky-row-1.sticky-col-1 {
		z-index: 10;
		background-color: lightgray;
	}
`;
