import * as React from "react";
import { reducer, initState } from "../../../reducers/job/download";
import { JobDownloadPage } from "../../../components/pages/job/download";
import { Loading, BetweenDateValues } from "../../../../../react-components/lu-component/src/index";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { fileDownload, makeQueies } from "../../../lib/request";
import { endpoint } from "../../../../../server/router/endpoint";

export const JobDownloadContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, filteredValue } = state;
	const onChange = React.useCallback((values: BetweenDateValues) => {
		dispatch({ type: "changeFiltered", payload: values });
	}, []);
	const onDownload = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownload(`${endpoint.jobDownload}?${makeQueies(filteredValue)}`, "job-list.csv")
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch(() => {
				dispatch({
					type: "changeMessageInfo",
					payload: { message: "開始日、または終了日は必ず設定してください。", isSuccess: false },
				});
			});
	}, [filteredValue]);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox titleLabel={"ダウンロード"} info={info} />
			<JobDownloadPage
				errors={info.errors}
				filtered={filteredValue}
				onFilteredChange={onChange}
				onDownload={onDownload}
			/>
		</>
	);
};
