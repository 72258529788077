/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/naming-convention */
import classNames from "classnames";
import React, { FunctionComponent, useMemo } from "react";
import styled from "styled-components";
import { concept2html, imageWidth } from "../../../../../../../server/lib/file/concept2html";
import { Concept } from "../../../../../../../server/models/concept";

const Viewer = styled.div`
	height: fit-content;
	position: sticky;
	top: 4.5rem;

	> div {
		> div {
			width: ${imageWidth}px;
			height: min-content;

			#all-wrapper {
				font-family: "JPAGothic", sans-serif;
				font-size: 16px;
				letter-spacing: -0.05em;
				line-height: 1;
				margin: 8px;
			}
		}
	}
`;

const View = styled.div`
	min-height: 500px;
`;

const Attention = styled.div`
	margin: 1rem 0 1rem 1rem;
	white-space: pre-wrap;

	&::before {
		content: "※";
		margin-left: -1rem;
	}
`;

type Props = {
	concept: Concept;
};

export const ConceptViewer: FunctionComponent<Props> = React.memo(({ concept }) => {
	const __html = useMemo(() => concept2html(concept), [concept]);

	return (
		<Viewer className={classNames("my-3")}>
			<h4>■プレビュー</h4>
			<Attention className="text-sm">
				システム上、コンセプトプレビューとコンセプト画像の改行位置の差異が発生してしまうことがあります。
				<br />
				コンセプトプレビューはコンセプト作成時の補助的な機能としてご利用いただき、確定前に必ずコンセプト画像を確認ください。
			</Attention>
			<View className={classNames("border", "p-3", "rounded")}>
				<div className={classNames("shadow")} dangerouslySetInnerHTML={{ __html }} />
			</View>
			<div className={classNames("mt-3", "text-sm")}>
				<a href="https://moji.or.jp/ipafont/license/" rel="noopener noreferror" target="_blank">
					IPAフォントライセンスv1.0
				</a>
				で公開されている
				<a href="https://jpafonts.osdn.jp" rel="noopener noreferror" target="_blank">
					JPAフォント
				</a>
				を使用しています。
			</div>
		</Viewer>
	);
});
