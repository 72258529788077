import * as React from "react";
import { Button } from "react-bootstrap";

import type { ResultDetailsOutputData } from "../../../../../../../server/models/ad-copy/output";
import type { AdCopyResultByTargetAction } from "../../../../../types/actions";
import { AdCopyReportTitle } from "../parts/report-title";
import { OutputTable } from "./output-table";

type Props = {
	data: ResultDetailsOutputData;
	dispatch: React.Dispatch<AdCopyResultByTargetAction>;
};

export const AdCopyResultDetail = React.memo((props: Props) => {
	const { data, dispatch } = props;

	const detailTableRef = React.useRef<HTMLTableElement>(null);

	const copyDetailTableToClipboard = React.useCallback(async () => {
		if (detailTableRef.current) {
			const range = document.createRange();

			range.selectNode(detailTableRef.current);

			const selection = window.getSelection();

			if (selection) {
				selection.removeAllRanges();
				selection.addRange(range);

				try {
					await navigator.clipboard.writeText(detailTableRef.current.outerHTML);

					selection.removeAllRanges(); // 選択範囲を解除

					dispatch({
						payload: {
							isSuccess: true,
							message: "結果詳細テーブルの内容をクリップボードにコピーしました。",
						},
						type: "changeMessageInfo",
					});
				} catch (err) {
					console.error("Failed to copy: ", err);

					dispatch({
						payload: {
							isSuccess: false,
							message: "クリップボードにコピーできませんでした。",
						},
						type: "changeMessageInfo",
					});
				}
			}
		}
	}, [dispatch]);

	return (
		<>
			<AdCopyReportTitle>結果詳細</AdCopyReportTitle>

			<Button onClick={copyDetailTableToClipboard} size="sm" variant="outline-secondary">
				クリップボードにコピー
			</Button>

			<OutputTable
				data={{
					tHead: data.purchaseIntentions.tHead,

					tBodies: [
						...data.purchaseIntentions.tBodies,
						...data.evaluationItems.tBodies,
						...data.catchCopyImpressions.tBodies,
						...data.catchCopyAttractions.tBodies,
						...(data.keyVisualEvaluations == null ? [] : data.keyVisualEvaluations.tBodies),
					],
				}}
				stickyThead
				tableRef={detailTableRef}
			/>
		</>
	);
});
