import * as React from "react";
import { CrossAggregateJob } from "../../../../../../server/models/cross-aggregate";
import { GetAggregatesResponse } from "../../../../../../server/types/request/aggregate";
import { Search, SearchColumn } from "../../../parts/search";
import { ListTable, SubTitle, Column } from "../../../../../../react-components/lu-component/src/index";
import { SettingIconMenu } from "../../../parts/icon/setting-icon-menu";
import { ObjectId } from "mongodb";

export type AggregatePageProps = GetAggregatesResponse & {
	searches: { [key in keyof CrossAggregateJob]?: CrossAggregateJob[key] };
	onChangeSearches: <K extends keyof CrossAggregateJob>(name: K, value: CrossAggregateJob[K]) => void;
	onEdit: (jobId: string | ObjectId, id: string | ObjectId) => void;
	onView: (jobId: string | ObjectId, id: string | ObjectId) => void;
	onDownload: (id: string | ObjectId) => void;
	onDelete: (id: string | ObjectId) => void;
};

export const AggregatePage = React.memo((props: AggregatePageProps) => {
	const { accountOptions, crossAggregates, searches, onChangeSearches, onEdit, onView, onDelete, onDownload } = props;
	const searchColums: SearchColumn[] = React.useMemo(
		() => [
			{
				name: "activityNo",
				label: "アクティビティNo.",
				type: "string",
			},
			{
				name: "jobNum",
				label: "ジョブNo.",
				type: "string",
			},
			{
				name: "accountId",
				type: "select",
				label: "作成者",
				options: accountOptions,
			},
			{
				name: "name",
				label: "集計名",
				type: "string",
			},
		],
		[accountOptions],
	);
	const filteredData = React.useMemo(() => {
		const searchArray = Object.entries(searches).filter(([_, value]) => !!value);
		if (searchArray.length === 0) {
			return crossAggregates;
		}
		return crossAggregates.filter((d) => {
			return searchArray.every(([key, value]) => {
				if (key in d === false) return false;
				return d[key as keyof CrossAggregateJob].toString() === value.toString();
			});
		});
	}, [crossAggregates, searches]);
	const columns = React.useMemo<Column[][]>(
		() => [
			[
				{
					name: "activityNo",
					label: "No.",
					type: "text",
				},
			],
			[
				{
					name: "jobNum",
					label: "ジョブNo.",
					type: "text",
				},
			],
			[
				{
					name: "name",
					label: "集計名",
					type: "textarea",
				},
			],
			[
				{
					name: "accountId",
					label: "作成者",
					type: "select",
					options: accountOptions,
				},
			],
			[
				{
					name: "categoryName",
					label: "カテゴリ名",
					type: "text",
				},
			],
			[
				{
					name: "brandName",
					label: "ブランド名",
					type: "text",
				},
			],
			[
				{
					name: "enddatetime",
					label: "調査終了日",
					type: "text",
				},
			],
			[
				{
					name: "createdAt",
					label: "作成日",
					type: "text",
				},
			],
			[
				{
					type: "custom",
					name: "otherSetting",
					label: "",
					view: (crossAggregateJob: CrossAggregateJob) => (
						<SettingIconMenu
							id={crossAggregateJob._id}
							items={[
								{ label: "編集", event: (id) => onEdit(crossAggregateJob.jobId, id) },
								{ label: "閲覧", event: (id) => onView(crossAggregateJob.jobId, id) },
								{ label: "DL", event: onDownload },
								{ label: "削除", event: onDelete },
							]}
						/>
					),
				},
			],
		],
		[accountOptions, onDelete, onDownload, onEdit, onView],
	);
	return (
		<>
			<div className="m-3 clearfix">
				<div className="h3 float-start">■集計一覧</div>
			</div>
			<div className="m-3">
				<SubTitle style={{ margin: "20px 0px" }}>検索</SubTitle>
				<Search columns={searchColums} searchValues={searches as any} onChangeSearchValue={onChangeSearches as any} />
			</div>
			<div className="m-3 ">
				<ListTable columns={columns} data={filteredData} />
			</div>
		</>
	);
});
