import * as React from "react";
import { Button } from "react-bootstrap";
import { SubTitle, CrossButtonArea } from "../../../parts/aggregate-setting/common";
import { Cross, ViewType } from "@brix/core";
import { CrossViewer } from "@brix/viewer";
import { styled } from "styled-components";

export type AggregateViewPageProps = {
	onEdit: () => void;
	onDownload: () => void;
	onList: () => void;
	crossData: Cross[];
};

const CrossFlex = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
	width: 100%;
	height: 85vh;
`;

const HeaderFlex = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const AggregateViewPage = React.memo((props: AggregateViewPageProps) => {
	const { onDownload, onEdit, onList, crossData } = props;

	const [vertical, setVertical] = React.useState(true);
	const [viewType, setViewType] = React.useState<ViewType>("both");
	const handleChangeVertical = React.useCallback((vertical: boolean) => setVertical(vertical), [setVertical]);
	const handleChangeViewType = React.useCallback((viewType: ViewType) => setViewType(viewType), [setViewType]);

	return (
		<>
			<HeaderFlex>
				<SubTitle>集計結果</SubTitle>
				<CrossButtonArea>
					<Button variant={"outline-secondary"} onClick={onEdit}>
						編集
					</Button>
					<Button variant={"outline-secondary"} onClick={onDownload}>
						ダウンロード
					</Button>
					<Button variant={"outline-secondary"} onClick={onList}>
						一覧に戻る
					</Button>
				</CrossButtonArea>
			</HeaderFlex>
			<CrossFlex>
				<CrossViewer
					datas={crossData}
					defaultVertical={vertical}
					defaultViewType={viewType}
					onChangeVertical={handleChangeVertical}
					onChangeViewType={handleChangeViewType}
				/>
			</CrossFlex>
		</>
	);
});
