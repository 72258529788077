import * as React from "react";
import { ElementEvaluation } from "../../../../../../../../server/models/graph/element-evaluation";
import styled from "styled-components";
import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts";
import { ConceptType } from "../../../../../../../../server/models/concept";

const GraphArea = styled.div`
	position: relative;
`;

export type ImpressionEvaluationGraphProps = {
	target: "category" | "strategy";
	evaluations: ElementEvaluation[];
	evaluationItemLabel: string;
};

const barSize = 20;
export const ImpressionEvaluationGraph = React.memo((props: ImpressionEvaluationGraphProps) => {
	const { evaluations, evaluationItemLabel } = props;
	const barData = React.useMemo(() => {
		return evaluations.map((ev) => {
			const targetEv = ev.evaluvationItem.find((b) => b.label === evaluationItemLabel);
			return {
				label: ev.conceptType === "norm" ? "ノーム" : ConceptType[ev.conceptType],
				val: targetEv.val,
			};
		});
	}, [evaluations, evaluationItemLabel]);

	return (
		<GraphArea>
			<BarChart
				data={barData}
				layout={"vertical"}
				height={(barSize + 2) * 2 * barData.length + 20}
				width={450}
				margin={{ top: 5, right: 30, left: 70, bottom: 5 }}
				barSize={barSize}
			>
				<XAxis type={"number"} domain={[1, 5]} orientation={"top"} ticks={[1, 2, 3, 4, 5]} />
				<YAxis type={"category"} dataKey={"label"} />
				<Bar dataKey={"val"} fill={"#aaa"}>
					<LabelList dataKey={"val"} position={"right"} formatter={(v: unknown) => `${Number(v).toFixed(2)}`} />
				</Bar>
			</BarChart>
		</GraphArea>
	);
});
