import { MessageInfo } from "../../../types/info";
import { PreviewAction } from "../../../types/actions";
import { Concept } from "../../../../../server/models/concept";

export type State = {
	concepts: Concept[];
	url: string;
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	concepts: [],
	url: "",
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: PreviewAction): State => {
	switch (action.type) {
		case "loadPreview":
			return {
				...state,
				loading: false,
				...action.payload,
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};
