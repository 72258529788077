import * as React from "react";
import { Col, Row, FormControl, FormGroup, Button, Form, Card } from "react-bootstrap";
import Select from "react-select";

type SeachColumn = {
	name: string;
	label: string;
	type: "string" | "date";
	col?: number;
};
type Option = {
	label: string;
	value: string | number;
};
type SelectBoxSearchColumn = {
	name: string;
	label: string;
	type: "select" | "search-select";
	options: Option[];
	col?: number;
};
export type SearchColumn = SeachColumn | SelectBoxSearchColumn;

export type SearchValues = { [key: string]: string | number };

export type SearchProps = {
	columns: SearchColumn[];
	searchValues: SearchValues;
	onChangeSearchValue: (name: string, value: string | number) => void;
	onSearch?: () => void;
};
export const Search = React.memo((props: SearchProps) => {
	const { columns, onSearch, searchValues, onChangeSearchValue } = props;
	const SearchField = React.useMemo(
		() =>
			columns.map((column) => {
				return (
					<Col md={column.col || 4} key={`search-${column.name}`}>
						<FormGroup style={{ marginBottom: "0.2em" }}>
							<Form.Label>{column.label}</Form.Label>
							{column.type === "string" && (
								<FormControl
									type="text"
									value={searchValues[column.name] ? searchValues[column.name].toString() : ""}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										onChangeSearchValue(column.name, e.target.value)
									}
								/>
							)}
							{column.type === "date" && (
								<Row>
									<Col md={5}>
										<FormControl
											value={searchValues[column.name + "_a"] ? searchValues[column.name + "_a"].toString() : ""}
											type="date"
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												onChangeSearchValue(column.name + "_a", e.target.value)
											}
										/>
									</Col>
									<Col md={1}>〜</Col>
									<Col md={5}>
										<FormControl
											value={searchValues[column.name + "_b"] ? searchValues[column.name + "_b"].toString() : ""}
											type="date"
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												onChangeSearchValue(column.name + "_b", e.target.value)
											}
										/>
									</Col>
								</Row>
							)}
							{column.type === "select" && (
								<Form.Select
									value={searchValues[column.name] ? searchValues[column.name].toString() : ""}
									onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
										onChangeSearchValue(column.name, e.target.value)
									}
								>
									<option></option>
									{column.options.map((option) => {
										return (
											<option key={`search-${column.name}-option.${option.value}`} value={option.value}>
												{option.label}
											</option>
										);
									})}
								</Form.Select>
							)}
							{column.type === "search-select" && (
								<Select
									onChange={(selected: Option) =>
										onChangeSearchValue(column.name, selected ? selected.value : undefined)
									}
									options={column.options as any}
									value={
										searchValues[column.name]
											? column.options.find((op) => op.value === searchValues[column.name])
											: undefined
									}
									isClearable
								/>
							)}
						</FormGroup>
					</Col>
				);
			}),
		[searchValues, onChangeSearchValue, columns],
	);
	return (
		<div className={"m-3"}>
			<Card>
				<Card.Body style={{ padding: "0.8em" }}>
					<Row>{SearchField}</Row>
					{onSearch && (
						<Row style={{ marginTop: "0.8em" }}>
							<Col md={{ span: 2, offset: 1 }}>
								<Button className="w-100" variant={"secondary"} onClick={onSearch}>
									検索
								</Button>
							</Col>
						</Row>
					)}
				</Card.Body>
			</Card>
		</div>
	);
});
