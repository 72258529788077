import type { Option } from "../../../../../../react-components/lu-component/src/index";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { AdCopyJobCreateAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	cantEditMessage?: string;
	evaluationItem: string[];
	info: MessageInfo;
	job: Partial<AdCopyJob>;
	jobOptions: Option[];
	loading: boolean;
	needCurrentProduct: boolean;
	prompt: boolean;
};

export const initState: State = {
	evaluationItem: [],
	info: {
		isSuccess: true,
		message: "",
	},
	job: {},
	jobOptions: [],
	loading: true,
	needCurrentProduct: false,
	prompt: false,
};

export const reducer = (state: State, action: AdCopyJobCreateAction): State => {
	switch (action.type) {
		case "loadJob": {
			return {
				...state,
				loading: false,
				...action.payload,
				job: {
					...state.job,
					...action.payload.job,
					benchmarkName: action.payload.job?.benchmarkName ?? action.payload.benchmarkName,
					hasBenchmark:
						action.payload.job?.hasBenchmark ?? (action.payload.benchmarkName == null ? state.job.hasBenchmark : true),
					hasCurrentProduct:
						action.payload.job?.hasCurrentProduct ?? (action.payload.needCurrentProduct || state.job.hasCurrentProduct),
					hasKeyVisual: action.payload.job?.hasKeyVisual ?? action.payload.hasKeyVisual ?? state.job.hasKeyVisual,
				},
			};
		}

		case "updateJob":
			return {
				...state,
				...action.payload,
				info: {
					isSuccess: true,
					message: `${state.job._id ? "更新" : "作成"}が完了しました。`,
				},
				loading: false,
				prompt: false,
			};

		case "changeJob":
			return {
				...state,
				job: {
					...state.job,
					[action.payload.name]: action.payload.value,
				},
				prompt: true,
			};

		case "changeEvaluationItem":
			return {
				...state,
				evaluationItem: action.payload,
				prompt: true,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
