/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopyEyeshaowTypeOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyEyeshadowTypeCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	const shouldShow = React.useMemo(
		() => strategyGroup.category?.some((c) => c.category.type === "eyeShadow"),
		[strategyGroup.category],
	);

	return (
		<Row>
			<FormGroup as={Col} md={5}>
				<AccordionForm
					defaultShow={strategyGroup.eyeshadowType != null && 0 < strategyGroup.eyeshadowType.length}
					eventKey="eyeshadowType"
					title={adCopyStrategyTargetGroupLabels.eyeshadowType ?? ""}
				>
					{shouldShow ? (
						<Checkboxes
							checkeds={strategyGroup.eyeshadowType ?? []}
							choices={adCopyEyeshaowTypeOptions.filter((x) => x.label !== "その他") as Choice[]}
							name="eyeshadowType"
							onChange={(value) => {
								onChange("eyeshadowType", value);
							}}
						/>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
