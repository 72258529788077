import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";

import { fileDownloadStream, get, makeError, post, put } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/aggregate/create";
import {
	GetAggregateConfigResponse,
	UpsertAggregateRequest,
	UpsertAggregateResponse,
} from "../../../../../server/types/request/aggregate";

import { EditableAggregateJob } from "../../../types/aggregate";
import {
	AggregateJobBase,
	convertToEditableAggregateJob,
	convertToEditableAggregateQuestions,
	convertToMuscatAggregateJob,
	importType,
} from "@brix/core";
import { AggregateCreatePage, AggregateCreatePageProps } from "../../../components/pages/aggregate/create";
import { Prompt } from "../../../components/parts/prompt";
import { Loading } from "../../../../../react-components/lu-component/src";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { clientEndpoint } from "../../../routes/endpoint";
import { AxiosResponse } from "axios";

export const AggreagteCreateContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, prompt, crossAggregate, ...aProps } = state;

	const navigate = useNavigate();

	const { aggregateId, jobId } = useParams<{ aggregateId: string; jobId: string }>();

	React.useEffect(() => {
		get<GetAggregateConfigResponse>(`${endpoint.aggregate}/${jobId}/${aggregateId}`)
			.then((response) => {
				const { data } = response;
				const questions = convertToEditableAggregateQuestions(data.questions);
				// Titleを設定してローディングをfalseにする
				dispatch({
					type: "loadAggreagteJob",
					payload: { config: data },
				});

				// AggregateJobEditor使うためEditableAggregateQuestions
				dispatch({ type: "updateQuestions", payload: questions });

				if (aggregateId === "new") {
					// 空のAggregateJobを作る
					dispatch({ type: "createEmptyAggregateJob", payload: { jobId, questions } });
				} else {
					const { aggregateJob, title } = data;
					const aggregateJobWithConfig: AggregateJobBase = {
						config: {
							selectionGroups: aggregateJob.selectionGroups,
							axis: aggregateJob.axis,
							targetQuestions: aggregateJob.targetQuestions,
							additionalQuestions: aggregateJob.additionalQuestions,
							jobNo: aggregateJob.jobNo,
							enqueteId: aggregateJob.enqueteId,
						},
						jobId: jobId.toString(),
						name: title,
						aggregateNo: aggregateJob.jobNo,
						importType: importType.completed,
					};
					const editableAggregateJob = convertToEditableAggregateJob(questions, aggregateJobWithConfig);
					/* AggregateJobEditor使うためeditableAggregateJobを設定する */
					/* Titleやコンセプトなどを設定してローディングをfalseにする */
					dispatch({
						type: "loadAggreagteJob",
						payload: {
							aggregateJob: editableAggregateJob,
						},
					});
				}
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [aggregateId, jobId]);

	const onSave = React.useCallback(() => {
		const muscatAggregateJob = convertToMuscatAggregateJob(state.aggregateQuestions, state.aggregateJob);

		const requestData: UpsertAggregateRequest = {
			crossAggregate: state.crossAggregate,
			aggregateJob: {
				additionalQuestions: [],
				axis: muscatAggregateJob.config.axis ?? [],
				rawdataFilepaths: muscatAggregateJob.config.rawdataFilepaths ?? [],
				selectionGroups: muscatAggregateJob.config.selectionGroups ?? [],
				targetQuestions: muscatAggregateJob.config.targetQuestions ?? [],
			},
		};
		dispatch({ type: "changeLoading", payload: true });

		let request: Promise<AxiosResponse<UpsertAggregateResponse, any>>;

		if (state.aggregateId) {
			request = put<UpsertAggregateResponse>(`${endpoint.aggregate}/${state.aggregateId}`, requestData);
		} else if (aggregateId !== "new") {
			request = put<UpsertAggregateResponse>(`${endpoint.aggregate}/${aggregateId}`, requestData);
		} else {
			request = post<UpsertAggregateResponse>(`${endpoint.aggregate}/${jobId}`, requestData);
		}
		request
			.then((response) => {
				dispatch({ type: "upsertAggregate", payload: response.data });
				dispatch({ type: "updateAggregateId", payload: response.data.crossAggregate._id.toString() });
				// 表示されてるエラーをクリアをする
				dispatch({ type: "editorError", payload: undefined });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error, false, true) });
				// aggregateJobEditorコンポネントに表示されるエラー
				dispatch({ type: "editorError", payload: error.response.data });
			});
	}, [aggregateId, jobId, state]);
	const onChangeAggregate = React.useCallback<AggregateCreatePageProps["onChangeAggregate"]>(
		(name, value) => {
			dispatch({ type: "changeCrossAggregate", payload: { name, value } });
		},
		[dispatch],
	);

	const handleUpdate = React.useCallback(
		(payload: EditableAggregateJob) => dispatch({ payload, type: "updateAggregateJob" }),
		[],
	);
	const onDownload = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownloadStream(
			`${endpoint.aggregateDownload}/${state.aggregateId ? state.aggregateId : aggregateId.toString()}`,
			`クロス集計-${crossAggregate.name}.csv`,
		)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: error });
			});
	}, [aggregateId, crossAggregate.name, state.aggregateId]);
	const onView = React.useCallback(
		() =>
			navigate(
				replaceEndpointUrl(clientEndpoint.aggtegateView, {
					jobId: jobId.toString(),
					aggregateId: state.aggregateId ? state.aggregateId : aggregateId.toString(),
				}),
			),
		[aggregateId, jobId, navigate, state.aggregateId],
	);
	const onList = React.useCallback(() => navigate(clientEndpoint.aggregate), [navigate]);

	return (
		<>
			<Prompt message={"変更が保存されていません。ページを移動してよろしいですか？"} when={prompt && !loading} />
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} />
			{aProps.aggregateQuestions.length > 0 && aProps.aggregateJob.config && (
				<AggregateCreatePage
					onList={onList}
					{...aProps}
					handleUpdate={handleUpdate}
					crossAggregate={crossAggregate}
					onChangeAggregate={onChangeAggregate}
					onSave={onSave}
					errors={info.errors}
					onDownload={state.aggregateId || aggregateId !== "new" ? onDownload : undefined}
					onView={state.aggregateId || aggregateId !== "new" ? onView : undefined}
				/>
			)}
		</>
	);
};
