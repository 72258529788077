import { AdCopyConceptKey } from "../concept";
import { adCopyTargetGroupMap } from "../enquete";
import type {
	BrandUserAxisName,
	CategoryUserAxisName,
	StrategyMarketMavenAxisName,
	StrategyAxisName,
} from "./aggregateAxisNameMap";

/**
 * クロス集計でのテーブルデータへの変換のための中間データの型定義
 *
 * muscat analyticsから出力したjsonデータをBI形式に転換して、
 * 一次加工した中間データの型定義
 */
export const cellValueTypeMap = {
	n: "単純数字",
	percent: "％数字",
	// pt: "pt",
} as const;
export type CellValueType = keyof typeof cellValueTypeMap;

export const choiceDataTypeMap = {
	average: "加重平均",
	/** 選択肢1 */
	top1: "TopBox(％)",
	bottom2: "Bottom2 Boxes(%)",
	choice: "設問選択肢",
	n: "(n)",
	oneNumberScore: "ワンナンバースコア",
	/** 選択肢1+2  */
	top2: "Top2 Boxes(%)",
} as const;
export type ChoiceDataType = keyof typeof choiceDataTypeMap;

/** 最小単位の１セルのデータ */
export type CellValues = {
	type: CellValueType;
	value: number;
};

export const aggregateTargetGroupMap = {
	...adCopyTargetGroupMap,
	strategyMarketMaven: "戦略ターゲットG内　Market　Maven",
} as const;

/**
 * 集計ターゲットグループ
 * - カテゴリユーザー （需要予測に使う）
 * - 戦略ターゲット
 * - 戦略ターゲット内のMarket Maven
 * - ブランドユーザー
 */
export type AggregateTargetGroup = keyof typeof aggregateTargetGroupMap;

/**
 * 集計表に使うターゲットグループ（カテゴリユーザーを除く）
 */
export type AggregateTargetGroupExceptREP = Exclude<AggregateTargetGroup, "categoryUser">;

/**
 * cellDataの軸名または、"norm"などほかの種類名
 */
export type CellDataType = AxisNameType;

export type AxisNameType = StrategyAxisName | StrategyMarketMavenAxisName | BrandUserAxisName | CategoryUserAxisName;

export type CellData = {
	name: CellDataType;
	values: CellValues;
};

/** テスト品別の集計データ */
export type DataByConcept = {
	cellData: CellData[];
	conceptType: AdCopyConceptKey;
};

/**
 * 集計ターゲット別のcellデータ
 *
 */
export type DataByTarget = {
	dataByConcept: DataByConcept[];
	aggregateTargetGroup: AggregateTargetGroup;
};

/** (n), top2など特殊の行 */
export type SpecialChoiceData = Omit<NormalChoiceData, "choiceNum" | "type"> & {
	type: SpecialChoiceDataType;
};

export type SpecialChoiceDataType = Exclude<ChoiceDataType, "choice">;

export type NormalChoiceData = {
	/** ターゲット別の1行のData配列 */
	dataByTarget: DataByTarget[];
	choiceNum: number;
	label: string;
	type: "choice";
};

export type ChoiceData = NormalChoiceData | SpecialChoiceData;

export type ChildQuestionData = Omit<NormalQuestionData, "quename">;

export type MatrixQuestionData = {
	childQuestionData: ChildQuestionData[];
	quelabel: string;
	quename: string;
};

export type NormalQuestionData = {
	/** (n), top2など特殊の行も含める */
	choiceData: ChoiceData[];
	quelabel: string;
	quename: string;
};

export type QuestionData = MatrixQuestionData | NormalQuestionData;

export type AggregateResultData = QuestionData[];
