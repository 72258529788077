import { Loading } from "../../../../../react-components/lu-component/src/index";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { endpoint } from "../../../../../server/router/endpoint";
import { GetSimulationResponse } from "../../../../../server/types/request/report/simulation";
import { SimulationPage, SimulationPageProps } from "../../../components/pages/report/simulation";
import { ConfirmBox } from "../../../components/parts/confirm-box";

import { get, makeError, makeErrorBlob, makeDwnloadImage, put, remove } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/report/simulation";
import { isDefined } from "class-validator";

export const SimulationContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, loading, ...sProps } = state;
	const { jobId } = useParams<{ jobId: string }>();
	const refetch = React.useCallback(() => {
		get<GetSimulationResponse>(`${endpoint.simulation}/${jobId}`)
			.then((response) => {
				dispatch({ type: "loadData", payload: response.data });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId]);
	React.useEffect(() => refetch(), [refetch]);
	const onChangeRatio = React.useCallback<SimulationPageProps["onChangeRatio"]>((target, name, value) => {
		dispatch({ type: "changeRatio", payload: { target, name, value } });
	}, []);
	const onSimulation = React.useCallback<SimulationPageProps["onSimulation"]>(
		(conceptType) => {
			const html = document.getElementsByClassName("simulation-table").item(0).outerHTML;
			dispatch({ type: "changeLoading", payload: true });
			const correctionValue = sProps.data[conceptType].correctionValue;
			makeDwnloadImage(
				`${endpoint.simulation}/${jobId}`,
				{ html, correctionValue },
				`${sProps.job.jobNum}_executive_summary.png`,
			)
				.then(() => dispatch({ type: "downloadSimulation", payload: true }))
				.catch(async (error) => {
					dispatch({ type: "changeMessageInfo", payload: await makeErrorBlob(error, true) });
				});
		},
		[sProps.data, sProps.job?.jobNum, jobId],
	);
	const onSubmitCP = React.useCallback<SimulationPageProps["onSubmitCP"]>(() => {
		const cp = sProps.cp;
		if (!isDefined(cp)) return;
		const { actualValue, type, similarProductInfo, reason } = cp;
		dispatch({ type: "changeLoading", payload: true });
		put<GetSimulationResponse>(`${endpoint.cp}/${jobId}`, { actualValue, type, similarProductInfo, reason })
			.then((response) => dispatch({ type: "updateData", payload: response.data }))
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
	}, [jobId, sProps.cp]);
	const onSubmitCPCorrectionValues = React.useCallback<SimulationPageProps["onSubmitCPCorrectionValues"]>(() => {
		const cp = sProps.cp;
		if (!isDefined(cp)) return;
		const { correctionValues } = cp;
		dispatch({ type: "changeLoading", payload: true });
		put<GetSimulationResponse>(`${endpoint.cpCorrectionValues}/${jobId}`, correctionValues)
			.then((response) => dispatch({ type: "updateData", payload: response.data }))
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error, true) });
			});
	}, [jobId, sProps.cp]);
	const onChangeCP = React.useCallback<SimulationPageProps["onChangeCP"]>((name, value) => {
		dispatch({ type: "changeCP", payload: { name, value } });
	}, []);
	const onClearCP = React.useCallback(() => {
		remove<GetSimulationResponse>(`${endpoint.cp}/${jobId}`)
			.then((response) => dispatch({ type: "clearCPData", payload: response.data }))
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error, true) });
			});
	}, [jobId]);
	const onSimulationSave = React.useCallback<SimulationPageProps["onSimulationSave"]>(
		(conceptType) => {
			dispatch({ type: "changeLoading", payload: true });
			const correctionValue = sProps.data[conceptType].correctionValue;
			put(`${endpoint.simulation}/${jobId}`, { correctionValue })
				.then(() => dispatch({ type: "saveSimulation", payload: { message: "保存が完了しました。", isSuccess: true } }))
				.catch((error) => {
					dispatch({ type: "saveSimulation", payload: makeError(error, true) });
				});
		},
		[sProps.data, jobId],
	);
	const setChanged = React.useCallback((changed: boolean) => dispatch({ type: "setChanged", payload: changed }), []);
	return (
		<>
			<Loading loading={loading} />
			<ConfirmBox info={info} titleLabel="保存処理" />
			<SimulationPage
				{...sProps}
				setChanged={setChanged}
				errors={info.errors}
				refetch={refetch}
				loginAccount={loginAccount}
				onChangeRatio={onChangeRatio}
				onSimulation={onSimulation}
				onSimulationSave={onSimulationSave}
				onSubmitCP={onSubmitCP}
				onClearCP={onClearCP}
				onSubmitCPCorrectionValues={onSubmitCPCorrectionValues}
				onChangeCP={onChangeCP}
			/>
		</>
	);
};
