import { Loading } from "../../../../../react-components/lu-component/src/index";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { endpoint, replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { GetAggregateResponse } from "../../../../../server/types/request/aggregate";
import { AggregateViewPage } from "../../../components/pages/aggregate/view";
import { ConfirmBox } from "../../../components/parts/confirm-box";
import { fileDownloadStream, get, makeError } from "../../../lib/request";
import { reducer, initState } from "../../../reducers/aggregate/view";
import { clientEndpoint } from "../../../routes/endpoint";
import { convertCrosses } from "@brix/core";

export const AggregateViewConinar = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { jobId, aggregateId } = useParams<{ jobId: string; aggregateId: string }>();
	const { loading, info, crossAggregate: aggregateJob } = state;
	const navigate = useNavigate();
	const onList = React.useCallback(() => navigate(clientEndpoint.aggregate), [navigate]);
	const onEdit = React.useCallback(
		() => navigate(replaceEndpointUrl(clientEndpoint.aggtegateCreate, { jobId, aggregateId })),
		[aggregateId, jobId, navigate],
	);
	const onDownload = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownloadStream(`${endpoint.aggregateDownload}/${aggregateId}`, `クロス集計-${aggregateJob.name}.csv`)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
		// USE aggregateJob instead of aggregateJob.name to avoid crash
	}, [aggregateId, aggregateJob]);
	React.useEffect(() => {
		get<GetAggregateResponse>(`${endpoint.aggregateView}/${aggregateId}`)
			.then((response) => {
				dispatch({ type: "loadAggreagteJob", payload: response.data });
				const convertedCrossData = convertCrosses("combine", response.data.data);
				dispatch({ type: "convertedCrosses", payload: convertedCrossData });
			})
			.catch((error) => {
				dispatch({ type: "changeMessageInfo", payload: makeError(error) });
			});
		// don't add the state
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aggregateId]);

	return (
		<>
			<Loading loading={loading} text={"processing..."} />
			<ConfirmBox info={info} />
			{state.crossData && (
				<AggregateViewPage crossData={state.crossData} onDownload={onDownload} onEdit={onEdit} onList={onList} />
			)}
		</>
	);
};
