/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { adCopyStrategyTargetGroupLabels } from "../../../../../../../../label/ad-copy/activity";
import type { Choice } from "../../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../../react-components/lu-component/src/index";
import { adCopyChannelExcludeOtherOptions } from "../../../../../../../../server/models/ad-copy/activity";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AccordionForm } from "../../../../../parts/accodion-form";

type Props = {
	onChange: (name: keyof TrueAdCopyStrategyGroup, value: any) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyChannelCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	return (
		<Row>
			<FormGroup as={Col} md={10}>
				<AccordionForm
					defaultShow={!!strategyGroup.channel && strategyGroup.channel.length !== 0}
					eventKey="channel"
					title={adCopyStrategyTargetGroupLabels.channel ?? ""}
				>
					{strategyGroup.category?.length ? (
						<Checkboxes
							checkeds={strategyGroup.channel ?? []}
							choices={adCopyChannelExcludeOtherOptions as Choice[]}
							name="channel"
							onChange={(v) => onChange("channel", v)}
						/>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
