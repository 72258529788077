import * as React from "react";
import { Row, Col, FormControl } from "react-bootstrap";

export type BwtweenInputValues = {
	from: number;
	to: number;
};
export type BetweenInputOption = { max?: number; min?: number };

export type BetweenInputProps = {
	name: string;
	values: BwtweenInputValues;
	onChange: (values: BwtweenInputValues) => void;
	option?: BetweenInputOption;
};

export const BetweenInput = React.memo((props: BetweenInputProps) => {
	const { name, values, onChange, option } = props;
	const onChangeInput = React.useCallback(
		(target: keyof BwtweenInputValues, e: React.ChangeEvent<HTMLInputElement>) => {
			const value = Number(e.target.value);
			if (option && option.min !== undefined && option.min > value) {
				return alert(`${option.min}以上の数値で入力してください。`);
			}
			if (option && option.max !== undefined && option.max < value) {
				return alert(`${option.max}以下の数値で入力してください。`);
			}
			onChange({ ...values, [target]: value });
		},
		[values, onChange, option],
	);
	return (
		<Row>
			<Col md={"auto"}>
				<FormControl
					name={`${name}-from`}
					type={"number"}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeInput("from", e)}
					value={values.from || ""}
				/>
			</Col>
			<Col md={"auto"} style={{ textAlign: "center" }}>
				<span style={{ verticalAlign: "text-top" }}>〜</span>
			</Col>
			<Col md={"auto"}>
				<FormControl
					name={`${name}-to`}
					type={"number"}
					value={values.to || ""}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeInput("to", e)}
				/>
			</Col>
		</Row>
	);
});
