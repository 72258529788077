import type { AdCopyResultDetailsResBody } from "../../../../../../server/types/request/ad-copy/report/resultDetails";
import type { AdCopyResultByTargetAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = Partial<AdCopyResultDetailsResBody> & {
	info: MessageInfo;
	loading: boolean;
};

export const initState: State = {
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
};

export const reducer = (state: State, action: AdCopyResultByTargetAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
