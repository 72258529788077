import {
	Activity,
	Children,
	CategoryGroup,
	StrategyGroup,
	Married,
	Occupation,
	SkinAttribute,
	SensitiveSkin,
	Channel,
	BeautyType,
	Fragrance,
	FoundationType,
	FacewashFunction,
} from "../../models/activity";
import { Job } from "../../models/job";
import { QuotaWithoutCount } from "@muscat/types";
import { priceChoices } from "../../models/category";
import {
	genderQuename,
	ageQuename,
	conceptQuename,
	categoryQuename,
	priceQuename,
	marriedQuename,
	familyQuename,
	familyChoices,
	occupationQuename,
	skinAttributeQuename,
	sensitiveSkinQuename,
	channelQuename,
	beautyTypeQuename,
	fregranceQuename,
	usedBrandQuename,
	foundationTypeQuename,
	facewarhFunctionQuename,
} from "../enquete/config";
import { ConceptType } from "../../models/concept";
import {
	categoryTargetGroupLabels,
	strategyTargetGroupLabels,
	strategyGroupCategoryLabels,
} from "../../../label/activity";
import { conceptNumber, quotaCode, countQuotaCode } from "../enquete/static";
import { hasCategroyTargetGroup } from "../common";
import { makeOverSample } from "./samplesize";
import { Age, Gender, Price } from "../../models/shared";

export const makePriceChoice = (price: Price) => {
	const { to, from } = price;
	return priceChoices.filter((choice) => {
		// toがundefinedは以上の判定。選択肢のfromよりもターゲットのfromが大きければ有効。
		if (to === undefined) {
			return from <= choice.from;
		}
		// fromがundefinedは以下の判定toよりも大きければ有効。
		if (from === undefined) {
			return to >= choice.to;
		}
		return choice.from <= to && from <= choice.to;
	}, []);
};

/*/
const dummyGeneralQuota: GeneralGroupQuota[] = [
	{ gender: "female", age: { from: 15, to: 19 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 20, to: 24 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 25, to: 29 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 30, to: 34 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 35, to: 39 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 40, to: 44 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 45, to: 49 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 50, to: 54 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 55, to: 59 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 60, to: 64 }, sampleSize: 100, isUser: true },
	{ gender: "female", age: { from: 65, to: 69 }, sampleSize: 100, isUser: true },

	{ gender: "female", age: { from: 15, to: 19 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 20, to: 24 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 25, to: 29 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 30, to: 34 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 35, to: 39 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 40, to: 44 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 45, to: 49 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 50, to: 54 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 55, to: 59 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 60, to: 64 }, sampleSize: 100, isUser: false },
	{ gender: "female", age: { from: 65, to: 69 }, sampleSize: 100, isUser: false },
];
/*/
const CategorySampleSize = 200;
const StrategySampleSize = 100;

const isSetStrategy = (data?: any[]) => {
	return !!data && data.length;
};

export const makePriceLabel = (price?: Price): string => {
	if (!price) return "設定なし";
	// if (price.label) return price.label;
	if (price.from !== undefined && price.to === undefined) {
		return price.from.toLocaleString() + "円以上";
	}
	if (price.from === undefined && price.to !== undefined) {
		return price.to.toLocaleString() + "円以下";
	}
	if (price.from !== undefined && price.to !== undefined) {
		return `${price.from.toLocaleString()}〜${price.to.toLocaleString()}円`;
	}
	return "設定なし";
};
export const makeAgeLabel = (age: Age) => {
	return `${age.from}〜${age.to}歳`;
};

export const makeCategoryGroupText = (categoryTargetGroup: CategoryGroup, gender: keyof typeof Gender): string => {
	const { age, price } = categoryTargetGroup;
	return [
		`性別：${Gender[gender]}`,
		`${categoryTargetGroupLabels.age}：${makeAgeLabel(age)}`,
		`${categoryTargetGroupLabels.price}：${makePriceLabel(price)}`,
	].join("、");
};

export const makeStrategyGroupText = (strategyTargetGroup: StrategyGroup, gender: keyof typeof Gender): string => {
	const {
		age,
		married,
		children,
		occupation,
		skinAttribute,
		sensitiveSkin,
		categoryOrAnd,
		category,
		channel,
		fragrance,
		foundationType,
		facewashFunction,
	} = strategyTargetGroup;
	const tmp = [`性別：${Gender[gender]}、${strategyTargetGroupLabels.age}：${makeAgeLabel(age)}`];
	if (isSetStrategy(married)) {
		tmp.push(`${strategyTargetGroupLabels.married}：${married.map((target) => Married[target]).join()}`);
	}
	if (isSetStrategy(children)) {
		tmp.push(`${strategyTargetGroupLabels.children}：${children.map((target) => Children[target]).join()}`);
	}
	if (isSetStrategy(occupation)) {
		tmp.push(`${strategyTargetGroupLabels.occupation}：${occupation.map((target) => Occupation[target]).join()}`);
	}
	if (isSetStrategy(skinAttribute)) {
		tmp.push(
			`${strategyTargetGroupLabels.skinAttribute}：${skinAttribute.map((target) => SkinAttribute[target]).join()}`,
		);
	}
	if (isSetStrategy(sensitiveSkin)) {
		tmp.push(
			`${strategyTargetGroupLabels.sensitiveSkin}：${sensitiveSkin.map((target) => SensitiveSkin[target]).join()}`,
		);
	}
	if (isSetStrategy(channel)) {
		tmp.push(`${strategyTargetGroupLabels.channel}：${channel.map((target) => Channel[target])}`);
	}
	if (isSetStrategy(fragrance)) {
		tmp.push(`${strategyTargetGroupLabels.fragrance}：${fragrance.map((target) => Fragrance[target])}`);
	}
	if (foundationType) {
		tmp.push(`${strategyTargetGroupLabels.foundationType}：${foundationType.map((target) => FoundationType[target])}`);
	}
	if (facewashFunction) {
		tmp.push(`${strategyTargetGroupLabels.facewashFunction}：${FacewashFunction[facewashFunction]}`);
	}
	const categoryTexts: string[] = [];
	for (const c of category) {
		const { price, beautyType, usedBrand, unusedBrand, surveyChoice: surveyChoiceNuber } = c;
		const targetCategory = c.category;
		const surveyChoice = targetCategory.surveyChoice.find((sc) => sc.value === surveyChoiceNuber);
		const categoryTmp = [`${strategyGroupCategoryLabels.price}：${makePriceLabel(price)}`];
		if (isSetStrategy(beautyType)) {
			categoryTmp.push(
				`${strategyGroupCategoryLabels.beautyType}：${beautyType.map((target) => BeautyType[target]).join("、")}`,
			);
		}
		/*/
		if (isSetStrategy(fragrance)) {
			categoryTmp.push(
				`${strategyGroupCategoryLabels.fragrance}：${fragrance.map((target) => Fragrance[target]).join("、")}`
			);
		}
		/*/
		// ブランド名のリストになる。
		if (isSetStrategy(usedBrand)) {
			categoryTmp.push(`${strategyGroupCategoryLabels.usedBrand}：${usedBrand.join("、")}`);
		}
		if (isSetStrategy(unusedBrand)) {
			categoryTmp.push(`${strategyGroupCategoryLabels.unusedBrand}：${unusedBrand.join("、")}`);
		}
		categoryTexts.push(`＜${surveyChoice.label}＞\n${categoryTmp.join("\n")}`);
	}
	if (categoryTexts.length) {
		tmp.push(`\n■ 以下カテゴリ間の条件は${categoryOrAnd}で設定する\n${categoryTexts.join(`\n`)}`);
	}
	return tmp.join("\n");
};

export class ConceptQuota {
	private quotas: QuotaWithoutCount[] = [];
	constructor(
		private activity: Activity,
		private job: Job,
	) {}
	public static make(activity: Activity, job: Job): QuotaWithoutCount[] {
		const cq = new ConceptQuota(activity, job);
		cq.create();
		return cq.getQuotas();
	}
	/**
	 * 全クォータを生成。
	 * @returns {void}
	 */
	public create(): void {
		this.createGeneral();
		this.createCategory();
		this.createStrategy();
	}
	/**
	 * 一般サンプルのクォータを生成
	 * @returns {void}
	 */
	public createGeneral(): void {
		this.setGeneralCountQuota();
		this.setGeneralQuota("test1");
		if (this.job.hasTwoTest) {
			this.setGeneralQuota("test2");
		}
	}
	/**
	 * ベースとなる一般サンプルのクォータを作る。
	 * このまま使用はせずに、加工して使用。
	 * @returns {QuotaWithoutCount[]}
	 */
	private makeBaseGeneralQuota(): QuotaWithoutCount[] {
		const quotas: QuotaWithoutCount[] = [];
		const { category } = this.activity;
		const { generalGroupQuota } = category;
		// dummyを後日変更する。
		// for (const q of dummyGeneralQuota) {
		for (const q of generalGroupQuota) {
			if (q.sampleSize === 0) continue; // サンプルがなければ、skip
			quotas.push({
				name: `【一般】${q.isUser ? "カテゴリユーザー" : "カテゴリノンユーザー"}、${Gender[q.gender]}、${makeAgeLabel(
					q.age,
				)}`,
				condition: `${genderQuename}=${q.gender === "male" ? 1 : 2} and ${ageQuename}>=${
					q.age.from
				} and ${ageQuename}<=${q.age.to} and ${categoryQuename}${q.isUser ? "=" : "!="}${category.surveyChoice
					.map((sc) => sc.value)
					.join(",")}`,
				code: `general`,
				enqueteId: this.job.enqueteId,
				plan: q.sampleSize,
				// 5％取っておくが、サンプルサイズが小さい場合は1加算できるようにしておく。
				limit: makeOverSample(q.sampleSize),
			});
		}
		return quotas;
	}
	/**
	 * カウント用の一般サンプルクォータを作成
	 * @returns {void}
	 */
	private setGeneralCountQuota(): void {
		let codeNumber = 1;
		for (const q of this.makeBaseGeneralQuota()) {
			this.quotas.push({
				...q,
				name: "カウント：" + q.name,
				code: `${countQuotaCode.general}${String(codeNumber).padStart(3, "0")}`,
				limit: 0,
				plan: 0,
			});
			codeNumber++;
		}
	}
	/**
	 * 一般サンプルクォータ生成。テスト品1とテスト品2でコードが異なる。
	 * @param {"test1" | "test2"} type
	 * @returns {void}
	 */
	private setGeneralQuota(type: "test1" | "test2"): void {
		let codeNumber = 1;
		const quotas = this.makeBaseGeneralQuota();
		for (const q of quotas) {
			this.quotas.push({
				...q,
				name: q.name + `（${ConceptType[type]}）`,
				condition: q.condition + ` and ${conceptQuename}=${type === "test1" ? 1 : 2}`,
				code: `${quotaCode[type]}${String(codeNumber).padStart(3, "0")}`,
			});
			codeNumber++;
		}
	}
	/**
	 * カテゴリターゲットのクォータを生成
	 * @returns {void}
	 */
	private createCategory(): void {
		// カウント用は Y001となる。
		if (!hasCategroyTargetGroup(this.activity)) return;
		const quota = this.makeBaseCategoryQuota();
		this.quotas.push({
			...quota,
			name: `カウント：${quota.name}`,
			code: `${countQuotaCode.category}001`,
			limit: 0,
			plan: 0,
		});
		this.setCategoryQuota("test1");
		const { hasTwoTest, hasBenchmark, hasCurrentProduct } = this.job;
		if (hasTwoTest) {
			this.setCategoryQuota("test2");
		}
		if (hasBenchmark) {
			this.setCategoryQuota("benchmark");
		}
		if (hasCurrentProduct) {
			this.setCategoryQuota("currentProduct");
		}
	}
	/**
	 * ベースとなるカテゴリターゲットのサンプルのクォータを作る。
	 * このまま使用はせずに、加工して使用。
	 * @returns {QuotaWithoutCount[]}
	 */
	public makeBaseCategoryQuota(): QuotaWithoutCount {
		const { categoryTargetGroup } = this.activity;
		const { category } = this.activity;
		const { price, age } = categoryTargetGroup;
		let priceCondition = "";
		if (price) {
			const choices = makePriceChoice(price);
			priceCondition = `and (${category.surveyChoice
				.map((sc) => `${priceQuename}s${sc.value}=${choices.map((choice) => choice.value).join(",")}`)
				.join(" or ")})`;
		}
		return {
			name: `【カテゴリターゲット】${makeCategoryGroupText(categoryTargetGroup, category.gender)}`,
			code: "category",
			enqueteId: this.job.enqueteId,
			condition: `${genderQuename}=${category.gender === "male" ? 1 : 2} and ${ageQuename}>=${
				age.from
			} and ${ageQuename}<=${age.to} and ${categoryQuename}=${category.surveyChoice
				.map((sc) => sc.value)
				.join(",")} ${priceCondition}`,
			plan: CategorySampleSize,
			limit: makeOverSample(CategorySampleSize),
		};
	}
	/**
	 * カテゴリターゲットのクォータ生成。typeに応じてコードが決まっている。
	 * @param {keyof typeof ConceptType} type
	 * @returns {void}
	 */
	private setCategoryQuota(type: keyof typeof ConceptType): void {
		const quota = this.makeBaseCategoryQuota();
		this.quotas.push({
			...quota,
			name: quota.name + `（${ConceptType[type]}）`,
			code: `${quotaCode.category}${String(conceptNumber[type]).padStart(3, "0")}`,
			condition: `${quota.condition} and ${conceptQuename}=${conceptNumber[type]}`,
			isBoost: true,
		});
	}
	/**
	 * 戦略ターゲットのクォータを作る。
	 * @returns {void}
	 */
	public createStrategy(): void {
		// 戦略ターゲットがなければ終了。
		if (!this.activity.strategyTargetGroup || !this.job.hasStrategicTarget) return;
		// カウント用のクォータを作成
		const quota = this.makeBaseStrategyQuota();
		this.quotas.push({
			...quota,
			name: "カウント：" + quota.name,
			code: `${countQuotaCode.strategy}001`,
			limit: 0,
			plan: 0,
		});
		this.setStrategyQuota("test1");
		const { hasTwoTest, hasBenchmark, hasCurrentProduct } = this.job;
		if (hasTwoTest) {
			this.setStrategyQuota("test2");
		}
		if (hasBenchmark) {
			this.setStrategyQuota("benchmark");
		}
		if (hasCurrentProduct) {
			this.setStrategyQuota("currentProduct");
		}
	}
	/**
	 * 戦略ターゲットのクォータ生成。typeに応じてコードが決まっている。
	 * @param {keyof typeof ConceptType} type
	 * @returns {void}
	 */

	private setStrategyQuota(type: keyof typeof ConceptType): void {
		const quota = this.makeBaseStrategyQuota();
		this.quotas.push({
			...quota,
			name: `【戦略ターゲットグループ】${quota.name}（${ConceptType[type]}）`,
			code: `${quotaCode.strategy}${String(conceptNumber[type]).padStart(3, "0")}`,
			condition: `(${quota.condition}) and ${conceptQuename}=${conceptNumber[type]}`,
			isBoost: true,
		});
	}
	/**
	 * ベースの戦略ターゲットのクォータを作る。
	 * @returns {QuotaWithoutCount}
	 */
	private makeBaseStrategyQuota(): QuotaWithoutCount {
		const { strategyTargetGroup } = this.activity;
		const { category } = this.activity;
		const conditions: string[] = [];
		conditions.push(`${genderQuename}=${category.gender === "male" ? 1 : 2}`);
		if (strategyTargetGroup.age) {
			conditions.push(
				`${ageQuename}>=${strategyTargetGroup.age.from} and ${ageQuename}<=${strategyTargetGroup.age.to}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.married)) {
			conditions.push(
				`${marriedQuename}=${strategyTargetGroup.married
					.map((m) => {
						return m === "married" ? "2" : "1,3";
					})
					.join(",")}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.children)) {
			conditions.push(
				`${familyQuename}=${strategyTargetGroup.children
					.map((c) => {
						const choice = familyChoices.find((choice) => choice.text === Children[c]);
						return choice.value;
					})
					.join(",")}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.occupation)) {
			conditions.push(
				`${occupationQuename}=${strategyTargetGroup.occupation
					.map((o) => {
						if (o === "employed") {
							return "1~5";
						}
						if (o === "student") {
							return "6,7";
						}
						if (o === "notEmployed") {
							return "8,9";
						}
						throw new Error("設定できない項目がある（職業）。");
					})
					.join(",")}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.skinAttribute)) {
			// enumは0始まりなので1ずらす。
			conditions.push(
				`${skinAttributeQuename}=${strategyTargetGroup.skinAttribute
					.map((s) => {
						return Number(s) + 1;
					})
					.join(",")}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.sensitiveSkin)) {
			// enumは0始まりなので1ずらす。
			conditions.push(
				`${sensitiveSkinQuename}=${strategyTargetGroup.sensitiveSkin
					.map((s) => {
						return Number(s) + 1;
					})
					.join(",")}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.channel)) {
			// enumは0始まりなので1ずらす。
			const tmp: string[] = [];
			for (const { surveyChoice } of strategyTargetGroup.category) {
				tmp.push(
					`${channelQuename}s${surveyChoice}=${strategyTargetGroup.channel
						.map((s) => {
							return Number(s) + 1;
						})
						.join(",")}`,
				);
			}
			conditions.push(`(${tmp.join(" Or ")})`);
		}
		if (isSetStrategy(strategyTargetGroup.fragrance)) {
			// enumは0始まりなので1ずらす。
			conditions.push(
				`${fregranceQuename}=${strategyTargetGroup.fragrance
					.map((s) => {
						return Number(s) + 1;
					})
					.join(",")}`,
			);
		}
		if (isSetStrategy(strategyTargetGroup.foundationType)) {
			// enumは0始まりなので1ずらす。
			conditions.push(
				`${foundationTypeQuename}=${strategyTargetGroup.foundationType
					.map((s) => {
						return Number(s) + 1;
					})
					.join(",")}`,
			);
		}
		if (strategyTargetGroup.facewashFunction != null) {
			// enumは0始まりなので1ずらす。
			conditions.push(`${facewarhFunctionQuename}=${Number(strategyTargetGroup.facewashFunction) + 1}`);
		}
		/**
			usedBrand?: (ObjectId | string)[];
			unusedBrand?: (ObjectId | string)[];
		 */
		const categoryConditions: string[] = [];
		for (const target of strategyTargetGroup.category) {
			const targetCategory = target.category;
			const surveyChoice = targetCategory.surveyChoice.find((sc) => sc.value === target.surveyChoice);
			const { brandlist } = targetCategory;
			const tmpConditions: string[] = [`${categoryQuename}=${surveyChoice.value}`];
			if (target.price && (target.price.from || target.price.to)) {
				const choices = makePriceChoice(target.price);
				tmpConditions.push(`${priceQuename}s${surveyChoice.value}=${choices.map((choice) => choice.value).join(",")}`);
			}
			if (isSetStrategy(target.beautyType)) {
				tmpConditions.push(
					`${beautyTypeQuename}s${surveyChoice.value}=${target.beautyType
						.map((s) => {
							return Number(s) + 1;
						})
						.join(",")}`,
				);
			}
			if (isSetStrategy(target.usedBrand)) {
				const targetBrand = brandlist.filter((b) => target.usedBrand.includes(b.brandName));
				tmpConditions.push(
					`${usedBrandQuename}-${surveyChoice.value}=${targetBrand
						.map((b) => b.surveyChoice.map((choice) => choice.value).join(","))
						.join(",")}`,
				);
			}
			if (isSetStrategy(target.unusedBrand)) {
				const targetBrand = brandlist.filter((b) => target.unusedBrand.includes(b.brandName));
				tmpConditions.push(
					`${usedBrandQuename}-${surveyChoice.value}!=${targetBrand
						.map((b) => b.surveyChoice.map((choice) => choice.value).join(","))
						.join(",")}`,
				);
			}
			categoryConditions.push(`(${tmpConditions.join(" and ")})`);
		}
		if (categoryConditions.length) {
			conditions.push(`(${categoryConditions.join(` ${strategyTargetGroup.categoryOrAnd} `)})`);
		}
		const quota = {
			name: `【戦略ターゲット】${makeStrategyGroupText(strategyTargetGroup, category.gender)}`,
			code: `strategy`,
			enqueteId: this.job.enqueteId,
			plan: StrategySampleSize,
			limit: makeOverSample(StrategySampleSize),
			count: 0,
			condition: conditions.join(" and "),
		};
		return quota;
	}
	public getQuotas(): QuotaWithoutCount[] {
		return this.quotas;
	}
}
