import * as React from "react";
import { ReportBigTitle } from "../parts/report-title";
import { Row, Col, Button } from "react-bootstrap";
import { GraphWrapArea } from "../common";
import { AccountWithoutPassword } from "../../../../../../server/models/account";
import { Role } from "../../../../../../server/lib/permission/role";
import { Job } from "../../../../../../server/models/job";

export type DownloadType = "rawdata" | "question-sheet";
export type ReportDownloadPageProps = {
	onDownload: (type: DownloadType) => void;
	job: Job;
	loginAccount: AccountWithoutPassword;
};
export const ReportDownloadPage = React.memo((props: ReportDownloadPageProps) => {
	const { onDownload, loginAccount, job } = props;
	const onRawdataDownload = React.useCallback(() => onDownload("rawdata"), [onDownload]);
	const onQuestionSheetDownload = React.useCallback(() => onDownload("question-sheet"), [onDownload]);
	const canDownloadRawdata = React.useMemo(() => Role.hasOperarorManageRole(loginAccount), [loginAccount]);
	return (
		<>
			<ReportBigTitle>データダウンロード{job ? `（${job.jobNum}）` : ""}</ReportBigTitle>
			<GraphWrapArea>
				<Row>
					{canDownloadRawdata && (
						<Col md={3}>
							<Button variant={"outline-secondary"} onClick={onRawdataDownload}>
								ローデータDownload
							</Button>
						</Col>
					)}
					<Col md={3}>
						<Button variant={"outline-secondary"} onClick={onQuestionSheetDownload}>
							質問票Download
						</Button>
					</Col>
				</Row>
			</GraphWrapArea>
		</>
	);
});
