/**
 * クロス集計画面で表示する軸名
 */
/**
 * 軸の順番は、クロス集計画面での表示順に依存するため、変更しないこと
 */
export const strategyTargetAxisNameMap = {
	all: "全体",
	purchaseIntender: "購入意向者",
	nonPurchaseIntender: "非購入意向者",
} as const;
export type StrategyAxisName = keyof typeof strategyTargetAxisNameMap;
export const strategyTargetAxisNames = Object.keys(strategyTargetAxisNameMap) as StrategyAxisName[];

export const strategyMarketMavenAxisNameMap = {
	all: "全体",
} as const;

export type StrategyMarketMavenAxisName = keyof typeof strategyMarketMavenAxisNameMap;
export const strategyMarketMavenAxisName = Object.keys(strategyMarketMavenAxisNameMap) as StrategyMarketMavenAxisName[];

export const brandUserAxisNameMap = {
	all: "全体",
} as const;

export type BrandUserAxisName = keyof typeof brandUserAxisNameMap;
export const brandUserAxisNames = Object.keys(brandUserAxisNameMap) as BrandUserAxisName[];

/**
 * カテゴリユーザーは、集計軸に含まないが、需要予測に使うため、ここに定義
 */
export const categoryUserAxisNameMap = {
	all: "全体",
};

export type CategoryUserAxisName = keyof typeof categoryUserAxisNameMap;
export const categoryUserAxisNames = Object.keys(categoryUserAxisNameMap) as CategoryUserAxisName[];
