export type Searched = {
	targets: Element[];
	index: number;
	text: string;
};

export type State = {
	text: string;
	searched?: Searched;
};

export const initState: State = {
	text: "",
};
