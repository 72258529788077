export enum AdCopyJobStatus {
	progress = "進行中",
	complete = "調査完了",
	stopping = "休止中",
	deleted = "削除済",
	completeWithZeroSamples = "調査完了（回収数0）",
}

export const AdCopyJobStatusOptions = Object.entries(AdCopyJobStatus)
	.filter(([value, label]) => {
		if (label === AdCopyJobStatus.deleted) return false;
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopySurveyStatus {
	deleted = "削除済",
	conceptSetting = "コピー未完成",
	beforeApplication = "申請前",
	stopping = "調査中止",
	applying = "申請中",
	applicationReject = "申請却下",
	waitingToStart = "調査開始待ち",
	progress = "回収中",
	complete = "調査完了",
	completeWithZeroSamples = "調査完了（回収数0）",
}

export const adCopysurveyStatusOptions = Object.entries(AdCopySurveyStatus)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyActivityStatus {
	progress = "非公開",
	complete = "公開",
	deleted = "削除済",
}

export const AdCopyActivityStatusOptions = Object.entries(AdCopyActivityStatus)
	.filter(([value, label]) => {
		if (label === AdCopyActivityStatus.deleted) return false;
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export enum AdCopyApproveStatus {
	approve = "承認",
	reject = "却下",
}
