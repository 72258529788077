import * as React from "react";
import { Button, Col } from "react-bootstrap";

import { type AdCopyConceptKey, AdCopyConceptTypeMap } from "../../../../../../../../server/models/ad-copy/concept";
import type { TrueAdCopyConcept } from "../../../../../../types/ad-copy/concept";

type Props = {
	concept: TrueAdCopyConcept;
	conceptType: "test1" | "test2" | "test3";
	onRetestCopy: (original: AdCopyConceptKey, copyTo: AdCopyConceptKey) => void;
};

export const AdCopyRetestCopyButton = React.memo((props: Props) => {
	const { concept, conceptType, onRetestCopy } = props;

	return (
		<Col md={3}>
			<Button
				onClick={() => {
					onRetestCopy(conceptType, concept.type);
				}}
				variant="outline-secondary"
			>
				【過去調査】{AdCopyConceptTypeMap[conceptType]}からコピー
			</Button>
		</Col>
	);
});
