export type SentenceTotalConfig = {
	max: number;
	min: number;
	indication: number;
};
export type SentenceConfig = {
	maxCount: number;
	minCount: number;
	indicationCount: number;
	max: number;
	min: number;
};
export const sentenceTotalConfig: SentenceTotalConfig = {
	min: 200,
	max: 400,
	indication: 300,
};
export const sentenceConfig: SentenceConfig = {
	minCount: 6,
	maxCount: 20,
	min: 2,
	max: 110,
	indicationCount: 15,
};
