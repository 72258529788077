import * as React from "react";
import { Col, Row, Button, Alert } from "react-bootstrap";
import { Concept, ConceptType } from "../../../../../../server/models/concept";
import { TabViewer, TabSetting, ErrorObject } from "../../../../../../react-components/lu-component/src/index";
import { ConceptFormFunc, ConceptForm } from "./concept-form";
import { RetestConcepts } from "../../../../../../server/types/request/concept";

export type ConceptPageProps = {
	retestConcepts?: RetestConcepts;
	concepts: Concept[];
	cantEditMessage?: string;
	onPreview: () => void;
	onSubmit: () => void;
	onTemporarySave: () => void;
	errors?: ErrorObject;
} & ConceptFormFunc;

export const ConceptPage = React.memo((props: ConceptPageProps) => {
	const {
		concepts,
		onPreview,
		onSubmit,
		errors,
		cantEditMessage,
		onTemporarySave,
		retestConcepts,
		...ConceptFormProps
	} = props;
	const conceptTitles = React.useMemo(() => {
		return concepts.map((concept) => ConceptType[concept.type]);
	}, [concepts]);
	const conceptErorrs = React.useMemo(() => {
		if (!errors) return undefined;
		if (!errors["concepts"]) return undefined;
		const e = errors["concepts"];
		if (typeof e === "string") return undefined;
		return e;
	}, [errors]);

	const tabs = React.useMemo(() => {
		return concepts.map((concept, index) => {
			return {
				key: concept.type,
				title: ConceptType[concept.type],
				children: (
					<ConceptForm
						retestConcepts={retestConcepts}
						concept={concept}
						{...ConceptFormProps}
						errors={
							conceptErorrs && conceptErorrs[index] && typeof conceptErorrs[index] !== "string"
								? (conceptErorrs[index] as ErrorObject)
								: undefined
						}
					/>
				),
			} as TabSetting;
		});
	}, [concepts, retestConcepts, ConceptFormProps, conceptErorrs]);
	return (
		<>
			{!!cantEditMessage && (
				<div className="m-3">
					<Alert variant={"danger"}>{cantEditMessage}</Alert>
				</div>
			)}
			<div className="m-3 clearfix">
				<Row>
					<Col md={12}>
						<div className="h3 float-start">■コンセプト設定</div>
					</Col>
				</Row>
				{!!conceptErorrs && (
					<Row>
						<Col md={12} style={{ marginBottom: "10px" }}>
							<Alert variant={"danger"}>
								{Object.keys(conceptErorrs)
									.map((key) => conceptTitles[Number(key)])
									.join("、")}
								に対して登録エラーがあります。各タブにてご確認をお願いします。
							</Alert>
						</Col>
					</Row>
				)}
				{tabs.length && <TabViewer tabs={tabs} id={"concept"} />}
				<Row style={{ marginTop: "30px" }}>
					<Col md={{ span: 6, offset: 2 }}>
						{!cantEditMessage ? (
							<Row>
								<Col md={4}>
									<Button variant="secondary" className="w-100" onClick={onTemporarySave}>
										一時保存
									</Button>
								</Col>
								<Col md={4}>
									<Button variant="secondary" className="w-100" onClick={onPreview}>
										一時保存＆プレビュー
									</Button>
								</Col>
								<Col md={4}>
									<Button variant="secondary" className="w-100" onClick={onSubmit}>
										登録
									</Button>
								</Col>
							</Row>
						) : (
							<Button variant="secondary" className="w-100" disabled>
								保存できません
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
});
