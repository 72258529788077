import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import type { ErrorObject } from "../../../../../../../../server/types/error";
import type { TrueAdCopySku } from "../../../../../../types/ad-copy/concept";
import { AdCopySkuForm } from "./sku-form";

const SubTitle = styled.div`
	padding: 0.4rem;
	margin-bottom: 0.4rem;
	border-bottom: 1px solid #ddd;
`;

type Props = {
	disabled?: boolean;
	errors?: ErrorObject;
	onAddSku: (index: number) => void;
	onChangeSku: <T extends keyof TrueAdCopySku>(index: number, name: T, value: TrueAdCopySku[T]) => void;
	onDeleteSku: (index: number) => void;
	setTaxIncluded: (index: number) => void;
	skuItems: TrueAdCopySku[];
};

export const AdCopyMultipleSkuForm = React.memo((props: Props) => {
	const { disabled, errors, onAddSku, onChangeSku, onDeleteSku, setTaxIncluded, skuItems } = props;

	return (
		<>
			{skuItems.length === 0 && (
				<div style={{ padding: "10px" }}>
					<Button disabled={disabled} onClick={() => onAddSku(0)} variant="outline-secondary">
						価格・容量設定
					</Button>
				</div>
			)}

			{skuItems.map((skuItem, index) => (
				<Row key={`sku-item-${index}`}>
					<Col md={12}>
						<SubTitle>{index + 1}SKU目</SubTitle>
					</Col>

					<Col md={8}>
						<AdCopySkuForm
							disabled={disabled}
							errors={
								errors && errors[index] && typeof errors[index] !== "string"
									? (errors[index] as ErrorObject)
									: undefined
							}
							onChange={(name, value) => onChangeSku(index, name, value)}
							setTaxIncluded={() => setTaxIncluded(index)}
							sku={skuItem}
						/>
					</Col>

					<Col>
						<Button
							disabled={disabled}
							onClick={() => onDeleteSku(index)}
							style={{ display: "inline" }}
							variant="outline-danger"
						>
							×
						</Button>

						<Button
							disabled={disabled}
							onClick={() => onAddSku(index + 1)}
							style={{ display: "inline" }}
							variant="outline-secondary"
						>
							+
						</Button>
					</Col>
				</Row>
			))}
		</>
	);
});
