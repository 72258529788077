import * as React from "react";
import { Col, Row } from "react-bootstrap";

import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyInvoice } from "../../../../../../server/models/ad-copy/invoice";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";

const Contains = React.memo((props: { children: React.ReactNode; classAdd?: string; field: string }) => {
	return (
		<Row className="mt-3">
			<Col className={`text-end${props.classAdd ? ` ${props.classAdd}` : ""}`} md={3}>
				{props.field}
			</Col>

			<Col>{props.children}</Col>
		</Row>
	);
});

type Props = {
	account: AccountWithoutPassword;
	activity: AdCopyActivityWithBrand;
	invoice: AdCopyInvoice;
	job: AdCopyJob;
};

export const AdCopyInvoicePage = React.memo((props: Props) => {
	const { account, activity, invoice, job } = props;

	return (
		<div className="m-3 clearfix">
			<Row>
				<Col md={12}>
					<div className="h3 float-start">■ご請求詳細　※請求書は別途届きます。</div>
				</Col>
			</Row>

			<Row>
				<Col className="mb-3" md={12}>
					<Row className="m-3">
						<Col md={12}>
							<div className="h4">・アクティビティ情報</div>

							<Contains field="ブランド：">{activity.brand.name}</Contains>

							<Contains field="カテゴリ：">{activity.category.name}</Contains>
						</Col>
					</Row>

					<Row className="m-3">
						<Col md={12}>
							<div className="h4">・ジョブ情報</div>

							<Contains field="ID：">{job.jobNum}</Contains>

							<Contains field="入力担当者：">{account.name}</Contains>
						</Col>
					</Row>

					<Row className="m-3">
						<Col md={12}>
							<div className="h4">・請求情報</div>

							<Contains field="請求ID：">{invoice._id?.toString()}</Contains>

							<Contains field="SC計（税抜き）：">{invoice.sc.toLocaleString()}円</Contains>

							<Contains field="本調査計（税抜き）：">{invoice.complete.toLocaleString()}円</Contains>

							<Contains field="集計費（税抜き）：">{(invoice.aggregationCost || 0).toLocaleString()}円</Contains>

							<hr />

							<Contains classAdd="fw-bold" field="税抜き計：">
								{(invoice.sc + invoice.complete + (invoice.aggregationCost || 0)).toLocaleString()}円
							</Contains>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
});
