import * as React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { post } from "../../lib/request";
import styled from "styled-components";
import { AccountWithoutPassword } from "../../../../server/models/account";
import { AuthResponse } from "../../../../server/types/request/login";
import { endpoint, replaceEndpointUrl } from "../../../../server/router/endpoint";
import { NavigationBar, NavigationBarProps } from "../../components/parts/navigation-bar";
import { Container } from "react-bootstrap";
import { clientEndpoint } from "../../routes/endpoint";
import { Role } from "../../../../server/lib/permission/role";
import { SideMenuPage, SideMenuItem, SideMenuItemType } from "../../components/parts/sidemenu";
import {
	faHome,
	faFileInvoice,
	faChartLine,
	faCommentAlt,
	faSearch,
	faDownload,
	faImage,
} from "@fortawesome/free-solid-svg-icons";
import { filterAdCopyBrands } from "../../../../server/lib/ad-copy/brand";

const top = 50;
const AreaWrap = styled.div<{ top: number }>`
	margin-top: ${({ top }) => top}px;
`;

export type WithAuthProps = {
	isReportPage?: boolean;
	doNotUseTab?: boolean;
	children: ((account: AccountWithoutPassword) => React.ReactNode) | React.ReactNode;
};

export const WithAuth = React.memo((props: WithAuthProps) => {
	const { children, isReportPage, doNotUseTab } = props;
	const [requested, setRequested] = React.useState<boolean>(false);
	const [account, setAccount] = React.useState<AccountWithoutPassword>();
	React.useEffect(() => {
		post<AuthResponse>(endpoint.authentication)
			.then((response) => {
				if (response.data.account) {
					const loginedAccount = response.data.account;
					setAccount({ ...loginedAccount, viewAdCopyBrands: filterAdCopyBrands(loginedAccount.brands ?? []) });
				}
				setRequested(true);
			})
			.catch((err) => {
				console.error(err);
				setRequested(true);
			});
	}, []);
	const navigate = useNavigate();
	const { jobId, activityId, onRetest } = useParams<{ jobId: string; activityId: string; onRetest: string }>();
	const navProps = React.useMemo(() => {
		const tmp: NavigationBarProps = {
			account,
			onActivityPage: () => navigate(clientEndpoint.activity),
			onMenuPage: () => navigate(clientEndpoint.menu),
		};
		if (account && Role.hasOperarorManageRole(account)) {
			tmp.onJobDownloadPage = () => navigate(clientEndpoint.jobDownload);
		}
		tmp.onJobSearchPage = () => navigate(clientEndpoint.jobSearch);
		if (account && Role.hasOperarorManageRole(account)) {
			tmp.onAggregatePage = () => navigate(clientEndpoint.aggregate);
		}
		if ((jobId || onRetest) && activityId) {
			tmp.onJobPage = () => navigate(`/activity/${activityId}/job/`);
			if (jobId !== "new" && !onRetest) {
				tmp.jobAction = {
					onConceptPage: () => navigate(replaceEndpointUrl(clientEndpoint.concept, { activityId, jobId })),
					onPreviewPage: () => navigate(replaceEndpointUrl(clientEndpoint.preview, { activityId, jobId })),
					onJobEditPage: () => navigate(replaceEndpointUrl(clientEndpoint.jobCreate, { activityId, jobId })),
				};
			}
		}
		tmp.onLogout = () => navigate(clientEndpoint.logout);
		return tmp;
	}, [account, activityId, navigate, jobId, onRetest]);

	const reportPageEvents = React.useMemo<SideMenuItem[]>(() => {
		if (!isReportPage) return [];
		const tmp = [
			{
				label: "テストの概要",
				icon: faHome,
				onClick: () => navigate(replaceEndpointUrl(clientEndpoint.outline, { activityId, jobId })),
			},
			{
				label: "エグゼクティブサマリー",
				icon: faFileInvoice,
				onClick: () => navigate(replaceEndpointUrl(clientEndpoint.exectiveReport, { activityId, jobId })),
			},

			{
				label: "結果の詳細",
				icon: faChartLine,
				onClick: () => navigate(replaceEndpointUrl(clientEndpoint.resultByTargetReport, { activityId, jobId })),
			},
			{
				label: "自由回答集",
				icon: faCommentAlt,
				onClick: () => navigate(replaceEndpointUrl(clientEndpoint.oaReport, { activityId, jobId })),
			},
			{
				label: "集計表",
				icon: faSearch,
				onClick: () => navigate(replaceEndpointUrl(clientEndpoint.keyMeasurementsReport, { activityId, jobId })),
			},
			{
				label: "ダウンロード",
				icon: faDownload,
				onClick: () => navigate(replaceEndpointUrl(clientEndpoint.downloadReport, { activityId, jobId })),
			},
			{
				label: "提示コンセプト",
				icon: faImage,
				onClick: () =>
					window.open(
						replaceEndpointUrl(clientEndpoint.conceptReport, { activityId, jobId }),
						"_blank",
						"width=500,height=500",
					),
				type: "popUp" as SideMenuItemType,
			},
		];
		return tmp;
	}, [activityId, navigate, isReportPage, jobId]);

	return (
		<>
			{/** TODO権限をチェックしてリダイレクトを指定 */}
			{requested &&
				(account ? (
					<>
						{!doNotUseTab && <NavigationBar {...navProps} />}
						{!isReportPage && (
							<Container className="mb-5" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
								<AreaWrap className="mb-5" top={top}>
									{typeof children === "function" ? children(account) : children}
								</AreaWrap>
							</Container>
						)}
						{isReportPage && (
							<SideMenuPage items={reportPageEvents} top={top}>
								{typeof children === "function" ? children(account) : children}
							</SideMenuPage>
						)}
					</>
				) : (
					// 認証がない場合はloginへ遷移
					<Navigate to={clientEndpoint.login} />
				))}
		</>
	);
});
