import * as React from "react";
import { ElementEvaluation } from "../../../../../../../server/models/graph/element-evaluation";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import { evaluationColors } from "../../../../parts/graph/lib/color";
import { ConceptType, ConceptLabel, ConceptKey } from "../../../../../../../server/models/concept";
import { ReportTitle } from "../../parts/report-title";
import styled from "styled-components";
import { ConceptSampleSize } from "../../../../../../../server/models/graph/outline";
import { array2CsvBlob, downloadData } from "../../../../../lib/data";
import { Button } from "react-bootstrap";
import { TextRed } from "../../../../parts/font";

const GraphWrap = styled.div`
	max-width: 1000px;
	margin: 0px auto;
`;
const PrecedentWrap = styled.div`
	margin-left: 100px;
	margin-bottom: 20px;
`;

export type EvaluationGraphProps = {
	filename?: string;
	data: ElementEvaluation[];
	sampleSize: ConceptSampleSize;
};
export const EvaluationGraph = React.memo((props: EvaluationGraphProps) => {
	const { filename, data, sampleSize } = props;
	const keys = React.useMemo<(ConceptLabel | "ノーム")[]>(() => {
		const tmp = data.reduce((a, b) => {
			return a.concat(b.conceptType === "norm" ? "ノーム" : ConceptType[b.conceptType]);
		}, []);
		return Array.from(new Set(tmp));
	}, [data]);
	const lineChartData = React.useMemo(() => {
		const map = data.reduce((a, b) => {
			const label = b.conceptType === "norm" ? "ノーム" : ConceptType[b.conceptType];
			for (const ei of b.evaluvationItem) {
				const key = ei.label;
				const target = a.get(key) || { name: ei.label };
				a.set(key, { ...target, [label]: ei.val });
			}
			return a;
		}, new Map<string, { [key in ConceptLabel | "ノーム"]?: number } & { name: string }>());
		return Array.from(map).map(([, item]) => item);
	}, [data]);
	const onDownload = React.useCallback(() => {
		const concepts = Array.from(keys);
		const headers = ["評価項目", ...concepts];
		const map = data.reduce((a, { evaluvationItem, conceptType }) => {
			for (const { val, label, choiceNum } of evaluvationItem) {
				const key = `${choiceNum}.${label}`;
				if (!a.get(key)) {
					a.set(key, new Map<string, number>());
				}
				const tmp = a.get(key);
				tmp.set(conceptType === "norm" ? "ノーム" : ConceptType[conceptType], val);
			}
			return a;
		}, new Map<string, Map<string, number>>());
		const tmp = array2CsvBlob([
			headers,
			...Array.from(map).map(([key, m]) => {
				return [key, ...concepts.map((key) => m.get(key) || "")];
			}),
		]);
		downloadData(tmp, `${filename || "印象評価"}.csv`);
	}, [filename, data, keys]);
	return (
		<>
			<ReportTitle>印象評価（※5段階評価の平均値）</ReportTitle>
			<GraphWrap>
				<PrecedentWrap>
					{Object.entries(sampleSize ?? {}).map(([conceptType, sample]: [ConceptKey, number]) => (
						<div key={`concept-${conceptType}`}>
							{sample < 30 ? (
								<TextRed>
									{ConceptType[conceptType]}：{sample}s
								</TextRed>
							) : (
								`${ConceptType[conceptType]}：${sample}`
							)}
						</div>
					))}
				</PrecedentWrap>
				<Button size={"sm"} variant={"outline-secondary"} onClick={onDownload}>
					Download
				</Button>
				<LineChart
					layout="vertical"
					width={1000}
					height={lineChartData.length * 40}
					data={lineChartData}
					margin={{
						top: 20,
						right: 30,
						left: 250,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="2 2" />
					<XAxis type={"number"} ticks={[1, 2, 3, 4, 5]} domain={[1, 5]} />
					<YAxis dataKey={"name"} type={"category"} />
					<Tooltip formatter={(value) => Number(value).toFixed(2)} />
					<Legend />
					{keys.map((key, index) => (
						<Line
							key={`line-${index}`}
							dataKey={key}
							stroke={evaluationColors[key]}
							strokeWidth={key === ConceptType.test1 || key === ConceptType.test2 ? 3 : 1}
						/>
					))}
				</LineChart>
			</GraphWrap>
		</>
	);
});
