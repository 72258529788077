import { ActivityMemberAction } from "../../../types/actions";
import { ActivityWithBrand } from "../../../../../server/models/activity";
import { MessageInfo } from "../../../types/info";
import { AccountWithoutPassword } from "../../../../../server/models/account";

export type State = {
	activity: ActivityWithBrand;
	accounts: AccountWithoutPassword[];
	activityMembers: string[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	accounts: [],
	activityMembers: [],
	activity: undefined,
	loading: true,
	info: {
		message: "",
		isSuccess: true,
	},
};

export const reducer = (state: State, action: ActivityMemberAction): State => {
	switch (action.type) {
		case "loadActivity":
			return {
				...state,
				...action.payload,
				loading: false,
				...action.payload,
				activityMembers: action.payload.activity.accountIds as string[],
			};
		case "changeMembers":
			return {
				...state,
				activityMembers: action.payload,
			};
		case "updateMembers":
			return {
				...state,
				loading: false,
				info: {
					isSuccess: true,
					message: "アクティビティ登録者を更新しました。",
				},
			};
		case "changeMessageInfo":
			return {
				...state,
				loading: false,
				info: action.payload,
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};
