import * as React from "react";
import { Button } from "react-bootstrap";
import type { To } from "react-router-dom";
import { Link } from "react-router-dom";

type Props = React.ComponentProps<typeof Button> & {
	to: To;
};

export const ButtonLink = React.memo(({ children, to, ...props }: Props) => {
	return (
		<Button as={Link as any} to={to} {...props}>
			{children}
		</Button>
	);
});
