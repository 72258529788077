import * as React from "react";
import { Concept, ConceptKey, ConceptType } from "../../../../../../../server/models/concept";
import { OutlinePanelTitle, OutlinePanel, OutlineCard } from "..";
import styled from "styled-components";
import { Modal, Button } from "react-bootstrap";
import { get } from "../../../../../lib/request";
import { downloadData } from "../../../../../lib/data";

export type ConceptPanelProps = {
	concepts: Concept[];
};
const ConceptTitle = styled.div``;
const ConceptArea = styled.div`
	text-align: center;
	& img {
		width: 100%;
		max-width: 500px;
	}
	padding-bottom: 10px;
	margin-bottom: 10px;

	img.thumbnail:hover {
		cursor: pointer;
	}
`;

const ModalImage = React.memo((props: { label: string; url: string }) => {
	const [show, setShow] = React.useState<boolean>(false);
	const { label, url } = props;
	const onDownload = React.useCallback(() => {
		get<ArrayBuffer>(url, {}, { responseType: "arraybuffer" }).then((response) => {
			const blob = new Blob([response.data], { type: "image/png" });
			downloadData(blob, `${label}.png`);
		});
	}, [url, label]);
	return (
		<>
			<ConceptTitle>{label}</ConceptTitle>
			<ConceptArea onClick={() => setShow(true)}>
				<img src={url} className={"thumbnail"} />
			</ConceptArea>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{label}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ConceptArea>
						<img src={url} />
					</ConceptArea>
				</Modal.Body>
				<Modal.Footer>
					<Button variant={"secondary"} onClick={onDownload}>
						download
					</Button>
					<Button variant={"outline-secondary"} onClick={() => setShow(false)}>
						close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
export const ConceptPanel = React.memo((props: ConceptPanelProps) => {
	const { concepts } = props;
	const conceptMap = React.useMemo(() => {
		return concepts.reduce((a, b) => {
			a.set(b.type, b);
			return a;
		}, new Map<ConceptKey, Concept>());
	}, [concepts]);
	return (
		<OutlinePanel className={"panel"}>
			<OutlinePanelTitle>呈示コンセプト</OutlinePanelTitle>
			{conceptMap.has("test1") && (
				<OutlineCard width={250} key={`concept-test1`}>
					<ModalImage label={ConceptType.test1} url={conceptMap.get("test1").conseptUrl} />
				</OutlineCard>
			)}
			{conceptMap.has("test2") && (
				<OutlineCard width={250} key={`concept-test2`}>
					<ModalImage label={ConceptType.test2} url={conceptMap.get("test2").conseptUrl} />
				</OutlineCard>
			)}
			{conceptMap.has("benchmark") && (
				<OutlineCard width={250} key={`concept-benchmark`}>
					<ModalImage label={ConceptType.benchmark} url={conceptMap.get("benchmark").conseptUrl} />
				</OutlineCard>
			)}
			{conceptMap.has("currentProduct") && (
				<OutlineCard width={250} key={`concept-currentProduct`}>
					<ModalImage label={ConceptType.currentProduct} url={conceptMap.get("currentProduct").conseptUrl} />
				</OutlineCard>
			)}
			{/*concepts.map((concept) => {
				return (
					<OutlineCard width={250} key={`concept-${concept.type}`}>
						<ModalImage label={ConceptType[concept.type]} url={concept.conseptUrl} />
					</OutlineCard>
				);
			})*/}
		</OutlinePanel>
	);
});
