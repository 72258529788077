import { AggregateTargetQuestion } from "@analytics/types";
import { PageConfig, Question, WrapQuestion } from "@muscat/types";

// 構成がおかしい以外出ないエラー。
export class ConceptQuestionError extends Error {
	constructor(...params: any[]) {
		super(...params);
		Object.setPrototypeOf(this, ConceptQuestionError.prototype);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ConceptQuestionError);
		}
		this.name = "ConceptQuestionError";
	}
}

export const getTargetQuestionIndex = (questionnaire: PageConfig[], quename: string) => {
	for (let pageIndex = 0; pageIndex < questionnaire.length; pageIndex++) {
		const wrapQuestion = questionnaire[pageIndex];
		if (!("questions" in wrapQuestion)) {
			continue;
		}
		for (let queIndex = 0; queIndex < wrapQuestion.questions.length; queIndex++) {
			const q = wrapQuestion.questions[queIndex];
			if (quename === q.quename) {
				return { pageIndex, queIndex };
			}
		}
	}
	return undefined;
};

export const makeQuestionArray = (wrapQuestions: WrapQuestion[]): Question[] => {
	return wrapQuestions.reduce((a, { questions }) => {
		for (const question of questions) {
			a.push(question);
		}
		return a;
	}, []);
};

export const makeAggregateQuestions = (questionnaire: PageConfig[]): AggregateTargetQuestion[] => {
	return extractWrapQuestions(questionnaire).reduce((a: AggregateTargetQuestion[], { questions }) => {
		return [
			...a,
			...questions.filter(
				(question): question is AggregateTargetQuestion =>
					!(question.type === "E" || question.type === "FI" || question.type === "O"),
			),
		];
	}, []);
};

/**
 * アンケートの設問部分(pageConfigのwrapQuestionのみ)をを抽出する。
 */
export const extractWrapQuestions = (questionnaire: PageConfig[]): WrapQuestion[] => {
	return questionnaire.filter((pageConfig): pageConfig is WrapQuestion => {
		return "questions" in pageConfig;
	});
};
