import * as React from "react";

import {
	type AdCopyConcept,
	type AdCopyConceptKey,
	AdCopyConceptTypeMap,
} from "../../../../../../../../server/models/ad-copy/concept";
import { AdCopyOutlineCard, AdCopyOutlinePanel, AdCopyOutlinePanelTitle } from "..";
import { orderedConceptTypes } from "../constants";
import { AdCopyModalImage } from "./modal-image";

type Props = {
	concepts: AdCopyConcept[];
};

export const AdCopyConceptPanel = React.memo((props: Props) => {
	const { concepts } = props;

	const conceptMap = React.useMemo(
		() =>
			concepts.reduce((a, b) => {
				a.set(b.type, b);

				return a;
			}, new Map<AdCopyConceptKey, AdCopyConcept>()),
		[concepts],
	);

	const conceptArray = React.useMemo(() => orderedConceptTypes.map((key) => conceptMap.get(key)), [conceptMap]);

	return (
		<AdCopyOutlinePanel className="panel">
			<AdCopyOutlinePanelTitle>呈示素材</AdCopyOutlinePanelTitle>

			{conceptArray.map(
				(concept) =>
					concept != null && (
						<AdCopyOutlineCard key={concept.type} className="me-1" width={250}>
							<AdCopyModalImage
								label={AdCopyConceptTypeMap[concept.type]}
								skuArray={concept.sku}
								url={concept.imageUrl}
							/>
						</AdCopyOutlineCard>
					),
			)}
		</AdCopyOutlinePanel>
	);
});
