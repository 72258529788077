import type { GetAdCopyOAReportResponse } from "../../../../../../server/types/request/ad-copy/report/openAnswer";
import type { AdCopyOAAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = GetAdCopyOAReportResponse & {
	info: MessageInfo;
	loading: boolean;
};

export const initState: State = {
	data: {
		oaColumns: [],
		oaData: [],
	},
	info: {
		isSuccess: true,
		message: "",
	},
	job: undefined,
	loading: true,
};

export const reducer = (state: State, action: AdCopyOAAction): State => {
	switch (action.type) {
		case "loadData":
			return {
				...state,
				loading: false,
				...action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
