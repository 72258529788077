import * as React from "react";
import { FormControl, Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import { ErrorObject } from "../form-input-groups";
import Feedback from "react-bootstrap/esm/Feedback";
import { getErrorMessage } from "../../../../../client/scripts/lib/error";

export type MultipleTextprops = {
	errors?: ErrorObject;
	stepTitle?: { prefix?: string; suffix?: string };
	max?: number;
	values: string[];
	hasRow?: boolean;
	hasAddEvent?: boolean;
	sentenceOptions?: {
		breakCount?: boolean;
		min?: number;
		max?: number;
	};
	onChange: (values: string[]) => void;
	disabled?: boolean;
};

const NumberArea = styled.div`
	padding: 3px;
	text-align: right;
`;

export const MultipleText = React.memo((props: MultipleTextprops) => {
	const { errors, values, hasRow, onChange, max, sentenceOptions, hasAddEvent, stepTitle, disabled } = props;
	const onChangeText = React.useCallback(
		(index: number, value: string) => {
			const tmp = values.concat();
			tmp[index] = value;
			if (tmp[values.length - 1] !== "" && (!max || values.length < max)) {
				tmp.push("");
			}
			onChange(tmp);
		},
		[values, max, onChange],
	);
	const onDelete = React.useCallback(
		(index: number) => {
			const tmp = values.concat();
			tmp.splice(index, 1);
			onChange(tmp);
		},
		[onChange, values],
	);
	const onAdd = React.useCallback(
		(index: number) => {
			const tmp = values.concat();
			if (!max || values.length < max) {
				tmp.splice(index, 0, "");
				onChange(tmp);
			}
		},
		[max, onChange, values],
	);
	return (
		<>
			{!values.length && (
				<Button onClick={() => onChange([""])} disabled={disabled}>
					追加する
				</Button>
			)}
			{values.map((value, index) => {
				const length = value
					? sentenceOptions && sentenceOptions.breakCount
						? value.length
						: value.replace(/[\n|\s]/g, "").length
					: 0;
				let style: any = {};
				if (sentenceOptions) {
					if (sentenceOptions.min && sentenceOptions.min > length) {
						style = { fontWeight: "bold", color: "blue" };
					} else if (sentenceOptions.max && sentenceOptions.max < length) {
						style = { fontWeight: "bold", color: "red" };
					}
				}
				return (
					<Row key={`choice-${index}`} style={{ margin: "10px" }}>
						<Col md={"auto"}>
							<NumberArea>
								{`${stepTitle && stepTitle.prefix ? stepTitle.prefix : ""}${(index + 1).toString().padStart(2, "0")}${
									stepTitle && stepTitle.suffix ? stepTitle.suffix : ""
								}`}
							</NumberArea>
						</Col>
						<Col md={sentenceOptions ? 8 : 9}>
							<FormControl
								key={`multipleText-${index}`}
								{...(hasRow ? { as: "textarea" } : {})}
								value={value}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChangeText(index, e.target.value)}
								disabled={disabled}
								style={disabled ? { opacity: 0.5 } : undefined}
							/>
							{errors && errors[index] && (
								<Feedback type={"invalid"} style={{ display: "block" }}>
									{getErrorMessage(index, errors)}
								</Feedback>
							)}
						</Col>
						{sentenceOptions && (
							<Col md={1} style={style}>
								{length}
							</Col>
						)}
						<Col md={2}>
							<Button
								variant={"danger"}
								onClick={() => onDelete(index)}
								tabIndex={-1}
								style={{ display: "inline", margin: "0px 5px" }}
								disabled={disabled}
							>
								×
							</Button>
							{hasAddEvent && (
								<Button
									variant={"outline-secondary"}
									onClick={() => onAdd(index)}
									tabIndex={-1}
									style={{ display: "inline", margin: "0px 5px" }}
									disabled={disabled}
								>
									+
								</Button>
							)}
						</Col>
					</Row>
				);
			})}
		</>
	);
});
