import * as React from "react";
import { Gender } from "../../../../../../server/models/shared";

export type GeneralConditionsProps = {
	gender: keyof typeof Gender;
};

export const GeneralConditions = React.memo((props: GeneralConditionsProps) => {
	const { gender } = props;
	return (
		<table>
			<tbody>
				<tr>
					<td>性別</td>
					<td>：</td>
					<td>{Gender[gender]}</td>
				</tr>
				<tr>
					<td>年齢</td>
					<td>：</td>
					<td>15～69歳</td>
				</tr>
			</tbody>
		</table>
	);
});
