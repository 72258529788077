import type { AdCopyActivityStatus } from "../../../../../../server/models/ad-copy/status";
import type { AdCopyActivityStatusAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	info: MessageInfo;
	loading: boolean;
	status?: keyof typeof AdCopyActivityStatus;
};

export const initState: State = {
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
	status: undefined,
};

export const reducer = (state: State, action: AdCopyActivityStatusAction): State => {
	switch (action.type) {
		case "loadStatus":
			return {
				...state,
				loading: false,
				status: action.payload,
			};

		case "changeStatus":
			return {
				...state,
				status: action.payload,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
