import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { strategyGroupCategoryLabels, strategyTargetGroupLabels } from "../../../../../../label/activity";
import { makeAgeLabel, makePriceLabel } from "../../../../../../server/lib/quota/concept-quota";
import {
	BeautyType,
	StrategyGroup,
	Married,
	Children,
	Occupation,
	SkinAttribute,
	Fragrance,
	Channel,
	SensitiveSkin,
	FoundationType,
	FacewashFunction,
} from "../../../../../../server/models/activity";
import { IndentArea } from "../../../pages/report/outline";

const GroupPanel = React.memo((props: { label: string; value: string }) => {
	const { label, value } = props;
	return (
		<div>
			・{label}：{value}{" "}
		</div>
	);
});

const SCategoryRow = React.memo((props: { label: string; value: string }) => {
	const { label, value } = props;
	return (
		<>
			<div>
				・{label}：{value}{" "}
			</div>
		</>
	);
});

export type StrategyConditionsProps = {
	strategyTargetGroup: StrategyGroup;
};

export const StrategyConditions = React.memo((props: StrategyConditionsProps) => {
	const { strategyTargetGroup } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const onClose = React.useCallback(() => setShow(false), []);
	const onOpen = React.useCallback(() => setShow(true), []);
	return (
		<>
			<Button onClick={onOpen} variant={"dark"} size={"sm"}>
				戦略ターゲット詳細
			</Button>
			<Modal show={show} onHide={onClose}>
				<Modal.Header>
					<Modal.Title>戦略ターゲット詳細</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<GroupPanel label={strategyTargetGroupLabels.age} value={makeAgeLabel(strategyTargetGroup.age)} />
					{strategyTargetGroup.married && !!strategyTargetGroup.married.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.married}
							value={strategyTargetGroup.married.map((target) => Married[target]).join("、")}
						/>
					)}
					{strategyTargetGroup.children && !!strategyTargetGroup.children.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.children}
							value={strategyTargetGroup.children.map((target) => Children[target]).join("、")}
						/>
					)}
					{strategyTargetGroup.occupation && !!strategyTargetGroup.occupation.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.occupation}
							value={strategyTargetGroup.occupation.map((target) => Occupation[target]).join("、")}
						/>
					)}

					{strategyTargetGroup.skinAttribute && !!strategyTargetGroup.skinAttribute.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.skinAttribute}
							value={strategyTargetGroup.skinAttribute.map((target) => SkinAttribute[target]).join("、")}
						/>
					)}
					{strategyTargetGroup.sensitiveSkin && !!strategyTargetGroup.sensitiveSkin.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.sensitiveSkin}
							value={strategyTargetGroup.sensitiveSkin.map((target) => SensitiveSkin[target]).join("、")}
						/>
					)}
					{strategyTargetGroup.fragrance && !!strategyTargetGroup.fragrance.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.fragrance}
							value={strategyTargetGroup.fragrance.map((target) => Fragrance[target]).join("、")}
						/>
					)}
					{strategyTargetGroup.foundationType && !!strategyTargetGroup.foundationType.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.foundationType}
							value={strategyTargetGroup.foundationType.map((target) => FoundationType[target]).join("、")}
						/>
					)}
					{!!strategyTargetGroup.facewashFunction && (
						<GroupPanel
							label={strategyTargetGroupLabels.facewashFunction}
							value={FacewashFunction[strategyTargetGroup.facewashFunction].toString()}
						/>
					)}
					{strategyTargetGroup.channel && !!strategyTargetGroup.channel.length && (
						<GroupPanel
							label={strategyTargetGroupLabels.channel}
							value={strategyTargetGroup.channel.map((target) => Channel[target]).join("、")}
						/>
					)}
					<hr />
					<div>カテゴリ条件</div>
					<div>以下カテゴリ間の条件を{strategyTargetGroup.categoryOrAnd}条件で設定します。</div>
					{/* <div>以下カテゴリごとに{CategoryOrAnd[strategyTargetGroup.categoryOrAnd]}となります。</div> */}
					<hr />
					{strategyTargetGroup.category.map((cate) => {
						const surveyChoice = cate.category.surveyChoice.find((sc) => sc.value === cate.surveyChoice);
						return (
							<React.Fragment key={`category-${surveyChoice.value}`}>
								<div>{surveyChoice.label}</div>
								<IndentArea indent={1}>
									{cate.price && (
										<SCategoryRow label={strategyGroupCategoryLabels.price} value={makePriceLabel(cate.price)} />
									)}
									{cate.beautyType && !!cate.beautyType.length && (
										<SCategoryRow
											label={strategyGroupCategoryLabels.beautyType}
											value={cate.beautyType.map((target) => BeautyType[target]).join("、")}
										/>
									)}
									{cate.usedBrand && !!cate.usedBrand.length && (
										<SCategoryRow label={strategyGroupCategoryLabels.usedBrand} value={cate.usedBrand.join("、")} />
									)}
									{cate.unusedBrand && !!cate.unusedBrand.length && (
										<SCategoryRow label={strategyGroupCategoryLabels.unusedBrand} value={cate.unusedBrand.join("、")} />
									)}
								</IndentArea>
							</React.Fragment>
						);
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={onClose} variant={"outline-secondary"}>
						close
					</Button>
				</Modal.Footer>
			</Modal>
			<IndentArea indent={0.7}>
				<div>
					{strategyTargetGroupLabels.age}：{makeAgeLabel(strategyTargetGroup.age)}
				</div>
				{strategyTargetGroup.category ? (
					<>
						<div>{strategyTargetGroupLabels.category}：</div>
						<IndentArea indent={0.7}>
							{strategyTargetGroup.category.map((c) => {
								const surveyChoie = c.category.surveyChoice.find((sc) => sc.value === c.surveyChoice);
								const label =
									c.category.type === "liquid-foundation" &&
									strategyTargetGroup.foundationType &&
									strategyTargetGroup.foundationType.length
										? strategyTargetGroup.foundationType
												.map((target) => FoundationType[target].toString().replace("　タイプ", ""))
												.join("・") + "　ファンデーション"
										: surveyChoie.label;
								return <div key={`strategy-category-${surveyChoie.value}`}>{label}</div>;
							})}
						</IndentArea>
					</>
				) : (
					""
				)}
			</IndentArea>
		</>
	);
});
