/* eslint-disable @typescript-eslint/naming-convention */
import { PageConfig } from "@muscat/types";
import { PointRange } from "@muscat/monitas-deliver-library";
import { FtMonitasPoint } from "./ft-monitas-point";
// import { extractWrapQuestions } from "../helpers";
import { catchCopyAttractivenessQuePrefix, catchCopyImpressionQuePrefix } from "../config";

const dummyArray = (length: number): number[] => {
	return new Array(length).fill(0);
};

const mekeExculdeQuenames = (_questionnaire: PageConfig[]): Set<string> => {
	const conceptExcludeQuenames: string[] = dummyArray(3).reduce((a, _, currentIndex) => {
		const n = currentIndex + 2;
		// テスト品別のキャッチコピー印象度、魅力度設問は１問のみカウント
		return a.concat([`${catchCopyImpressionQuePrefix}-${n}`, `${catchCopyAttractivenessQuePrefix}-${n}`]);
	}, []);
	return new Set(conceptExcludeQuenames);
};

/**
 * アンケートのポイントを計算する関数
 *  1.スクリーニングポイントは5問ごとに1point
 *  2.本調査は1問ごとに1point
 * @param {PageConfig[]} questionnaire アンケートの設定
 * @returns {PointRange} アンケートのポイント
 */
export const makeAdCopyEnquetePoint = (questionnaire: PageConfig[]): PointRange => {
	const excludeQuenames = mekeExculdeQuenames(questionnaire);
	return FtMonitasPoint.makeEnquetePoint(questionnaire, excludeQuenames);
};
