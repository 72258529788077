import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyJobStatus } from "../../../../../../server/models/ad-copy/status";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type {
	GetAdCopyJobsResponse,
	PutAdCopyJobStatusResponse,
} from "../../../../../../server/types/request/ad-copy/job";
import type { GetAdCopyEnqueteTestResponse } from "../../../../../../server/types/request/ad-copy/preview";
import type { AdCopyJobPageType } from "../../../../components/pages/ad-copy/job";
import { AdCopyJobPage } from "../../../../components/pages/ad-copy/job";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { get, makeError, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/list";
import { adCopyClientEndpoint, adCopyRoot } from "../../../../routes/adCopyEndpoint";

export const AdCopyJobListContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { info, loading, ...jobPageProps } = state;

	const navigate = useNavigate();

	const { activityId } = useParams<{ activityId: string }>();

	React.useEffect(() => {
		get<GetAdCopyJobsResponse>(replaceEndpointUrl(adCopyEndpoint.loadJob, { activityId: activityId ?? "" }))
			.then((response) => {
				dispatch({ payload: response.data, type: "loadJobs" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activityId]);

	const onEditPage = React.useCallback(
		(id: string) => {
			navigate(replaceEndpointUrl(adCopyClientEndpoint.jobCreate, { activityId: activityId ?? "", jobId: id }));
		},
		[activityId, navigate],
	);

	const onRetest = React.useCallback(
		(id: string) => {
			navigate(
				replaceEndpointUrl(adCopyClientEndpoint.jobCreateRetest, { activityId: activityId ?? "", retestJobId: id }),
			);
		},
		[activityId, navigate],
	);

	const onChangePage = React.useCallback(
		(id: string, page: AdCopyJobPageType) => {
			if (page === "enquete-test") {
				get<GetAdCopyEnqueteTestResponse>(`${adCopyEndpoint.enqueteTest}/${id}`)
					.then((response) => {
						window.open(response.data.url);
					})
					.catch((error) => {
						dispatch({ payload: makeError(error), type: "changeMessageInfo" });
					});
			} else {
				navigate(`${adCopyRoot}/${page}/${activityId}/${id}`);
			}
		},
		[activityId, navigate],
	);

	const onOutlinePage = React.useCallback(
		(id: string) => {
			navigate(replaceEndpointUrl(adCopyClientEndpoint.outline, { activityId: activityId ?? "", jobId: id }));
		},
		[activityId, navigate],
	);

	const onJobStatusChange = React.useCallback((jobId: string, status: keyof typeof AdCopyJobStatus) => {
		put<PutAdCopyJobStatusResponse>(`${adCopyEndpoint.jobStatus}/${jobId}`, { status })
			.then((response) => {
				dispatch({ payload: { ...response.data, jobId }, type: "changeJobStatus" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, []);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="処理" />

			<AdCopyJobPage
				{...jobPageProps}
				loginAccount={loginAccount}
				onChangePage={onChangePage}
				onEditPage={onEditPage}
				onJobStatusChange={onJobStatusChange}
				onOutlinePage={onOutlinePage}
				onRetest={onRetest}
			/>
		</>
	);
};
