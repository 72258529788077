import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyInvoice } from "../../../../../../server/models/ad-copy/invoice";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { AdCopyInvoiceAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	info: MessageInfo;
	invoiceInfo?: {
		account: AccountWithoutPassword;
		activity: AdCopyActivityWithBrand;
		invoice: AdCopyInvoice;
		job: AdCopyJob;
	};
	loading: boolean;
};

export const initState: State = {
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
};

export const reducer = (state: State, action: AdCopyInvoiceAction): State => {
	switch (action.type) {
		case "loadInvoice":
			return {
				...state,
				invoiceInfo: action.payload,
				loading: false,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
