import * as React from "react";
import { Row, Card, Button, Col } from "react-bootstrap";
import { NoMenuNavigationBar } from "../../parts/nomenu-navigation-bar";

export type SuccessPasswordResetPageProps = {
	changeLocation: () => void;
};
export const SuccessPasswordResetPage = (props: SuccessPasswordResetPageProps) => {
	return (
		<>
			<NoMenuNavigationBar />
			<Row>
				<Col md={{ span: 6, offset: 3 }}>
					<Card className={"m-5 pt-5 pb-5"}>
						<Card.Body>
							<div className="text-center">
								パスワードの変更が完了しました。
								<br />
								ログインページからアクセスしてください。
								<br />
								<br />
								<Button variant={"secondary"} onClick={props.changeLocation}>
									ログインページへ
								</Button>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};
